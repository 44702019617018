import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import IntlMessages from '../../util/IntlMessages';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
const ModalContentWrapper = styled.div`
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .btnCtn div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
`;
const CloneModal = props => {
  const { setModalStatus, isModalOpen, cloneTask } = { ...props };

  const handleClose = () => {
    setModalStatus(false);
  };

  const handelSave = () => {
    cloneTask();
    handleClose();
  };

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      {/* //<DialogTitle>Subscribe</DialogTitle> */}

      <DialogTitle>Clone Task</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will clone title, description, assignee, and due date, no comments will be cloned.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handelSave}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneModal;
