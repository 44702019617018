export const kanbanBoard = {
  boards: {
    lanes: [
      {
        // currentPage: 1,
        // id: 1,
        id: 'board-tasks',
        title: 'Tasks',
        cards: [],
      },
    ],
  },
  members: [],
  teamIds: {},
  tmTeams: [],
  tmTasks: [],
  filterTeamSelected: [],
};
