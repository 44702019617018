/**
 * Version 1.0
 * Vikas W
 * 07.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import Select from 'react-select';
/* Material Icons */
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import CloseIcon from '@mui/icons-material/Close';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import { styled } from '@mui/material/styles';
import { IconButton, FormControl, InputLabel } from '@mui/material';
import Typography from '@mui/material/Typography';

//TimeLine Comp
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import PopUp from './PopUp';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';

/* Tabs Config */
// import Badge from '@mui/material/Badge';
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { Box, TextField, Tab, Tabs } from '@mui/material';
// import { styled, alpha } from "@mui/material/styles";
// import { Stack } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Notes from './Notes';
import ConatctAttributes from './ConatctAttributes';
import { makeStyles } from '@material-ui/core/styles';
import ContactAttachment from './ContactAttachment';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReactAudioPlayer from 'react-audio-player';
import { Button } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

/* credit safe table */
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function TabPanelSideBar(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const readMoreCardStyles = makeStyles(theme => ({
  actionIcon: {
    cursor: 'pointer',
  },
  root: {
    width: '100%',
    // minHeight: '100px',
    padding: '3px 5px',
    fontFamily: 'Normal',
    border: '1px solid rgba(0,0,0,0.125)',
    borderRadius: '5px',
    marginTop: '3px',
    '& .readContent': {
      fontSize: '0.85rem',
      fontFamily: 'normal',
    },
    minHeight: '45px',
  },
}));
const timeLineStyle = makeStyles(theme => ({
  root: {
    flexDirection: 'column-reverse',
    paddingInline: 0,
    '&  .MuiTimelineItem-oppositeContent': {
      flex: '0.55',
    },
    '& .MuiTimelineContent': {
      padding: '6px 0 6px 16px',
    },
  },
}));
const tabStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Noraml',
    '& .MuiTab-root': {
      fontFamily: 'Normal',
      fontWeight: 600,
    },
  },
  inputStyle: {
    '& .MuiFormLabel-root': {
      fontFamily: 'Normal',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Normal',
    },
  },
}));

const cardStyles = makeStyles(theme => ({
  root: {
    fontSize: '16px',
    fontFamily: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.235,
    padding: '10px 0 20px 10px',
    display: 'flex',
    justifyContent: 'space-between',

    gap: '30px',
  },
  heading: {
    fontSize: '16px',
    fontFamily: 'normal',
    fontWeight: 'bold',
    lineHeight: 1.235,
    padding: '10px 0 20px 10px',
    display: 'flex',
    justifyContent: 'start',
  },
  selctFormStyles: {
    zIndex: 9999,
    marginRight: '5px',
    minWidth: '175px !important',
  },
}));

const ReadMore = ({ children, isAudio, audioSrc }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const [playAudio, setPlayAudio] = useState(false);
  const [audioData, setAudioData] = useState('');
  const dispatch = useDispatch();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const ctx = new AudioContext();
  const getAudioFile = CallId => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`CloudTalk/GetCallRecording?CallId=${CallId}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          data.arrayBuffer();
          // setAudioData(data.arrayBuffer())
          // setPlayAudio(true)

          // setIsLabelLoaded(true);
        })
        .then(arrayBuffer => ctx.decodeAudioData(arrayBuffer))
        .then(decodeAudioData => {
          setAudioData(decodeAudioData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  return (
    <div>
      {isAudio ? (
        <div>
          <IconButton onClick={() => getAudioFile(audioSrc)}>
            <PlayCircleIcon />
          </IconButton>
          {/* {playAudio ?  */}
          <div className="w-100 d-flex">
            <ReactAudioPlayer
              className="mt-2"
              style={{ height: '40px' }}
              src={audioData}
              autoPlay={false}
              controls={true}></ReactAudioPlayer>
          </div>
          {/* :""} */}
        </div>
      ) : (
        <div className={readMoreCardStyles().root}>
          <Typography sx={{ fontFamily: 'normal', fontSize: '0.7rem' }}>Email Content :</Typography>
          <Typography component="div" className="readContent">
            {text && text.length > 100 ? (
              <p>
                {isReadMore ? text.slice(0, 100) : text}

                <span onClick={toggleReadMore} className="read-or-hide">
                  {isReadMore ? '...more' : 'less'}
                </span>
              </p>
            ) : (
              <p>{text}</p>
            )}
          </Typography>
        </div>
      )}
    </div>
  );
};
function a11yPropsSideBar(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const Sidebar = ({
  infoSidebar,
  infoSidebarData,
  notesData,
  setNotesData,
  emailCommunicationData,
  credisafeCompanyData,
  setMailData,
  OpenMailDialog,
  getContactHandler,
  Teams,
  selectedTeam,
  SelectedTeamMembers,
  sendEmailNotification,
  handleCheckCredits,
  setCreditSafeCompanydata,
  creditSafeCompanyNameData,
  continuePollingRef,
}) => {
  const dispatch = useDispatch();
  const [sValue, setSValue] = React.useState(1);
  const data = {
    crmId: '',
    crmStageName: 'new',
    crmContactTitle: '',
    crmContactFirst: '',
    crmContactMiddle: '',
    crmContactLast: '',
    crmLeadReferralSource: '',
    crmDateOfInitialContact: '',
    crmTitle: '',
    crmCompany: '',
    crmIndustry: '',
    crmAddressLine1: '',
    crmAddressLine2: '',
    crmAddressCity: '',
    crmAddressTown: '',
    crmAddressZip: '',
    crmAddressCountry: '',
    crmContactPhone: '',
    crmContactEmail: '',
    crmContactStatus: '',
    crmContactWebsite: '',
    crmLinkedInProfile: '',
    crmBackgroundInfo: '',
    crmSalesRep: 1,
    crmRating: '',
    crmProjectType: '',
    crmProjectDescription: '',
    crmProposalDueDate: '',
    crmBudget: 0,
    crmDeliverable: 0,
    crmDoNotContact: 0,
    crmDoNotEmail: 0,
    CrmTeamId: 0,
  };
  const [dataO, setDataO] = useState({});
  const [stages, setStages] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [obj, setObject] = useState(Object.assign({}, infoSidebarData ? { ...infoSidebarData } : { ...data }));
  const [keyValuePair, setKeyValuePair] = useState({});
  const [attachmentData, setAttachmentData] = useState([]);
  const [callData, setCallData] = useState([]);
  const [communicationData, setCommunicationData] = useState([]);

  //credit report modal

  const [isCrediSafeModalOpen, setIsCrediSafeModalOpen] = useState(false);

  const textFieldStyles = makeStyles(theme => ({
    root: {
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
      '& .MuiTextField-root': {
        // background:'black'
      },

      '& .MuiFormLabel-root.Mui-disabled': {
        color: 'rgba(0,0,0,0.6)',
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
        '-webkit-text-fill-color': 'rgba(0,0,0,0.75)',
      },
    },
  }));

  const getTeamMembers = () => {
    let currTeamMembers = SelectedTeamMembers.map(c => ({ value: c.crmUserId, label: c.Email, CrmUserId: c.crmUserId }));
    setTeamMembers(currTeamMembers);
  };
  useEffect(() => {
    if (infoSidebarData) {
      getStages();
      getTeamMembers();
      setDataO(infoSidebarData);
      setObject(Object.assign({}, infoSidebarData ? { ...infoSidebarData } : { ...data }));

      if (infoSidebarData != []) {
        getAttData();
        getContactAttachment();
        // getCallData();
      }
    }
  }, [infoSidebarData]);

  const CardStyles = cardStyles();
  // useEffect(() => {
  //   // setCommunicationData(mergeTwoByDate(emailCommunicationData,callData));
  //   if (infoSidebarData.crmId) {
  //     let result = mergeTwoByDate(emailCommunicationData, callData);
  //     console.log(result);
  //     setCommunicationData(result);
  //   }
  // }, [emailCommunicationData]);

  /* UTILS */
  function mergeTwoByDate(arr1, arr2) {
    let tempResult = [...arr1, ...arr2];
    // return result.sort((a,b) => a-b);
    let result = tempResult.map(item => {
      let newItem = item;
      if (item.EmailBody) {
        newItem = { ...item, startDate: item.TimeStart };
        return newItem;
      } else {
        newItem = { ...item, startDate: item.Cdr.started_at };
        return newItem;
      }
    });

    return result.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }

  /* Call */
  const getCallData = args => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`CloudTalk/GetCallHistory?Page=${1}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data) {
            let calldata = data.responseData.data.slice(0, 10);
            // console.log(calldata)
            setCallData(calldata);
          }

          // setIsLabelLoaded(true);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  /* Call End */

  /**  Contact Attribute   **/

  function getAttData() {
    if (infoSidebarData && infoSidebarData.CrmTeamId)
      getContactAttributesLabel(infoSidebarData, data => getContactAttributes(infoSidebarData, data));
  }

  function getContactAttributes(args, parm) {
    if (args.crmId === undefined) return;
    // setIsAttributeLoaded(false);
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`Contacts/GetContactAttributes/${args.crmId}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          let AttrData = data.reduce((a, v) => ({ ...a, [v.LabelId]: v }), {});
          if (parm) {
            let keyValueAtt = Object.entries(parm).reduce(
              (a, v) => ({
                ...a,
                [v[0]]: {
                  labelInfo: v,
                  attrInfo: AttrData[parseInt(v[0])],
                  labelValue: AttrData[parseInt(v[0])] ? AttrData[parseInt(v[0])].LabelValue : '',
                  isNewAttribute: AttrData[parseInt(v[0])] ? false : true,
                },
              }),
              {},
            );
            setKeyValuePair(keyValueAtt);
          }

          // setIsAttributeLoaded(true);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  function getContactAttributesLabel(args, fnc) {
    // setIsLoadingComplete({['label']:false})
    // setIsLabelLoaded(false);
    let url = 'Contacts/GetAttributeLabels/' + infoSidebarData.CrmTeamId;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          let labelData = data.reduce((a, v) => ({ ...a, [v.Id]: v.LabelName }), {});

          if (fnc) {
            fnc(labelData);
          }
          // setIsLabelLoaded(true);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  /**  inline edit   **/
  function onChange(args) {
    let newState = { ...obj };
    newState[args.target.name] = args.target.value;
    setObject(newState);
    // setObject({ [args.target.name]: args.target.value });
  }

  function onInputChangeHandler(args) {
    let InputVal = args.target.value;
    let key = args.target.name;
    let editedData = [];
    if (
      dataO[key]
        .trim()
        .toString()
        .toLowerCase() !==
      InputVal.trim()
        .toString()
        .toLowerCase()
    ) {
      editedData.push({
        TableName: 'CRM_Contacts',
        ColumnName: key,
        NewValue: InputVal,
        PrimaryKeyValue: dataO.crmId,
        crmTeamId: dataO.CrmTeamId,
      });

      dispatch({ type: FETCH_START });
      customAxios
        .post('/CRM/EditMultipleContacts', editedData, { headers: authHeader() })
        .then(e => {
          dispatch({ type: FETCH_SUCCESS });

          let newState = { ...dataO };
          newState[key] = InputVal;
          setDataO(newState);

          editedData = [];
          getContactHandler(dataO.crmId);
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  }

  const handleChangeS = (event, newValue) => {
    setSValue(newValue);
  };

  /** Get All Stages **/
  const getStages = () => {
    if (!selectedTeam.value) return;
    let url = 'CRM/Get_CRM_Stages/' + selectedTeam.value;
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            var stage = data.map(c => ({ value: c.StageId, label: c.crmStageName, CrmTeamId: c.CrmTeamId }));
            setStages(stage);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const onStageChange = args => {
    let data = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'crmStageId',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${dataO.crmId}`,
        crmTeamId: dataO.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', data, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });

        let newState = { ...obj };
        newState['crmStageName'] = args.label;
        newState['crmStageId'] = args.value;

        getContactHandler(dataO.crmId);

        setObject(newState);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const onSalesRepChange = args => {
    console.log(args);
    let data = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'crmSalesRep',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${dataO.crmId}`,
        crmTeamId: dataO.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', data, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });

        let newState = { ...obj };
        newState['crmSalesRep'] = args.value;
        setObject(newState);
        getContactHandler(dataO.crmId);
        let NotiMessage = `You are assigned as the Sales Rep of "${infoSidebarData.crmContactFirst} ${infoSidebarData.crmContactLast}"`;
        sendNotification(NotiMessage, args.value);
        sendEmailNotification([args.label], NotiMessage);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const sendNotification = (message, toUser) => {
    let notification = {
      UserIdTo: toUser,
      ActivityId: null,
      NotifyMessage: message,
      FunctionalityId: null,
      TeamId: infoSidebarData.CrmTeamId,
      IsClicked: 0,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Notifications/AddNotification', notification, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const onTeamChange = args => {
    let data = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'CRM_TeamId',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${dataO.crmId}`,
        crmTeamId: dataO.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', data, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });

        let newState = { ...obj };
        newState['CRM_TeamId'] = args.value;
        setObject(newState);
        getContactHandler(dataO.crmId);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /**   Attachment   **/
  const getContactAttachment = () => {
    if (!infoSidebarData.crmId) return;
    let contactObj = {
      crmContactId: infoSidebarData.crmId,
      crmTeamId: infoSidebarData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`ContactAttachment/GetContactAttachments`, contactObj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data) {
            setAttachmentData(data);
          }

          // setIsLabelLoaded(true);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  /* Credit Report Data */

  const columns = [
    { id: 'name', label: 'Company Name', minWidth: 170, align: 'left' },
    { id: 'regNo', label: 'Registration Number', minWidth: 100, align: 'left' },

    {
      id: 'type',
      label: 'Company Type',
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 80,
      align: 'center',
    },
  ];
  const openCrediSafeModal = () => {
    setIsCrediSafeModalOpen(true);
  };
  const closeCreditSafeModal = () => {
    setIsCrediSafeModalOpen(false);
  };
  const correctCredisafeCompany = regNo => {
    handleCheckCredits(regNo);
    closeCreditSafeModal();
  };

  return (
    <div className="app-wrapper">
      <SidebarComponent
        id="default1"
        width="700px"
        position="Right"
        ref={infoSidebar}
        z-zIndex="1000"
        className="info_sidebar"
        created={() => infoSidebar.current.hide()}
        style={{ fontFamily: 'Normal', visibility: 'hidden' }}
        enableGestures={false}>
        <div>
          <IconButton aria-label="Close" onClick={() => infoSidebar.current.toggle()} style={{ padding: '0.25em' }}>
            <CloseIcon
              onClick={() => {
                setCreditSafeCompanydata({});
                continuePollingRef.current = true;
              }}
            />
          </IconButton>
        </div>
        <div className="list-group" style={{ fontSize: '1rem', color: 'black !important' }}>
          <Box sx={{ width: '100%' }} className={tabStyles().inputStyle}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs className={tabStyles().root} value={sValue} onChange={handleChangeS}>
                <Tab label="Notes" {...a11yPropsSideBar(0)} wrapped />
                <Tab label="General" {...a11yPropsSideBar(1)} wrapped />
                <Tab label="Communication" {...a11yPropsSideBar(2)} wrapped />
                <Tab label="Credit Report " {...a11yPropsSideBar(3)} wrapped />
                <Tab label="Custom " {...a11yPropsSideBar(4)} wrapped />
                <Tab label="Attachments" {...a11yPropsSideBar(5)} wrapped />
              </Tabs>
            </Box>
            <TabPanelSideBar value={sValue} index={0}>
              <div className="w-100 d-flex justify-content-center pt-4 ">
                <Notes
                  userData={infoSidebarData}
                  notesData={notesData}
                  addNewNotes={setNotesData}
                  getContactHandler={getContactHandler}
                />
              </div>
            </TabPanelSideBar>
            <TabPanelSideBar value={sValue} index={1}>
              <div>
                <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
                  <CardContent component="div">
                    <div className={CardStyles.heading}> Prospect Info </div>
                    <div className={CardStyles.root}>
                      <FormControl className={CardStyles.selctFormStyles} size="small">
                        <p style={{ fontSize: 12, marginBottom: 5 }}>SalesRep</p>
                        <Select
                          style={{ zIndex: 9999, marginRight: '5px' }}
                          placeholder={'Select'}
                          options={teamMembers}
                          value={teamMembers.find(c => c.value == obj.crmSalesRep)}
                          onChange={onSalesRepChange}
                        />
                      </FormControl>
                      <FormControl className={CardStyles.selctFormStyles} size="small">
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Stage Name</p>
                        <Select
                          style={{ zIndex: 9999, marginRight: '5px' }}
                          placeholder={'Select Stage..'}
                          options={stages.filter(stage => stage.CrmTeamId == selectedTeam.value)}
                          value={stages.find(c => c.label == obj.crmStageName)}
                          onChange={onStageChange}
                        />
                      </FormControl>
                      <FormControl className={CardStyles.selctFormStyles} size="small">
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Team Name</p>
                        <Select
                          style={{ zIndex: 9999, marginRight: '5px' }}
                          placeholder={'Select Team..'}
                          options={Teams.filter(team => team.value == selectedTeam.value)}
                          value={Teams.find(c => c.value == obj.CrmTeamId)}
                          onChange={onTeamChange}
                        />
                      </FormControl>
                    </div>

                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off">
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="First Name"
                            id="crmContactFirst"
                            name="crmContactFirst"
                            type="text"
                            value={obj.crmContactFirst || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={obj.crmContactFirst}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Middle Name"
                            id="crmContactMiddle"
                            name="crmContactMiddle"
                            type="text"
                            value={obj.crmContactMiddle || ' '}
                            onChange={onChange}
                            onBlur={onInputChangeHandler || ''}
                            // defaultValue={`${infoSidebarData.crmContactMiddle}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Last Name"
                            id="crmContactLast"
                            name="crmContactLast"
                            type="text"
                            value={obj.crmContactLast || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactLast}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Title"
                            id="crmContactTitle"
                            name="crmContactTitle"
                            type="text"
                            value={obj.crmContactTitle || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactTitle}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Company"
                            id="crmCompany"
                            name="crmCompany"
                            type="text"
                            value={obj.crmCompany || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmCompany}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Email"
                            id="crmContactEmail"
                            name="crmContactEmail"
                            type="text"
                            value={obj.crmContactEmail || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Phone"
                            id="crmContactPhone"
                            name="crmContactPhone"
                            type="text"
                            value={obj.crmContactPhone || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactPhone}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Linkedin"
                            id="crmLinkedInProfile"
                            name="crmLinkedInProfile"
                            type="text"
                            value={obj.crmLinkedInProfile || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmLinkedInProfile}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Website"
                            id="crmContactWebsite"
                            name="crmContactWebsite"
                            type="text"
                            value={obj.crmContactWebsite || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactWebsite}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Address Line 1"
                            id="crmAddressLine1"
                            name="crmAddressLine1"
                            type="text"
                            value={obj.crmAddressLine1 || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressLine1}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Address Line 2"
                            id="crmAddressLine2"
                            name="crmAddressLine2"
                            type="text"
                            value={obj.crmAddressLine2 || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressLine2}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Town"
                            id="crmAddressTown"
                            name="crmAddressTown"
                            type="text"
                            value={obj.crmAddressTown || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressTown}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="City"
                            id="crmAddressCity"
                            name="crmAddressCity"
                            type="text"
                            value={obj.crmAddressCity || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressCity}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Country"
                            id="crmAddressCountry"
                            name="crmAddressCountry"
                            type="text"
                            value={obj.crmAddressCountry || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressCountry}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Zipcode"
                            id="crmAddressZip"
                            name="crmAddressZip"
                            type="text"
                            value={obj.crmAddressZip || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmAddressZip}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <TextField
                            label="Background Info"
                            id="crmBackgroundInfo"
                            name="crmBackgroundInfo"
                            type="text"
                            value={obj.crmBackgroundInfo || ''}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmBackgroundInfo}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '99%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>

                <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
                  <CardContent component="div">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}>
                      <div
                        style={{
                          fontSize: '16px',
                          fontFamily: 'normal',
                          fontWeight: 'bold',
                          lineHeight: 1.235,
                          padding: '10px',
                        }}>
                        Company Overview
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        font="small"
                        className="ml-5"
                        onClick={openCrediSafeModal}>
                        Request Credit Report
                      </Button>
                    </div>
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                      className={textFieldStyles().root}>
                      <div className="row">
                        <div className="col-md-6">
                          <TextField
                            label="Company"
                            id="credisafeCompanyData"
                            name="credisafeCompanyData"
                            type="text"
                            value={credisafeCompanyData.name || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            label="Company Type"
                            id="credisafeCompanyDataType"
                            name="credisafeCompanyDataType"
                            type="text"
                            value={credisafeCompanyData.type || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      {credisafeCompanyData != undefined || credisafeCompanyData != '' ? (
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="Company Rating"
                              id="credisafeCompanyDatacreditRating"
                              name="credisafeCompanyDatacreditRating"
                              type="text"
                              value={credisafeCompanyData.creditRating || ' '}
                              // onChange={onChange}
                              size="small"
                              disabled
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 12 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Company address"
                              id="credisafeCompanyDataAddress"
                              name="credisafeCompanyDataAddress"
                              type="text"
                              value={credisafeCompanyData.address === undefined ? '' : credisafeCompanyData.address}
                              // onChange={onChange}
                              disabled
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 12 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Company Background"
                              id="credisafeCompanyDataTypeBackground"
                              name="credisafeCompanyDataTypeBackground"
                              type="text"
                              value={credisafeCompanyData?.crmBackgroundInfo?.description || ''}
                              // onChange={onChange}
                              size="small"
                              disabled
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 12 } }}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Company Register No."
                            id="credisafeCompanyDataregNo"
                            name="credisafeCompanyDataregNo"
                            type="text"
                            value={credisafeCompanyData.regNo || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Company Safe Number"
                            id="credisafeCompanyDatasafeNo"
                            name="credisafeCompanyDatasafeNo"
                            type="text"
                            value={credisafeCompanyData.safeNo || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Status"
                            id="credisafeCompanyDataStatus"
                            name="credisafeCompanyDataStatus"
                            type="text"
                            value={credisafeCompanyData.status || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Status Description"
                            id="credisafeCompanyDataStatusDescription"
                            name="credisafeCompanyDataStatusDescription"
                            type="text"
                            value={credisafeCompanyData.statusDescription || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>

                <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
                  <CardContent component="div">
                    <div
                      style={{
                        fontSize: '16px',
                        fontFamily: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 1.235,
                        padding: '10px 0 20px 10px',
                      }}>
                      Company Credit Report
                    </div>
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                      className={textFieldStyles().root}>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Credit Rating"
                            id="CreditRating"
                            name="CreditRating"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.currentCreditRating?.commonValue || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Credit Description"
                            id="CreditDescription"
                            name="CreditDescription"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.currentCreditRating?.commonDescription || ''}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Credit Description"
                            id="CreditDescription"
                            name="CreditDescription"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.currentCreditRating?.commonDescription || ''}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Credit Limit"
                            id="CreditLimit"
                            name="CreditLimit"
                            type="text"
                            value={` ${credisafeCompanyData?.creditReport?.currentCreditRating?.creditLimit?.value ||
                              ''} ${credisafeCompanyData?.creditReport?.currentCreditRating?.creditLimit?.currency || ''}`}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="POD Value"
                            id="PODValue"
                            name="PODValue"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.currentCreditRating?.pod || ''}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Provider Value"
                            id="providerValue"
                            name="providerValue"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.currentCreditRating?.providerValue?.value || ''}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Contract Limit"
                            id="CLimit"
                            name="CLimit"
                            type="text"
                            value={
                              credisafeCompanyData?.creditReport?.currentContractLimit == undefined
                                ? ''
                                : `${credisafeCompanyData.creditReport.currentContractLimit.value ||
                                    ''} ${credisafeCompanyData.creditReport.currentContractLimit.currency || ''}`
                            }
                            // onChange={onChange}
                            size="small"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Last Report Update Date"
                            id="LRUD"
                            name="LRUD"
                            type="text"
                            value={credisafeCompanyData?.creditReport?.latestRatingChangeDate || ' '}
                            // onChange={onChange}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </TabPanelSideBar>
            <TabPanelSideBar value={sValue} index={2}>
              <div>
                <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
                  <CardContent component="div">
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      className={textFieldStyles().root}
                      noValidate
                      autoComplete="off">
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Email"
                            id="crmContactEmail"
                            name="crmContactEmail"
                            type="text"
                            value={obj.crmContactEmail}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={obj.crmContactEmail}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                          <button
                            className="normalBtn"
                            style={{ position: 'absolute', top: '12px', right: '20px' }}
                            onClick={e => {
                              e.preventDefault();

                              setMailData(infoSidebarData);

                              OpenMailDialog();
                            }}>
                            <EmailRoundedIcon />
                          </button>
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Phone Number"
                            id="crmContactPhone"
                            name="crmContactPhone"
                            type="text"
                            value={obj.crmContactPhone}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactPhone}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Linkedin"
                            id="crmLinkedInProfile"
                            name="crmLinkedInProfile"
                            type="text"
                            value={obj.crmLinkedInProfile}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmLinkedInProfile}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Website"
                            id="crmContactWebsite"
                            name="crmContactWebsite"
                            type="text"
                            value={obj.crmContactWebsite}
                            onChange={onChange}
                            onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmContactWebsite}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="No. Email Sent"
                            id="emailCommunicationData"
                            name="emailCommunicationData"
                            type="text"
                            value={emailCommunicationData.length}
                            disabled
                            // defaultValue={`${infoSidebarData.emailCommunicationData}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            label="Contact Status"
                            id="crmContactStatus"
                            name="crmContactStatus"
                            type="text"
                            value={'Active'}
                            disabled
                            // onChange={onChange}CustomeDatePicker
                            // onBlur={onInputChangeHandler}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <TextField
                            label="Reply Received"
                            id="crmReplyReceived"
                            name="crmReplyReceived"
                            type="text"
                            value={'None'}
                            disabled
                            // onChange={onChange}
                            // onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmReplyReceived}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                        {/* <div className="col-md-4">
                          <TextField
                            label="Email Sent Content "
                            id="crmEmailSentContent"
                            name="crmEmailSentContent"
                            type="text"
                            disabled
                            value={
                              emailCommunicationData.length > 0
                                ? emailCommunicationData[emailCommunicationData.length - 1]['EmailBody'].substring(0, 150)
                                : ''
                            }
                            // onChange={onChange}
                            // onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmEmailSentContent}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div> */}
                        <div className="col-md-4">
                          <TextField
                            label="Last Communication Date"
                            id="crmLastCommunicationDate"
                            name="crmLastCommunicationDate"
                            type="text"
                            value={
                              emailCommunicationData.length > 0
                                ? emailCommunicationData[emailCommunicationData.length - 1]['TimeStart'].substring(0, 10)
                                : ''
                            }
                            // onChange={onChange}
                            // onBlur={onInputChangeHandler}
                            // defaultValue={`${infoSidebarData.crmLastCommunicationDate}`}
                            disabled
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          />
                        </div>
                      </div>
                    </Box>
                  </CardContent>
                </Card>

                <Timeline
                  className={timeLineStyle().root}
                  align="left"
                  style={{ display: 'flex', flexDirection: 'column-reverse', paddingRight: 0 }}>
                  <TimelineItem>
                    <TimelineOppositeContent></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot>
                        <FastfoodIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 1 }}>
                      <Typography component="span">Start</Typography>
                    </TimelineContent>
                  </TimelineItem>

                  {/* {communicationData.map((item,index)=>{
                     if(item.EmailBody)
                     {
                      return ( <TimelineItem key={index}>
                        <TimelineOppositeContent sx={{ py: '12px', px: 1 }}>
                          <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.75rem' }}>
                            Delivered
                          </Typography>
                          <br />
                          <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.85rem' }}>
                            {item.TimeStart.substring(0, 10)} - {item.TimeStart.substring(11)}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              border: '1px solid rgba(0,0,0,0.175)',
                              background: 'rgba(125,228,31,1)',
                              cursor: 'pointer',
                            }}>
                            <PopUp displayContent={<EmailRoundedIcon style={{ color: '#fff' }} />} popupContent={item} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 1 }}>
                          <ReadMore isAudio={false}>{item.EmailBody}</ReadMore>
                        </TimelineContent>
                      </TimelineItem>)
                     }else {
                      return (
                      <TimelineItem key={index}>
                      <TimelineOppositeContent sx={{ py: '12px', px: 1 }}>
                        <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.75rem' }}>
                       { item.Cdr.is_redirected != '0' ? "Call Missed" :"Connected" }
                        </Typography>
                        <br />
                        <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.85rem' }}>
                          {item.Cdr.started_at.substring(0, 10)} - {item.Cdr.started_at.substring(11, 19)}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                          style={{
                            border: '1px solid rgba(0,0,0,0.175)',
                            background: 'rgba(125,228,31,1)',
                            cursor: 'pointer',
                          }}>
                          <PopUp
                            displayContent={<CallIcon style={{ color: '#fff' }} />}
                            popupContent={item.Cdr}
                          />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: '12px', px: 1 }}>
                        <ReadMore isAudio={true} audioSrc={item.Cdr.id}></ReadMore>
                      </TimelineContent>
                    </TimelineItem>
                     )
                    }})} */}
                  {emailCommunicationData.map((item, index) => {
                    return (
                      <TimelineItem key={index}>
                        <TimelineOppositeContent sx={{ py: '12px', px: 1 }}>
                          <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.75rem' }}>
                            Delivered
                          </Typography>
                          <br />
                          <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.85rem' }}>
                            {item.TimeStart.substring(0, 10)} - {item.TimeStart.substring(11)}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            style={{
                              border: '1px solid rgba(0,0,0,0.175)',
                              background: 'rgba(125,228,31,1)',
                              cursor: 'pointer',
                            }}>
                            <PopUp displayContent={<EmailRoundedIcon style={{ color: '#fff' }} />} popupContent={item} />
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 1 }}>
                          <ReadMore isAudio={false}>{item.EmailBody}</ReadMore>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                </Timeline>
              </div>
            </TabPanelSideBar>
            <TabPanelSideBar value={sValue} index={3}>
              coming soon...
            </TabPanelSideBar>
            <TabPanelSideBar value={sValue} index={4}>
              <ConatctAttributes
                contactInfo={infoSidebarData}
                keyValuePair={keyValuePair}
                setKeyValuePair={setKeyValuePair}
                getAttData={getAttData}
              />
            </TabPanelSideBar>
            <TabPanelSideBar value={sValue} index={5}>
              <ContactAttachment
                info={infoSidebarData}
                attachmentData={attachmentData}
                setAttachmentData={setAttachmentData}
                getContactAttachment={getContactAttachment}
              />
            </TabPanelSideBar>
          </Box>
        </div>
      </SidebarComponent>

      <BootstrapDialog
        onClose={closeCreditSafeModal}
        aria-labelledby="customized-dialog-title"
        open={isCrediSafeModalOpen}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Select Company
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeCreditSafeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditSafeCompanyNameData.map(item => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={item.id} id={item.id}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.regNo}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              onClick={() => correctCredisafeCompany(item.id)}
                              color="primary"
                              variant="contained"
                              size="small"
                              font="small"
                              className="ml-5">
                              Select
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Sidebar;
