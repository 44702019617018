import React from 'react';
import { WbSunnyIcon, Folder, Business, Money } from '@material-ui/icons';
import HoverInfoCard from '../../../../@jumbo/components/Common/HoverInfoCard';
// import { MoneyIcon } from '@mui/icons-material/Money';
// import WbSunnyIcon from '@material-ui/icons/WbSunny';

const IdeasWidget = ({ number, subTitle, icon, backgroundColor, linkOnArrow }) => {
  return (
    <HoverInfoCard
      icon={icon}
      backgroundColor={backgroundColor}
      title={number}
      subTitle={subTitle}
      linkOnArrow={linkOnArrow}
    />
  );
};

export default IdeasWidget;
