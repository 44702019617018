import React, { useEffect, useState, useCallback, useRef } from 'react';
import { usePlaidLink } from 'react-plaid-link';
//ID //long
//UserId // long
//AccountName //string
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  //  INIT_URL,
  //  SIGNOUT_USER_SUCCESS,
  //  USER_DATA,
  //  USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import Modal from '@mui/material/Modal';
import authHeader from '../../../../services/auth/jwt/auth-header';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import ContainerHeader from '../../components/ContainerHeader/index';
import IconButton from '@mui/material/IconButton';
import IntlMessages from '../../util/IntlMessages';
import TextField from '@mui/material/TextField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
/* Tabs Config */
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tooltip from '@mui/material/Tooltip';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { CSVLink } from 'react-csv';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SyncIcon from '@mui/icons-material/Sync';
import Select from 'react-select';
import { FormControl } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import TaskFilterDialog from './TaskFilterDialog';

import ExpenseModal from './ExpenseModal';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SalesModal from './SalesModal';
import CreateExpense from 'routes/Pages/Accounting/Expenses/CreateExpense';
import ImportExcel from './ImportExcel';

const viewModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  minHeight: 150,
  bgcolor: 'background.paper',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 22,
  boxShadow: 24,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const useTabStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));
let data = [
  {
    ID: 1,
    UserId: 2,
    AccountName: 'test',
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

const headCells = [
  {
    id: 'Account Name',
    numeric: false,
    disablePadding: false,
    label: 'Account Name',
    maxWidth: 300,
    width: 300,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 150,
  },
];
const headCells2 = [
  {
    id: 'Merchant Name',
    numeric: false,
    disablePadding: false,
    label: 'Merchant Name',
    minWidth: 300,
  },
  {
    id: 'Transaction Type',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Type',
    minWidth: 200,
  },
  {
    id: 'Currency Code',
    numeric: false,
    disablePadding: false,
    label: 'Currency Code',
    minWidth: 200,
  },
  {
    id: 'Amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
    minWidth: 200,
  },
  {
    id: 'Transaction Date',
    numeric: false,
    disablePadding: false,
    label: 'Transaction Date',
    minWidth: 200,
  },
  {
    id: 'Reconciliation',
    numeric: false,
    disablePadding: false,
    label: 'Reconciliation',
    minWidth: 200,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 200,
  },
];
const EditableTableRow = ({
  row,
  classes,
  currEdit,
  setCurrEdit,
  editName,
  deleteAccount,
  getTransaction,
  setSelectedAcc,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const ipRef = useRef(null);

  const startEditing = () => {
    // console.log(row.ID);

    setCurrEdit(row.ID);
    setIsEdit(true);
  };
  useEffect(() => {
    if (currEdit != row.ID) {
      setIsEdit(false);
    }
  }, [currEdit]);

  const setNewBankName = () => {
    let newName = ipRef.current.value;
    // console.log(newName)

    if (row.AccountName != newName.trim() && newName.trim().length > 0) {
      // console.log('edit name');
      let editObj = {
        BankId: parseInt(row.ID),
        NewName: newName,
      };
      editName(editObj);
    } else {
      setIsEdit(false);
    }
  };
  const deleteBankAccount = () => {
    deleteAccount(row.ID);
  };
  const refreshTransaction = () => {
    setSelectedAcc(row);
    getTransaction(row.ID);
  };
  return (
    <TableRow
      hover
      // onClick={event => console.log('row Clicked')}
      style={{ paddingBottom: isEdit ? 0 : '16px' }}
      // role="checkbox"
      // aria-checked={isItemSelected}

      // selected={isItemSelected}
    >
      <TableCell style={{ width: '700px' }} align="left">
        {isEdit ? (
          <TextField
            inputRef={ipRef}
            defaultValue={row.AccountName}
            size="small"
            variant="standard"
            focused
            sx={{ width: '100%', height: '25px' }}
          />
        ) : (
          row.AccountName
        )}
      </TableCell>

      <TableCell align="left">
        <Tooltip title={isEdit ? 'Save Account Name' : 'Edit Account Name'}>
          {isEdit ? (
            <SaveIcon className={classes.actionIcon} onClick={() => setNewBankName()} />
          ) : (
            <EditIcon className={classes.actionIcon} onClick={() => startEditing()} />
          )}
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Delete Account">
          <DeleteOutlineIcon className={classes.actionIcon} onClick={() => deleteBankAccount()} />
        </Tooltip>
        &nbsp;&nbsp;
        <Tooltip title="Refresh Transactions">
          <SyncIcon className={classes.actionIcon} onClick={() => refreshTransaction()} />
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};
function EnhancedTableHead({ classes, col, order, orderBy, onRequestSort, numSelected }) {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {col.map(headCell => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}

            padding={headCell.disablePadding ? 'none' : 'normal'}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};

const menuStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px ',
      '& div': {
        marginRight: '8px',
        fontSize: '0.95rem',
      },
    },
    top: '40px !important',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
const BankStatement = props => {
  const dispatch = useDispatch();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [data, setData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [linkToken, setLinkToken] = useState(null);
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState(0);
  const [currEdit, setCurrEdit] = useState(null);
  const cusclass = menuStyles();
  const downloadCsv = useRef(null);
  const [searchKey, setSearchKey] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emptyRows, setEmptyRows] = useState(10);
  const [filterSelectedData, setFilterSelectedData] = useState([]);
  const inputRef = useRef(null);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [excelUploadModal, setExcelUploadModal] = useState(false);
  // const [openViewModal, setOpenViewModal] = useState(false);
  // const [modalViewData, setModalViewData] = useState({});
  // const [isCreditted, setIsCreditted] = useState(false);
  // const [flagReconcile, setFlagReconcile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSalesModal, setIsSalesModal] = useState(false);

  const [openActionModal, setOpenActionModal] = useState(false);
  const handleOpenActionModal = () => setOpenActionModal(true);
  const handleCloseActionModal = () => setOpenActionModal(false);

  //add expense

  const [modal, setModal] = React.useState(false);
  const [addNewExpense, setAddNewExpense] = useState(false);
  const [expenseOpen, setExpenseOpen] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [details, setDetails] = useState([]);

  const excelUploadModaltoggle = () => {
    setExcelUploadModal(!excelUploadModal);
  };

  // const GetExpenses = () => {
  //   dispatch({ type: FETCH_START });
  //   try {
  //     customAxios
  //       .get('Expense/GetExpenses', { headers: authHeader() })
  //       .then(({ data }) => {
  //         if (data) {
  //           setCustomData(data);
  //           dispatch({ type: FETCH_SUCCESS });
  //         } else {
  //           dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //         }
  //       })
  //       .catch(error => {});
  //   } catch (e) {
  //     dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //   }
  // };

  // React.useEffect(() => {
  //   GetExpenses();
  // }, []);

  // const onClientsClose = () => {
  //   setExpenseOpen(false);
  //   GetExpenses();
  // };
  // const toggle = () => {
  //   setModal(!modal);
  //   setDetails([]);
  // };

  let refreshed = true;
  const toggles = () => {
    handleClickData();
  };
  const actionsList = [
    {
      onClick: toggles,
      label: 'Export Transaction (CSV/Excel)',
    },
    {
      onClick: excelUploadModaltoggle,
      label: 'Import Transaction (CSV/Excel)',
    },
  ];

  const handleMenuOpen = event => {
    // setOpenViewModal(true);
    setAnchorEl(event.currentTarget);
    // setModalViewData(data);
  };
  const openMenu = Boolean(anchorEl);

  const handleMenuClose = () => {
    // setOpenViewModal(false)
    setAnchorEl(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.ClientName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const UpdateEmptyRows = len => {
    let emptyRowsNo = page > 0 || transactions.length == 0 ? Math.max(0, (1 + page) * rowsPerPage - len) : 0;
    setEmptyRows(emptyRowsNo);
  };
  const handleClickData = () => {
    downloadCsv.current.link.click();
  };
  const [selectedAcc, setSelectedAcc] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const TabWithCount = ({ children, count }) => {
    return (
      <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', lineHeight: 'normal' }}>
        {count ? (
          <Typography component="div" variant="body2" sx={{ marginLeft: '0.5rem' }}>
            {count}
          </Typography>
        ) : (
          '0'
        )}
        <Typography component="div" style={{ textTransform: 'capitalize' }}>
          {children}
        </Typography>
      </Box>
    );
  };

  const getBankAccounts = () => {
    dispatch({ type: FETCH_START });

    customAxios
      .get('Plaid/GetUserBankAccounts', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          // console.log(data);
          // console.log('bank account data');
          let formatedAccount = data.map(acc => {
            let newAcc = {
              value: acc.ID,
              label: acc.AccountName,
              ...acc,
            };
            return newAcc;
          });

          setBankAccounts(formatedAccount);

          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };

  const editBankAccountName = obj => {
    dispatch({ type: FETCH_START });

    customAxios
      .post('Plaid/RenameBankAccount', obj, { headers: authHeader() })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });

        // getBankAccounts();
        let newBackAccounts = bankAccounts.map(item => {
          if (item.ID == obj.BankId) {
            item.AccountName = obj.NewName;
            item.label = obj.NewName;
            if (selectedAcc.ID == obj.BankId) {
              setSelectedAcc(item);
            }
            return item;
          }
          return item;
        });

        setBankAccounts(newBackAccounts);
        setCurrEdit(null);
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
  const deleteBankAccount = id => {
    let url = 'Plaid/DeleteBankAccount';
    dispatch({ type: FETCH_START });

    customAxios
      .post(url, { BankId: parseInt(id) }, { headers: authHeader() })
      .then(({ data }) => {
        let newBankAccList = bankAccounts.filter(item => item.ID != parseInt(id));
        if (selectedAcc.ID == id) {
          setTransactions([]);
        }
        setBankAccounts(newBankAccList);

        // getBankAccounts()
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
  const refreshTransactions = id => {
    dispatch({ type: FETCH_START });
    let url = 'Plaid/RefreshTransactions/' + id;
    customAxios
      .get(url, { headers: authHeader() })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });

        getTransactions(id);
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };
  const getTransactions = id => {
    let url = 'Plaid/GetTransactions/' + id;
    dispatch({ type: FETCH_START });

    customAxios
      .get(url, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setData(data);
          setTransactions(data);
          UpdateEmptyRows(data.length);
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };
  const handelAccountChange = acc => {
    setSelectedAcc(acc);
    refreshTransactions(acc.ID);
  };
  const getFirstRefresh = () => {
    dispatch({ type: FETCH_START });

    customAxios
      .get('Plaid/GetUserBankAccounts', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          // console.log(data);
          // console.log('bank account data');
          let formatedAccount = data.map(acc => {
            let newAcc = {
              value: acc.ID,
              label: acc.AccountName,
              ...acc,
            };
            return newAcc;
          });
          dispatch({ type: FETCH_SUCCESS });
          if (formatedAccount.length > 0) {
            setSelectedAcc({ ...formatedAccount[0] });
            refreshTransactions(formatedAccount[0].ID);
          }
          setBankAccounts(formatedAccount);
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };
  useEffect(() => {
    //getFirstRefresh();
    UpdateEmptyRows(transactions.length);
  }, []);

  const handelChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handelChangeRowPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const searchData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredRows = [];
    filteredRows = getArrayFilterData(data, searchkey);

    if (searchkey.length <= 0) {
      setTransactions(data);
    } else {
      setTransactions(filteredRows);
    }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.NameStr?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.amount
          ?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setTransactions(data);
    // setSearchDraftData(data);
    // setSearchSentData(customData);
  };

  useEffect(() => {
    handlefilterData();
  }, [filterSelectedData]);

  useEffect(() => {
    validateToken();
  }, []);
  const validateToken = () => {
    let urlParms = window.location.href;

    let paramString = urlParms.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    let obj = {};

    for (let pair of queryString.entries()) {
      // console.log('Key is: ' + pair[0]);
      obj[pair[0]] = pair[1];
      // console.log('Value is: ' + pair[1]);
    }
    // console.log(obj);
    if (obj.error) {
      alert('Sorry the bank account could not be connected');
      return;
    }
    if (!obj.code) return;
    let url = '/Bank/GetTinkTransactions?code=' + obj.code;
    customAxios.get(url, { headers: authHeader() }).then(({ data }) => {
      //
      if (data) {
        setTransactions(data);
        UpdateEmptyRows(data.length);
      } else {
        setTransactions([]);
        UpdateEmptyRows(0);
      }
    });
  };

  // console.log(filterSelectedData, 'filterSelectedData222');

  const handlefilterData = () => {
    let data = [];
    let transactionType = filterSelectedData.type;
    let fromDate = filterSelectedData.fromDate;
    let endDate = filterSelectedData.toDate;

    transactions
      .filter(row => {
        let isCorrectType = true;
        let isAboveFromDate = true;
        let isBelowToDate = true;
        const DtStr = new Date(row.DtStr);
        const acctype = row.amount;
        // if (filterSelectedData.fromDate && filterSelectedData.toDate && filterSelectedData.type) {
        //   filterPass =
        //     filterPass &&
        //     new Date(filterSelectedData.fromDate) < DtStr &&
        //     new Date(filterSelectedData.toDate) > DtStr &&
        //     acctype < 0;
        // }
        if (transactionType) {
          if (transactionType === 'credit') {
            isCorrectType = acctype < 0;
          } else if (transactionType === 'debit') {
            isCorrectType = acctype > 0;
          }
        }
        if (fromDate) {
          isAboveFromDate = new Date(fromDate) < DtStr;
        }
        if (endDate) {
          isBelowToDate = new Date(endDate) > DtStr;
        }
        return isCorrectType && isAboveFromDate && isBelowToDate;
      })
      .map(row => {
        data = [...data, row];
      });

    // if (filterSelectedData.type === 'credit') {

    // } else if (filterSelectedData.type === 'debit') {
    //   transactions
    //     .filter(row => {
    //       let filterPass = true;
    //       const acctype = row.amount;
    //       const DtStr = new Date(row.DtStr);
    //       if (filterSelectedData.fromDate && filterSelectedData.toDate) {
    //         filterPass =
    //           filterPass &&
    //           new Date(filterSelectedData.fromDate) < DtStr &&
    //           new Date(filterSelectedData.toDate) > DtStr &&
    //           acctype > 0;
    //       }
    //       return filterPass;
    //     })
    //     .map(row => {
    //       data = [...data, row];
    //     });
    // }
    setTransactions(data);
  };
  const loadTink = () => {
    window.location.href =
      'https://link.tink.com/1.0/transactions/connect-accounts/?client_id=bab37b671fca4176acd04564b7357677&redirect_uri=https://app.focus247.online/bank-statement/overview&market=IE&locale=en_US';
  };

  const calcTransactionAmount = amountData => {
    if (!amountData) return 0;

    const unscaledValue = amountData.unscaledValue;
    const scale = amountData.scale;

    // Calculate the actual amount by dividing the unscaled value by the scale
    const actualAmount = unscaledValue * Math.pow(10, -scale);

    return actualAmount.toFixed(2);
  };
  return (
    <div className="app-wrapper">
      <div>
        <ContainerHeader match={props.match} title={''} />
      </div>
      {/* {modal && (
        <CreateExpense
          modal={modal}
          details={details}
          toggle={toggle}
          onClientsClose={onClientsClose}
          clients={{ name: '' }}
          editData={''}
          addNewExpense={addNewExpense}
          customData={customData}
          isBankStatement={true}
          handleMenuClose={handleMenuClose}
          setOpenActionModal={setOpenActionModal}
          setIsSalesModal={setIsSalesModal}
        />
      )} */}

      <div className="justify-content-right pb-3 text-right d-flex justify-content-end">
        <div className="d-flex justify-content-start  flex-grow-1 align-items-center">
          <span style={{ fontSize: '18px', fontWeight: 600 }}>Select Account</span>
          <div style={{ minWidth: 250, textAlign: 'left' }}>
            <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
              <Select
                style={{ textAlign: 'left' }}
                placeholder={'Select Bank..'}
                options={bankAccounts}
                value={bankAccounts.filter(c => c.AccountName == (selectedAcc.label ? selectedAcc.AccountName : ''))}
                onChange={handelAccountChange}
              />
            </FormControl>
          </div>
        </div>
        <TaskFilterDialog
          filterData={transactions}
          filterSelectedData={filterSelectedData}
          setFilterSelectedData={setFilterSelectedData}
          getFirstRefresh={clearSearchData}
          // setFilterSelectedSend={setFilterSelectedSend}
          value={value}
        />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={e => searchData(e)}
            value={searchKey}
            inputRef={inputRef}
          />
          {searchKey && (
            <CloseIcon
              onClick={clearSearchData}
              style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
            />
          )}
        </Search>
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={() => {
            loadTink();
          }}>
          Connect Bank
        </Button>
        &nbsp;&nbsp;
        {/* <Button variant="contained" color="primary" onClick={() => refreshTransactions()}>
          Refresh Transaction
        </Button> */}
        <CmtDropdownMenu
          TriggerComponent={
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickData}
              sx={{
                mx: 2,
              }}
              style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
              <MoreVertIcon />
            </Button>
          }
          items={[...actionsList]}
          menuClasses={cusclass.root}
        />
        <CSVLink data={transactions} ref={downloadCsv} filename={'transactions-file.csv'} />
        {excelUploadModal && (
          <ImportExcel
            modal={excelUploadModal}
            toggle={excelUploadModaltoggle}
            // getContactsHandler={getContacts}
            // Teams={Teams}
            // Stages={Stages}
            currentTeam={{ IsAdmin: 1, label: 'test', value: 71 }}
            // currTabInfo={getCurrentTabInfo()}
            // handleRecordToastMessage={handleRecordToastMessage}
            // setUploadedRecordsCount={setUploadedRecordsCount}
          />
        )}
      </div>

      <Box className={tabClasses.root}>
        {/* <AppBar position="static"> */}
        <Paper square>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary">
            <Tab
              label={
                <TabWithCount count={transactions.length} style={{ textTransform: 'capitalize' }}>
                  Transactions
                </TabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <TabWithCount count={bankAccounts.length} style={{ textTransform: 'capitalize' }}>
                  Bank Accounts
                </TabWithCount>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Paper>
        {/* </AppBar> */}
        <TabPanel value={value} index={0} className={classes.tabBody}>
          {/* Bank Accounts */
          /* Bank Transactions */}
          <Box className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer className={classes.root}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35, 40]}
                  component="div"
                  count={transactions.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handelChangePage}
                  onRowsPerPageChange={handelChangeRowPerPage}
                />
                <Table className={classes.table} aria-label="bank account table">
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    col={headCells2}
                  />

                  <TableBody>
                    {transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((transac, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {transac?.descriptions?.display}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transac.amount.unscaledValue > 0 ? 'Debit' : 'Credit'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transac.amount.currencyCode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {calcTransactionAmount(transac.amount.value)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {new Date(transac.dates.booked).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* <IconButton onClick={''}>
                            <VisibilityIcon />
                          </IconButton> */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* <IconButton>
                            <MoreHorizIcon
                              id="basic-button"
                              aria-controls={openMenu ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? 'true' : undefined}
                              onClick={handleMenuOpen}
                            />
                          </IconButton> */}

                          {/* <div>
                          </div> */}
                        </TableCell>
                      </TableRow>
                    ))}
                    <Menu
                      open={openMenu}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      onClose={handleMenuClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                      <MenuItem
                        sx={{ fontFamily: 'normal' }}
                        onClick={() => {
                          handleMenuClose();
                          setOpenActionModal(true);
                          setIsSalesModal(true);
                        }}>
                        Connect to Sales invoice
                      </MenuItem>
                      <MenuItem
                        sx={{ fontFamily: 'normal' }}
                        onClick={() => {
                          handleMenuClose();
                          setOpenActionModal(true);
                          setIsSalesModal(false);
                        }}>
                        Attach to expense receipt
                      </MenuItem>
                    </Menu>
                    <Modal
                      open={openActionModal}
                      onClose={handleCloseActionModal}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description">
                      <Box sx={viewModalstyle}>
                        {isSalesModal ? (
                          <SalesModal handleCloseActionModal={handleCloseActionModal} />
                        ) : (
                          <ExpenseModal
                            handleCloseActionModal={handleCloseActionModal}
                            setAddNewExpense={setAddNewExpense}
                            setModal={setModal}
                            setDetails={setDetails}
                          />
                        )}
                      </Box>
                    </Modal>
                    {/* <ActionModal /> */}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabBody}>
          <Box className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>
                <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                  <EnhancedTableHead classes={classes} col={headCells} />
                  <TableBody>
                    {bankAccounts.map((row, index) => {
                      return (
                        // <TableRow
                        //   hover
                        //   onClick={event => console.log('row Clicked')}
                        //   // role="checkbox"
                        //   // aria-checked={isItemSelected}

                        //   // selected={isItemSelected}
                        //   key={index}>
                        //   <TableCell align="left">{row.AccountName}</TableCell>

                        //   <TableCell align="left">
                        //     <EditIcon className={classes.actionIcon} onClick={() => console.log('edit')} />
                        //     &nbsp;&nbsp;
                        //     <DeleteOutlineIcon className={classes.actionIcon} onClick={() => console.log('delete')} />
                        //   </TableCell>
                        // </TableRow>
                        <EditableTableRow
                          key={index}
                          row={row}
                          classes={classes}
                          setCurrEdit={setCurrEdit}
                          currEdit={currEdit}
                          editName={editBankAccountName}
                          deleteAccount={deleteBankAccount}
                          getTransaction={refreshTransactions}
                          setSelectedAcc={setSelectedAcc}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </TabPanel>
      </Box>
    </div>
  );
};

export default BankStatement;
