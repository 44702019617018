import React, { useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const AddCardButton = ({ onClick }) => {

  function tmClickHandler() {
    onClick();
    setTimeout(() => {
      tmTsk();
    }, 500)
  }
  function tmTsk() {
    var wrapper = document.querySelectorAll('.tmAddTsk');
    wrapper.forEach(btn => {
      var wrapper2 = document.querySelectorAll('.tmNewCloseButton');
      btn.addEventListener('click', event => {
        wrapper2.forEach(function (el) {
          el.click();
        });
      });
    });
  }
  useEffect(() => {
    tmTsk();
  })
  return (
    <Box mt={2}>
      <Button color="primary" onClick={() => tmClickHandler()} startIcon={<Add />} className="tmAddTsk">
        Add Task
      </Button>
    </Box>
  );
};

export default AddCardButton;
