import React, { useState, useRef, useEffect } from 'react';

export default function Dropdown({
  addWindow,
  options,
  defaultVal,
  id,
  label,
  prompt,
  value,
  onChange,
  onClick,
  placeholder,
  addProductsItem,
}) {
  const [open, setOpen] = useState(false);
  const [defaultText, setDefaultText] = useState(defaultVal ? defaultVal : '');
  const [query, setQuery] = useState('');
  const [newProduct, setNewProduct] = useState('');
  const ref = useRef(null);
  const refAddInput = useRef(null);

  useEffect(() => {
    ['click', 'touchend'].forEach(e => {
      document.addEventListener(e, toggle);
    });
    return () => {
      ['click', 'touchend'].forEach(e => {
        document.removeEventListener(e, toggle);
      });
    };
  }, []);

  function toggle(e) {
    // console.dir(e.target, ref.current);
    setOpen((e && e.target === ref.current) || (e && e.target === refAddInput.current));
  }

  function filter(options) {
    return options.filter(option => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  function displayValue() {
    if (query.length > 0) return query;
    if (value) return value[label];
    if (defaultText) return defaultText[label];
    return '';
  }
  return (
    <div className="custom_dropdown">
      <div
        className="control"
        //   onClick={() => setOpen(prev => !prev)}
      >
        <div className="selected-value">
          <input
            type="text"
            ref={ref}
            placeholder={value ? value[label] : prompt}
            value={displayValue()}
            onChange={e => {
              setQuery(e.target.value);
              onChange(null);
            }}
            onClick={
              // () => setOpen(prev => !prev)
              toggle
            }
            onTouchEnd={toggle}
          />
        </div>
        <div className={`arrow ${open ? 'open' : null}`} />
      </div>
      <div className={`options ${open ? 'open' : null}`}>
        {filter(options).map(option => (
          <div
            key={option[id]}
            className={`option ${value === option ? 'selected' : null}`}
            onClick={() => {
              setQuery('');
              onChange(option);
              setOpen(false);
            }}
            onTouchEnd={() => {
              setQuery('');
              onChange(option);
              setOpen(false);
            }}>
            {option[label]}
          </div>
        ))}
      </div>

      {addWindow && (
        <div className={`new_dropdown_item ${open ? 'open' : null}`}>
          <input
            type="text"
            ref={refAddInput}
            placeholder={placeholder ? placeholder : 'Create Product'}
            value={newProduct}
            onChange={e => setNewProduct(e.target.value)}
          />
          <button
            onClick={() => {
              onClick(newProduct);
              setNewProduct('');
            }}
            disabled={newProduct.length === 0}>
            New
          </button>
        </div>
      )}
    </div>
  );
}
