import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Card, CardContent, TextField } from '@material-ui/core';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

export default function Filters({
  filterInputData,
  setSearchData,
  closeFilterModel,
  filterModalOpen,

  setNormalFilter,
  setIsFilterApplied,
  isFilterApplied,
}) {
  // folloup date Filter
  const [followUpDateRange, setFollowUpDateRange] = useState({ start: '', end: '' });
  const [lastContactDateRange, setLastContactDateRange] = useState({ start: '', end: '' });
  const [applyColumLevelFilter, setApplyColumLevelFilter] = useState(false);
  const applyFilter = () => {
    setNormalFilter(applyColumLevelFilter);
    let opArr = filterInputData;

    let ipArr = filterInputData;

    if (followUpDateRange.start !== '' || followUpDateRange.end !== '') {
      opArr = dateFilter(followUpDateRange, ipArr, 'FollowUpDate');
      ipArr = opArr;
    }

    if (lastContactDateRange.start !== '' || lastContactDateRange.end !== '') {
      opArr = dateFilter(lastContactDateRange, ipArr, 'LastContactDate');
    }
    setIsFilterApplied(true);
    setSearchData(opArr);
  };
  const dateFilter = (filterRange, filterIpData, filterDataLabel) => {
    let startDate = filterRange.start === '' ? null : new Date(filterRange.start);
    let endDate = filterRange.end === '' ? null : new Date(filterRange.end);
    let opArr = [];

    if (startDate && endDate) {
      opArr = filterIpData.filter(item => {
        let itemDate = new Date(item[filterDataLabel]);
        return itemDate >= startDate && itemDate <= endDate;
      });
    } else if (startDate) {
      opArr = filterIpData.filter(item => {
        let itemDate = new Date(item[filterDataLabel]);
        return itemDate >= startDate;
      });
    } else {
      opArr = filterIpData.filter(item => {
        let itemDate = new Date(item[filterDataLabel]);
        return itemDate <= endDate;
      });
    }
    return opArr;
  };
  const clearFilter = () => {
    setIsFilterApplied(false);
    setNormalFilter(false);
    setApplyColumLevelFilter(false);
    setSearchData(filterInputData);
    setFollowUpDateRange({ start: '', end: '' });
    setLastContactDateRange({ start: '', end: '' });
  };

  const handleClose = () => {
    // setOpen(false);
    closeFilterModel();
  };

  const filterTitles = {
    FollowUpDate: 'FollowUpdate',
    LastContactDate: 'LastContactDate',
  };
  const handelDateRange = (args, dateType) => {
    if (dateType === filterTitles.FollowUpDate) {
      let newRange = { ...followUpDateRange };
      newRange[args.target.name] = args.target.value;

      setFollowUpDateRange(newRange);
    } else if (dateType === filterTitles.LastContactDate) {
      let newRange = { ...lastContactDateRange };
      newRange[args.target.name] = args.target.value;
      setLastContactDateRange(newRange);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        // fullWidth={fullWidth}
        // style={{width:'400px'}}
        maxWidth="md"
        open={filterModalOpen}
        onClose={handleClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0,0,0,0.250)' }}>
          <div className="w-100 d-flex justify-content-between ">
            <div> Filters</div>
            <div className="d-flex" style={{ gap: '2rem' }}>
              <div>
                <Button style={{ textTransform: 'none' }} size="medium" variant="contained" onClick={applyFilter}>
                  Apply
                </Button>
                {isFilterApplied ? (
                  <Button
                    className="ml-3"
                    style={{ textTransform: 'none' }}
                    size="medium"
                    variant="contained"
                    onClick={clearFilter}>
                    Clear Filter{' '}
                  </Button>
                ) : (
                  ''
                )}
              </div>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseRoundedIcon />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <Card style={{ marginBottom: '1rem', border: 'none', boxShadow: 'none', paddingInline: '0' }}>
            <CardContent component="div" style={{ padding: 0, width: '600px', height: '400px' }}>
              <div>
                <Box
                  sx={{
                    '& .MuiTextField-root': { m: 1, border: 'none', boxShadow: 'none' },
                  }}>
                  <div className="border-bottom w-100 pl-6">
                    <FormControlLabel
                      sx={{ mt: 1 }}
                      control={
                        <Checkbox
                          checked={applyColumLevelFilter}
                          onChange={() => {
                            setApplyColumLevelFilter(!applyColumLevelFilter);
                          }}
                        />
                      }
                      label="Apply Column Filters"
                      style={{ fontFamily: 'normal' }}
                    />
                  </div>
                  <div className="w-100 pl-6 pr-6 pt-6 d-flex justify-content-between ">
                    <FilterDateWrapper filterLabel="FollowUp Date">
                      <div>
                        <TextField
                          variant="outlined"
                          size="small"
                          name="start"
                          label="Start"
                          type="date"
                          onChange={e => {
                            handelDateRange(e, filterTitles.FollowUpDate);
                          }}
                          value={followUpDateRange.start}
                          InputLabelProps={{ shrink: true }}
                        />{' '}
                      </div>

                      <div>To</div>
                      <div>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="End"
                          name="end"
                          type="date"
                          onChange={e => handelDateRange(e, filterTitles.FollowUpDate)}
                          value={followUpDateRange.end}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </FilterDateWrapper>
                    <FilterDateWrapper filterLabel="Last Contact Date">
                      <div>
                        <TextField
                          variant="outlined"
                          size="small"
                          name="start"
                          label="Start"
                          type="date"
                          onChange={e => {
                            handelDateRange(e, filterTitles.LastContactDate);
                          }}
                          value={lastContactDateRange.start}
                          InputLabelProps={{ shrink: true }}
                        />{' '}
                      </div>

                      <div>To</div>
                      <div>
                        <TextField
                          variant="outlined"
                          size="small"
                          label="End"
                          name="end"
                          type="date"
                          onChange={e => handelDateRange(e, filterTitles.LastContactDate)}
                          value={lastContactDateRange.end}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </FilterDateWrapper>
                  </div>
                </Box>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button style={{ textTransform: 'none' }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const FilterDateWrapper = ({ children, filterLabel }) => {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ width: '250px', cursor: 'pointer' }}>
      <div style={{ border: '1px solid rgba(0,0,0,0.25)', borderRadius: '5px' }}>
        <div
          className="w-100 d-flex justify-content-between align-items-center p-2 border-bottom"
          onClick={() => setOpen(!open)}>
          <div>{filterLabel}</div>
          <div>
            <KeyboardArrowDown />
          </div>
        </div>
        <div className={`w-100  justify-content-center flex-column align-items-center p-2 ${open ? 'd-flex' : 'd-none'} `}>
          {children}
        </div>
      </div>
    </div>
  );
};
