/**
 * Version 1.0
 * Raul Santos Barreira
 * 27.04.2022
 * -
 * This component is a row in the Invoice Items, when we want to add a new invoice.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../util/IntlMessages';
import { SingleSelect } from 'react-select-material-ui';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Dropdown from '../../Accounting/InvoicePage/Dropdown';
import $ from 'jquery';
/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  actionIcon: {
    cursor: 'pointer',
    marginTop: '15px',
  },
}));

const InvoiceRow = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [getProducts, setGetProducts] = useState([]);

  /**
   * Delete the row on click
   */
  const deleteRow = event => {
    $('.' + props.quantityId).remove();
    console.log(props.quantityId);
    props.deleteHandler(props.index);
  };

  useEffect(() => {
    //get Products
    dispatch({ type: FETCH_START });
    customAxios
      .get('/Invoice/GetProductItems', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var products = data.map(c => ({ value: c.ID, label: c.ItemName }));
          setGetProducts(products);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          // console.log('payload: data.error', data.error);
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // console.log('Error****:', error.message);
      });
  }, [props]);

  return (
    <>
      <div className={`col-lg-3 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 mt-2 ${props.quantityId}`}>
        <Controller
          name={props.product}
          control={props.control}
          defaultValue={props.editProduct ? props.editProduct : ''}
          render={({ field: { onChange, value, ref } }) => (
            <Dropdown
              options={getProducts}
              // options={props.products}
              defaultVal={props.editProduct}
              id="value"
              label="label"
              prompt="Select product.."
              value={value}
              onChange={val => {
                setValue(val);
                onChange(val);
              }}
              onClick={val => props.addProductsItem(val)}
              // onProductAdd={}
            />
          )}
        />
        <ErrorMessage errors={props.errors} name="iProduct" render={({ message }) => <p>{message}</p>} />
      </div>
      <div className={`col-lg-2 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 ${props.quantityId}`}>
        <Controller
          /*{...props.register("quantity")}*/
          name={props.quantityId}
          control={props.control}
          defaultValue={props.quantity ? props.quantity : ''}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ marginTop: '0px' }}
              label={<IntlMessages id="appModule.iQuantity" />}
              margin="normal"
            />
          )}
        />
        <ErrorMessage errors={props.errors} name="iQuantity" render={({ message }) => <p>{message}</p>} />
      </div>
      <div className={`col-lg-2 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 ${props.quantityId}`}>
        <Controller
          name={props.rateId}
          control={props.control}
          defaultValue={props.productRate ? props.productRate : ''}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ marginTop: '0px' }}
              label={<IntlMessages id="appModule.iRate" />}
              margin="normal"
            />
          )}
        />
        <ErrorMessage errors={props.errors} name="iRate" render={({ message }) => <p>{message}</p>} />
      </div>
      {!props.isCommonVat && (
        <div className={`col-lg-1 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 ${props.quantityId}`}>
          <Controller
            name={props.vat}
            control={props.control}
            defaultValue={props.vatRate ? props.vatRate : ''}
            render={({ field }) => (
              <TextField
                {...field}
                style={{ marginTop: '0px' }}
                label={<IntlMessages id="appModule.iVat" />}
                margin="normal"
              />
            )}
          />
          <ErrorMessage errors={props.errors} name="iVat" render={({ message }) => <p>{message}</p>} />
        </div>
      )}

      <div className={`col-lg-3 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 ${props.quantityId}`}>
        <Controller
          name={props.descriptionId}
          control={props.control}
          defaultValue={props.productDescription ? props.productDescription : ''}
          render={({ field }) => (
            <TextField
              {...field}
              style={{ marginTop: '0px' }}
              label={<IntlMessages id="appModule.iDescription" />}
              margin="normal"
            />
          )}
        />
        <ErrorMessage errors={props.errors} name="iDescription" render={({ message }) => <p>{message}</p>} />
      </div>
      <div className={`col-lg-1 d-flex flex-column tableHeadBorderMiddleBody order-lg-1 ${props.quantityId}`}>
        <DeleteOutlineIcon className={classes.actionIcon} onClick={deleteRow} />
      </div>
    </>
  );
};

export default InvoiceRow;
