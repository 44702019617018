import React, { useState, useEffect, useRef } from 'react';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import axios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../../microsoft-context/AppContext';
import { sendEmail } from '../../../../microsoft-context/services/GraphService';

import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import customAxios from '../../util/Api';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
// import Select from '@material-ui/core/Select';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import InputLabel from '@material-ui/core/InputLabel';
import IntlMessages from '../../util/IntlMessages';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import { useForm, Controller } from 'react-hook-form';
//dropdown
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@material-ui/icons/Edit';
import DeleterIcon from '@material-ui/icons/Delete';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { NavLink } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import { height } from '@mui/system';
import DescriptionInput from 'routes/Pages/Planning/TaskmanagementPage/components/DescriptionInput';
import ReactQuill from 'react-quill';
const useHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBlock: '0.75rem',
    '& .modal-title': {
      width: '100%',
    },
    '& .header-content': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingInline: '10px',
    },
    '& .flex25': {
      flex: 0.25,
    },
    '& .flex60': {
      flex: 0.6,
      textAlign: 'center',
    },
    '& .flex20': {
      flex: 0.2,
    },
    '& .flex15': {
      flex: 0.15,
    },
    '& .close': {
      fontSize: '2rem',
      border: 'none',
      padding: 0,
      position: 'relative',
      top: '16px',
      right: '10px',
    },
    '& .warning': {
      border: '1px solid red',
      borderRadius: '5px',
      color: 'red',
    },
    '& .connect': {
      border: '1px solid rgb(63, 81, 181)',
      borderRadius: '5px',
    },
  },
}));
//React quil setting start

const MailFormTemplate = ({
  rowData,
  closeMail,
  onMailSentSuccess,
  getEmailCommunication,
  bulkMail,
  bulkData,
  setBulkMail,
  getContactHandler,
}) => {
  const Quill = ReactQuill.Quill;

  var Block = Quill.import('blots/block');
  // var Block = Quill.import('blots/inline')
  //  Block.tagName = 'div';

  // Quill.register(Block);
  class ParagraphBlot extends Block {
    static create(value) {
      let node = super.create();
      value['style'] = 'margin-block-start:0;margin-block-end:0;';
      console.log(value);

      if (value.class !== '') {
        node.setAttribute('class', value.class);
      }
      if (value.style !== '') {
        node.setAttribute('style', value.style);
      }
      return node;
    }
    static formats(node) {
      if (node) {
        return {
          class: node.getAttribute('class') ? node.getAttribute('class') : '',
          style: node.getAttribute('style') ? node.getAttribute('style') : 'margin-block-start:0;margin-block-end:0;',
        };
      }
    }
  }
  ParagraphBlot.blotName = 'p';
  ParagraphBlot.tagName = 'p';
  Quill.register(ParagraphBlot);

  // react quill setting end
  const [mailSub, setMailSub] = useState('');
  const [mailContent, setMailContent] = useState('');

  const [textMailContent, setTextMailContent] = useState(''); //its is the text conent without any style
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openCustomTemplate, setOpenCustomTemplate] = useState(false);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  // dialog comp test start
  const [emailsTo, setEmailsTo] = useState([]);
  const [emailsCC, setEmailsCC] = useState([]);

  // snipets start
  const currMaibodyRef = useRef(null);
  const currMailSubRef = useRef(null);
  const [lastTestAreaFocus, setLastTestAreaFocus] = useState(null);
  const [openAddSnippet, setOpenAddSnippet] = useState(false);
  const [openEditSnippet, setOpenEditSnippet] = useState(false);
  const [snippetsMap, setSnippetsMap] = useState({});
  const [customSnippets, setCustomSnippets] = useState([]);
  const [newSnippetData, setNewSnippetData] = useState({
    SnippetName: '',
    SnippetDescription: '',
    SnippetId: '',
  });
  const defaultSnippetsMap = {
    City: 'crmAddressCity',
    Country: 'crmAddressCountry',
    Town: 'crmAddressTown',
    Zip: 'crmAddressZip',
    CompanyName: 'crmCompany',
    Email: 'crmContactEmail',
    FirstName: 'crmContactFirst',
    LastName: 'crmContactLast',
    MiddleName: 'crmContactMiddle',
    PhoneNo: 'crmContactPhone',
    Website: 'crmContactWebsite',
    FullName: 'FullName',
  };
  const initialSnippetData = {
    SnippetName: '',
    SnippetDescription: '',
  };

  const EditSnippetModalOpen = args => {
    let snip_data = {
      SnippetName: args.SnippetName,
      SnippetDescription: args.SnippetDescription,
      snippetId: args.Id,
    };
    setOpenEditSnippet(true);
    setNewSnippetData(snip_data);
  };
  const onTextAreaFocus = e => {
    setLastTestAreaFocus(e);
  };

  const onSnippetSelected = args => {
    if (lastTestAreaFocus) {
      switch (lastTestAreaFocus) {
        case 'TemplateSubject':
          let startPos = currMailSubRef.current.selectionStart;
          let endPos = currMailSubRef.current.selectionEnd;
          let currVal = currMailSubRef.current.value;

          let newVal = currVal.substring(0, startPos) + '[' + args + ']' + currVal.substring(endPos, currVal.length);
          let updatedValue = { TemplateSubject: newVal };
          setCustomTemplateData(customTemplateData => ({
            ...customTemplateData,
            ...updatedValue,
          }));
          // addSelectedSnippet(currMailSubRef)
          break;
        case 'TemplateBody':
          let range = currMaibodyRef.current.getEditor().getSelection();
          let position = range ? range.index : 0;
          let textInsert = '[' + args + ']';
          currMaibodyRef.current.getEditor().insertText(position, textInsert);

          // addSelectedSnippet(currMaibodyRef)
          break;
      }
    }
  };

  const saveSnippet = () => {
    if (openEditSnippet) saveEditedSnippet(newSnippetData);
    else saveNewSnippet();
  };

  const addCRMNotes = args => {
    const bodyParameters = {
      ContactId: rowData.crmId,
      AccountId: rowData.UserId,
      Note: `TO : ${emailsTo}, CC : ${emailsCC}`,
      NoteDate: new Date(),
      crmTeamId: rowData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMNotes/AddCRMNote', JSON.stringify(bodyParameters), {
          headers: authHeader(),
        })
        .then(({ data }) => {
          console.log(data, 'success');
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const saveEditedSnippet = args => {
    let newSnippet = {
      Id: parseInt(args.snippetId),
      CrmTeamId: rowData.CrmTeamId,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/EditSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenEditSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const saveNewSnippet = () => {
    if (newSnippetData.SnippetName == '' || newSnippetData.SnippetDescription == '') return;
    let newSnippet = {
      CrmTeamId: rowData.CrmTeamId,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/AddSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenAddSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const getSnippet = () => {
    let url = 'CRMSnippets/GetSnippets/' + rowData.CrmTeamId;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setCustomSnippets(data);
          let customSnippetData = data.reduce((a, v) => ({ ...a, [v.SnippetName]: v.SnippetDescription }), {});
          setSnippetsMap({ ...rowData, ...customSnippetData });

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  // snippests end

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  // dial comp test end
  const app = useAppContext();
  const data = {
    crmCompany: undefined,
    crmContactFirst: undefined,
    crmContactEmail: undefined,
    crmAddressCity: undefined,
    crmContactPhone: undefined,
    crmAddressCountry: undefined,
    crmContactMiddle: undefined,
    crmContactLast: undefined,
    mailSubject: undefined,
    mailContent: undefined,
    textMailContent: undefined,
  };

  const toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo',
    ],
  };
  const quickToolbarSettings = {
    image: [
      'Replace',
      'Align',
      'Caption',
      'Remove',
      'InsertLink',
      'OpenImageLink',
      '-',
      'EditImageLink',
      'RemoveImageLink',
      'Display',
      'AltText',
      'Dimension',
    ],
  };
  /* text editor  */
  const textEditorModules = {
    toolbar: [
      // [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image' /* "video"*/],
      // ["clean"],
      // ["fullscreen"]
    ],
  };

  const templateInitialData = {
    TemplateName: '',
    TemplateSubject: '',
    TemplateBody: '',
  };
  const [customTemplateData, setCustomTemplateData] = useState(templateInitialData);

  function clearCurrentMailContent() {
    setMailSub('');
    setMailContent('');
  }

  function sendMail(mailData) {
    // console.log(mailContent);
    addCRMNotes();
    if (bulkMail) {
      sendBulkEmail();
      return;
    }
    try {
      doCreate();

      closeMail();

      // setRedirect(true);
    } catch (err) {
      app.displayError('Error creating event', JSON.stringify(err));
    }
  }

  // utility function start
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  function getFormatedMailContent(s) {
    let extContent = extractContent(s);
    return extContent.length > 110 ? `${extContent.substring(0, 110)}...` : extContent;
  }

  // utility function end
  const doCreate = async () => {
    let ccEmails = [];
    let ccEmailsStr = '';
    let emailToStr = ' ';
    emailsCC.forEach(email => {
      ccEmails.push({ emailAddress: { address: email } });
      ccEmailsStr += `${email}   `;
    });
    let toEmails = [];
    emailsTo.forEach(email => {
      toEmails.push({ emailAddress: { address: email } });
      emailToStr += `${email},  `;
    });
    const Sendmemail = {
      message: {
        subject: mailSub,
        body: {
          contentType: 'HTML',
          content: `<html
          lang="en"
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta name="x-apple-disable-message-reformatting" />
            <title></title>
             <style>
                p{
                  margin-block-start:unset;
                  margin-block-end:unset;
                  margin-block:0 !important;
                }
             </style>
            <!--[if mso]>
              <style>
                table {
                  border-collapse: collapse;
                  border-spacing: 0;
                  border: none;
                  margin: 0;
                }
                div,
                td {
                  padding: 0;
                }
                div {
                  margin: 0 !important;
                }
                p{
                  margin-block:0 !important;
                  margin-block-start:unset;
                  margin-block-end:unset;
                }
              </style>
              <noscript>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              </noscript>
            <![endif]-->
            <style>
              table,
              td,
              div,
              h1,
              p {
                font-family: Arial, sans-serif;
               
              }
              p,h1,h2,h3,h4,pre,div{
                margin:0 !important;
                line-height:'normal' !important;

               
              }
              @media screen and (max-width: 530px) {
                .unsub {
                  display: block;
                  padding: 8px;
                  margin-top: 14px;
                  border-radius: 6px;
                  background-color: #555555;
                  text-decoration: none !important;
                  font-weight: bold;
                }
                .col-lge {
                  max-width: 100% !important;
                }
              }
              @media screen and (min-width: 531px) {
                .col-sml {
                  max-width: 27% !important;
                }
                .col-lge {
                  max-width: 73% !important;
                }
              }
            </style>
          </head>
          <body>
          <div >
          ${mailContent}
          

          </div>
          </body>
          </html>
          `,
        },
        toRecipients: toEmails,
        ccRecipients: ccEmails,
      },
    };
    let emailCommunicationData = {
      CrmContactId: rowData.crmId,
      FromEmail: app.user?.email,
      ToEmail: emailToStr,
      CcEmail: ccEmailsStr,
      BccEmail: '',
      EmailSubject: mailSub,
      EmailBody: extractContent(mailContent),
      Isdelivered: 0,
      IsOpened: 0,
      IsReplied: 0,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRM/AddEmailCommunication', emailCommunicationData, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          getEmailCommunication(rowData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }

    try {
      await sendEmail(app.authProvider, Sendmemail);

      onMailSentSuccess();

      clearCurrentMailContent();

      // setRedirect(true);
    } catch (err) {
      app.displayError('Error creating event', JSON.stringify(err));
    }
    getContactHandler(rowData.crmId);
  };

  const [Templatedata, setTempDataArr] = useState([]);
  const dispatch = useDispatch();

  function openTemplates(e) {
    e.preventDefault();
    GetTemplateData();
    setOpenTemplate(true);
  }
  function GetTemplateData() {
    let url = 'CRM/GetEmailTemplates/' + rowData.CrmTeamId;
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setTempDataArr(data);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  useEffect(() => {
    console.log(rowData);
    GetTemplateData();
    getSnippet();
    if (bulkMail) {
      let emailTo = bulkData.map(item => item.crmContactEmail);
      setEmailsTo(emailTo);
    } else setEmailsTo([rowData.crmContactEmail]);
  }, []);

  useEffect(() => {}, [customSnippets]);

  function closeTemplate() {
    setOpenTemplate(false);
  }

  String.prototype.interpolate = function(args) {
    try {
      const names = Object.keys(args);
      const vals = Object.values(args);

      let propData = new Function(...names, `return \`${extractContent(this)}\`;`)(...vals);
      return propData;
    } catch (e) {
      return this;
    }
  };

  function interpolateStr(string, obj) {
    return string.replace(/(?<!\[)\[([^[\]]*)](?!\])/g, (m, p1) => obj[p1] ?? `[${p1}]`);
  }

  function selectTemplate(item) {
    let tempSubject = item.TemplateSubject;
    let tempContent = item.TemplateBody;

    if (!bulkMail) {
      // tempSubject = item.TemplateSubject.interpolate(snippetsMap);

      // tempContent = item.TemplateBody.interpolate(snippetsMap);
      tempSubject = interpolateStr(item.TemplateSubject, snippetsMap);

      tempContent = interpolateStr(item.TemplateBody, snippetsMap);
    }

    setMailSub(tempSubject);
    setMailContent(tempContent);
    if (!bulkMail) setTextMailContent(extractContent(interpolateStr(item.TemplateBody, snippetsMap)), {});
    closeTemplate();
  }

  function addToTemplate() {
    if (
      customTemplateData.TemplateBody == '' &&
      customTemplateData.TemplateName == '' &&
      customTemplateData.TemplateSubject == ''
    ) {
      return;
    }

    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/AddEmailTemplate', customTemplateData, { headers: authHeader() })
      .then(() => {
        GetTemplateData();
        setCustomTemplateData(templateInitialData);

        setOpenCustomTemplate(false);

        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const deleteTemplate = index => {
    let obj = {
      TemplateId: index,
      crmTeamId: rowData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/DeleteEmailTemplate', obj, { headers: authHeader() })
      .then(index => {
        GetTemplateData();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const editTemplate = (item, itemFnc) => {
    setOpenEditTemplate(true);
    setOpenCustomTemplate(true);
    // setOpenTemplate(false);
    setCustomTemplateData(item);
    itemFnc();
  };
  const saveEditTemplate = () => {
    let tempObj = [
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateSubject',
        NewValue: customTemplateData.TemplateSubject,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateBody',
        NewValue: customTemplateData.TemplateBody,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateName',
        NewValue: customTemplateData.TemplateName,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/EditMultipleEmailTemplates', tempObj, { headers: authHeader() })
      .then(() => {
        GetTemplateData();
        setOpenCustomTemplate(false);
        setOpenEditTemplate(false);
        setCustomTemplateData(templateInitialData);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    // dispatch({ type: FETCH_START });
  };

  const handelChange = (dataName, datavalue) => {
    if (openAddSnippet || openEditSnippet) {
      // console.log(dataName, datavalue);
      let updatedValue = {};
      if (dataName == 'SnippetName') {
        updatedValue = { SnippetName: datavalue };
      } else {
        updatedValue = { SnippetDescription: datavalue };
      }
      setNewSnippetData(newSnippetData => ({
        ...newSnippetData,
        ...updatedValue,
      }));
      return;
    }

    if (openCustomTemplate) {
      let updatedValue = {};
      if (dataName === 'TemplateBody') {
        updatedValue = { TemplateBody: datavalue };
      } else {
        updatedValue = { [dataName]: datavalue };
      }

      setCustomTemplateData(customTemplateData => ({
        ...customTemplateData,
        ...updatedValue,
      }));
    } else {
      if (dataName === 'MailSubject') {
        setMailSub(datavalue);
      } else if (dataName === 'MailContent') {
        // console.log(datavalue);
        setMailContent(datavalue);
      }
    }
  };
  const handelClose = () => {
    if (openCustomTemplate) {
      if (openEditTemplate) setOpenEditTemplate(false);

      setOpenCustomTemplate(false);
      setCustomTemplateData(templateInitialData);
    } else {
      closeMail();
    }
  };

  // bulk email
  const sendBulkEmail = async () => {
    let bulkEmailCount = 0;
    let customSnippetData = customSnippets.reduce((a, v) => ({ ...a, [v.SnippetName]: v.SnippetDescription }), {});
    bulkData.forEach(item => {
      try {
        bulkEmailCount++;
        doCreateBulkEmail({ ...item, ...customSnippetData }, bulkEmailCount);
        // setRedirect(true);
      } catch (err) {
        app.displayError('Error creating event', JSON.stringify(err));
      }
    });
    getContactHandler();

    closeMail();

    // clearCurrentMailContent();
  };

  const doCreateBulkEmail = async (args, emailCount) => {
    let ccEmails = [];
    let ccEmailsStr = '';
    let emailToStr = ' ';
    let currSubject = interpolateStr(mailSub, args);
    let currContent = interpolateStr(mailContent, args);
    emailsCC.forEach(email => {
      ccEmails.push({ emailAddress: { address: email } });
      ccEmailsStr += `${email}   `;
    });
    let toEmails = [];

    toEmails.push({ emailAddress: { address: args.crmContactEmail } });
    emailToStr += `${args.crmContactEmail},  `;

    const Sendmemail = {
      message: {
        subject: currSubject,
        body: {
          contentType: 'HTML',
          content: `<html
          lang="en"
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta name="x-apple-disable-message-reformatting" />
            <title></title>
            <!--[if mso]>
              <style>
                table {
                  border-collapse: collapse;
                  border-spacing: 0;
                  border: none;
                  margin: 0;
                }
                div,
                td {
                  padding: 0;
                }
                div {
                  margin: 0 !important;
                }
              </style>
              <noscript>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              </noscript>
            <![endif]-->
            <style>
              table,
              td,
              div,
              h1,
              p {
                font-family: Arial, sans-serif;
              }
              @media screen and (max-width: 530px) {
                .unsub {
                  display: block;
                  padding: 8px;
                  margin-top: 14px;
                  border-radius: 6px;
                  background-color: #555555;
                  text-decoration: none !important;
                  font-weight: bold;
                }
                .col-lge {
                  max-width: 100% !important;
                }
              }
              @media screen and (min-width: 531px) {
                .col-sml {
                  max-width: 27% !important;
                }
                .col-lge {
                  max-width: 73% !important;
                }
              }
            </style>
          </head>
          <body>
          <div>
          ${currContent}

          </div>
          </body>
          </html>
          `,
        },
        toRecipients: toEmails,
        ccRecipients: ccEmails,
      },
    };
    let emailCommunicationData = {
      CrmContactId: args.crmId,
      FromEmail: app.user?.email,
      ToEmail: emailToStr,
      CcEmail: ccEmailsStr,
      BccEmail: '',
      EmailSubject: currSubject,
      EmailBody: extractContent(currContent),
      Isdelivered: 0,
      IsOpened: 0,
      IsReplied: 0,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRM/AddEmailCommunication', emailCommunicationData, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          // getEmailCommunication(rowData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }

    try {
      await sendEmail(app.authProvider, Sendmemail);
      // console.log(emailCount + "  "+bulkData.length)
      if (emailCount == bulkData.length) {
        onMailSentSuccess();
        // setBulkMail(false)

        clearCurrentMailContent();
      }

      // setRedirect(true);
    } catch (err) {
      if (emailCount == bulkData.length) {
        // setBulkMail(false)
      }
      app.displayError('Error creating event', JSON.stringify(err));
    }
  };

  // add template from send Email
  const openTemplateWindow = () => {
    let currTemplatedata = {
      TemplateName: '',
      TemplateSubject: mailSub,
      TemplateBody: mailContent,
    };
    setCustomTemplateData(currTemplatedata);
    setOpenCustomTemplate(true);
  };

  return (
    <div>
      <Modal className="invoice modal-box" size="lg" isOpen={true}>
        <ModalHeader className={useHeaderStyles().root}>
          <div className="header-content">
            <div className="flex25" style={{ paddingRight: app.user ? '30px' : '0' }}>
              {openCustomTemplate ? (openEditTemplate ? 'Edit Template' : 'Create New Template') : 'Create Mail'}
            </div>
            <div className={`flex60 ${app.user ? 'connect' : 'warning'}`}>
              {app.user ? `From ${app.user.email}` : 'No Mailbox Connected'}
            </div>
            <div className="flex20 ">
              <NavLink to="/microsoft/authentication" style={{ display: app.user ? 'none' : 'block', marginLeft: '20px' }}>
                <Button
                  variant="contained"
                  style={{ background: 'rgb(63, 81, 181)', height: '32px', fontFamily: 'normal' }}

                  // disabled={details.length === 0 ? false : true}
                >
                  Connect
                </Button>
              </NavLink>

              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => {
                  handelClose();
                }}
                style={{
                  // position: 'absolute',
                  // right: '19px',
                  // top: '18px',
                  background: '#fff',
                  opacity: 1,
                  top: app.user ? '16px' : '-16px',
                }}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </ModalHeader>
        <form
          name="myForm"
          onSubmit={e => console.log(e)}
          style={{
            paddingBottom: '4px',
            paddingTop: '2px',
            paddingLeft: '4px',
            paddingRight: '4px',
          }}>
          <ModalBody>
            <div className="modal-box-content">
              <div className="row no-gutters">
                {openCustomTemplate ? (
                  <React.Fragment>
                    {/* template start */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            className="sm-button"
                            {...bindTrigger(popupState)}
                            style={{
                              paddingBlock: '0.5em',
                              fontFamily: 'normal',
                              fontSize: '0.75rem',
                              marginBottom: '15px',
                              backgroundColor: '#3f51b5',
                            }}>
                            snippets
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{ height: '400px', marginTop: '2px' }}
                            className="info_sidebar pt-0 pb-0">
                            <div
                              className="w-100 "
                              style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', paddingInline: '10px' }}>
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => setOpenAddSnippet(true)}
                                className="sm-button">
                                Add Snippets
                              </Button>
                            </div>
                            {openAddSnippet || openEditSnippet ? (
                              <div style={{ width: '300px' }}>
                                <div className="w-100 pt-2">
                                  <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                                    <InputLabel htmlFor="age-native-helper">Snippet Name</InputLabel>
                                    <Controller
                                      name="SnippetName"
                                      control={control}
                                      defaultValue=""
                                      render={({ field }) => (
                                        <TextField
                                          onChange={e => {
                                            handelChange(field.name, e.target.value);
                                            field.onChange(e.target.value);
                                          }}
                                          // label={<IntlMessages id="appModule.invoiceNumber" />}
                                          value={newSnippetData.SnippetName}
                                          margin="none"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                                    <InputLabel htmlFor="age-native-helper">Snippet Description</InputLabel>
                                    <Controller
                                      name="SnippetDescription"
                                      control={control}
                                      render={({ field }) => (
                                        <TextField
                                          onChange={e => {
                                            handelChange(field.name, e.target.value);
                                            field.onChange(e.target.value);
                                          }}
                                          // label={<IntlMessages id="appModule.invoiceNumber" />}
                                          value={newSnippetData.SnippetDescription}
                                          margin="none"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div
                                    className="w-100 "
                                    style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', paddingInline: '15px 10px' }}>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        saveSnippet();
                                      }}
                                      className="sm-button">
                                      save
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => {
                                        openEditSnippet ? setOpenEditSnippet(false) : setOpenAddSnippet(false);
                                      }}
                                      className="sm-button ml-2"
                                      style={{ backgroundColor: 'white' }}>
                                      cancel
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                {Object.keys(defaultSnippetsMap).map((item, index) => {
                                  return (
                                    <MenuItem
                                      sx={{ fontFamily: 'normal' }}
                                      style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', width: '300px' }}
                                      onClick={() => {
                                        popupState.close();
                                        onSnippetSelected(defaultSnippetsMap[item]);
                                      }}
                                      key={index}>
                                      <div>
                                        <span> {item}</span>
                                      </div>
                                    </MenuItem>
                                  );
                                })}

                                {customSnippets.map((item, index) => {
                                  return (
                                    <MenuItem
                                      sx={{ fontFamily: 'normal' }}
                                      style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', width: '300px', padding: '0' }}
                                      key={index}>
                                      <div className="w-100 d-flex justify-content-between">
                                        <span
                                          style={{ flex: 1, padding: '0.5em 0 0.5em 15px' }}
                                          onClick={() => {
                                            popupState.close();
                                            onSnippetSelected(item.SnippetName);
                                          }}>
                                          {' '}
                                          {item.SnippetName}
                                        </span>
                                        <Button
                                          variant="contained"
                                          size="small"
                                          style={{
                                            background: '#3f51b5',
                                            fontFamily: 'normal',
                                            fontSize: '0.75rem',
                                            padding: '0px',
                                            height: '25px',
                                            marginBlock: 'auto',
                                          }}
                                          onClick={() => {
                                            EditSnippetModalOpen(item);
                                          }}>
                                          <EditIcon fontSize="small" />
                                        </Button>
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </div>
                            )}
                          </Menu>
                        </div>
                      )}
                    </PopupState>

                    <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                      <InputLabel htmlFor="age-native-helper">Template Title</InputLabel>
                      <Controller
                        name="TemplateName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            onChange={e => {
                              handelChange(field.name, e.target.value);
                              field.onChange(e.target.value);
                            }}
                            // label={<IntlMessages id="appModule.invoiceNumber" />}
                            value={customTemplateData.TemplateName}
                            margin="none"
                          />
                        )}
                      />
                    </div>
                    {/* template end */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {popupState => (
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            {...bindTrigger(popupState)}
                            style={{
                              paddingBlock: '0.5em',
                              fontFamily: 'Normal',
                              fontSize: '0.75rem',
                              marginBottom: '15px',

                              backgroundColor: '#3f51b5',
                            }}>
                            Choose/Add Template
                          </Button>
                          <Menu
                            {...bindMenu(popupState)}
                            style={{ height: '400px', marginTop: '2px' }}
                            className="info_sidebar">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setOpenCustomTemplate(true);
                                setCustomTemplateData(templateInitialData);
                                popupState.close();
                              }}
                              style={{
                                marginInline: '15px',
                                fontFamily: 'Normal',
                                fontSize: '0.75rem',
                                marginBottom: '10px',
                                background: '#3f51b5',
                              }}>
                              Add New Template
                            </Button>
                            <div className="d-flex flex-column-reverse">
                              {Templatedata.map((item, index) => {
                                return (
                                  <MenuItem
                                    // onClick={popupState.close}
                                    key={index}
                                    style={{
                                      width: '450px',
                                      margin: 0,
                                      padding: 0,
                                      border: '1px solid rgba(0,0,0,0.250)',
                                      borderBottom: '1px solid rgba(0,0,0,.250)',
                                      borderCollapse: 'collapse',
                                      borderRadius: '5px',
                                      paddingTop: '0.25em',
                                      paddingBottom: '0.25em',
                                      marginInline: '0.1em',
                                      fontFamily: 'Normal',

                                      flexDirection: 'column',
                                    }}>
                                    <div
                                      className="w-100"
                                      style={{
                                        fontSize: '0.95rem',
                                        paddingBlock: '0.5em',
                                      }}>
                                      <div className="d-flex-column w-100  m-0  p-0 ">
                                        <div
                                          className="text-capitalize  "
                                          style={{
                                            minHeight: '25px',
                                            padding: '5px 10px',
                                            borderBottom: '1px solid rgba(0,0,0,.125)',
                                          }}>
                                          <ButtonGroup size="small" aria-label="small button group">
                                            <Button
                                              variant="contained"
                                              size="small"
                                              style={{ background: '#3f51b5' }}
                                              onClick={() => {
                                                editTemplate(item, popupState.close);
                                              }}>
                                              <EditIcon fontSize="small" />
                                            </Button>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              style={{ background: '#3f51b5' }}
                                              onClick={() => deleteTemplate(item.Id)}>
                                              <DeleterIcon fontSize="small" />
                                            </Button>
                                          </ButtonGroup>
                                        </div>
                                      </div>
                                      <div
                                        onClick={() => {
                                          selectTemplate(item);
                                          popupState.close();
                                        }}>
                                        <div className="d-flex-column w-100  m-0  p-0 ">
                                          <div className="text-capitalize emailTempItem ">
                                            <small className="font-weight-normal">Title : </small>
                                            <br />
                                            <b>{item.TemplateName}</b>
                                          </div>
                                        </div>
                                        <div className="d-flex-column w-100  m-0 p-0 ">
                                          <div className=" emailTempItem" style={{ minHeight: '40px' }}>
                                            <small className="font-weight-normal">Subject' : </small>
                                            <br />

                                            {item.TemplateSubject && item.TemplateSubject.length > 60
                                              ? `${item.TemplateSubject.substring(0, 60)}...`
                                              : item.TemplateSubject}
                                          </div>
                                        </div>
                                        <div className="d-flex-column w-100  m-0  p-0  ">
                                          <div
                                            className=" w-100 text-wrap text-break emailTempItem"
                                            style={{
                                              minHeight: '40px',
                                              borderBottom: 'none',
                                            }}>
                                            <small className="font-weight-normal">Content : </small>
                                            <br />
                                            {getFormatedMailContent(item.TemplateBody)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </MenuItem>
                                );
                              })}
                            </div>
                          </Menu>
                        </div>
                      )}
                    </PopupState>
                    <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                      <TextField
                        label="To"
                        variant="outlined"
                        fullWidth
                        placeholder="Add multiple email with comma separated value."
                        value={emailsTo.join(', ')}
                        onChange={e => {
                          const emailList = e.target.value.split(', ');
                          setEmailsTo(emailList);
                        }}
                        onBlur={e => {
                          const filteredEmails = emailsTo.filter(email => email && isEmail(email));
                          setEmailsTo(filteredEmails);
                        }}
                      />
                      <br />
                      <TextField
                        label="CC"
                        variant="outlined"
                        fullWidth
                        placeholder="Add multiple email with comma separated value."
                        value={emailsCC.join(', ')}
                        onChange={e => {
                          const emailList = e.target.value.split(', ');
                          setEmailsCC(emailList);
                        }}
                        onBlur={e => {
                          const filteredEmails = emailsCC.filter(email => email && isEmail(email));
                          setEmailsCC(filteredEmails);
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}

                <br />
                <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                  {/* <InputLabel htmlFor="age-native-helper">
                    {openCustomTemplate ? 'Template Subject' : <IntlMessages id="appModule.invoice_subject_email" />}
                  </InputLabel> */}
                  <Controller
                    name={openCustomTemplate ? 'TemplateSubject' : 'MailSubject'}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        label={
                          openCustomTemplate ? 'Template Subject' : <IntlMessages id="appModule.invoice_subject_email" />
                        }
                        inputRef={currMailSubRef}
                        value={openCustomTemplate ? customTemplateData.TemplateSubject : mailSub}
                        name={openCustomTemplate ? 'TemplateSubject' : 'MailSubject'}
                        onChange={e => {
                          handelChange(field.name, e.target.value);
                          field.onChange(e.target.value);
                        }}
                        onFocus={e => onTextAreaFocus('TemplateSubject')}
                        margin="none"
                        style={{ padding: '10px 0px' }}
                      />
                    )}
                  />
                </div>
                <br />
                <div className="col-lg-12 d-flex flex-column invoice_label mb-4" style={{ paddingBottom: '20px' }}>
                  <div className="d-flex justify-content-between align-items-center my-2">
                    <div>
                      {' '}
                      <InputLabel htmlFor="age-native-helper">
                        {openCustomTemplate ? 'Template Content' : 'Content'}
                      </InputLabel>
                    </div>
                    <div>
                      {' '}
                      {openEditTemplate ? (
                        <Button
                          // type="submit"

                          variant="contained"
                          style={{ background: 'rgb(63, 81, 181)', marginRight: '2em', fontFamily: 'normal' }}
                          onClick={() => {
                            handleSubmit(e => console.log(''));
                            saveEditTemplate();
                          }}
                          // disabled={details.length === 0 ? false : true}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          // type="submit"
                          variant="contained"
                          style={{ background: 'rgb(63, 81, 181)', marginRight: '2em', fontFamily: 'normal' }}
                          onClick={() => {
                            !openCustomTemplate ? openTemplateWindow() : addToTemplate();
                          }}
                          // disabled={details.length === 0 ? false : true}
                        >
                          Add To Template
                        </Button>
                      )}
                      {openCustomTemplate ? (
                        ''
                      ) : (
                        <Button
                          // type="submit"
                          disabled={app.user ? false : true}
                          variant="contained"
                          style={{ background: app.user ? 'rgb(63, 81, 181)' : 'grey', fontFamily: 'normal' }}
                          onClick={handleSubmit(e => sendMail(e))}
                          // disabled={details.length === 0 ? false : true}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  </div>
                  <Controller
                    name={openCustomTemplate ? 'TemplateBody' : 'MailContent'}
                    control={control}
                    defaultValue=""
                    // defaultValue={InvoiceNumber ? 'Invoice No: ' + InvoiceNumber : ''}
                    render={({ field }) => (
                      <ReactQuill
                        ref={currMaibodyRef}
                        name={openCustomTemplate ? 'TemplateBody' : 'MailContent'}
                        value={openCustomTemplate ? customTemplateData.TemplateBody : mailContent}
                        style={{ height: '500px', textTransform: 'none' }}
                        modules={textEditorModules}
                        onChange={e => {
                          //  field.onChange(e === null ? '' : e);
                          handelChange(field.name, e);
                        }}
                        onFocus={e => onTextAreaFocus('TemplateBody')}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* {openEditTemplate ? (
              <Button
             
                variant="contained"
                style={{ background: 'rgb(63, 81, 181)', marginRight: '2em' }}
                onClick={() => {
                  handleSubmit(e => console.log(''));
                  saveEditTemplate();
                }}
               
              >
                Save
              </Button>
            ) : (
              <Button
                // type="submit"
                variant="contained"
                style={{ background: 'rgb(63, 81, 181)', marginRight: '2em' }}
                onClick={() => {
                  !openCustomTemplate ? openTemplateWindow() : addToTemplate();
                }}
             
              >
                Add To Template
              </Button>
            )}

            {openCustomTemplate ? (
              ''
            ) : (
              <Button
                // type="submit"
                disabled={app.user ? false : true}
                variant="contained"
                style={{ background: app.user ? 'rgb(63, 81, 181)' : 'grey' }}
                onClick={handleSubmit(e => sendMail(e))}
               
              >
                Send
              </Button>
            )} */}

            <Button
              sx={{ fontFamily: 'normal' }}
              onClick={() => {
                handelClose();
              }}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default MailFormTemplate;
