import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function TaskInformation({ taskDetails, index, teamMembers }) {
  const userInformation = teamMembers?.find(element => element.TMUserId === taskDetails.UserId);
  const assignedTo = userInformation?.Email?.split('@')[0];
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date
      ?.getDate()
      ?.toString()
      ?.padStart(2, '0');
    const month = (date?.getMonth() + 1)?.toString()?.padStart(2, '0');
    const year = date?.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return (
    <List key={index} sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={assignedTo?.substring(0, 2)} src={`data:image/png;base64,${userInformation?.AvatarImage}`} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <p style={{ fontFamily: 'normal' }}>
              {taskDetails?.TaskName} <CheckCircleIcon color={taskDetails.IsCompleted === 1 ? 'success' : 'disabled'} />
            </p>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline', fontFamily: 'normal' }}
                component="span"
                variant="body2"
                color="text.primary">
                {assignedTo}
              </Typography>
              {taskDetails?.tDueDate !== null ? ` - ${formatDate(taskDetails?.tDueDate)}` : ''}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}
