import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageViewerModal = ({ open, onClose, imageUrl }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <img src={imageUrl} alt="User Avatar" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
      </Box>
    </Modal>
  );
};

export default ImageViewerModal;
