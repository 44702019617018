/**
 * Version 2.0
 * Vikas W
 * 28.07.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
import CreateExpense from './CreateExpense';
import { NavLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { FormControl } from '@mui/material';
import Select from 'react-select';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  //  INIT_URL,
  //  SIGNOUT_USER_SUCCESS,
  //  USER_DATA,
  //  USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Material Tables */
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
//  import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useRef } from 'react';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { CSVLink } from 'react-csv';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadPdf from './DownloadPdf';
import { useDownloadPdf } from './useDownloadPdf';
import TaskFilterDialog from './TaskToolbarComponent/TaskFilterDialog';
import SkeletonLoader from './SkeletonLoader';

const MenuStyled = styled(Menu)({
  padding: 8,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: 5,
});

const CustomTableCell = styled(TableCell)`
  && {
    padding: 8px; /* Set the desired padding here */
  }
`;

const MenuIconsText = {
  fontSize: '16px',
  color: '#0e0e0e',
  textDecoration: 'none',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
};

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 3,
    marginTop: theme.spacing(1),
    // display: 'flex',
    // flexDirection: 'row',
    // width: 140,
    // height: 45,
    overflow: 'hidden',
    // border: 'none',
    // color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: 'rgba(14, 14, 30, 0.07) 0px 4px 9px -2px, rgba(0, 0, 0, 0.07) 0px 3px 4px -1px',
    '& .MuiMenu-list': {
      padding: '4px 0px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(0.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const viewModalstyleHorizontal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const viewModalstyleVertical = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'ImgHref',
    numeric: false,
    disablePadding: false,
    label: '',
    minWidth: 10,
  },
  {
    id: 'ExpenseDescription',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    minWidth: 150,
  },
  {
    id: 'ExpenseType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
    minWidth: 100,
  },
  {
    id: 'ExpenseAmount',
    numeric: false,
    disablePadding: false,
    label: 'Gross Amount',
    minWidth: 100,
  },
  {
    id: 'TaxRate',
    numeric: false,
    disablePadding: false,
    label: 'Tax Rate',
    minWidth: 100,
  },
  {
    id: 'TaxAmount',
    numeric: false,
    disablePadding: false,
    label: 'Tax Amount',
    minWidth: 100,
  },
  {
    id: 'ExpenseDateTime',
    numeric: false,
    disablePadding: false,
    label: 'Date',
    minWidth: 110,
  },

  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 120,
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <CustomTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const menuStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px ',
      '& div': {
        marginRight: '8px',
        fontSize: '0.95rem',
      },
    },

    top: '40px !important',
  },
}));

const Expenses = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [customData, setCustomData] = useState([]);

  /* Modal open/close */
  const [details, setDetails] = useState([]);
  const [open, setOpen] = useState(false);

  // search
  const [searchKey, setSearchKey] = useState('');
  const inputRef = useRef(null);

  //table config
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const cusclass = menuStyles();
  const downloadCsv = useRef(null);
  const [modalViewData, setModalViewData] = useState({});
  const [openViewModal, setOpenViewModal] = useState(false);
  const [getId, setGetId] = useState();
  const [addNewExpense, setAddNewExpense] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isActionMenu, setIsActionMenu] = useState(false);
  const [directViewForExpense, setDirectViewForExpense] = useState();
  const [originalData, setOriginalData] = useState([]);
  const [imageDimentions, setImageDimentions] = useState({});

  const { handleViewPdf } = useDownloadPdf();
  const openActions = Boolean(anchorEl);
  //AccTeams
  const [accTeamId, setAccTeamId] = useState(null);
  const [accTeamAll, setAccTeamAll] = useState([]);

  if (directViewForExpense || modalViewData) {
    const img = new Image();
    img.onload = function() {
      const width = this.width;
      const height = this.height;
      setImageDimentions({ width, height });
    };
    img.onerror = e => {};
    img.src = `data:image/png;base64,${directViewForExpense?.ImgData}` || `data:image/png;base64,${modalViewData}`;
  }

  const handleClickActions = (id, event) => {
    setAnchorEl(event.currentTarget);
    setModalViewData(customData.find(item => item.Id === id));
    setGetId(id);
    setIsActionMenu(true);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const handleOpen = (data, id) => {
    setOpenViewModal(true);
    setGetId(id);
  };
  const handleClose = () => {
    setOpenViewModal(false);
    setDirectViewForExpense(null);
    setModalViewData({});
  };

  const toggles = () => {
    handleClickData();
  };
  const actionsList = [
    {
      onClick: toggles,
      label: <IntlMessages id="crm.excelCSVExport" />,
    },
  ];

  const handleClickData = () => {
    downloadCsv.current.link.click();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.ClientName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const expenseActionHandler = (action, data) => {
    if (action === 'detail') {
      data.edit = true;
      toggle();
      setDetails(data);
      setAddNewExpense(false);
    }
    if (action === 'delete') {
      deleteExpense(modalViewData.Id);
    }
  };

  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredDraftRows = [];
    let filteredSentRows = [];
    filteredDraftRows = getArrayFilterData(data, searchkey);
    // filteredSentRows = getArrayFilterData(completedData, searchkey);

    // if (searchkey.length <= 0) {
    //   setSearchDraftData(data);
    //   setSearchSentData(completedData);
    // } else {
    //   setSearchDraftData(filteredDraftRows);
    //   setSearchSentData(filteredSentRows);
    // }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.ExpenseDescription.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.ExpenseType.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
  };

  /** Delete Expense **/
  function deleteExpense(id) {
    let url = '/Expense/DeleteExpense/' + id + '?AcctTeamId=' + accTeamId;
    dispatch({ type: FETCH_START });
    customAxios
      .delete(url, { headers: authHeader() })
      .then(data => {
        GetExpenses();
        setModalViewData({});
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const handleClick = (event, data) => {
    data.edit = true;

    const selectedIndex = selected.indexOf(data.name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);

  const GetExpenses = () => {
    if (!accTeamId) return;
    dispatch({ type: FETCH_START });
    try {
      let url = 'Expense/GetExpenses/' + accTeamId;
      console.log('fetching');
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            console.log('success');
            setData(data);
            setCustomData(data.sort((a, b) => new Date(b.ExpenseDateTime) - new Date(a.ExpenseDateTime)));
            setOriginalData(data);
            dispatch({ type: FETCH_SUCCESS });
            setSkeletonLoaderState(false);
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    GetExpenses();
  }, [accTeamId]);
  const onClientsClose = () => {
    setOpen(false);
    GetExpenses();
  };
  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get('Acct/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data && data.length > 0) {
            let allteam = data.map(c => ({ value: c.Id, label: c.AcctTeamName }));

            setAccTeamAll(allteam);
            setAccTeamId(allteam[0].value);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            setAccTeamId(null);
            dispatch({ type: FETCH_SUCCESS });
            // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  //modal open/close
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setDetails([]);
  };
  const onTeamChangeHandler = args => {
    // console.log(args);
    setAccTeamId(args.value);
  };

  const [orientation, setOrientation] = useState('unknown');

  // useEffect(() => {
  //   console.log('running');
  //   const img = new Image();

  //   img.onload = function() {
  //     const width = this.width;
  //     const height = this.height;

  //     if (width > height) {
  //       console.log('Landscape (Horizontal) Orientation');
  //     } else {
  //       console.log('Portrait (Vertical) Orientation');
  //     }
  //   };
  // }, [directViewForExpense?.ImgData]);

  // const handleImageLoad = () => {
  //   const width = directViewForExpense?.ImgData.naturalWidth;
  //   const height = directViewForExpense?.ImgData.naturalHeight;
  //   console.log(height, width);
  //   if (height > width) {
  //     setOrientation('vertical');
  //   } else if (width > height) {
  //     setOrientation('horizontal');
  //   } else {
  //     setOrientation('square');
  //   }
  // };

  // console.log({ orientation });

  return (
    <div className="app-wrapper">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.expensesAll" />} /> */}

      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.expensesList" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={accTeamAll}
              value={accTeamAll.find(c => c.value == accTeamId)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        {accTeamAll.length > 0 ? (
          <div className="content-horizontal__center">
            <TaskFilterDialog
              setCustomData={setCustomData}
              originalData={originalData}

              // filterSelectedData={filterSelectedData}
              // setFilterSelectedData={setFilterSelectedData}
              // getUserProfileData={getUserProfileData}
              // setFilterSelectedSend={setFilterSelectedSend}
              // value={value}
              // setIsFilterApplied={setIsFilterApplied}
            />
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => searchCRMData(e)}
                value={searchKey}
                inputRef={inputRef}
              />
              {searchKey && (
                <CloseIcon
                  onClick={clearSearchData}
                  style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
                />
              )}
            </Search>
            <Button
              variant="contained"
              style={{ textTransform: 'none' }}
              color="primary"
              onClick={() => {
                toggle();
                setAddNewExpense(true);
              }}>
              Add
            </Button>
            <CmtDropdownMenu
              TriggerComponent={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickData}
                  sx={{
                    mx: 2,
                  }}
                  style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
                  <MoreVertIcon />
                </Button>
              }
              items={[...actionsList]}
              menuClasses={cusclass.root}
            />
            <CSVLink data={customData} ref={downloadCsv} filename={'expenses-file.csv'} />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {accTeamAll.length > 0 ? (
        <Box className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={customData.length}
                />
                <TableBody>
                  {skeletonLoaderState ? (
                    <SkeletonLoader />
                  ) : (
                    <>
                      {stableSort(customData, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          let isPdf = modalViewData?.ImgName?.endsWith('pdf');
                          let isPdfIcon = row?.ImgName?.endsWith('pdf');

                          const date = new Date(row.ExpenseDateTime);

                          const day = date
                            .getDate()
                            .toString()
                            .padStart(2, '0');
                          const month = (date.getMonth() + 1).toString().padStart(2, '0');
                          const year = date.getFullYear().toString();
                          const formattedDate = `${day}-${month}-${year}`;

                          return (
                            <TableRow hover onClick={event => handleClick(event, row)} tabIndex={-1} key={index}>
                              <CustomTableCell align="left" sx={{ padding: 7 }} component="th" id={labelId} scope="row">
                                {row.ImgName && isPdfIcon ? (
                                  <DownloadPdf fileData={row} isAction={false} />
                                ) : (
                                  <div
                                    className={`${classes.actionIcon} `}
                                    // style={{ marginBottom: '10px' }}
                                    onClick={() => {
                                      handleOpen(row, row.Id);
                                      setAnchorEl(null);
                                      setIsActionMenu(false);
                                      setDirectViewForExpense(row);
                                    }}>
                                    {/* <img
                                    id="ItemPreview"
                                    src={`data:image/png;base64,${row.ImgData}`}
                                    alt={row.ImgName}
                                    width="50"
                                  /> */}
                                    <VisibilityIcon className={classes.actionIcon} />
                                  </div>
                                )}
                              </CustomTableCell>
                              <CustomTableCell component="th" align="left" id={labelId} scope="row">
                                {row.ExpenseDescription}
                              </CustomTableCell>
                              <CustomTableCell align="left">{row.ExpenseType}</CustomTableCell>
                              <CustomTableCell align="left">
                                <strong>&euro;</strong>
                                {row.ExpenseAmount}
                              </CustomTableCell>
                              <CustomTableCell align="left">
                                {/* <strong>&euro;</strong> */}
                                {row.VatRate}%
                              </CustomTableCell>
                              <CustomTableCell align="left">
                                <strong>&euro;</strong>
                                {row.VatAmount}
                              </CustomTableCell>
                              <CustomTableCell align="left">{formattedDate}</CustomTableCell>
                              <CustomTableCell align="left">
                                <IconButton
                                  sx={{ boxShadow: 'none' }}
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={e => handleClickActions(row.Id, e)}>
                                  <MoreHorizIcon />
                                </IconButton>
                                <StyledMenu
                                  id="long-menu"
                                  anchorEl={anchorEl}
                                  open={openActions}
                                  onClose={handleCloseActions}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}>
                                  <div className="py-2 px-3 d-flex flex-column justify-content-center align-items-start">
                                    {isPdf ? (
                                      <div
                                        className={`${classes.actionIcon} d-flex flex-row justify-content-start align-items-center`}
                                        style={{ marginBottom: '10px' }}>
                                        <VisibilityIcon sx={{ color: '#0e0e0e' }} className={classes.actionIcon} />{' '}
                                        <span onClick={e => handleViewPdf(e, modalViewData?.ImgData)} style={MenuIconsText}>
                                          View
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className={`${classes.actionIcon} d-flex flex-row justify-content-start align-items-center`}
                                        style={{ marginBottom: '10px' }}
                                        onClick={() => {
                                          handleOpen(row, row.Id);
                                          setAnchorEl(null);
                                          setIsActionMenu(true);
                                        }}>
                                        <VisibilityIcon sx={{ color: '#0e0e0e' }} className={classes.actionIcon} />{' '}
                                        <span style={MenuIconsText}>View</span>
                                      </div>
                                    )}

                                    <div
                                      style={{ marginBottom: '10px' }}
                                      className={`${classes.actionIcon} d-flex flex-row justify-content-start align-items-center`}
                                      onClick={() => {
                                        expenseActionHandler('detail', row);
                                        setAnchorEl(null);
                                      }}>
                                      {' '}
                                      <EditIcon sx={{ color: '#0e0e0e' }} />{' '}
                                      <span className="" style={MenuIconsText}>
                                        Edit
                                      </span>
                                    </div>

                                    <div
                                      className={`${classes.actionIcon} d-flex flex-row justify-content-start align-items-center`}
                                      onClick={() => {
                                        expenseActionHandler('delete', row);
                                        setAnchorEl(null);
                                      }}>
                                      <DeleteIcon sx={{ color: '#0e0e0e' }} />{' '}
                                      <span className="" style={MenuIconsText}>
                                        Delete
                                      </span>
                                    </div>
                                  </div>
                                </StyledMenu>
                              </CustomTableCell>
                            </TableRow>
                          );
                        })}

                      <Modal
                        open={openViewModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box
                          sx={
                            imageDimentions.height > imageDimentions.width
                              ? viewModalstyleVertical
                              : viewModalstyleHorizontal
                          }>
                          {isActionMenu ? (
                            <img src={`data:image/png;base64,${modalViewData?.ImgData}`} />
                          ) : (
                            <img src={`data:image/png;base64,${directViewForExpense?.ImgData}`} />
                          )}
                        </Box>
                      </Modal>

                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              getid={getId}
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={customData.length}
              customdata={customData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      ) : (
        <div style={{ marginTop: '5px', fontSize: '1rem', padding: '5px 20px' }}>
          You are not part of any team, Start your own Accounts team to add expenses. Go to "Accounting Team" page.
          <NavLink to="/accounting/accounting-teams">"Here"</NavLink>{' '}
        </div>
      )}
      {modal && (
        <CreateExpense
          modal={modal}
          details={modalViewData}
          toggle={toggle}
          onClientsClose={onClientsClose}
          clients={{ name: '' }}
          editData={modalViewData}
          addNewExpense={addNewExpense}
          customData={customData}
          isBankStatement={false}
          accTeamId={accTeamId}
        />
      )}
    </div>
  );
};

export default Expenses;
