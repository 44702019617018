/**
 * Version 1.0
 * Vikas W
 * 07.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';

/* Material Components */
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import IntlMessages from '../../util/IntlMessages';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

/* import xlsx package */
import * as XLSX from 'xlsx';

const ImportExcel = ({
  modal,
  toggle,
  getContactsHandler,
  handleRecordToastMessage,
  Teams,
  Stages,
  currentTeam,
  currTabInfo,
  setUploadedRecordsCount,
}) => {
  const [teamCheck, setTeamCheck] = useState(true);
  const [stageCheck, setStageCheck] = useState(true);
  const [crmTeamId, setCrmTeamId] = useState(null);
  const [crmStageId, setCrmStageId] = useState(null);
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [crmDefault, setCrmDefault] = useState([
    // { TableColumnName: '', ExelColumnName: '' },
    // { TableColumnName: 'crmStageName', ExelColumnName: '' },
    { TableColumnName: 'Posted Account', ExelColumnName: '' },
    { TableColumnName: 'Posted Transaction Date', ExelColumnName: '' },
    { TableColumnName: 'Description 1', ExelColumnName: '' },
    { TableColumnName: 'Description 2', ExelColumnName: '' },
    { TableColumnName: 'Description 3', ExelColumnName: '' },
    { TableColumnName: 'Debit Amount', ExelColumnName: '' },
    { TableColumnName: 'Credit Amount', ExelColumnName: '' },
    { TableColumnName: 'Balance', ExelColumnName: '' },
    { TableColumnName: 'Posted Currency', ExelColumnName: '' },
    { TableColumnName: 'Transaction Type', ExelColumnName: '' },
    { TableColumnName: 'Local Currency Amount', ExelColumnName: '' },
    { TableColumnName: 'Local Currency', ExelColumnName: '' },
  ]);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [isSaveDisabled, setIsSaveDidabled] = useState(true);
  let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  let fileCSVType = 'text/csv';
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  /* Select Dropdown column Map*/
  const [excelColumnMap, setExcelColumnMap] = useState([
    { label: 'Company Name', value: 'Company Name' },
    { label: 'Website', value: 'Website' },
  ]);

  const handleChange = (event, mapName) => {
    setFields(false);
    let finalMap = crmDefault.map(data => {
      if (data.TableColumnName === mapName.TableColumnName) {
        data.ExelColumnName = event.target.value;
      }
      return data;
    });
  };
  const handleTeamChange = event => {
    setTeamCheck(false);
    setCrmTeamId(event.target.value);
  };
  const handleStageChange = (event, mapName) => {
    setStageCheck(false);
    setCrmStageId(event.target.value);
  };

  const handlefle = e => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && (fileType.includes(selectedFile.type) || fileCSVType.includes(selectedFile.type))) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = e => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError('please select excel file type');
        setExcelFile(null);
      }
    }
  };
  const [fields, setFields] = useState(false);

  const importSubmit = e => {
    e.preventDefault();
    console.log('submited');
    setButtonDisabled(true);
    setIsSaveDidabled(false);

    let sheetWiseData = [];
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });

      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        sheetWiseData = [...sheetWiseData, ...data];
      });

      if (sheetWiseData) {
        setExcelData(sheetWiseData);
        var keys = Object.keys(sheetWiseData[0]);
        let data = keys.map(data => ({ label: data, value: data }));
        setExcelColumnMap(data);
      }
    } else {
      setExcelData(null);
    }
  };

  // console.log({ excelData, excelColumnMap }, 'excelData');
  // console.log(excelColumnMap, 'excelColumnMap');
  const fileSubmitHandler = () => {
    const finalObj = [];
    excelData.forEach(data => {
      finalObj.push({
        // crmStageName: data[crmDefault.filter(val => val.TableColumnName === 'crmStageName')[0].ExelColumnName],
        crmStageId: crmStageId,
        CrmTeamId: crmTeamId,
        crmContactTitle: data[crmDefault.filter(val => val.TableColumnName === 'crmContactTitle')[0].ExelColumnName],
        crmContactFirst: data[crmDefault.filter(val => val.TableColumnName === 'crmContactFirst')[0].ExelColumnName],
        crmContactMiddle: data[crmDefault.filter(val => val.TableColumnName === 'crmContactMiddle')[0].ExelColumnName],
        crmContactLast: data[crmDefault.filter(val => val.TableColumnName === 'crmContactLast')[0].ExelColumnName],
        crmLeadReferralSource:
          data[crmDefault.filter(val => val.TableColumnName === 'crmLeadReferralSource')[0].ExelColumnName],
        crmDateOfInitialContact:
          data[crmDefault.filter(val => val.TableColumnName === 'crmDateOfInitialContact')[0].ExelColumnName],
        crmTitle: data[crmDefault.filter(val => val.TableColumnName === 'crmTitle')[0].ExelColumnName],
        crmCompany: data[crmDefault.filter(val => val.TableColumnName === 'crmCompany')[0].ExelColumnName],
        crmIndustry: data[crmDefault.filter(val => val.TableColumnName === 'crmIndustry')[0].ExelColumnName],
        crmAddressLine1: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressLine1')[0].ExelColumnName],
        crmAddressLine2: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressLine2')[0].ExelColumnName],
        crmAddressCity: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressCity')[0].ExelColumnName],
        crmAddressTown: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressTown')[0].ExelColumnName],
        crmAddressZip: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressZip')[0].ExelColumnName],
        crmAddressCountry: data[crmDefault.filter(val => val.TableColumnName === 'crmAddressCountry')[0].ExelColumnName],
        crmContactPhone: data[crmDefault.filter(val => val.TableColumnName === 'crmContactPhone')[0].ExelColumnName],
        crmContactEmail: data[crmDefault.filter(val => val.TableColumnName === 'crmContactEmail')[0].ExelColumnName],
        crmContactStatus: data[crmDefault.filter(val => val.TableColumnName === 'crmContactStatus')[0].ExelColumnName],
        crmContactWebsite: data[crmDefault.filter(val => val.TableColumnName === 'crmContactWebsite')[0].ExelColumnName],
        crmLinkedInProfile: data[crmDefault.filter(val => val.TableColumnName === 'crmLinkedInProfile')[0].ExelColumnName],
        crmBackgroundInfo: data[crmDefault.filter(val => val.TableColumnName === 'crmBackgroundInfo')[0].ExelColumnName],
        crmSalesRep: data[crmDefault.filter(val => val.TableColumnName === 'crmSalesRep')[0].ExelColumnName],
        crmRating: data[crmDefault.filter(val => val.TableColumnName === 'crmRating')[0].ExelColumnName],
        crmProjectType: data[crmDefault.filter(val => val.TableColumnName === 'crmProjectType')[0].ExelColumnName],
        crmProjectDescription:
          data[crmDefault.filter(val => val.TableColumnName === 'crmProjectDescription')[0].ExelColumnName],
        crmProposalDueDate: data[crmDefault.filter(val => val.TableColumnName === 'crmProposalDueDate')[0].ExelColumnName],
        crmBudget: data[crmDefault.filter(val => val.TableColumnName === 'crmBudget')[0].ExelColumnName],
        crmDeliverable: data[crmDefault.filter(val => val.TableColumnName === 'crmDeliverable')[0].ExelColumnName],
        crmDoNotContact: data[crmDefault.filter(val => val.TableColumnName === 'crmDoNotContact')[0].ExelColumnName],
        crmDoNotEmail: data[crmDefault.filter(val => val.TableColumnName === 'crmDoNotEmail')[0].ExelColumnName],
      });

      const mandatoryFields = [crmDefault[1], crmDefault[3]];

      const crmDefaultAllField = mandatoryFields.filter(data => data.ExelColumnName !== '');
      // console.log({ finalObj, crmDefaultAllField }, 'finalObj');

      if (crmDefaultAllField.length === mandatoryFields.length) {
        setUploadedRecordsCount(crmDefaultAllField.length);
        setFields(false);
        dispatch({ type: FETCH_START });
        customAxios
          .post('/CRM/AddMultipleContacts', finalObj, { headers: authHeader() })
          .then(data => {
            dispatch({ type: FETCH_SUCCESS });
            getContactsHandler();
            toggle();
          })
          .catch(function(error) {
            if (error.response.status == '400') {
              var errorList = error.response.data.errors;
              if (errorList) {
                Object.keys(errorList).forEach((key, index) => {
                  setError('apiError', {
                    message: errorList[key],
                  });
                });
              }
            }
            dispatch({ type: FETCH_ERROR, payload: error.message });
          });
        handleRecordToastMessage();
      } else {
        setFields(true);
      }
    });
  };

  // useEffect(() => {
  //   let teamID = currentTeam != {} ? currentTeam.value : null;
  //   let stageID = currTabInfo != {} ? currTabInfo.StageId : null;
  //   setCrmTeamId(currentTeam != {} ? currentTeam.value : null);
  //   setCrmStageId(currTabInfo != {} ? currTabInfo.StageId : null);
  //   if (teamID) {
  //     setTeamCheck(false);
  //   }
  //   if (stageID) {
  //     setStageCheck(false);
  //   }
  // }, [excelData]);

  function modifyString(input) {
    // Convert camel case to space-separated words
    const modified = input.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Convert to lowercase and remove the initial prefix
    let output = modified.toLowerCase().replace(/^crm/, '');

    // Remove the word "contact" from the modified string
    output = output.replace(/\bcontact\b/g, '');

    return output.trim(); // Trim any leading or trailing whitespace
  }

  return (
    <Modal className="invoice modal-box" size="sm" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        {/* <IntlMessages id="crm.excelImport" /> */}
        Bulk Upload CSV/Excel
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>

      <ModalBody>
        <div className="modal-box-content" style={{ padding: '0px 140px' }}>
          <ErrorMessage errors={errors} name="importExcel" render={({ message }) => <p>{message}</p>} />
          <form
            name="myForm"
            onSubmit={importSubmit}
            style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
            <div className="row no-gutters">
              <div className="col-lg-9 d-flex flex-column order-lg-1 invoice_label">
                <input type="file" style={{ padding: '3px' }} className="form-control" required onChange={handlefle} />
                <div className="json-container">{excelFileError && <div>{excelFileError}</div>}</div>
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-2 d-flex flex-column order-lg-1 invoice_label">
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="crm.upload" />
                </Button>
              </div>
            </div>
          </form>
          {excelData && (
            <>
              {fields && <p className="text-danger">*First and last name are mandatory fields</p>}
              <div className="row no-gutters">
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label mb-1">
                  <br />
                  <InputLabel htmlFor="age-native-helper">
                    <strong>Columns</strong>
                  </InputLabel>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                  <br />
                  <InputLabel htmlFor="age-native-helper">
                    <strong>Imported Column Names</strong>
                  </InputLabel>
                </div>
              </div>

              {/* <div className="row no-gutters">
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                  <span className="import-label">Team Name</span>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 mt-1  invoice_label mt-xl-n3">
                  <FormControl error={teamCheck} size="small" variant="standard">
                  
                    <Select
                      fullWidth
                      id={'columnMap_' + 'team'}
                      labelId={'columnMapL_' + 'team'}
                      displayEmpty
                      name={'columnMap_' + 'team'}
                      defaultValue={currentTeam != {} ? currentTeam.value : null}
                      onChange={e => handleTeamChange(e)}>
                   
                      <MenuItem value={currentTeam.value}>{currentTeam.label}</MenuItem>
                      
                    </Select>
                  </FormControl>
                </div>
                <hr />
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                  <span className="import-label">Stage Name</span>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 mt-1 invoice_label mt-xl-n3">
                  <FormControl error={stageCheck} size="small" variant="standard">
                    <Select
                      fullWidth
                      id={'columnMap_' + 'stage'}
                      labelId={'columnMapL_' + 'stage'}
                      displayEmpty
                      name={'columnMap_' + 'stage'}
                      onChange={e => handleStageChange(e)}
                      defaultValue={currTabInfo != {} ? currTabInfo.StageId : null}>
                      {Stages.map((stage, idx) => {
                        if (stage.CrmTeamId == currentTeam.value) {
                          return (
                            <MenuItem value={stage.StageId} key={idx}>
                              {stage.crmStageName}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </div>
                <hr />
              </div> */}
            </>
          )}
          {excelData &&
            crmDefault.map((row, index) => {
              // const firstKey = Object?.keys(row)[0];
              return (
                <div className="row no-gutters" key={index}>
                  <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                    {/* <span className="import-label">{modifyString(row.TableColumnName)}</span> */}
                    <span className="import-label">{row.TableColumnName}</span>
                  </div>
                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                    <FormControl size="small" variant="standard">
                      <Select
                        fullWidth
                        id={'columnMap_' + index}
                        labelId={'columnMapL_' + index}
                        // margin="dense"
                        displayEmpty
                        name={'columnMap_' + index}
                        onChange={e => handleChange(e, row)}
                        // value={value}
                      >
                        <MenuItem value="">Empty</MenuItem>
                        {excelColumnMap.map((element, idx) => {
                          const [[key, val]] = Object.entries(element);
                          return (
                            <MenuItem value={val} key={idx}>
                              {val}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <hr />
                </div>
              );
            })}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // onClick={fileSubmitHandler}
          disabled={isSaveDisabled}>
          <IntlMessages id="invoices.createInvoice" />
        </Button>

        <Button onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImportExcel;
