import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import globalStyles from '../../../theme/GlobalCss';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import { LAYOUT_TYPES } from '../../constants/ThemeOptions';
import VerticalDefault from './VerticalLayouts/VerticalDefault';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import MinimalNoHeader from './VerticalLayouts/MinimalNoHeader';
import ModernSideBar from './VerticalLayouts/ModernSidebar';
import HorizontalDefault from './HorizontalLayouts/HorizontalDefault';
import HorizontalDark from './HorizontalLayouts/HorizontalDark';
import HorizontalMinimal from './HorizontalLayouts/HorizontalMinimal';
import HorizontalTopMenu from './HorizontalLayouts/HorizontalTopMenu';
import { fetchError } from 'redux/actions';
import { Poller } from 'routes/Pages/util/Poller';
import customAxios from 'routes/Pages/util/Api';
// import axios from 'axios';

const useStyles = makeStyles(() => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  // const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')));
  const { layout } = useContext(AppContext);
  const { loadUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();
  const [seconds, setSeconds] = useState(0);

  // const testingToken = () => {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   window.location.href = '/signin';
  // };
  // useEffect(() => {
  //   // testingToken();
  // }, []);

  const Checkuser = () => {
    const token = JSON.parse(localStorage.getItem('token'));

    if (token && token.access_token) {
      const expiresInSeconds = token.expires_in;
      const expirationDate = new Date(new Date().getTime() + expiresInSeconds * 1000);
      const current = new Date();

      if (!isNaN(expirationDate) && current < expirationDate) {
        dispatch(AuhMethods[CurrentAuthMethod].getAuthUserWithLocalStorage(true, token.access_token));
        setLayoutLoader(false);
      } else {
        dispatch(AuhMethods.jwtAuth.onLogout());
        setLayoutLoader(false);
        dispatch(fetchError('Session expired, logging out'));
      }
    }
  };

  // ...

  useEffect(() => {
    Checkuser();
    setLayoutLoader(false);
  }, [dispatch]);

  Poller(Checkuser, 10000, null);

  useEffect(() => {
    Checkuser();
    setLayoutLoader(false);
  }, [dispatch]);

  Poller(Checkuser, 10000, null);

  customAxios.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      let newError = JSON.stringify(error);
      let statusCode = JSON.parse(newError);
      const originalRequest = error.config;

      if (error === 401 && originalRequest.url === 'https://api.focus247.online/Token') {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/signin';
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/signin';
        const refreshToken = localStorage.getRefreshToken();
        return customAxios
          .post('/token', {
            refresh_token: refreshToken,
          })
          .then(res => {
            if (res?.status === 201) {
              // localStorage.setToken(res.data);
              // customAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getAccessToken();
              // return customAxios(originalRequest);
            }
          });
      }
      return Promise.reject(error);
    },
  );

  if (showLayoutLoader && !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  if (
    [
      '/signin',
      '/signup',
      '/forgot-password',
      '/confirm-user',
      '/confirm',
      '/setpassword',
      '/reset-password',
      '/verifyInviteUser',
    ].includes(location.pathname)
  ) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  switch (layout) {
    case LAYOUT_TYPES.VERTICAL_DEFAULT: {
      return <VerticalDefault children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL: {
      return <VerticalMinimal children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MINIMAL_NO_HEADER: {
      return <MinimalNoHeader children={children} />;
    }
    case LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR: {
      return <ModernSideBar children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DEFAULT: {
      return <HorizontalDefault children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_DARK: {
      return <HorizontalDark children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_MINIMAL: {
      return <HorizontalMinimal children={children} />;
    }
    case LAYOUT_TYPES.HORIZONTAL_TOP_MENU: {
      return <HorizontalTopMenu children={children} />;
    }
    default:
      return <VerticalDefault children={children} />;
  }
};

export default AppLayout;
