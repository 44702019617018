import {
  Client,
  // GraphRequestOptions,
  // PageCollection,
  PageIterator,
} from '@microsoft/microsoft-graph-client';
// import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { endOfWeek, startOfWeek } from 'date-fns';
// import { zonedTimeToUtc } from 'date-fns-tz';

let graphClient = undefined;

// function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
function ensureClient(AuthCodeMSALBrowserAuthenticationProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: AuthCodeMSALBrowserAuthenticationProvider,
    });
  }

  return graphClient;
}
// function ensureClient(authProvider: AuthCodeMSALBrowserAuthenticationProvider) {
function ensureClient2(AuthCodeMSALBrowserAuthenticationProvider) {
  console.log(AuthCodeMSALBrowserAuthenticationProvider, 'AuthCodeMSALBrowserAuthenticationProvider');
  if (!graphClient) {
    graphClient = Client.init({
      authProvider: AuthCodeMSALBrowserAuthenticationProvider,
    });
  }

  return graphClient;
}

// export async function getUser(authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<User> {
export async function getUser(AuthCodeMSALBrowserAuthenticationProvider) {
  ensureClient(AuthCodeMSALBrowserAuthenticationProvider);

  // Return the /me API endpoint result as a User object
  const user = await graphClient
    .api('/me')
    // Only retrieve the specific fields needed
    .select('displayName,mail,mailboxSettings,userPrincipalName')
    .get();

  return user;
}

// export async function getUserWeekCalendar(AuthCodeMSALBrowserAuthenticationProvider, timeZone) {
//   ensureClient(AuthCodeMSALBrowserAuthenticationProvider);

//   const now = new Date();
//   const startDateTime = zonedTimeToUtc(startOfWeek(now), timeZone).toISOString();
//   const endDateTime = zonedTimeToUtc(endOfWeek(now), timeZone).toISOString();
//   var response = await graphClient
//     .api('/me/calendarview')
//     .header('Prefer', `outlook.timezone="${timeZone}"`)
//     .query({ startDateTime: startDateTime, endDateTime: endDateTime })
//     .select('subject,organizer,start,end')
//     .orderby('start/dateTime')
//     .top(25)
//     .get();

//   if (response['@odata.nextLink']) {
//     // Presence of the nextLink property indicates more results are available
//     // Use a page iterator to get all results
//     var events = [];

//     // Must include the time zone header in page
//     // requests too
//     var options = {
//       headers: { Prefer: `outlook.timezone="${timeZone}"` },
//     };

//     var pageIterator = new PageIterator(
//       graphClient,
//       response,
//       event => {
//         events.push(event);
//         return true;
//       },
//       options,
//     );

//     await pageIterator.iterate();

//     return events;
//   } else {
//     return response.value;
//   }
// }

export async function createEvent(AuthCodeMSALBrowserAuthenticationProvider, newEvent) {
  ensureClient(AuthCodeMSALBrowserAuthenticationProvider);

  // POST /me/events
  // JSON representation of the new event is sent in the
  // request body
  return await graphClient.api('/me/events').post(newEvent);
}

export async function sendEmail(AuthCodeMSALBrowserAuthenticationProvider, newEmail) {
  ensureClient2(AuthCodeMSALBrowserAuthenticationProvider);

  // POST /me/events
  // JSON representation of the new event is sent in the
  // request body
  return await graphClient.api('/me/sendMail').post(newEmail);
}
