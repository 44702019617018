import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TM_TEAM_MEMBERS,
  GET_TM_TASK_BY_TEAM,
  GET_TM_STATUS_BY_TEAM,
  SET_CARD_DATA,
  SET_CARD_DATE_FLAG,
  SET_SELECTED_TEAM,
  SET_PROJECT_LIST,
  SET_BOARD_DATA,
  SET_SINGHL_ARCHIVE,
  SET_MULTI_ARCHIVE,
  SET_CHECKED,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  initialURL: '/',
  error: '',
  message: '',
  tmTeamMembers: [],
  tmCardData: [],
  projectList: [],
  archiveBoardData: [],
  singleArchive: [],
  multiArchive: [],
  tmDatePickerPlag: [],
  tmSelectedTeam: [],
  loading: false,
  cardCheck: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', loading: false, message: action.payload };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, message: '', error: action.payload };
    }
    case GET_TM_TEAM_MEMBERS: {
      return { ...state, error: '', loading: false, tmTeamMembers: action.payload };
    }
    case GET_TM_TASK_BY_TEAM: {
      return { ...state, error: '', loading: false, tmTaskByTeam: action.payload };
    }
    case GET_TM_STATUS_BY_TEAM: {
      return { ...state, error: '', loading: false, tmStatusByTeam: action.payload };
    }
    case SET_CARD_DATA: {
      return { ...state, error: '', loading: false, tmCardData: action.payload };
    }
    case SET_CARD_DATE_FLAG: {
      return { ...state, error: '', loading: false, tmDatePickerPlag: action.payload };
    }
    case SET_SELECTED_TEAM: {
      return { ...state, error: '', loading: false, tmSelectedTeam: action.payload };
    }
    case SET_PROJECT_LIST: {
      return { ...state, error: '', loading: false, projectList: action.payload };
    }
    case SET_BOARD_DATA: {
      return { ...state, error: '', loading: false, archiveBoardData: action.payload };
    }
    case SET_SINGHL_ARCHIVE: {
      return { ...state, error: '', loading: false, singleArchive: action.payload };
    }
    case SET_MULTI_ARCHIVE: {
      return { ...state, error: '', loading: false, multiArchive: action.payload };
    }
    case SET_CHECKED: {
      return { ...state, error: '', loading: false, cardCheck: action.payload };
    }
    default:
      return state;
  }
};
