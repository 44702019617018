import NoteAdd from '@material-ui/icons/NoteAdd';
import React, { useEffect, useState } from 'react';
import PopUp from './PopUp';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Note from '@material-ui/icons/Note';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { NotesOutlined } from '@material-ui/icons';
import NoteItem from './NoteItem';
import Box from '@material-ui/core/Box';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';
import { Dialog } from '@mui/material';
import moment from 'moment';
const Notes = ({ userData, notesData, addNewNotes, getContactHandler }) => {
  console.log(userData, 'userData');
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(notesData);
  const [openDate, setOpenDate] = useState(false);
  const [currNote, setCurrNote] = useState('');
  const [followUpDate, setFollowUpDate] = useState(null);

  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 0.3,
      '& .MuiTimelineItem-root': {},

      '& .MuiTimelineItem-missingOppositeContent': {},
      '& .MuiTimelineItem-missingOppositeContent:before': {
        flex: 0,
      },
    },
    notesPaper: {
      '& .MuiPaper-rounded': {
        borderRadius: '10px',
      },
    },
  }));
  const textFieldStyles = makeStyles(theme => ({
    root: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important',
      },
      '&.MuiInputBase-root.Mui-disabled': {
        borderBottom: 'none !important',
      },
    },
  }));

  const handlefollowDateHandler = dateChange => {
    console.log(dateChange);
    setFollowUpDate(dateChange);
  };

  const classes = useStyles();
  // add Note start
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    console.log(event.currentTarget, 'event.currentTarget');
    setAnchorEl(event.currentTarget);
    setFollowUpDate(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const saveNote = () => {
    if (followUpDate) {
      addFollowUpDate();
    }
    addNotes();
    getContactHandler(userData.crmId);
  };

  const addNotes = () => {
    if (currNote == '') {
      handleClose();
      return;
    }
    let dataObj = {
      ContactId: userData.crmId,
      AccountId: userData.UserId,
      Note: currNote,
      NoteDate: new Date().toISOString(),
      crmTeamId: userData.CrmTeamId,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/AddCRMNote`, dataObj, {
          headers: authHeader(),
        })
        .then(() => {
          getNotesData();
          setCurrNote('');
          handleClose();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  function addFollowUpDate() {
    console.log(new Date(followUpDate).toLocaleDateString('en-GB'));
    const folllowupobj = {
      CRM_ContactId: userData.crmId,
      NextContactDate: new Date(followUpDate).toDateString(),
    };
    console.log(followUpDate, 'in api');

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`Contacts/AddContactFollowUpDate`, folllowupobj, {
          headers: authHeader(),
        })
        .then(() => {
          // console.log('Follow up date Added')
          setFollowUpDate(null);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  function getNotesData() {
    let noteObj = {
      crmContactId: userData.crmId,
      crmTeamId: userData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/GetCRMNotes`, noteObj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data) {
            addNewNotes(data);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  function deleteNote(args) {
    let dataObj = {
      ContactId: userData.crmId,
      NoteId: args.Id,
      crmTeamId: userData.CrmTeamId,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/DeleteCRMNote`, dataObj, {
          headers: authHeader(),
        })
        .then(() => {
          getNotesData();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  function editNote(dataObj) {
    let updateDataObj = { ...dataObj, crmTeamId: userData.CrmTeamId };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/EditCRMNote`, updateDataObj, {
          headers: authHeader(),
        })
        .then(() => {
          getNotesData();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Add Note End

  let timer = 50,
    timeout;

  const debounce = func => {
    clearTimeout(timeout);
    timeout = setTimeout(func, timer);
  };
  const updateNewNotesText = args => {
    setCurrNote(args);
  };

  return (
    <Timeline align="left" className={classes.root} style={{ display: 'flex', flexDirection: 'column' }}>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot
            style={{
              background: '#3f51b5',
            }}>
            <IconButton size="small" variant="contained" onClick={handleClick}>
              <NoteAdd style={{ color: 'white' }} />
            </IconButton>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Popover
            id={id}
            open={open}
            disableEnforceFocus
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            transformOrigin={{
              horizontal: -50,
              vertical: 20,
            }}
            className={classes.notesPaper}>
            <div
              className="e-content"
              style={{ border: '1px solid  rgba(0,0,0,.225)', width: '425px', height: '308px', borderRadius: '10px' }}>
              <div className="d-flex-column w-100  m-0 p-0 ">
                <div
                  className="word-wrap text-break px-3 pt-2 "
                  style={{ minHeight: '50px', maxHeight: '420px', background: 'whiteSmoke', borderRadius: '10px' }}>
                  <TextField
                    name="note"
                    value={currNote}
                    onChange={e => updateNewNotesText(e.target.value)}
                    placeholder="Notes"
                    fullWidth
                    multiline
                    autoFocus
                    className={textFieldStyles().root}
                    minRows={12}
                    maxRows={12}></TextField>
                </div>
                <div className="px-4 w-100 pt-2  d-flex justify-content-between" style={{ gap: '10px' }}>
                  <Box
                    // component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="row">
                      <div className="followup">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={moment().subtract(2, 'years')}
                            open={openDate}
                            inputFormat="DD-MM-YYYY"
                            onOpen={() => setOpenDate(true)}
                            onClose={() => setOpenDate(false)}
                            value={followUpDate}
                            // onChange={handleDueDateChange}
                            label={'Follow-Up Date'}
                            onChange={event => handlefollowDateHandler(event)}
                            // disableOpenPicker
                            clearable={true}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onClick={() => setOpenDate(true)}
                              />
                            )}
                            componentsProps={{
                              actionBar: { actions: ['clear'] },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <div className="d-flex flex-row-reverse" style={{ gap: '5px' }}>
                    <Button variant="contained" style={{ background: '#3f51b5', color: 'white' }} onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" style={{ background: '#3f51b5', color: 'white' }} onClick={saveNote}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </TimelineContent>
      </TimelineItem>
      {notesData.map((item, index) => {
        return (
          <TimelineItem key={index} style={{ flexGrow: 0 }}>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                style={{
                  color: '#fff',
                  background: '#3f51b5',
                }}>
                <IconButton size="small" variant="contained">
                  <NotesOutlined style={{ color: 'white' }} />
                </IconButton>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }} style={{ width: '400px' }}>
              <NoteItem value={notesData} editNote={editNote} deleteNote={deleteNote} noteItem={item} />
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export default Notes;
