import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateEmailTemplate from './CreateEmailTemplate';
import EditEmailTemplate from './editEmailTemplate';
import IntlMessages from '../../util/IntlMessages';
import { TableHeader } from './TabelHeader';
import { FormControl } from '@mui/material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';

const EmailTemplates = () => {
  const ITEM_HEIGHT = 48;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [dense, setDense] = React.useState(false);
  const [SelectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [tabs, setTabs] = React.useState([]);
  const [teamWiseTabs, setTeamWiseTabs] = useState([]);
  const [Teams, setTeams] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [checkId, setCheckId] = useState([]);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [details, setDetails] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [TeamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    // setDense(false);
    getTeams();
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const classes = useStyles();

  const filterModalToggle = () => {
    setDetails([]);
    setFilterModalOpen(!filterModalOpen);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleActionMenuClick = (index, event) => setAnchorEl({ [index]: event.currentTarget });

  const handleMenuClose = () => setAnchorEl(null);

  const teamActionHandler = (action, data) => {
    setDetails({ ...data, edit: true });
    if (action === 'detail') {
      setOpenDrawer(!openDrawer);
    }
    if (action === 'delete') {
      deleteEmailTemplate(data.Id);
    }
    setAnchorEl(null);
  };

  const deleteEmailTemplate = id => {
    let deleteObj = {
      TemplateId: parseInt(id),
      crmTeamId: 0,
    };
    let url = 'CRM/DeleteEmailTemplate';
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, deleteObj, { headers: authHeader() })
      .then(data => {
        getEmailTemplate();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  //no team id access
  const getEmailTemplate = id => {
    let url = 'CRM/GetEmailTemplates';
    let currTeam = id ? id : selectedTeam.value;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`CRM/GetEmailTemplates/${currTeam}`, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setData(data);
            setCustomData(data);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  // const getTeams = () => {
  //   dispatch({ type: FETCH_START });
  //   try {
  //     customAxios
  //       .get('CRMTeams/GetCRMTeams', { headers: authHeader() })
  //       .then(({ data }) => {
  //         if (data) {
  //           console.log(data);
  //           // setData(data);
  //           setCheckId(data);
  //           getEmailTemplate(data[0]?.Id);
  //           dispatch({ type: FETCH_SUCCESS });
  //         } else {
  //           dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //         }
  //       })
  //       .catch(error => {});
  //   } catch (e) {
  //     dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //   }
  // };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
    getEmailTemplate();
    setDetails([]);
  };

  const onClientsClose = () => {
    setFilterModalOpen(false);
    getEmailTemplate();
    setDetails([]);
  };

  // useEffect(() => {
  //   setDense(false);
  //   getEmailTemplate();
  // }, []);

  console.log(checkId, 'checkId');

  /* START: Teams selection */
  const onTeamChangeHandler = args => {
    setSelectedTeam(args);
    localStorage.setItem('crmTeam', JSON.stringify(args));
    localStorage.setItem('crmCurrStage', JSON.stringify('1'));
    const teamWise = tabs.filter(obj => obj.CrmTeamId === args.value);
    /* Change Team data based on top left Team dropdown selected and change updated drag and drop order */
    const teamW = teamWise.map((obj, index) => {
      var temp = Object.assign({}, obj);
      if (temp.CrmTeamId == args.value) {
        temp.value = `${index + 1}`;
        return temp;
      }
    });
    setTeamWiseTabs(teamW);
    if (args.value) {
      getTeamMembers(args);
    }
  };
  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('CRMTeams/GetCRMTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data.length > 0) {
            var team = data.map(c => ({ value: c.Id, label: c.crmTeamName, IsAdmin: c.IsAdmin }));
            if (team) {
              setTeams(team);
              let lastTeam = JSON.parse(localStorage.getItem('crmTeam'));
              let currTeam = team[0];
              // console.log(currTeam, lastTeam);
              if (lastTeam) {
                let LastTeam = team.filter(item => item.value === lastTeam.value);
                currTeam = LastTeam.length != 0 ? LastTeam[0] : team[0];
              }
              // console.log('setting Stage', currTeam);
              // getStages(currTeam.value);

              setTimeout(() => {
                // console.log(currTeam);

                setSelectedTeam(currTeam);
                getTeamMembers(currTeam);
                getEmailTemplate(currTeam.value);
              }, 1000);
            }
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getTeamMembers = args => {
    customAxios.get('CRMTeams/GetCRMTeamMembers/' + args.value, { headers: authHeader() }).then(({ data }) => {
      if (data) {
        const members = data.filter(obj => obj.TeamId === args.value);

        setSelectedTeamMembers(members);
        let tMemebers = data.map(item => ({ value: item.crmUserId, label: item.Email, ...item }));
        setTeamMembers(tMemebers);
      }
    });
  };

  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${name.charAt(0)}${name.charAt(1)}`,
    };
  }

  return (
    <div>
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="sidebar.main.crm.emailTemplates" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={Teams}
              value={Teams.find(c => c.label == selectedTeam.label)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
          {SelectedTeamMembers && (
            <AvatarGroup max={6} className="ml-3">
              {SelectedTeamMembers.map((avatar, index) => (
                <Avatar key={index} {...stringAvatar(avatar.Email)} />
              ))}
            </AvatarGroup>
          )}
        </div>
        <div className="content-horizontal__center">
          <div className="justify-content-right pb-3 text-right">
            <Button variant="contained" style={{ textTransform: 'none' }} color="primary" onClick={filterModalToggle}>
              <IntlMessages id="emailTemplate.addTemplate" />
            </Button>
          </div>
        </div>
      </div>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table">
              <TableHeader classes={classes} rowCount={customData.length} />
              <TableBody>
                {customData.map((row, index) => {
                  // const isItemSelected = isSelected(row.CompanyName);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover key={index}>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.TemplateName}
                      </TableCell>
                      <TableCell align="left">{row.TemplateSubject}</TableCell>
                      <TableCell align="left">
                        <div dangerouslySetInnerHTML={{ __html: row.TemplateBody }} />
                      </TableCell>
                      <TableCell align="right">
                        <>
                          <IconButton onClick={e => handleActionMenuClick(index, e)}>
                            <MoreHorizIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl && anchorEl[index]}
                            keepMounted
                            open={Boolean(anchorEl && anchorEl[index])}
                            onClose={handleMenuClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}>
                            <MenuItem onClick={() => teamActionHandler('detail', row)}>
                              <EditIcon className={classes.actionIcon} /> Edit
                            </MenuItem>
                            <MenuItem onClick={() => teamActionHandler('delete', row)}>
                              <DeleteOutlineIcon className={classes.actionIcon} /> Delete
                            </MenuItem>
                          </Menu>
                        </>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={customData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {filterModalOpen && (
        <CreateEmailTemplate
          toggle={filterModalToggle}
          modal={filterModalOpen}
          details={details}
          onClientsClose={onClientsClose}
          selectedTeam={selectedTeam}
        />
      )}
      <EditEmailTemplate
        filterModalOpen={openDrawer}
        details={details}
        onClientsClose={onCloseDrawer}
        toggle={toggleDrawer}
        selectedTeam={selectedTeam}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    overflowX: 'scroll',
    position: 'relative',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    overflow: 'scroll',
  },
  table: {
    minWidth: 750,
    overflowY: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    // overflow: 'scroll',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

export default EmailTemplates;
