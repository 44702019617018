import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { NavLink } from 'react-router-dom';

export default function ErrorMessage() {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert variant="filled" severity="error">
        You are not part of the team <NavLink to={`/embedded-analytics/view-reports`}>Go back</NavLink>
      </Alert>
    </Stack>
  );
}
