import React from 'react';

import Snackbar from '@mui/material/Snackbar';

import MuiAlert from '@mui/material/Alert';
import { Slide } from '@material-ui/core';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const Tostify = ({ showMessage, setShowMessage, inviteEmail }) => {
  const handleClose = () => {
    setShowMessage(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={showMessage}
      autoHideDuration={2000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}>
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} style={{ background: '#4caf50' }}>
        User {inviteEmail} Invited Successfully
      </Alert>
    </Snackbar>
  );
};

export default Tostify;
