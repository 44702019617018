/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@mui/icons-material/Mail';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
// import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import Button from '@material-ui/core/Button';
// import Button from '@mui/material/Button';
import CreateEditInvoice from '../../components/Invoice/CreateEditInvoice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {
  FETCH_ALL_MAIL_SUCCESS,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';

/* Microsoft 365 login */
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../../microsoft-context/AppContext';
import { sendEmail } from '../../../../microsoft-context/services/GraphService';
import OutlookInvoice from './OutlookInvoice';
import SendGridEmail from './SendGridEmail';
import PaidIcon from '@mui/icons-material/Paid';

/* table config */
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
/* Tabs Config */
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CSVLink } from 'react-csv';
// import data from './data.csv';

// Search Config
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@material-ui/icons/Close';
import Tostify from './Tostify';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { useRef } from 'react';
import TaskFilterDialog from './TaskToolbarComponent.js/TaskFilterDialog';
import { visuallyHidden } from '@mui/utils';
import { TableSortLabel } from '@material-ui/core';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateCloneInvoice from 'routes/Pages/components/Invoice/CreateCloneInvoice';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';

import { FormControl } from '@mui/material';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SkeletonLoader from '@jumbo/components/PageComponents/SkeletonLoader';
import FileToBase64Converter from './FileToBase64Converter';
/* START: Search Icon */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
/* End: Search Icon */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useTabStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  outlookImg: {
    marginRight: '10px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
});

/* Action Dropdown Menu in List Action */
const ITEM_HEIGHT = 48;
function Row(props) {
  /* Action Dropdown Menu in List Action */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { row, details, handelSelect, value, isSelected, labelId, handleClicks, setSearchDraftData } = props;

  const isItemSelected = isSelected(row.InvoiceNumber);
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  //selected row

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.InvoiceNumber}
        selected={isItemSelected}>
        {/* <TableCell>
          <Checkbox onChange={e => handelSelect(e, row)} />
        </TableCell> */}
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            // onChange={e => handelSelect(e, row)}
            onClick={event => {
              handleClicks(event, row.InvoiceNumber);
              console.log(row.InvoiceNumber, 'number');
            }}
            inputProps={{
              'aria-labelledby': labelId,
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.CompanyName}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.ClientName}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.InvoiceNumber}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.IBAN}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.IsPaid}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {formatDate(row.IssueDate)}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {formatDate(row.DueDate)}
        </TableCell>
        {/* <TableCell component="th" scope="row" align="left">
          {row.IssueDate}
        </TableCell> */}
        <TableCell component="th" scope="row" align="left">
          <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            disableScrollLock={true}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}>
            <AuthenticatedTemplate>
              <MenuItem
                onClick={() => {
                  details('sendEmail', row);
                  setAnchorEl(null);
                }}>
                <span>
                  <img src={'/images/microsoft/outlook.png'} className={classes.outlookImg} alt="outlook" />
                </span>
                Send via Email
              </MenuItem>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <MenuItem
                onClick={() => {
                  details('sendGridEmail', row);
                  setAnchorEl(null);
                }}>
                <MailIcon className={classes.pointer} /> Send Email
              </MenuItem>
            </UnauthenticatedTemplate>
            <MenuItem
              onClick={() => {
                details('detail', row);
                setAnchorEl(null);
              }}>
              <EditIcon className={classes.pointer} /> Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                details('delete', row);
                setAnchorEl(null);
              }}>
              <DeleteIcon className={classes.pointer} /> Delete
            </MenuItem>
            {value === 1 ? (
              <MenuItem
                onClick={() => {
                  details('paid', row);
                  setAnchorEl(null);
                }}>
                <PaidIcon className={classes.pointer} /> Mark as Paid
              </MenuItem>
            ) : null}
            <MenuItem
              onClick={() => {
                details('downloadPDF', row);
                setAnchorEl(null);
              }}>
              <PictureAsPdfIcon className={classes.pointer} /> Download PDF
            </MenuItem>
            <MenuItem
              onClick={() => {
                // cloneInvoiceHandler(row);
                // setAnchorEl(null);
                details('cloneInvoice', row);
                setAnchorEl(null);
              }}
              className={classes.pointer}>
              <FileCopyIcon /> Clone Invoice
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="left">Product Rate</TableCell>
                    <TableCell align="left">VAT Rate %</TableCell>
                    <TableCell align="left">Net Amount</TableCell>
                    <TableCell align="left">Gross Amount</TableCell>
                    <TableCell align="left">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.child.map((childRow, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {childRow.Product}
                      </TableCell>
                      <TableCell>{childRow.Quantity}</TableCell>
                      <TableCell align="left">{childRow.ProductRate}</TableCell>
                      <TableCell align="left">{childRow.VatRate}</TableCell>
                      <TableCell align="left">{childRow.NetAmount}</TableCell>
                      <TableCell align="left">{childRow.GrossAmount}</TableCell>
                      <TableCell align="left">{childRow.ProductDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    CompanyName: PropTypes.string.isRequired,
    ClientName: PropTypes.string.isRequired,
    IBAN: PropTypes.string.isRequired,
    IsPaid: PropTypes.string.isRequired,
    InvoicePrefix: PropTypes.string.isRequired,
    IssueDate: PropTypes.string.isRequired,
    child: PropTypes.arrayOf(
      PropTypes.shape({
        Product: PropTypes.string.isRequired,
        Quantity: PropTypes.number.isRequired,
        ProductRate: PropTypes.number.isRequired,
        VatRate: PropTypes.number.isRequired,
        NetAmount: PropTypes.number.isRequired,
        GrossAmount: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

// table header

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: true,
    label: 'Company Name',
  },
  {
    id: 'ClientName',
    numeric: true,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'InvoiceNumber',
    numeric: true,
    disablePadding: false,
    label: 'Invoice Number',
  },
  {
    id: 'IBAN',
    numeric: true,
    disablePadding: false,
    label: 'Bank Account ID',
  },
  {
    id: 'Is Paid?',
    numeric: true,
    disablePadding: false,
    label: 'Is Paid?',
  },
  {
    id: 'IssueDate',
    numeric: true,
    disablePadding: false,
    label: 'Issue Date',
  },
  {
    id: 'DueDate',
    numeric: true,
    disablePadding: false,
    label: 'Due Date',
  },
  {
    id: 'Action',
    numeric: true,
    disablePadding: false,
    label: 'Action',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {''}
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Invoice = props => {
  /* Tab Styles */
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showMessage, setShowMessage] = useState(false);
  // const [cloneInvoice, setCloneInvoice] = useState({});

  const app = useAppContext();

  const menuStyles = makeStyles(theme => ({
    root: {
      '& .MuiList-padding': {
        paddingTop: 0,
        paddingBottom: 0,
      },
      '& .MuiMenuItem-root': {
        padding: '8px ',
        '& div': {
          marginRight: '8px',
          fontSize: '0.95rem',
        },
      },
      top: '40px !important',
    },
  }));

  // search
  const [searchKey, setSearchKey] = useState('');
  const [searchDraftData, setSearchDraftData] = useState([]);
  const [searchSentData, setSearchSentData] = useState([]);

  /** modal open/close **/
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState([]);

  /** clone modal open/close **/
  const [cloneModal, setCloneModal] = useState(false);
  const [cloneDetails, setCloneDetails] = useState([]);

  /** modal outlook open/close **/
  const [modalOutlook, setModalOutlook] = useState(false);
  const [detailsOutlook, setDetailsOutlook] = useState([]);

  /** modal sendGrid open/close **/
  const [modalSendGrid, setModalSendGrid] = useState(false);
  const [detailsSendGrid, setDetailsSendGrid] = useState([]);

  const classes = useStyles();
  const cusclass = menuStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const downloadCsv = useRef(null);
  const selectedDownloadCsv = useRef();
  const inputRef = useRef(null);
  const [csvFile, setCsvFile] = useState([]);

  const [selectedItem, setSelectedItem] = useState([]);
  const [allSelcted, setAllSelected] = useState([]);

  //csv file export data
  const [filterSendData, setFilterSendData] = useState([]);
  const [filterDraftData, setFilterDraftData] = useState([]);

  //csv file selected Data
  const [filterSelectedSendData, setFilterSelectedSendData] = useState([]);
  const [filterSelectedData, setFilterSelectedData] = useState([]);
  const [filterSelectedSend, setFilterSelectedSend] = useState([]);
  const [newCloneInvoice] = useState('cloneInvoice');
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [allInvoiceIDs, setAllInvoiceIDs] = useState([]);
  const { authUser } = useSelector(({ auth }) => auth);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);

  //Account teams
  const [accTeam, setAccTeam] = useState(null);
  const [accTeamAll, setAccTeamAll] = useState([]);
  const handleClose = () => {
    setOpenAlert(false);
  };

  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get('Acct/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data && data.length > 0) {
            let allteam = data.map(c => ({ value: c.Id, label: c.AcctTeamName }));

            setAccTeamAll(allteam);
            setAccTeam(allteam[0].value);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            setAccTeam(null);
            dispatch({ type: FETCH_SUCCESS });
            // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          }
          return true;
        })
        .catch(error => {
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  const toggles = () => {
    // setModal(!modal);
    handleClickData();
    // return <csvlink {...csvReport}>Export to CSV</csvlink>;
  };

  const selectedDownload = () => {
    selectedDownloadcsv();
  };

  useEffect(() => {
    const invoiceIDs = selectedInvoices.map(invoice => invoice.InvoiceId);
    setAllInvoiceIDs(invoiceIDs);
  }, [selectedInvoices]);

  const bulkInoviceDownload = async () => {
    // console.log(selectedInvoices);
    // console.log(allInvoiceIDs);
    // return;
    try {
      if (allInvoiceIDs.length === 0) {
        setOpenAlert(true);
      } else {
        const downloadUrl = 'Invoice/DownloadMultipleInvoices';
        dispatch({ type: FETCH_START });

        const response = await customAxios.post(downloadUrl, allInvoiceIDs, { headers: authHeader(), responseType: 'blob' });

        dispatch({ type: FETCH_SUCCESS });

        // Create a temporary link element to trigger the download
        const downloadLink = document.createElement('a');
        const fileName = 'invoices.zip'; // Adjust the file name as needed

        downloadLink.href = URL.createObjectURL(response.data);
        downloadLink.download = fileName;
        downloadLink.click();

        // Clean up the temporary link
        URL.revokeObjectURL(downloadLink.href);
        // setSelected([]);
        // setSelectedItem([]);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };

  const groupBy = (data, key) => {
    return data.reduce(function(acc, item) {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
  };

  const selectedDownloadcsv = () => {
    // selectedDownloadCsv.current.link.click();

    dispatch({ type: FETCH_START });

    const downloadPromises = selectedInvoices.map(async invoice => {
      const urlInvoiceDownload = `/Invoice/GetInvoiceDownloadUrl/${invoice.InvoiceId}`;

      try {
        const { data } = await customAxios.get(urlInvoiceDownload, { headers: authHeader() });
        if (data) {
          window.open(data, '_blank');
        }
        return await Promise.resolve();
      } catch (error) {
        console.error(`Error downloading invoice ${invoice.InvoiceId}:`, error);
        return await Promise.resolve();
      }
    });

    Promise.all(downloadPromises)
      .then(() => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const actionsList = [
    {
      onClick: toggles,
      label: <IntlMessages id="crm.excelCSVExport" />,
    },
    {
      onClick: bulkInoviceDownload,
      label: <IntlMessages id="crm.bulddownload" />,
    },
  ];

  const handleClickData = () => {
    downloadCsv.current.link.click();
  };
  // console.log(accTeam, 'accTeam');

  // console.log(authUser, 'authuserdesi');

  const getUserProfileData = value => {
    dispatch({ type: FETCH_START });

    customAxios
      .get('Users/GetUserProfile', { headers: authHeader() })
      .then(({ data }) => {
        console.log({ data });
        if (data) {
          fetchData(data, value);

          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };

  const fetchData = (userInfo, value) => {
    if (accTeam === null) {
      return;
    }
    // let url = `/Invoice/GetInvoices/'+accTeam;
    let url = '/Invoice/GetInvoices/' + accTeam;
    dispatch({ type: FETCH_START });
    customAxios
      .get(url, { headers: authHeader() })
      .then(({ data }) => {
        setSkeletonLoaderState(false);
        setCsvFile(data);
        if (data) {
          //Fill the table with the datas got from the database
          var updatedData = groupBy(data, 'InvoiceId');
          var requiredFormat = [];
          var draftData = [];
          var completedData = [];
          // updatedData.forEach(item => {});
          for (const key in updatedData) {
            var childD = { child: [] };
            updatedData[key].map((k, i) => {
              childD['InvoiceId'] = k['InvoiceId'];
              childD['InvoicePrefix'] = k['InvoicePrefix'];
              childD['ClientName'] = k['ClientName'];
              childD['ClientEmail'] = k['ClientEmail'];
              childD['ClientAddress1'] = k['ClientAddress1'];
              childD['ClientAddress2'] = k['ClientAddress2'];
              childD['ClientPhoneNumber'] = k['ClientPhoneNumber'];
              childD['ClientPhoneNumber'] = k['ClientPhoneNumber'];
              childD['ClientVATNumber'] = k['ClientVATNumber'];
              childD['CompanyName'] = k['CompanyName'];
              childD['CompanyLogoUrl'] = k['CompanyLogoUrl'];
              childD['CompanyAddress1'] = k['CompanyAddress1'];
              childD['CompanyAddress2'] = k['CompanyAddress2'];
              childD['CompanyPhoneNumber'] = k['CompanyPhoneNumber'];
              childD['CompanyVATNumber'] = k['CompanyVATNumber'];
              childD['IssueDate'] = k['IssueDate'].split('T')[0];
              childD['DueDate'] = k['DueDate'].split('T')[0];
              if (k['IsPaid'] == 1) {
                childD['IsPaid'] = 'Yes';
              } else {
                childD['IsPaid'] = 'No';
              }
              if (k['IsDraft'] == 1) {
                childD['IsDraft'] = 'Yes';
              } else {
                childD['IsDraft'] = 'No';
              }
              childD['InvoiceNumber'] = k['InvoiceNumber'];
              childD['BankName'] = k['BankName'];
              childD['BIC'] = k['BIC'];
              childD['Sort'] = k['Sort'];
              childD['PaymentTerms'] = k['PaymentTerms'];
              childD['IBAN'] = k['IBAN'];
              childD['InvoiceItemId'] = k['InvoiceItemId'];
              if (k['InvoiceItemId'] != null) childD['child'].push(k);
            });
            requiredFormat.push(childD);

            draftData = requiredFormat.filter(row => row.IsDraft === 'Yes');
            completedData = requiredFormat.filter(row => row.IsDraft === 'No');
          }
          setData(draftData);

          setCompletedData(completedData);

          if (value === 'editing') {
            let filterData = [];
            const startDate = moment(filterSelectedData?.startDate).format('YYYY-MM-DD');
            const endDate = moment(filterSelectedData?.endDate).format('YYYY-MM-DD');

            draftData
              .filter(row => {
                let filterPass = true;
                const IssueDate = new Date(row.IssueDate);
                if (startDate && endDate) {
                  filterPass = filterPass && new Date(startDate) <= IssueDate && new Date(endDate) >= IssueDate;
                }
                return filterPass;
              })
              .map(row => {
                filterData = [...filterData, row];
              });

            setSearchDraftData(filterData.sort((a, b) => b.InvoiceNumber - a.InvoiceNumber));
          } else {
            setSearchDraftData(draftData.sort((a, b) => b.InvoiceNumber - a.InvoiceNumber));
            setSearchSentData(completedData.sort((a, b) => b.InvoiceNumber - a.InvoiceNumber));
          }

          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    fetchData();
  }, [accTeam]);

  const onInvoicesClose = () => {
    // getUserProfileData();
  };

  const cloneToggle = () => {
    setCloneModal(!cloneModal);
    setCloneDetails([]);
  };

  /* modal open/close */
  const toggle = () => {
    setModal(!modal);
    setDetails([]);
  };
  const toggleOutlook = () => {
    setModalOutlook(!modalOutlook);
    getUserProfileData();
  };
  const toggleSendGrid = () => {
    setModalSendGrid(!modalSendGrid);
    getUserProfileData();
  };
  const detailsClickHandler = (action, row) => {
    if (action === 'cloneInvoice') {
      setCloneModal(!cloneModal);
      const { InvoiceNumber, ...cloneData } = row;
      setCloneDetails(cloneData);
      handleToastMessageClick();
      // fetchData();
      return;
    }

    if (action === 'detail') {
      data.edit = true;
      console.log(row);
      toggle();
      setDetails(row);
      // return false
      return;
    }
    if (action === 'delete') {
      let url = 'Invoice/DeleteInvoice/' + row.InvoiceId + '?AcctTeamId=' + accTeam;
      deleteInvoice(url);
    }

    if (action === 'sendGridEmail') {
      toggleSendGrid();
      setDetailsSendGrid(row);
    }

    if (action === 'paid') {
      // toggleSendGrid();
      // setDetailsSendGrid(row);
      UpdateIsPaid(row);
    }

    if (action === 'sendEmail') {
      // console.log(row);
      // return;
      toggleOutlook();
      setDetailsOutlook(row);
      // sendEmailOutLook(row.InvoiceId);
    }
    if (action === 'downloadPDF') {
      let urlInvoiceDownload = '/Invoice/GetInvoiceDownloadUrl/' + row.InvoiceId;
      dispatch({ type: FETCH_START });
      customAxios
        .get(urlInvoiceDownload, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            window.open(data);
          }
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  /* OutLook Email send Invoice */
  function sendEmailOutLook(row, emailsTo, emailsCC, emailSubject, fileAttachments) {
    let urlInvoiceDownload = '/Invoice/GetInvoiceDownloadUrl/' + row.InvoiceId;
    dispatch({ type: FETCH_START });
    customAxios
      .get(urlInvoiceDownload, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          doCreate(data, row, emailsTo, emailsCC, emailSubject, fileAttachments);
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }
  /* SendGrid Email send Invoice */
  function sendEmailSendGrid(row, emailsTo, emailsCC, emailSubject, fileAttachments) {
    let urlInvoiceDownload = '/Invoice/GetInvoiceDownloadUrl/' + row.InvoiceId;
    dispatch({ type: FETCH_START });
    customAxios
      .get(urlInvoiceDownload, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          doCreate(data, row, emailsTo, emailsCC, emailSubject, fileAttachments);
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  /* Outlook Email send template */
  const doCreate = async (link, row, emailsTo, emailsCC, emailSubject, fileAttachments) => {
    const readFileAsBase64 = file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    };

    const { child } = row;

    let ccEmails = [];
    emailsCC.forEach(email => {
      ccEmails.push({ emailAddress: { address: email } });
    });
    let toEmails = [];
    emailsTo.forEach(email => {
      // toEmails.push(email);
      toEmails.push({ emailAddress: { address: email } });
    });

    const fileAttachmentsArr = fileAttachments?.map(file => ({
      file: file,
    }));

    let attachments = [];

    // Process attachments
    for (const file of fileAttachmentsArr) {
      const contentBytes = await readFileAsBase64(file.file.file);

      attachments.push({
        '@odata.type': '#microsoft.graph.fileAttachment',
        name: file.file.name,
        contentBytes: contentBytes,
      });
    }

    function totalNetAmount() {
      let netAmountAdded = 0;
      let count = 0;
      if (child) {
        child.forEach((data, index) => {
          count = parseInt(+data.Quantity * +data.ProductRate);
          netAmountAdded += count;
        });
      }
      return netAmountAdded.toFixed(2);
    }
    function totalTaxAmount() {
      let taxAmountAdded = 0;
      let count = 0;
      if (child) {
        child.forEach((data, index) => {
          if (data.vat !== 0) {
            count = parseInt(+data.Quantity * +data.ProductRate);
            taxAmountAdded += (count * +data.VatRate) / 100;
          }
        });
      }
      return taxAmountAdded.toFixed(2);
    }

    let ItemArr = row.child;
    let currSymbol = '€';
    if (ItemArr.length > 0) {
      let currencyCode = ItemArr[0].CurrencyCode;
      if (currencyCode) {
        if (currencyCode == 'dollar') {
          currSymbol = '$';
        } else if (currencyCode == 'pound') {
          currSymbol = '£';
        } else if (currSymbol == 'rupee') {
          currSymbol = '₹';
        }
      }
    }

    const Sendmemail = {
      message: {
        subject: emailSubject,
        body: {
          contentType: 'HTML',
          content: `<!DOCTYPE html>
          <html
            lang="en"
            xmlns="http://www.w3.org/1999/xhtml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
          >
            <head>
              <meta charset="utf-8" />
              <meta name="viewport" content="width=device-width,initial-scale=1" />
              <meta name="x-apple-disable-message-reformatting" />
              <style>
              .download{
                color: #fff !important; background-color: #3f51b5 !important; padding: 6px 16px !important; font-size: 13px !important; min-width: 64px !important; box-sizing: border-box !important; transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important; font-family: Normal !important; font-weight: bold !important; line-height: 1.75 !important; border-radius: 4px !important; letter-spacing: 1.25px !important; text-transform: uppercase !important; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important; text-align: center !important; margin: 0 auto !important; display: flex !important; margin-bottom: 20px !important; font-family: arial !important;    width: 200px !important; text-decoration: none !important;
              }
              </style>
            </head>
            <body>
            
            <a class="download" tabindex="0" href="${link}" style="color: #fff !important; background-color: #3f51b5; padding: 6px 16px; font-size: 13px; min-width: 64px; box-sizing: border-box; transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; font-family: Normal; font-weight: bold; line-height: 1.75; border-radius: 4px; letter-spacing: 1.25px; text-transform: uppercase; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%); text-align: center; margin: 0 auto; display: flex; margin-bottom: 20px; font-family: arial;    width: 200px; text-decoration: none;">Download Invoice</a>
            <div style="width: 100%; margin: 0; padding: 0; font-family: "arial"; font-size: 14px; padding: 10px; box-sizing: border-box;background-color: #f0eaea;">
                  <div style="background: grey; display: block; height: 30px; text-align: left; color: white; font-size: 12px; justify-content: start; flex-direction: row; align-items: center; display: flex; padding: 10px;">
                    <h2 style="font-size: 18px; margin: 0;">Invoice ${row.InvoiceNumber}</h2>
                  </div>
                  <div class="in_name">
                    <p style="display: block; padding: 10px; margin: 0; width: 100%; box-sizing: border-box;">
                      ${row.CompanyLogoUrl && `<img src="${row.CompanyLogoUrl}" width="80" />`}
                      <span style="text-align: right; float: right;">
                        Issue Date: ${row.IssueDate}
                        <br />
                        Due Date: ${row.DueDate}
                      </span>
                    </p>
                    <hr />
                    <div style="float: left; width: 50%; padding: 10px; box-sizing: border-box;">
                      <p>
                        <strong style="font-size: 16px">${row.ClientName}</strong>
                        <br />
                        ${row.ClientAddress1}
                        <br />
                        ${row.ClientAddress2}
                        <br />
                        Phone: - ${row.ClientPhoneNumber}
                        <br />
                        VAT: ${row.ClientVATNumber}
                        <br />
                        Email: ${row.ClientEmail}
                      </p>
                    </div>
                    <div style="float: right; width: 50%; text-align: right; padding: 10px; box-sizing: border-box;">
                      <p>
                        <strong style="font-size:16px">${row.CompanyName}</strong>
                        <br />
                        ${row.CompanyAddress1}
                        <br />
                        ${row.CompanyAddress2}
                        <br />
                        Phone: ${row.CompanyPhoneNumber}
                        <br />
                        VAT Number: ${row.CompanyVATNumber}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div style="height: 80px; clear: both;"></div>

                  <table class"product-table" style="height: 80px; clear: both; font-family: arial, sans-serif; border-collapse: collapse; width: 100%; background: white;">
                    <thead>
                      <tr style="background: pink ">
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Product</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Qty</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Rate</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">VAT</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Net Amount</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Gross Amount</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${child &&
                        child.map(
                          row =>
                            `<tr key=${row.InvoiceId}>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.Product}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.Quantity}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.ProductRate}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.VatRate}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${currSymbol}${row.NetAmount.toFixed(
                              2,
                            )}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${currSymbol}${row.GrossAmount.toFixed(
                              2,
                            )}</td>
                            <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${
                              row.ProductDescription
                            }</td>
                          </tr>`,
                        )}
                    </tbody>
                  </table>
                  <p>&nbsp;</p>

                  <table style="height: 80px; clear: both; font-family: arial, sans-serif; border-collapse: collapse; width: 100%; background: white;">
                    <thead>
                      <tr style="background: pink">
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Subtotal</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">VAT</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Total Gross Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${currSymbol}${totalNetAmount()}</td>
                      <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${currSymbol}${totalTaxAmount()}</td>
                      <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${currSymbol}${+totalNetAmount() +
            +totalTaxAmount()}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <h3 style="font-size: 16px;">Bank Details:</h3>
                  <table style="height: 80px; clear: both; font-family: arial, sans-serif; border-collapse: collapse; width: 100%; background: white;">
                    <thead>
                      <tr style="background: pink">
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Bank Name</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">IBAN</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Sort Code</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">BIC</th>
                        <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Payment Terms</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.BankName}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.IBAN}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.Sort}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.BIC}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${row.PaymentTerms}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <p style="text-align: center; font-size: 12px">Copyright Focus247 © 2022</p>
                  <a class="download" tabindex="0" href="${link}" style="margin-top: 20px;">Download Invoice</a>
                </div>
                 </body>
          </html>
          `,
        },
        toRecipients: toEmails,
        ccRecipients: ccEmails,
        attachments: attachments,
      },
    };

    // Assuming `fileAttachmentsArr` is an array of file objects
    // Convert each file to Base64 and include them as attachments in Sendmemail
    // Convert each file to Base64 and include them as attachments in Sendmemail

    const formData = new FormData();
    if (fileAttachmentsArr.length !== 0) {
      formData.append('EmailSubject', emailSubject);
      formData.append('EmailPlainTextBody', '');
      formData.append('EmailHtmlBody', Sendmemail.message.body.content);
      toEmails.forEach(email => {
        formData.append('ToList', email.emailAddress.address || '');
      });
      ccEmails.forEach(email => {
        formData.append('CcList', email.emailAddress.address || '');
      });
      // formData.append('BccList', '');
      // Assuming `fileAttachments` is an array of file objects
      fileAttachmentsArr.forEach((file, index) => {
        formData.append(`file[${index - 0}]`, file.file.file);
      });
    } else {
      formData.append('EmailSubject', emailSubject);
      formData.append('EmailPlainTextBody', '');
      formData.append('EmailHtmlBody', Sendmemail.message.body.content);
      // formData.append('ToList', toEmails[0] || '');
      toEmails.forEach(email => {
        console.log(email);
        formData.append('ToList', email.emailAddress.address || '');
      });
      ccEmails.forEach(email => {
        formData.append('CcList', email.emailAddress.address || '');
      });
      // formData.append('BccList', '');
    }

    try {
      if (modalSendGrid) {
        dispatch({ type: FETCH_START });
        if (formData) {
          await customAxios.post('Invoice/SendEmail', formData, { headers: authHeader() });
        }
        // toggleSendGrid();
        // customAxios
        //   .post('Invoice/SendEmail', EmailObj, { headers: authHeader() })
        //   .then((data) => {
        //     debugger
        //     toggleSendGrid();
        //     dispatch({ type: FETCH_SUCCESS });
        //   })
        //   .catch(function(error) {
        //     dispatch({ type: FETCH_ERROR, payload: error.message });
        //   });
      } else {
        console.log(modalSendGrid, 'failed');
        await sendEmail(app.authProvider, Sendmemail);
        toggleOutlook();
      }
      // dispatch({ type: FETCH_SUCCESS, payload: 'Mail Success' });
      setShowMessage(true);
    } catch (err) {
      console.log('Error creating event', err, err.message);
      app.displayError('Error creating event', JSON.stringify(err));
      dispatch({ type: FETCH_ERROR, payload: err.message });
      // toggleOutlook();
      setModalOutlook(false);
      setModalSendGrid(false);
    }
  };
  /* Delete Invoice */
  function deleteInvoice(url) {
    dispatch({ type: FETCH_START });
    customAxios
      .delete(url, { headers: authHeader() })
      .then(data => {
        getUserProfileData();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const UpdateIsPaid = row => {
    let editInvoice = {
      TableName: 'Acct_Invoices',
      ColumnName: 'IsPaid',
      NewValue: '1',
      PrimaryKeyValue: row.InvoiceId.toString(),
      AcctTeamId: accTeam,
      // InvoiceItems: InvoiceItems,
    };
    dispatch({ type: FETCH_START });
    customAxios.post('/Invoice/EditInvoiceOnly', editInvoice, { headers: authHeader() }).then(data => {
      dispatch({ type: FETCH_SUCCESS });
      onInvoicesClose();
      /* Send Email and close modal */
    });
  };

  const TabWithCount = ({ children, count }) => {
    return (
      <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', lineHeight: 'normal' }}>
        {count ? (
          <Typography component="div" variant="body2" sx={{ marginLeft: '0.5rem' }}>
            {count}
          </Typography>
        ) : (
          '0'
        )}
        <Typography component="div">{children}</Typography>
      </Box>
    );
  };

  // Search Bar Function
  /* START: Search bar */
  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredDraftRows = [];
    let filteredSentRows = [];
    filteredDraftRows = getArrayFilterData(data, searchkey);
    filteredSentRows = getArrayFilterData(completedData, searchkey);

    if (searchkey.length <= 0) {
      setSearchDraftData(data);
      setSearchSentData(completedData);
    } else {
      setSearchDraftData(filteredDraftRows);
      setSearchSentData(filteredSentRows);
    }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.CompanyName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.ClientName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };
  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setSearchDraftData(data);
    setSearchSentData(completedData);
  };

  /* START: Search bar after Menu click */

  let filteredDraftRowsText = [];
  let filteredSentRowsText = [];

  useEffect(() => {
    if (searchKey.length) {
      // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);

      filteredDraftRowsText = getTextArrayFilterData(data, searchKey);
      filteredSentRowsText = getTextArrayFilterData(completedData, searchKey);

      if (searchKey.length <= 0) {
        setSearchDraftData(data);
        setSearchSentData(completedData);
      } else {
        setSearchDraftData(filteredDraftRowsText);
        setSearchSentData(filteredSentRowsText);
      }
    }
  }, [data, completedData, searchKey]);

  const getTextArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.CompanyName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.ClientName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  /* END: Search bar after Menu click */

  function getFilterTabData(obj, IsDraft) {
    let objArr = [];
    objArr = obj.filter(obj => obj.IsDraft === IsDraft);
    return objArr;
  }
  /* END: Search bar */
  const textFieldStyles = makeStyles(theme => ({
    root: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none !important',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none !important',
      },
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
    },
    stack: {
      fontFamily: 'Normal',
    },
  }));

  // row selection

  const handelSelectAll = (e, tabelData) => {
    if (e.target.checked) {
      setAllSelected(tabelData);
    }
  };
  const handelSelect = (e, rowData) => {
    let data = [];
    // if (e.target.checked) {
    //   data = [...data, rowData];
    //   setSelectedItem([...selectedItem, rowData]);
    //   sentSelectedNewData();
    // } else {
    //   let newData = selectedItem.filter(e => e !== rowData);
    //   setSelectedItem(newData);
    //   sentSelectedNewData();
    // }
  };

  const sentData = () => {
    let newdata = [];
    searchSentData.map(item => {
      item.child.map(newitem => {
        newdata = [...newdata, newitem];
      });
    });
    setFilterSendData(newdata);
  };

  const draftData = () => {
    let newdata = [];
    searchDraftData.map(item => {
      item.child.map(newitem => {
        newdata = [...newdata, newitem];
      });
    });
    setFilterDraftData(newdata);
  };

  useEffect(() => {
    sentData();
    draftData();
  }, [searchSentData, searchDraftData]);

  const sentSelectedNewData = () => {
    let newdata = [];
    selectedItem.map(item => {
      // item.child.map(newitem => {
      //   newdata = [...newdata, newitem];
      // });
      let selectedRow = {
        CompanyName: item.CompanyName,
        ClientName: item.ClientName,
        InvoiceNumber: item.InvoiceNumber,
        BankAccountID: item.IBAN,
        IsPaid: item.IsPaid,
        IssueDate: item.IssueDate,
        DueDate: item.DueDate,
      };
      newdata = [...newdata, selectedRow];
    });
    setFilterSelectedSendData(newdata);
  };

  // filter date start
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    sentSelectedNewData();
  }, [selectedItem]);

  useEffect(() => {
    handlefilterData();
  }, [filterSelectedData]);

  useEffect(() => {
    handlefilterSendData();
  }, [filterSelectedSend]);

  const handlefilterData = () => {
    let data = [];
    const startDate = moment(filterSelectedData?.startDate).format('YYYY-MM-DD');
    const endDate = moment(filterSelectedData?.endDate).format('YYYY-MM-DD');

    searchDraftData
      .filter(row => {
        let filterPass = true;
        const IssueDate = new Date(row.IssueDate);
        if (startDate && endDate) {
          filterPass = filterPass && new Date(startDate) <= IssueDate && new Date(endDate) >= IssueDate;
        }
        return filterPass;
      })
      .map(row => {
        data = [...data, row];
      });
    setSearchDraftData(data);
  };

  // console.log({ filterSelectedSend, filterSelectedData, searchDraftData }, 'filterSelectedSendData');

  const handlefilterSendData = () => {
    let data = [];
    searchSentData
      .filter(row => {
        let filterPass = true;
        const IssueDate = new Date(row.IssueDate);
        if (filterSelectedSend.startDate && filterSelectedSend.endDate) {
          filterPass =
            filterPass &&
            new Date(filterSelectedSend.startDate) < IssueDate &&
            new Date(filterSelectedSend.endDate) > IssueDate;
        }
        return filterPass;
      })
      .map(row => {
        data = [...data, row];
      });
    setSearchSentData(data);
  };

  // filter date end

  //table function

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceNumber, setInvoiceNumber] = React.useState();

  const [openToastMessage, setOpenToastMessage] = React.useState(false);

  const handleToastMessageClick = () => {
    setOpenToastMessage(true);
    customAxios
      .get('/Invoice/GetNextInvoiceNumber', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setInvoiceNumber(data);

          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleToastMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToastMessage(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" style={{ textTransform: 'none' }} onClick={handleToastMessageClose}>
        Close
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleToastMessageClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, table) => {
    if (isFilterApplied) {
      if (event.target.checked) {
        const newSelectedDraft = filterDraftData.map(n => n.InvoiceNumber);
        const newSelectedSent = filterSendData.map(n => n.InvoiceNumber);
        if (value === 0) {
          setSelected(newSelectedDraft);
          setSelectedItem(filterDraftData);
          setSelectedInvoices(filterDraftData);
        } else {
          setSelected(newSelectedSent);
          setSelectedItem(filterSendData);
          setSelectedInvoices(filterSendData);
        }
        // setSelectedItem(table);
        // setSelectedInvoices(table);
        return;
      }
    } else {
      if (event.target.checked) {
        const newSelectedDraft = searchDraftData.map(n => n.InvoiceNumber);
        const newSelectedSent = searchSentData.map(n => n.InvoiceNumber);
        if (value === 0) {
          setSelected(newSelectedDraft);
        } else {
          setSelected(newSelectedSent);
        }
        setSelectedItem(table);
        setSelectedInvoices(table);
        return;
      }
    }
    setSelected([]);
    setSelectedItem([]);
  };

  // const handleSelectAllClicks = (event, table) => {
  //   if (event.target.checked) {
  //     const newSelected = searchSentData.map(n => n.InvoiceNumber);
  //     setSelected(newSelected);
  //     console.log(table);
  //     setSelectedItem(table);
  //     // setSelectedItem(table);
  //     return;
  //   }
  //   setSelected([]);
  //   setSelectedItem([]);
  // };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    const newArr = searchDraftData.filter(instance => newSelected.includes(instance.InvoiceNumber));
    const newSend = searchSentData.filter(instance => newSelected.includes(instance.InvoiceNumber));
    if (value === 0) {
      setSelectedItem(newArr);
    } else {
      setSelectedItem(newSend);
    }
    setSelected(newSelected);
    setSelectedInvoices(newArr);

    if (value === 0) {
      setSelectedInvoices(newArr);
    } else {
      setSelectedInvoices(newSend);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchDraftData.length) : 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleActionMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // console.log(searchDraftData, 'array');
  const onTeamChangeHandler = args => {
    setAccTeam(args.value);
  };
  return (
    <div className="app-wrapper invoice-row">
      <div>
        <Snackbar
          open={openToastMessage}
          autoHideDuration={6000}
          onClose={handleToastMessageClose}
          message={`Invoice number ${invoiceNumber}`}
          action={action}
        />
      </div>
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.invoice" />} /> */}

      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.invoiceList" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={accTeamAll}
              value={accTeamAll.find(c => c.value === accTeam)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        {accTeamAll.length > 0 ? (
          <div className="content-horizontal__center">
            <TaskFilterDialog
              filterData={value === 0 ? searchDraftData : searchSentData}
              filterSelectedData={filterSelectedData}
              setFilterSelectedData={setFilterSelectedData}
              getUserProfileData={getUserProfileData}
              setFilterSelectedSend={setFilterSelectedSend}
              value={value}
              setIsFilterApplied={setIsFilterApplied}
            />

            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => searchCRMData(e)}
                value={searchKey}
                inputRef={inputRef}
              />
              {searchKey && (
                <CloseIcon
                  onClick={clearSearchData}
                  style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
                />
              )}
            </Search>
            <Button
              variant="contained"
              style={{ textTransform: 'none' }}
              color="primary"
              onClick={() => {
                toggle();
                handleToastMessageClick();
              }}>
              Add
            </Button>
            <CmtDropdownMenu
              TriggerComponent={
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleClickData}
                  sx={{
                    mx: 2,
                  }}
                  style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
                  <MoreVertIcon />
                </Button>
              }
              items={[...actionsList]}
              menuClasses={cusclass.root}
            />
            <Dialog
              open={openAlert}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description">
              <DialogTitle>{'Bulk Inovice Download Warning'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  To continue downloading the invoice, please select at least one invoice
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} style={{ textTransform: 'none' }}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <CSVLink data={value === 0 ? filterDraftData : filterSendData} ref={downloadCsv} filename={'invoice-file.csv'} />
            <CSVLink data={filterSelectedSendData} ref={selectedDownloadCsv} filename={'invoice-file.csv'} />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {accTeamAll.length > 0 ? (
        <Box className={tabClasses.root}>
          <Paper square>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              indicatorColor="primary"
              textColor="primary">
              <Tab label={<TabWithCount count={searchDraftData.length}>Drafts</TabWithCount>} {...a11yProps(0)} />
              <Tab label={<TabWithCount count={searchSentData.length}>Sent</TabWithCount>} {...a11yProps(1)} />
            </Tabs>
          </Paper>

          <TabPanel value={value} index={0}>
            {skeletonLoaderState ? (
              <>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100]}
                  component="div"
                  count={searchDraftData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer component={Paper} className={classes.root}>
                  <Table aria-label="collapsible table">
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={e => handleSelectAllClick(e, data)}
                      onRequestSort={handleRequestSort}
                      rowCount={searchDraftData.length}
                    />
                    <TableBody>
                      <SkeletonLoader />
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <>
                {data.length > 0 ? (
                  <React.Fragment>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 100]}
                      component="div"
                      count={searchDraftData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <TableContainer component={Paper} className={classes.root}>
                      <Table aria-label="collapsible table">
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={e => handleSelectAllClick(e, data)}
                          onRequestSort={handleRequestSort}
                          rowCount={searchDraftData.length}
                        />
                        <TableBody>
                          {/* {getFilterTabData(searchDraftData, 'Yes').map((row, index) => ( */}
                          {stableSort(searchDraftData, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              // console.log(row, 'row');
                              return (
                                <Row
                                  key={index}
                                  row={row}
                                  details={detailsClickHandler}
                                  handelSelect={handelSelect}
                                  isSelected={isSelected}
                                  labelId={index}
                                  handleClicks={handleClick}
                                  setSearchDraftData={setSearchDraftData}
                                />
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {completedData.length > 0 ? (
              <React.Fragment>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 100]}
                  component="div"
                  count={searchSentData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* {console.log(completedData)} */}
                <TableContainer component={Paper} className={classes.root}>
                  <Table aria-label="collapsible table">
                    {/* <TableHead>
                    <TableRow>
                      <TableCell>
                        {' '}
                        <Checkbox onChange={e => handelSelectAll(e, completedData)} />
                      </TableCell>
                      <TableCell />

                      <TableCell>Company Name</TableCell>
                      <TableCell align="left">Client Name</TableCell>
                      <TableCell align="left">Invoice Number</TableCell>
                      <TableCell align="left">Bank Account ID</TableCell>
                      <TableCell align="left">Is Paid?</TableCell>
                      <TableCell align="left">Issue Date</TableCell>

                      <TableCell align="left">Due Date</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead> */}
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={e => handleSelectAllClick(e, completedData)}
                      onRequestSort={handleRequestSort}
                      rowCount={searchSentData.length}
                    />
                    <TableBody>
                      {/* {getFilterTabData(searchSentData, 'No').map((row, index) => ( */}
                      {stableSort(searchSentData, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          return (
                            <Row
                              key={index}
                              row={row}
                              details={detailsClickHandler}
                              handelSelect={handelSelect}
                              isSelected={isSelected}
                              labelId={index}
                              handleClicks={handleClick}
                              value={value}
                              setSearchSentData={setSearchSentData}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            ) : (
              ''
            )}
          </TabPanel>
        </Box>
      ) : (
        <div style={{ marginTop: '5px', fontSize: '1rem', padding: '5px 20px' }}>
          You are not part of any team, Start your own Accounts team to add invoices. Go to "Accounting Team" page.
          <NavLink to="/accounting/accounting-teams">"Here"</NavLink>{' '}
        </div>
      )}

      {modal && (
        <CreateEditInvoice
          getUserProfileData={getUserProfileData}
          modal={modal}
          details={details ? details : []}
          toggle={toggle}
          onInvoicesClose={onInvoicesClose}
          invoices={{ name: '' }}
          newCloneInvoice={newCloneInvoice}
          acctTeamId={accTeam}
          setFilterCount={setFilterCount}
          filterSelectedData={filterSelectedData}
          searchDraftData={searchDraftData}
          setSearchDraftData={setSearchDraftData}
        />
      )}
      {cloneModal && (
        <CreateCloneInvoice
          modal={cloneModal}
          details={cloneDetails ? cloneDetails : []}
          toggle={cloneToggle}
          onInvoicesClose={onInvoicesClose}
          invoices={{ name: '' }}
          newCloneInvoice={newCloneInvoice}
          acctTeamId={accTeam}
          isFilterApplied={isFilterApplied}
          filterSelectedData={filterSelectedData}
          getUserProfileData={getUserProfileData}
        />
      )}
      {modalOutlook && (
        <OutlookInvoice
          modalOutlook={modalOutlook}
          detailsOutlook={detailsOutlook ? detailsOutlook : []}
          toggleOutlook={toggleOutlook}
          sendEmailOutLook={sendEmailOutLook}
          acctTeamId={accTeam}
        />
      )}
      {modalSendGrid && (
        <SendGridEmail
          modalSendGrid={modalSendGrid}
          detailsSendGrid={detailsSendGrid ? detailsSendGrid : []}
          toggleSendGrid={toggleSendGrid}
          sendEmailSendGrid={sendEmailSendGrid}
          acctTeamId={accTeam}
        />
      )}

      <Tostify showMessage={showMessage} setShowMessage={setShowMessage} />
    </div>
  );
};

export default Invoice;
