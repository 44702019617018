import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Menu, MenuItem, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../services/auth';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SidebarThemeContext from '../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));
const CmtDropdownMenu = ({ TriggerComponent, items, onItemClick, menuClasses }) => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const open = Boolean(anchorEl);
  const { authUser } = useSelector(({ auth }) => auth);
  const [enableScrollLock, setEnableScrollLock] = useState(false);

  useEffect(() => {
    setMenuItems(items);
  }, [items]);

  const openMenu = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setEnableScrollLock(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setEnableScrollLock(false);
  };

  const handleMenuItemClick = (item, selectedIndex, event) => {
    event.stopPropagation();
    let updatedItem = { ...item };

    if (item.onClick && typeof item.onClick === 'function') {
      updatedItem = item.onClick(item);
    } else if (onItemClick && typeof onItemClick === 'function') {
      updatedItem = onItemClick(item);
    }

    if (updatedItem) {
      let hasChange = false;
      const newMenuItems = menuItems.map((item, index) => {
        if (selectedIndex === index) {
          hasChange = true;
          item = updatedItem;
        }
        return item;
      });

      if (hasChange) setMenuItems(newMenuItems);
    }

    closeMenu();
  };

  return (
    <React.Fragment>
      <div className="pointer">
        <TriggerComponent.type {...TriggerComponent.props} onClick={openMenu} />
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
        style={{ top: '50px' }}
        disableScrollLock={enableScrollLock}
        className={menuClasses ? menuClasses : null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        // anchorOrigin={{ vertical:'bottom', horizontal: 'right' }}
      >
        {/* <MenuItem>
          <div className="ml-2"> {authUser ? authUser.Email : ''}</div>
        </MenuItem> */}
        {menuItems.map((item, index) => {
          return (
            <MenuItem key={index} disabled={item.disabled} onClick={event => handleMenuItemClick({ ...item }, index, event)}>
              {item.icon}
              <div className="ml-2">{item.label}</div>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

CmtDropdownMenu.propTypes = {
  items: PropTypes.array.isRequired,
  TriggerComponent: PropTypes.element.isRequired,
  onItemClick: PropTypes.func,
};

export default CmtDropdownMenu;
