import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

const headCells = [
  {
    id: 'TemplateName',
    numeric: false,
    disablePadding: false,
    label: 'TemplateName',
    minWidth: 80,
  },
  {
    id: 'TemplateSubject',
    numeric: false,
    disablePadding: false,
    label: 'TemplateSubject',
    minWidth: 80,
  },
  {
    id: 'TemplateBody',
    numeric: false,
    disablePadding: false,
    label: 'Template Body',
    minWidth: 150,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: '',
    minWidth: 100,
  },
];

export const TableHeader = props => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}>
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};
