import React from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';

import { styled } from '@material-ui/styles';

const CustomTableCell = styled(TableCell)`
  && {
    padding: 8px; /* Set the desired padding here */
  }
`;

const SkeletonLoader = () =>
  Array.from({ length: 5 }).map((_, index) => (
    <TableRow key={index}>
      <CustomTableCell sx={{ display: 'flex', gap: 3 }}>
        <Skeleton variant="rounded" width={40} height={40} />
        {/* <Skeleton variant="text" width={150} /> */}
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={10} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={100} height={40} />
      </CustomTableCell>
      <CustomTableCell>
        <Skeleton variant="text" width={10} height={40} />
      </CustomTableCell>
    </TableRow>
  ));

export default SkeletonLoader;
