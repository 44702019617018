import React, { useEffect, useState } from 'react';

//api
import { useDispatch, useSelector } from 'react-redux';

import {
  setTmTeamMembers,
  setTmTaskByTeam,
  setTmStatusByTeam,
  setTmCardData,
  setTmCardDateFlag,
  setTmSelectedTeam,
  setBordeListData,
  setChecked,
} from '../../../../redux/actions';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';

//modal import
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import IntlMessages from '../../util/IntlMessages';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const ModalContentWrapper = styled.div`
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .btnCtn div {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
`;

const TimeManagementModal = props => {
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const { isModalOpen, setIsModalOpen, openTimeModaData, allTasks } = props;

  const [inputHrs, setInputHrs] = useState(0);
  const [inputMin, setInputMin] = useState(0);
  const [taskTimeData, setTaskTimeData] = useState(null);
  const classes = useStyles();
  const taskId = openTimeModaData && openTimeModaData?.dispatchObj[0]?.PrimaryKeyValue;
  const findTask = allTasks?.find(element => element.id === +taskId);
  const [selectedDate, setSelectedDate] = useState(null);
  function handleClose() {
    setTaskTimeData(null);
    // findTask(null);
    setIsModalOpen(!isModalOpen);
    setInputHrs(0);
    setInputMin(0);
  }
  let isHrInputvalid = inputHrs <= 24 && inputHrs >= 0;
  let isMinInputValid = inputMin < 60 && inputMin >= 0;

  function handelHrInputChange(args) {
    let currVal = args.target.value;
    // console.log(currVal);
    setInputHrs(parseFloat(currVal));
    // if( currVal && currVal >=0 &&  currVal<=24 ){

    // }else{
    //     alert("Please enter valid hours, hours cannot be more than 24 !");
    // }
  }

  function handelMinInputChange(args) {
    let currVal = args.target.value;
    console.log(currVal);

    setInputMin(parseFloat(currVal));
    // if( currVal && currVal >=0 &&  currVal<=60 ){

    // }else{
    //     alert("Please enter valid minutes, minutes cannot be more than 24 !");
    // }
  }
  let hrsBtnArr = [1, 2, 3, 4, 5, 6, 7];
  let minBtnArr = [10, 20, 30, 40, 50];

  function hrsBtnClickHandler(hr) {
    setInputHrs(hr);
  }
  function minBtnClickHandler(min) {
    setInputMin(min);
  }
  function taskCompleteHandler() {
    try {
      if (!inputHrs && !inputMin) {
        alert('Both hours and minutes cannot be zero');
        return;
      }

      openTimeModaData.setIsCompletCheckBox(true);
      let bodyEdit = openTimeModaData.bodyEdit;
      dispatch({ type: FETCH_START });
      customAxios
        .post('/Task/EditTask', bodyEdit, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          AddTaskTimeRegistration();
          dispatch(setChecked(openTimeModaData?.dispatchObj));
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    } catch (ex) {
      console.log(ex);
    }
  }

  function AddTaskTimeRegistration() {
    try {
      let taskInfo = openTimeModaData.bodyEdit;
      let obj = {
        TmTeamId: taskInfo?.TeamId,
        TmTaskId: taskInfo?.PrimaryKeyValue,
        TimeHours: inputHrs,
        TimeMinutes: inputMin,
        LogDateTime: selectedDate ? formateDate(selectedDate) : null,
      };
      dispatch({ type: FETCH_START });
      customAxios
        .post('/TaskTeams/AddTaskTimeRegistration', obj, { headers: authHeader() })
        .then(data => {
          setSelectedDate(null);
          handleClose();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          setSelectedDate(null);
        });
    } catch (ex) {
      console.log(ex);
      setSelectedDate(null);
    }
  }

  const formateDate = dateString => {
    const date = new Date(dateString);
    const isoString = date.toISOString(); // e.g., "2024-11-04T14:25:35.875Z"

    // Insert extra precision (e.g., microseconds)
    const formattedDate = isoString.replace('Z', '000+00:00'); // "2024-11-04T14:25:35.875000+00:00"
    return formattedDate;
  };

  // Get today's date
  const today = new Date();
  const maxDate = today.toISOString().split('T')[0];

  // Get the date from one week ago
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 7);
  const minDate = lastWeek.toISOString().split('T')[0];

  const handleDateChange = e => {
    setSelectedDate(e.target.value);
  };
  const getFormattedDate = dateString => {
    const date = new Date(dateString);

    const day = date
      ?.getDate()
      .toString()
      .padStart(2, '0'); // Get day and pad with leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (January is 0) and pad with leading zero if needed
    const year = date.getFullYear(); // Get full year

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const getHoursLogged = dateString => {
    const date = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - date;

    // Convert milliseconds to hours
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

    return hours;
    // Output: Number of hours from the given date until now
  };

  // const getTaskTime = async () => {
  //   let url = `Task/GetTaskTimeRegistrations?tmTaskId=${23176}`;
  //   try {
  //     const response = await customAxios.get(url, { headers: authHeader() });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getTaskTime = () => {
    // dispatch({ type: FETCH_START });
    let url = `Task/GetTaskTimeRegistrations?tmTaskId=${findTask?.id}`;

    try {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          // setTaskTimeData(data);
          let totalHours = 0;
          let totalMinutes = 0;

          // Calculate total hours and minutes
          data.forEach(entry => {
            totalHours += entry.TimeHours;
            totalMinutes += entry.TimeMinutes;
          });

          // Adjust total hours if minutes exceed 60
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes %= 60;

          // Format total hours and minutes in hh:mm format
          const formattedTotal = `${totalHours > 9 ? totalHours : '0' + totalHours}:${totalMinutes
            .toString()
            .padStart(2, '0')}`;

          setTaskTimeData(formattedTotal);
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  useEffect(() => {
    if (findTask) {
      getTaskTime(findTask.id);
    }
  }, [openTimeModaData]);

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      {/* //<DialogTitle>Subscribe</DialogTitle> */}
      <DialogContent>
        <div className="my-3 d-flex justify-content-between align-items-center">
          <p>Date Created : {findTask && getFormattedDate(findTask?.tCreated)}</p>
          <p>Hours Logged : {taskTimeData && taskTimeData}</p>
        </div>
        <DialogContentText sx={{ fontFamily: 'normal' }} component="h5">
          Please fill the time taken to complete this activity
        </DialogContentText>
        <ModalContentWrapper>
          <TextField
            type="date"
            name="dateSelected"
            label="Task worked on"
            value={selectedDate || ''}
            onChange={handleDateChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: minDate, // Set minimum date to one week ago
              max: maxDate, // Set maximum date to today
            }}
          />
          <div className="btnCtn">
            <h6>Hours</h6>
            <div>
              {hrsBtnArr.map(item => {
                return (
                  <Button key={item} onClick={() => hrsBtnClickHandler(item)} variant="contained">
                    {item} hr
                  </Button>
                );
              })}
              {/* <Button onClick={()=>hrsBtnClickHandler(1)}  variant="contained">1 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(2)} variant="contained">2 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(3)} variant="contained">3 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(4)} variant="contained">4 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(5)} variant="contained">5 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(6)} variant="contained">6 hr</Button>
              <Button onClick={()=>hrsBtnClickHandler(7)} variant="contained">7 hr</Button> */}
            </div>
          </div>
          <div className="btnCtn">
            <h6>Minutes</h6>
            <div>
              {minBtnArr.map(item => {
                return (
                  <Button key={item} onClick={() => minBtnClickHandler(item)} variant="contained">
                    {item} min
                  </Button>
                );
              })}
              {/* <Button variant="contained">10 min </Button>
              <Button variant="contained">20 min</Button>
              <Button variant="contained">30 min</Button>
              <Button variant="contained">40 min</Button>
              <Button variant="contained">50 min</Button>
              <Button variant="contained">60 min</Button> */}
            </div>
          </div>
        </ModalContentWrapper>

        <div>
          <h6 style={{ marginLeft: '10px' }}>Registered hrs and min</h6>
          <div>
            <TextField
              label="Hrs"
              type="number"
              error={!isHrInputvalid}
              value={inputHrs}
              id="outlined-start-adornment"
              className={classes.input}
              sx={{ m: 1, width: '25ch' }}
              onChange={handelHrInputChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">hr</InputAdornment>,
              }}
              helperText={!isHrInputvalid ? 'Value should be greater than 0 and less than 24' : ''}
            />
            <TextField
              label="Minutes"
              className={classes.input}
              type="number"
              error={!isMinInputValid}
              value={inputMin}
              onChange={handelMinInputChange}
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                endAdornment: <InputAdornment position="end">min</InputAdornment>,
              }}
              helperText={!isMinInputValid ? 'Value should be greater than 0 and less than 60' : ''}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!isHrInputvalid || !isMinInputValid} onClick={taskCompleteHandler}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeManagementModal;
