import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TaskInformation from './TaskInformation';
const divStyle = {
  maxHeight: '100%', // Fill all available height
  overflowY: 'auto', // Enable vertical scrolling when necessary

  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(155, 155, 155, 0.5) rgba(255, 255, 255, 0.5)',
  // Add other scrollbar properties as needed
};

const ShowTasks = ({ selectedTeamValue, projectDetails, teamMembers }) => {
  const dispatch = useDispatch();

  const [projectTasks, setProjectTasks] = useState([]);
  const [isTask, setIsTask] = useState('');

  const getAllTasks = () => {
    setProjectTasks([]);
    dispatch({ type: FETCH_START });
    customAxios
      .get(`Task/GetTasks/${selectedTeamValue.Id}`, { headers: authHeader() })
      .then(({ data }) => {
        const filterTasks = data.filter(element => {
          return element?.ProjectId === projectDetails?.pId;
        });
        setProjectTasks(filterTasks);
        if (filterTasks.length === 0) {
          setIsTask('No Tasks Available');
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  useEffect(() => {
    getAllTasks();
  }, []);

  return (
    <div style={divStyle}>
      {projectTasks?.map((element, index) => {
        return <TaskInformation taskDetails={element} index={index} key={index} teamMembers={teamMembers} />;
      })}
      <p style={{ marginLeft: '8px', fontFamily: 'normal', color: 'gray' }}>{isTask}</p>
    </div>
  );
};

export default ShowTasks;
