/**
 * Version 2.0
 * Vikas W
 * 03.05.2022
 * -
 * This component defines all the routes to the other pages
 */
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import DashboardPage from './Pages/DashboardPage';
import TaskManageTeamsPage from './Pages/Planning/TaskManageTeamsPage';
import TaskmanagementPage from './Pages/Planning/TaskmanagementPage';
import ClientsPage from './Pages/Accounting/ClientsPage';
import CompanyProfilePage from './Pages/Accounting/CompanyProfilePage';
import BankAccountPage from './Pages/Accounting/BankAccountPage';
import Expenses from './Pages/Accounting/Expenses/index';
import InvoicePage from './Pages/Accounting/InvoicePage';
import QuotationPage from './Pages/Accounting/QuotationPage';
import CreditNote from './Pages/Accounting/CreditNote';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import ResetPasswordPage from './Auth/ResetPassword';
import { Dashboard } from '@material-ui/icons';
import MicrosoftPage from './Pages/Microsoft/Authentication';
import BankStatement from './Pages/BankStatement/Overview/index';
// import ContactsOverview from './Pages/CRM/ContactOverview/index';
import ContactsOverview from './Pages/CRM/ContactOverview/index';
import AccTeams from './Pages/Accounting/AccountingTeam/index';
import SendEmail from './Pages/CRM/SendEmail/SendEmail';
import CrmTeams from './Pages/CRM/CrmTeams/index';
import ChangePassword from './Pages/MyAccount/ChangePassword/index';
import EmailTemplates from './Pages/CRM/EmailTemplates';
import ProjectSectionPage from './Pages/Planning/ProjectSectionPage';
import ConfirmVerifyEmail from './Auth/ConfirmVerifyEmail';
import NewSetPasswordPage from './Auth/NewSetPasswordPage';
import ConfirmUserEmail from './Auth/ConfirmUserEmail';
import InviteUserToTmTeam from './Auth/InviteMember';
import ManageTeams from './Pages/EmbeddedAnalytics/Analytics/index';
import moment from 'moment';
import UploadPBIX from './Pages/EmbeddedAnalytics/UploadPBIX';
import EAReport from './Pages/EmbeddedAnalytics/EAReportPage/EAReport';

//plaid /plaid/test

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const history = useHistory();

  // useEffect(() => {
  //   const token = JSON.parse(localStorage.getItem('token'));
  //   if (token) {
  //     const getexpireDate = token['.expires'];
  //     let expireDate = moment.utc(getexpireDate).toDate();
  //     // Thu Feb 09 2023 13:42:19 GMT+0530
  //     const currentTime = moment(expireDate).format('YYYY-MM-DD HH:mm:ss z');
  //     let newCurrentDate = moment().format('YYYY-MM-DD HH:mm:ss z');
  //     if (currentTime > newCurrentDate) {
  //     } else {
  //       localStorage.removeItem('token');
  //       localStorage.removeItem('user');
  //       localStorage.clear();
  //     }
  //   } else {
  //   }
  // }, []);

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/signin'} />;
  } else if (
    authUser &&
    (location.pathname === '/signin' ||
      location.pathname === '/signup' ||
      location.pathname === '/verifyInviteUser' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/confirm-user' ||
      location.pathname === '/confirm-email' ||
      location.pathname === '/setpassword' ||
      location.pathname === '/reset-password')
  ) {
    return <Redirect to={'/dashboard-page'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard-page" component={DashboardPage} />
        <RestrictedRoute path="/accounting/company-profile" component={CompanyProfilePage} />
        <RestrictedRoute path="/accounting/clients-page" component={ClientsPage} />
        <RestrictedRoute path="/accounting/bankAccount-page" component={BankAccountPage} />
        <RestrictedRoute path="/accounting/credit-note" component={CreditNote} />
        <RestrictedRoute path="/accounting/invoice-page" component={InvoicePage} />
        <RestrictedRoute path="/accounting/accounting-teams" component={AccTeams} />
        <RestrictedRoute path="/accounting/quotation-page" component={QuotationPage} />
        <RestrictedRoute path="/planning/manage-teams" component={TaskManageTeamsPage} />
        <Route path="/embedded-analytics/report/:creditionals" component={EAReport} />
        <RestrictedRoute path="/planning/taskmanagement-page" component={TaskmanagementPage} />
        <RestrictedRoute path="/planning/project-page" component={ProjectSectionPage} />
        <RestrictedRoute path="/microsoft/authentication" component={MicrosoftPage} />
        <RestrictedRoute path="/bank-statement/overview" component={BankStatement} />
        <RestrictedRoute path="/crm/contactsOverview-page" component={ContactsOverview} />
        <RestrictedRoute path="/crm/sendEmail" component={SendEmail} />
        {/* <RestrictedRoute path="/crm/contactsOverviewBeta-page" component={ContactsOverviewBeta} /> */}
        {/* <RestrictedRoute path="/plaid/test" component={BankAccounts} /> */}
        <RestrictedRoute path="/crm/emailTemplates-page" component={EmailTemplates} />
        <RestrictedRoute path="/crm/teams" component={CrmTeams} />
        <RestrictedRoute path="/embedded-analytics/manage-teams" component={ManageTeams} />
        <RestrictedRoute path="/embedded-analytics/view-reports" component={UploadPBIX} />
        <RestrictedRoute path="/expenses/all" component={Expenses} />
        <RestrictedRoute path="/my-account/change-password" component={ChangePassword} />
        <Route path="/signin" component={Login} />
        <Route path="/confirm-user" component={ConfirmVerifyEmail} />
        <Route path="/confirm" component={ConfirmUserEmail} />
        <Route path="/verifyInviteUser" component={InviteUserToTmTeam} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/setpassword" component={NewSetPasswordPage} />
        {/* <Route path="/setpassword/:token" component={NewSetPasswordPage} /> */}
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/reset-password/:token" component={ResetPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
