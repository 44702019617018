import React, { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import 'global-styles.css';

//microsoft Graph api
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import ProvideAppContext from './microsoft-context/AppContext';
import ErrorMessage from './microsoft-context/ErrorMessage';

/* Plaid Provider context*/
import { PlaidProvider } from './plaid-context/PlaidContext';
import { createSignalRContext } from 'react-signalr';

//npm install react-image-lightbox
import 'react-image-lightbox/style.css';

export const store = configureStore();
const SignalRContext = createSignalRContext();

const App = ({ pca }) => (
  // const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')));

  <MsalProvider instance={pca}>
    <ProvideAppContext>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppContextProvider>
            <PlaidProvider>
              <AppWrapper>
                <Switch>
                  <Routes />
                </Switch>
                {/* </SignalRContext.Provider> */}
              </AppWrapper>
            </PlaidProvider>
          </AppContextProvider>
        </ConnectedRouter>
      </Provider>
    </ProvideAppContext>
  </MsalProvider>
);

export default App;
