/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useRef, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
import CardLayout from '../../components/CardLayout/index';
import CreateEdit from '../../components/Clients/CreateEdit';
import CreateCompany from '../../Accounting/CompanyProfilePage/CreateCompany';
import Select from 'react-select';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormControl } from '@mui/material';

/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { styled, alpha } from '@mui/material/styles';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CSVLink } from 'react-csv';
import CustomSkeletonLoader from '@jumbo/components/PageComponents/CustomSkeletonLoader';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'ImageUrl',
    numeric: false,
    disablePadding: false,
    label: 'Company Logo',
    minWidth: 80,
  },
  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Company Name',
    minWidth: 150,
  },
  {
    id: 'CompanyEmail',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    minWidth: 150,
  },
  {
    id: 'AddressLine1',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    minWidth: 120,
  },
  {
    id: 'PhoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
    minWidth: 120,
  },
  {
    id: 'VATNumber',
    numeric: false,
    disablePadding: false,
    label: 'VAT Number',
    minWidth: 100,
  },
  {
    id: 'Country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
    minWidth: 100,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 100,
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const menuStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px ',
      '& div': {
        marginRight: '8px',
        fontSize: '0.95rem',
      },
    },
    top: '40px !important',
  },
}));

const CompanyProfile = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [customData, setCustomData] = useState([]);

  /* Modal open/close */
  const [details, setDetails] = useState([]);
  const [open, setOpen] = useState(false);

  // search
  const [searchKey, setSearchKey] = useState('');

  //table config
  const classes = useStyles();
  const cusclass = menuStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const downloadCsv = useRef(null);
  const inputRef = useRef(null);
  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);

  //AccTeams
  const [accTeamId, setAccTeamId] = useState(null);
  const [accTeamAll, setAccTeamAll] = useState([]);

  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get('Acct/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data && data.length > 0) {
            let allteam = data.map(c => ({ value: c.Id, label: c.AcctTeamName }));

            setAccTeamAll(allteam);
            setAccTeamId(allteam[0].value);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            setAccTeamId(null);
            dispatch({ type: FETCH_SUCCESS });
            // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.ClientName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const companyActionHandler = (action, data) => {
    if (action === 'detail') {
      data.edit = true;
      toggle();
      setDetails(data);
    }
    if (action === 'delete') {
      deleteCompany(data.Id);
    }
  };

  /** Delete company **/
  function deleteCompany(id) {
    let url = '/Company/DeleteCompany/' + id + '?AcctTeamId=' + accTeamId;
    dispatch({ type: FETCH_START });
    customAxios
      .delete(url, { headers: authHeader() })
      .then(data => {
        getCompanies();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const handleClick = (event, data) => {
    data.edit = true;

    const selectedIndex = selected.indexOf(data.name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const getCompanies = () => {
    if (!accTeamId) return;
    dispatch({ type: FETCH_START });
    try {
      let url = 'Company/GetCompanies/' + accTeamId;
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setData(data);
            setCustomData(data);
            setSkeletonLoaderState(false);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    getCompanies();
  }, [accTeamId]);
  const onClientsClose = () => {
    setOpen(false);
    getCompanies();
  };

  //modal open/close
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setDetails([]);
  };

  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredDraftRows = [];
    let filteredSentRows = [];
    filteredDraftRows = getArrayFilterData(data, searchkey);
    // filteredSentRows = getArrayFilterData(completedData, searchkey);

    if (searchkey.length <= 0) {
      setCustomData(data);
      // setSearchSentData(completedData);
    } else {
      setCustomData(filteredDraftRows);
      // setSearchSentData(filteredSentRows);
    }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.CompanyName?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.AddressLine1?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setCustomData(data);
    // setSearchDraftData(data);
    // setSearchSentData(customData);
  };

  const toggles = () => {
    // setModal(!modal);
    handleClickData();
    // return <csvlink {...csvReport}>Export to CSV</csvlink>;
  };

  const actionsList = [
    {
      onClick: toggles,
      label: <IntlMessages id="crm.excelCSVExport" />,
    },
  ];

  const handleClickData = () => {
    downloadCsv.current.link.click();
  };
  const onTeamChangeHandler = args => {
    // console.log(args);
    setAccTeamId(args.value);
  };
  return (
    <div className="app-wrapper">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.Company" />} /> */}
      <div className="justify-content-right pb-3 text-right d-flex justify-content-start"></div>
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.CompanyList" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={accTeamAll}
              value={accTeamAll.find(c => c.value == accTeamId)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        <div className="content-horizontal__center">
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => searchCRMData(e)}
              value={searchKey}
              inputRef={inputRef}
            />
            {searchKey && (
              <CloseIcon
                onClick={clearSearchData}
                style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
              />
            )}
          </Search>
          <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={toggle}>
            Add
          </Button>
          <CmtDropdownMenu
            TriggerComponent={
              <Button
                variant="contained"
                color="primary"
                // onClick={handleClickData}
                sx={{
                  mx: 2,
                }}
                style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
                <MoreVertIcon />
              </Button>
            }
            items={[...actionsList]}
            menuClasses={cusclass.root}
          />
          <CSVLink data={customData} ref={downloadCsv} filename={'Company-Profile.csv'} />
        </div>
      </div>

      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={customData.length}
              />
              <TableBody>
                {skeletonLoaderState ? (
                  <CustomSkeletonLoader tableCellCount={8} />
                ) : (
                  <>
                    {stableSort(customData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.CompanyName);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={event => handleClick(event, row)}
                            // role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            // selected={isItemSelected}
                            key={index}>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.ImageUrl && <img src={`${row.ImageUrl}?${Math.random()}`} width="30" />}
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.CompanyName}
                            </TableCell>
                            <TableCell align="left">{row.CompanyEmail}</TableCell>
                            <TableCell align="left">
                              {row.AddressLine1}, {row.AddressLine2}
                            </TableCell>
                            <TableCell align="left">{row.PhoneNumber}</TableCell>
                            <TableCell align="left">{row.VATNumber}</TableCell>
                            <TableCell align="left">{row.Country}</TableCell>
                            <TableCell align="left">
                              <EditIcon className={classes.actionIcon} onClick={() => companyActionHandler('detail', row)} />
                              &nbsp;&nbsp;
                              <DeleteOutlineIcon
                                className={classes.actionIcon}
                                onClick={() => companyActionHandler('delete', row)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={customData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* {modal && (
         <CreateEdit modal={modal} details={details} toggle={toggle} onClientsClose={onClientsClose} clients={{ name: '' }} />
       )} */}
      {modal && (
        <CreateCompany
          modal={modal}
          details={details}
          toggle={toggle}
          onClientsClose={onClientsClose}
          clients={{ name: '' }}
          accTeamId={accTeamId}
        />
      )}
      {/* {openDetails && <DetailsClient open={openDetails} onClientsClose={onClientsClose} row={row} />} */}
    </div>
  );
};

export default CompanyProfile;
