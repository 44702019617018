import React, {
  useContext,
  createContext,
  useState,
  // MouseEventHandler,
  useEffect,
} from 'react';
import config from './Config';

import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import {
  InteractionType,
  // PublicClientApplication
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { getUser } from './services/GraphService';

const appContext = createContext({
  user: undefined,
  error: undefined,
  signIn: undefined,
  signOut: undefined,
  displayError: undefined,
  clearError: undefined,
  authProvider: undefined,
});

export function useAppContext() {
  return useContext(appContext);
}

export default function ProvideAppContext({ children }) {
  const auth = useProvideAppContext();
  return <appContext.Provider value={auth}>{children}</appContext.Provider>;
}

function useProvideAppContext() {
  const msal = useMsal();
  const [user, setUser] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        try {
          // Check if user is already signed in
          const account = msal.instance.getActiveAccount();
          if (account) {
            // Get the user from Microsoft Graph
            const user = await getUser(authProvider);

            setUser({
              displayName: user.displayName || '',
              email: user.mail || user.userPrincipalName || '',
              timeFormat: user.mailboxSettings?.timeFormat || 'h:mm a',
              timeZone: user.mailboxSettings?.timeZone || 'UTC',
            });
          }
        } catch (err) {
          displayError(err.message);
        }
      }
    };
    checkUser();
  });

  const displayError = (message, debug) => {
    setError({ message, debug });
  };

  const clearError = () => {
    setError(undefined);
  };

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
    account: msal.instance.getActiveAccount(),
    scopes: config.scopes,
    interactionType: InteractionType.Popup,
  });

  // const signIn = async () => {
  //     const result = await msal.instance.loginPopup({
  //         scopes: config.scopes,
  //         prompt: 'select_account'
  //     });

  //     // TEMPORARY: Show the access token
  //     displayError('Access token retrieved', result.accessToken);
  // };

  const signIn = async () => {
    await msal.instance.loginPopup({
      scopes: config.scopes,
      prompt: 'select_account',
    });

    // Get the user from Microsoft Graph
    const user = await getUser(authProvider);
    console.log(user, 'login user');
    setUser({
      displayName: user.displayName || '',
      email: user.mail || user.userPrincipalName || '',
      timeFormat: user.mailboxSettings?.timeFormat || '',
      timeZone: user.mailboxSettings?.timeZone || 'UTC',
    });
  };

  const signOut = async () => {
    await msal.instance.logoutPopup();
    setUser(undefined);
  };

  return {
    user,
    error,
    signIn,
    signOut,
    displayError,
    clearError,
    authProvider,
  };
}
