import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useDownloadPdf } from './useDownloadPdf';

const MenuIconsText = {
  fontSize: '16px',
  color: '#0e0e0e',
  textDecoration: 'none',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
};

function DownloadPdf({ fileData, isAction }) {
  const { handleViewPdf } = useDownloadPdf();

  return (
    <>
      {/* {isAction ? (
        <span onClick={e => handleViewPdf(e, fileData.ImgData)} style={MenuIconsText}>
          View
        </span>
      ) : (
        <PictureAsPdfIcon
          onClick={e => handleViewPdf(e, fileData.ImgData)}
          sx={{ fontSize: 35, cursor: 'pointer', color: '#D32F2F' }}
        />
      )} */}
      <PictureAsPdfIcon
        onClick={e => handleViewPdf(e, fileData.ImgData)}
        sx={{ fontSize: 35, cursor: 'pointer', color: '#D32F2F' }}
      />
    </>
  );
}

export default DownloadPdf;
