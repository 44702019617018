import React from 'react';
import './Error.css';
// import FocusLogo from "../../../../public/focus-logo.png"

const ErrorPage = () => {
  const logoSymbolUrl = '/images/focus-logo/focus-logo.png';
  return (
    <div className="error-page">
      <img style={{ width: '600px', paddingLeft: '50px' }} src={logoSymbolUrl} alt="Focus247" />
      <h1>Sorry Something Went Wrong !</h1>
      {/* 🚨 */}
      <p> Please Reload The Page</p>
    </div>
  );
};

export default ErrorPage;
