import React, { useState, useEffect, useRef } from 'react';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import axios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../../microsoft-context/AppContext';
import { sendEmail } from '../../../../microsoft-context/services/GraphService';

import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import ReactQuill from 'react-quill';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@material-ui/icons/Edit';
import DeleterIcon from '@material-ui/icons/Delete';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-email/style.css';
import { useForm, Controller } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import InputLabel from '@material-ui/core/InputLabel';
import IntlMessages from '../../util/IntlMessages';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import customAxios from '../../util/Api';
import { FormControl } from '@mui/material';
import Select from 'react-select';
import Tostify from 'routes/Pages/Accounting/InvoicePage/Tostify';
import TextField from '@material-ui/core/TextField';
import '../../../../css/reactQuill.css';
const useHeaderStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingBlock: '0.75rem',
    '& .modal-title': {
      width: '100%',
    },
    '& .header-content': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      paddingInline: '10px',
    },
    '& .flex25': {
      flex: 0.25,
    },
    '& .flex60': {
      flex: 0.6,
      textAlign: 'center',
    },
    '& .flex20': {
      flex: 0.2,
    },
    '& .flex15': {
      flex: 0.15,
    },
    '& .close': {
      fontSize: '2rem',
      border: 'none',
      padding: 0,
      position: 'relative',
      top: '16px',
      right: '10px',
    },
    '& .warning': {
      border: '1px solid red',
      borderRadius: '5px',
      color: 'red',
    },
    '& .connect': {
      border: '1px solid rgb(63, 81, 181)',
      borderRadius: '5px',
    },
  },
}));
const SendEmail = ({
  rowData,
  closeMail,
  onMailSentSuccess,
  getEmailCommunication,
  bulkMail,
  bulkData,
  setBulkMail,
  getContactHandler,
}) => {
  const [Teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const dispatch = useDispatch();

  const Quill = ReactQuill.Quill;

  var Block = Quill.import('blots/block');
  const Font = Quill.import('formats/font'); // <<<< ReactQuill exports it
  Font.whitelist = ['mirza', 'roboto']; // allow ONLY these fonts and the default
  Quill.register(Font, true);
  // var Block = Quill.import('blots/inline')
  //  Block.tagName = 'div';

  // Quill.register(Block);
  class ParagraphBlot extends Block {
    static create(value) {
      let node = super.create();
      value['style'] = 'margin-block-start:0;margin-block-end:0;';
      console.log(value);

      if (value.class !== '') {
        node.setAttribute('class', value.class);
      }
      if (value.style !== '') {
        node.setAttribute('style', value.style);
      }
      return node;
    }
    static formats(node) {
      if (node) {
        return {
          class: node.getAttribute('class') ? node.getAttribute('class') : '',
          style: node.getAttribute('style') ? node.getAttribute('style') : 'margin-block-start:0;margin-block-end:0;',
        };
      }
    }
  }
  ParagraphBlot.blotName = 'p';
  ParagraphBlot.tagName = 'p';
  Quill.register(ParagraphBlot);

  // react quill setting end
  const [mailSub, setMailSub] = useState('');
  const [mailContent, setMailContent] = useState('');

  const [textMailContent, setTextMailContent] = useState(''); //its is the text conent without any style
  const [openTemplate, setOpenTemplate] = useState(false);
  const [openCustomTemplate, setOpenCustomTemplate] = useState(false);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  // dialog comp test start
  const [emailsTo, setEmailsTo] = useState([]);
  const [emailsCC, setEmailsCC] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  // snipets start
  const currMaibodyRef = useRef(null);
  const currMailSubRef = useRef(null);
  const [lastTestAreaFocus, setLastTestAreaFocus] = useState(null);
  const [openAddSnippet, setOpenAddSnippet] = useState(false);
  const [openEditSnippet, setOpenEditSnippet] = useState(false);
  const [snippetsMap, setSnippetsMap] = useState({});
  const [customSnippets, setCustomSnippets] = useState([]);
  const [newSnippetData, setNewSnippetData] = useState({
    SnippetName: '',
    SnippetDescription: '',
    SnippetId: '',
  });
  const defaultSnippetsMap = {
    City: 'crmAddressCity',
    Country: 'crmAddressCountry',
    Town: 'crmAddressTown',
    Zip: 'crmAddressZip',
    CompanyName: 'crmCompany',
    Email: 'crmContactEmail',
    FirstName: 'crmContactFirst',
    LastName: 'crmContactLast',
    MiddleName: 'crmContactMiddle',
    PhoneNo: 'crmContactPhone',
    Website: 'crmContactWebsite',
    FullName: 'FullName',
  };
  const initialSnippetData = {
    SnippetName: '',
    SnippetDescription: '',
  };

  const EditSnippetModalOpen = args => {
    let snip_data = {
      SnippetName: args.SnippetName,
      SnippetDescription: args.SnippetDescription,
      snippetId: args.Id,
    };
    setOpenEditSnippet(true);
    setNewSnippetData(snip_data);
  };
  const onTextAreaFocus = e => {
    setLastTestAreaFocus(e);
  };
  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('CRMTeams/GetCRMTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data.length > 0) {
            var team = data.map(c => ({ value: c.Id, label: c.crmTeamName, IsAdmin: c.IsAdmin }));
            if (team) {
              setTeams(team);
              let currTeam = team[0];

              setSelectedTeam(currTeam);
              console.log(currTeam);
            }
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const onSnippetSelected = args => {
    if (lastTestAreaFocus) {
      switch (lastTestAreaFocus) {
        case 'TemplateSubject':
          let startPos = currMailSubRef.current.selectionStart;
          let endPos = currMailSubRef.current.selectionEnd;
          let currVal = currMailSubRef.current.value;

          let newVal = currVal.substring(0, startPos) + '[' + args + ']' + currVal.substring(endPos, currVal.length);
          let updatedValue = { TemplateSubject: newVal };
          setCustomTemplateData(customTemplateData => ({
            ...customTemplateData,
            ...updatedValue,
          }));
          // addSelectedSnippet(currMailSubRef)
          break;
        case 'TemplateBody':
          let range = currMaibodyRef.current.getEditor().getSelection();
          let position = range ? range.index : 0;
          let textInsert = '[' + args + ']';
          currMaibodyRef.current.getEditor().insertText(position, textInsert);

          // addSelectedSnippet(currMaibodyRef)
          break;
      }
    }
  };

  const saveSnippet = () => {
    if (openEditSnippet) saveEditedSnippet(newSnippetData);
    else saveNewSnippet();
  };

  const addCRMNotes = args => {
    const bodyParameters = {
      ContactId: rowData.crmId,
      AccountId: rowData.UserId,
      Note: `TO : ${emailsTo}, CC : ${emailsCC}`,
      NoteDate: new Date(),
      crmTeamId: rowData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMNotes/AddCRMNote', JSON.stringify(bodyParameters), {
          headers: authHeader(),
        })
        .then(({ data }) => {
          console.log(data, 'success');
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const saveEditedSnippet = args => {
    let newSnippet = {
      Id: parseInt(args.snippetId),
      CrmTeamId: rowData.CrmTeamId,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/EditSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenEditSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const saveNewSnippet = () => {
    if (newSnippetData.SnippetName == '' || newSnippetData.SnippetDescription == '') return;
    let newSnippet = {
      CrmTeamId: selectedTeam.value,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/AddSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenAddSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const getSnippet = () => {
    let url = 'CRMSnippets/GetSnippets/' + selectedTeam.value;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setCustomSnippets(data);
          let customSnippetData = data.reduce((a, v) => ({ ...a, [v.SnippetName]: v.SnippetDescription }), {});
          setSnippetsMap({ ...rowData, ...customSnippetData });

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  // snippests end

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  // dial comp test end
  const app = useAppContext();
  const data = {
    crmCompany: undefined,
    crmContactFirst: undefined,
    crmContactEmail: undefined,
    crmAddressCity: undefined,
    crmContactPhone: undefined,
    crmAddressCountry: undefined,
    crmContactMiddle: undefined,
    crmContactLast: undefined,
    mailSubject: undefined,
    mailContent: undefined,
    textMailContent: undefined,
  };

  const toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      'StrikeThrough',
      'FontName',
      'FontSize',
      'FontColor',
      'BackgroundColor',
      'LowerCase',
      'UpperCase',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      'Outdent',
      'Indent',
      '|',
      'CreateLink',
      'Image',
      '|',
      'ClearFormat',
      'Print',
      'SourceCode',
      'FullScreen',
      '|',
      'Undo',
      'Redo',
    ],
  };
  const quickToolbarSettings = {
    image: [
      'Replace',
      'Align',
      'Caption',
      'Remove',
      'InsertLink',
      'OpenImageLink',
      '-',
      'EditImageLink',
      'RemoveImageLink',
      'Display',
      'AltText',
      'Dimension',
    ],
  };
  /* text editor  */
  const ToolBarContainer = () => {
    return (
      <div id="standalone-container">
        <div id="toolbar-container">
          <span class="ql-formats">
            <select class="ql-font">
              <option selected>Sans Serif</option>
              <option value="inconsolata">Inconsolata</option>
              <option value="roboto">Roboto</option>
              <option value="mirza">Mirza</option>
              <option value="arial">Arial</option>
            </select>
            <select class="ql-size"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>
          <span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-blockquote"></button>
            <button class="ql-code-block"></button>
            <button class="ql-link"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-header" value="1"></button>
            <button class="ql-header" value="2"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-direction" value="rtl"></button>
            <select class="ql-align"></select>
          </span>
          <span class="ql-formats">
            <button class="ql-script" value="sub"></button>
            <button class="ql-script" value="super"></button>
          </span>
          <span class="ql-formats">
            <button class="ql-clean"></button>
          </span>
        </div>
      </div>
    );
  };

  const textEditorModules = {
    toolbar: [
      // [{ font: [] }],
      [{ header: '1' }, { header: '2' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'image' /* "video"*/],
      // ["clean"],
      // ["fullscreen"]
    ],
  };

  const templateInitialData = {
    TemplateName: '',
    TemplateSubject: '',
    TemplateBody: '',
  };
  const [customTemplateData, setCustomTemplateData] = useState(templateInitialData);

  function clearCurrentMailContent() {
    setMailSub('');
    setMailContent('');
    setEmailsTo([]);
    setEmailsCC([]);
  }

  function sendMail(mailData) {
    // console.log(mailContent);

    try {
      doCreate();

      // setRedirect(true);
    } catch (err) {
      app.displayError('Error creating event', JSON.stringify(err));
    }
  }

  // utility function start
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  function getFormatedMailContent(s) {
    let extContent = extractContent(s);
    return extContent.length > 110 ? `${extContent.substring(0, 110)}...` : extContent;
  }
  function onMailSentSuccess() {
    setShowAlert(true);
  }
  // utility function end
  const doCreate = async () => {
    let ccEmails = [];
    let ccEmailsStr = '';
    let emailToStr = ' ';
    if (emailsTo.length === 0) {
      alert('Please enter a recipient  email id !');
      return;
    }
    dispatch({ type: FETCH_START });
    emailsCC.forEach(email => {
      ccEmails.push({ emailAddress: { address: email } });
      ccEmailsStr += `${email}   `;
    });
    let toEmails = [];
    emailsTo.forEach(email => {
      toEmails.push({ emailAddress: { address: email } });
      emailToStr += `${email},  `;
    });
    const newEmailObj = {
      message: {
        subject: mailSub,
        body: {
          contentType: 'HTML',
          content: `<html
          lang="en"
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta name="x-apple-disable-message-reformatting" />
            <title></title>
             <style>
                p{
                  margin-block-start:unset;
                  margin-block-end:unset;
                  margin-block:0 !important;
                }
             </style>
            <!--[if mso]>
              <style>
                table {
                  border-collapse: collapse;
                  border-spacing: 0;
                  border: none;
                  margin: 0;
                }
                div,
                td {
                  padding: 0;
                }
                div {
                  margin: 0 !important;
                }
                p{
                  margin-block:0 !important;
                  margin-block-start:unset;
                  margin-block-end:unset;
                }
              </style>
              <noscript>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              </noscript>
            <![endif]-->
            <style>
              table,
              td,
              div,
              h1,
              p {
                font-family: Arial, sans-serif;
               
              }
              p,h1,h2,h3,h4,pre,div{
                margin:0 !important;
                line-height:'normal' !important;

               
              }
              @media screen and (max-width: 530px) {
                .unsub {
                  display: block;
                  padding: 8px;
                  margin-top: 14px;
                  border-radius: 6px;
                  background-color: #555555;
                  text-decoration: none !important;
                  font-weight: bold;
                }
                .col-lge {
                  max-width: 100% !important;
                }
              }
              @media screen and (min-width: 531px) {
                .col-sml {
                  max-width: 27% !important;
                }
                .col-lge {
                  max-width: 73% !important;
                }
              }
            </style>
          </head>
          <body>
          <div >
          ${mailContent}
          

          </div>
          </body>
          </html>
          `,
        },
        toRecipients: toEmails,
        ccRecipients: ccEmails,
      },
    };

    try {
      await sendEmail(app.authProvider, newEmailObj);

      onMailSentSuccess();

      clearCurrentMailContent();

      // setRedirect(true);
    } catch (err) {
      app.displayError('Error creating event', JSON.stringify(err));
    }
  };

  const [Templatedata, setTempDataArr] = useState([]);

  function openTemplates(e) {
    e.preventDefault();
    GetTemplateData();
    setOpenTemplate(true);
  }
  function GetTemplateData() {
    let url = 'CRM/GetEmailTemplates/' + selectedTeam.value;
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setTempDataArr(data);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    if (selectedTeam && selectedTeam.value) {
      getSnippet();
      GetTemplateData();
    }
  }, [selectedTeam]);

  function closeTemplate() {
    setOpenTemplate(false);
  }

  String.prototype.interpolate = function(args) {
    try {
      const names = Object.keys(args);
      const vals = Object.values(args);

      let propData = new Function(...names, `return \`${extractContent(this)}\`;`)(...vals);
      return propData;
    } catch (e) {
      return this;
    }
  };

  function interpolateStr(string, obj) {
    return string.replace(/(?<!\[)\[([^[\]]*)](?!\])/g, (m, p1) => obj[p1] ?? `[${p1}]`);
  }

  function selectTemplate(item) {
    let tempSubject = item.TemplateSubject;
    let tempContent = item.TemplateBody;

    setMailSub(tempSubject);
    setMailContent(tempContent);
    if (!bulkMail) setTextMailContent(extractContent(interpolateStr(item.TemplateBody, snippetsMap)), {});
    closeTemplate();
  }

  function addToTemplate() {
    if (
      customTemplateData.TemplateBody == '' &&
      customTemplateData.TemplateName == '' &&
      customTemplateData.TemplateSubject == ''
    ) {
      return;
    }

    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/AddEmailTemplate', customTemplateData, { headers: authHeader() })
      .then(() => {
        GetTemplateData();
        setCustomTemplateData(templateInitialData);

        setOpenCustomTemplate(false);

        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const deleteTemplate = index => {
    let obj = {
      TemplateId: index,
      crmTeamId: rowData.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/DeleteEmailTemplate', obj, { headers: authHeader() })
      .then(index => {
        GetTemplateData();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const editTemplate = (item, itemFnc) => {
    setOpenEditTemplate(true);
    setOpenCustomTemplate(true);
    // setOpenTemplate(false);
    setCustomTemplateData(item);
    itemFnc();
  };
  const saveEditTemplate = () => {
    let tempObj = [
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateSubject',
        NewValue: customTemplateData.TemplateSubject,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateBody',
        NewValue: customTemplateData.TemplateBody,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
      {
        TableName: 'CRM_EmailTemplates',
        ColumnName: 'TemplateName',
        NewValue: customTemplateData.TemplateName,
        PrimaryKeyValue: customTemplateData.Id,
        crmTeamId: rowData.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/EditMultipleEmailTemplates', tempObj, { headers: authHeader() })
      .then(() => {
        GetTemplateData();
        setOpenCustomTemplate(false);
        setOpenEditTemplate(false);
        setCustomTemplateData(templateInitialData);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    // dispatch({ type: FETCH_START });
  };

  const handelChange = (dataName, datavalue) => {
    if (openAddSnippet || openEditSnippet) {
      // console.log(dataName, datavalue);
      let updatedValue = {};
      if (dataName == 'SnippetName') {
        updatedValue = { SnippetName: datavalue };
      } else {
        updatedValue = { SnippetDescription: datavalue };
      }
      setNewSnippetData(newSnippetData => ({
        ...newSnippetData,
        ...updatedValue,
      }));
      return;
    }

    if (openCustomTemplate) {
      let updatedValue = {};
      if (dataName === 'TemplateBody') {
        updatedValue = { TemplateBody: datavalue };
      } else {
        updatedValue = { [dataName]: datavalue };
      }

      setCustomTemplateData(customTemplateData => ({
        ...customTemplateData,
        ...updatedValue,
      }));
    } else {
      if (dataName === 'MailSubject') {
        setMailSub(datavalue);
      } else if (dataName === 'MailContent') {
        // console.log(datavalue);
        setMailContent(datavalue);
      }
    }
  };
  const handelClose = () => {
    if (openCustomTemplate) {
      if (openEditTemplate) setOpenEditTemplate(false);

      setOpenCustomTemplate(false);
      setCustomTemplateData(templateInitialData);
    } else {
      closeMail();
    }
  };

  // bulk email
  const sendBulkEmail = async () => {
    let bulkEmailCount = 0;
    let customSnippetData = customSnippets.reduce((a, v) => ({ ...a, [v.SnippetName]: v.SnippetDescription }), {});
    bulkData.forEach(item => {
      try {
        bulkEmailCount++;
        doCreateBulkEmail({ ...item, ...customSnippetData }, bulkEmailCount);
        // setRedirect(true);
      } catch (err) {
        app.displayError('Error creating event', JSON.stringify(err));
      }
    });
    getContactHandler();

    closeMail();

    // clearCurrentMailContent();
  };

  const doCreateBulkEmail = async (args, emailCount) => {
    let ccEmails = [];
    let ccEmailsStr = '';
    let emailToStr = ' ';
    let currSubject = interpolateStr(mailSub, args);
    let currContent = interpolateStr(mailContent, args);
    emailsCC.forEach(email => {
      ccEmails.push({ emailAddress: { address: email } });
      ccEmailsStr += `${email}   `;
    });
    let toEmails = [];

    toEmails.push({ emailAddress: { address: args.crmContactEmail } });
    emailToStr += `${args.crmContactEmail},  `;

    const Sendmemail = {
      message: {
        subject: currSubject,
        body: {
          contentType: 'HTML',
          content: `<html
          lang="en"
          xmlns="http://www.w3.org/1999/xhtml"
          xmlns:o="urn:schemas-microsoft-com:office:office"
        >
          <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <meta name="x-apple-disable-message-reformatting" />
            <title></title>
            <!--[if mso]>
              <style>
                table {
                  border-collapse: collapse;
                  border-spacing: 0;
                  border: none;
                  margin: 0;
                }
                div,
                td {
                  padding: 0;
                }
                div {
                  margin: 0 !important;
                }
              </style>
              <noscript>
                <xml>
                  <o:OfficeDocumentSettings>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                  </o:OfficeDocumentSettings>
                </xml>
              </noscript>
            <![endif]-->
            <style>
              table,
              td,
              div,
              h1,
              p {
                font-family: Arial, sans-serif;
              }
              @media screen and (max-width: 530px) {
                .unsub {
                  display: block;
                  padding: 8px;
                  margin-top: 14px;
                  border-radius: 6px;
                  background-color: #555555;
                  text-decoration: none !important;
                  font-weight: bold;
                }
                .col-lge {
                  max-width: 100% !important;
                }
              }
              @media screen and (min-width: 531px) {
                .col-sml {
                  max-width: 27% !important;
                }
                .col-lge {
                  max-width: 73% !important;
                }
              }
            </style>
          </head>
          <body>
          <div>
          ${currContent}

          </div>
          </body>
          </html>
          `,
        },
        toRecipients: toEmails,
        ccRecipients: ccEmails,
      },
    };
    let emailCommunicationData = {
      CrmContactId: args.crmId,
      FromEmail: app.user?.email,
      ToEmail: emailToStr,
      CcEmail: ccEmailsStr,
      BccEmail: '',
      EmailSubject: currSubject,
      EmailBody: extractContent(currContent),
      Isdelivered: 0,
      IsOpened: 0,
      IsReplied: 0,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRM/AddEmailCommunication', emailCommunicationData, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          // getEmailCommunication(rowData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }

    try {
      await sendEmail(app.authProvider, Sendmemail);
      // console.log(emailCount + "  "+bulkData.length)
      if (emailCount == bulkData.length) {
        onMailSentSuccess();
        // setBulkMail(false)

        clearCurrentMailContent();
      }

      // setRedirect(true);
    } catch (err) {
      if (emailCount == bulkData.length) {
        // setBulkMail(false)
      }
      app.displayError('Error creating event', JSON.stringify(err));
    }
  };

  // add template from send Email
  const openTemplateWindow = () => {
    let currTemplatedata = {
      TemplateName: '',
      TemplateSubject: mailSub,
      TemplateBody: mailContent,
    };
    setCustomTemplateData(currTemplatedata);
    setOpenCustomTemplate(true);
  };
  const onTeamChangeHandler = args => {
    setSelectedTeam(args);
  };
  return (
    <div className="app-wrapper contact-overview">
      <Tostify showMessage={showAlert} setShowMessage={setShowAlert} />
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', gap: '20px' }}>
        <div className="content-horizontal__center">
          <h4>Send Email </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={Teams}
              value={Teams.find(c => c.label === selectedTeam.label)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        <div className={useHeaderStyles().root} style={{ flex: 1, justifyContent: 'end' }}>
          <div
            style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBlock: '5px' }}
            className={`flex60 ${app.user ? 'connect' : 'warning'}`}>
            {app.user ? `From ${app.user.email}` : 'No Mailbox Connected'}
          </div>
          <div>
            <NavLink to="/microsoft/authentication" style={{ display: app.user ? 'none' : 'block', marginLeft: '20px' }}>
              <Button
                variant="contained"
                style={{ background: 'rgb(63, 81, 181)', height: '32px', fontFamily: 'normal' }}

                // disabled={details.length === 0 ? false : true}
              >
                Connect
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
      <form
        name="myForm"
        onSubmit={e => console.log(e)}
        style={{
          paddingBottom: '4px',
          paddingTop: '2px',
          paddingLeft: '4px',
          paddingRight: '4px',
        }}>
        <ModalBody style={{ paddingTop: 0 }}>
          <div className="modal-box-content">
            <div className="row no-gutters">
              <React.Fragment>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        {...bindTrigger(popupState)}
                        style={{
                          paddingBlock: '0.5em',
                          fontFamily: 'Normal',
                          fontSize: '0.75rem',
                          marginBottom: '15px',

                          backgroundColor: '#3f51b5',
                        }}>
                        Choose Template
                      </Button>
                      <Menu {...bindMenu(popupState)} style={{ height: '400px', marginTop: '2px' }} className="info_sidebar">
                        <div className="d-flex flex-column-reverse">
                          {Templatedata.map((item, index) => {
                            return (
                              <MenuItem
                                // onClick={popupState.close}
                                key={index}
                                style={{
                                  width: '450px',
                                  margin: 0,
                                  padding: 0,
                                  border: '1px solid rgba(0,0,0,0.250)',
                                  borderBottom: '1px solid rgba(0,0,0,.250)',
                                  borderCollapse: 'collapse',
                                  borderRadius: '5px',
                                  paddingTop: '0.25em',
                                  paddingBottom: '0.25em',
                                  marginInline: '0.1em',
                                  fontFamily: 'Normal',

                                  flexDirection: 'column',
                                }}>
                                <div
                                  className="w-100"
                                  style={{
                                    fontSize: '0.95rem',
                                    paddingBlock: '0.5em',
                                  }}>
                                  <div
                                    onClick={() => {
                                      selectTemplate(item);
                                      popupState.close();
                                    }}>
                                    <div className="d-flex-column w-100  m-0  p-0 ">
                                      <div className="text-capitalize emailTempItem ">
                                        <small className="font-weight-normal">Title : </small>
                                        <br />
                                        <b>{item.TemplateName}</b>
                                      </div>
                                    </div>
                                    <div className="d-flex-column w-100  m-0 p-0 ">
                                      <div className=" emailTempItem" style={{ minHeight: '40px' }}>
                                        <small className="font-weight-normal">Subject' : </small>
                                        <br />

                                        {item.TemplateSubject && item.TemplateSubject.length > 60
                                          ? `${item.TemplateSubject.substring(0, 60)}...`
                                          : item.TemplateSubject}
                                      </div>
                                    </div>
                                    <div className="d-flex-column w-100  m-0  p-0  ">
                                      <div
                                        className=" w-100 text-wrap text-break emailTempItem"
                                        style={{
                                          minHeight: '40px',
                                          borderBottom: 'none',
                                        }}>
                                        <small className="font-weight-normal">Content : </small>
                                        <br />
                                        {getFormatedMailContent(item.TemplateBody)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </MenuItem>
                            );
                          })}
                        </div>
                      </Menu>
                      <Button
                        // type="submit"
                        disabled={app.user ? false : true}
                        variant="contained"
                        size="small"
                        style={{
                          background: app.user ? 'rgb(63, 81, 181)' : 'grey',
                          fontFamily: 'normal',
                          paddingBlock: '0.5em',
                          fontSize: '0.75rem',
                          marginBottom: '15px',
                          marginLeft: '25px',
                        }}
                        onClick={handleSubmit(e => sendMail(e))}
                        // disabled={details.length === 0 ? false : true}
                      >
                        Send Email
                      </Button>
                    </div>
                  )}
                </PopupState>
                <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                  <TextField
                    label="To"
                    variant="outlined"
                    fullWidth
                    placeholder="Add multiple email with comma separated value."
                    value={emailsTo.join(', ')}
                    onChange={e => {
                      const emailList = e.target.value.split(', ');
                      setEmailsTo(emailList);
                    }}
                    onBlur={e => {
                      const filteredEmails = emailsTo.filter(email => email && isEmail(email));
                      setEmailsTo(filteredEmails);
                    }}
                  />
                  <br />
                  <TextField
                    label="CC"
                    variant="outlined"
                    fullWidth
                    placeholder="Add multiple email with comma separated value."
                    value={emailsCC.join(', ')}
                    onChange={e => {
                      const emailList = e.target.value.split(', ');
                      setEmailsCC(emailList);
                    }}
                    onBlur={e => {
                      const filteredEmails = emailsCC.filter(email => email && isEmail(email));
                      setEmailsCC(filteredEmails);
                    }}
                  />
                </div>
              </React.Fragment>

              <br />
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                {/* <InputLabel htmlFor="age-native-helper">
                    {openCustomTemplate ? 'Template Subject' : <IntlMessages id="appModule.invoice_subject_email" />}
                  </InputLabel> */}
                <Controller
                  name="MailSubject"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      label={openCustomTemplate ? 'Template Subject' : <IntlMessages id="appModule.invoice_subject_email" />}
                      inputRef={currMailSubRef}
                      value={openCustomTemplate ? customTemplateData.TemplateSubject : mailSub}
                      name={openCustomTemplate ? 'TemplateSubject' : 'MailSubject'}
                      onChange={e => {
                        handelChange(field.name, e.target.value);
                        field.onChange(e.target.value);
                      }}
                      onFocus={e => onTextAreaFocus('TemplateSubject')}
                      margin="none"
                      style={{ padding: '10px 0px' }}
                    />
                  )}
                />
              </div>
              <br />
              <div
                className="col-lg-12 d-flex flex-column invoice_label mb-4"
                id="toolbar-container"
                style={{ paddingBottom: '20px' }}>
                <div className="d-flex justify-content-between align-items-center my-2">
                  <div>
                    {' '}
                    <InputLabel htmlFor="age-native-helper">
                      {openCustomTemplate ? 'Template Content' : 'Content'}
                    </InputLabel>
                  </div>
                  <div></div>
                </div>

                <Controller
                  name={openCustomTemplate ? 'TemplateBody' : 'MailContent'}
                  control={control}
                  defaultValue=""
                  // defaultValue={InvoiceNumber ? 'Invoice No: ' + InvoiceNumber : ''}
                  render={({ field }) => (
                    <ReactQuill
                      ref={currMaibodyRef}
                      name={openCustomTemplate ? 'TemplateBody' : 'MailContent'}
                      value={openCustomTemplate ? customTemplateData.TemplateBody : mailContent}
                      style={{ height: '500px', textTransform: 'none' }}
                      modules={textEditorModules}
                      onChange={e => {
                        //  field.onChange(e === null ? '' : e);
                        handelChange(field.name, e);
                      }}
                      onFocus={e => onTextAreaFocus('TemplateBody')}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </form>
    </div>
  );
};

export default SendEmail;
