/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useRef, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
import CreateTeam from './CreateTeam';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  //  INIT_URL,
  //  SIGNOUT_USER_SUCCESS,
  //  USER_DATA,
  //  USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TeamDetailsDrawer from './TeamDetailsDrawer';

import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { styled, alpha } from '@mui/material/styles';
import CustomSkeletonLoader from '../../../../@jumbo/components/PageComponents/CustomSkeletonLoader';
import LogoutIcon from '@mui/icons-material/Logout';

//confirmation modal
import ConfirmationModal from 'routes/Pages/Planning/TaskmanagementPage/ConfirmationModal';
import AvatarLoader from 'routes/Pages/components/loaders/AvatarLoader';
import { getEmailBody } from 'routes/Pages/components/emailBody/emailBody';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'AcctTeamName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    minWidth: 80,
  },
  {
    id: 'AcctTeamDesc',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    minWidth: 150,
  },
  {
    id: 'AcctTeam',
    numeric: false,
    disablePadding: false,
    label: 'Team',
    minWidth: 150,
  },
  {
    id: 'admins',
    numeric: false,
    disablePadding: false,
    label: 'Admins',
    minWidth: 150,
  },

  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: '',
    minWidth: 100,
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    // onSelectAllClick,
    // numSelected,
    // rowCount,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const AccTeams = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [tmMembers, setTmMembers] = useState([]);

  /* Modal open/close */
  const [details, setDetails] = useState([]);
  const [open, setOpen] = useState(false);

  // search
  const [searchKey, setSearchKey] = useState('');
  const inputRef = useRef(null);

  //table config
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);

  /* START: Team Details */
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [teamDetails, setTeamDetails] = React.useState(false);
  const [teamAdminFilter, setTeamAdminFilter] = useState(null);
  const toggleDrawer = () => event => {
    setOpenDrawer(!openDrawer);
  };

  /* END: Team Details */

  //leave team
  //confirmation modal
  //default params
  const defaultConfirmationModalObj = {
    isModalOpen: false,
    handleSave: null,
    handleClose: null,
    modalTitle: '',
    modalMainText: '',
    hideCloseBtn: false,
    cancelBtnTxt: null,
    saveBtnText: null,
    teamId: null,
  };

  const [confirmationData, setConfirmationData] = useState({ ...defaultConfirmationModalObj });
  const [userDetails, setUserDetails] = useState();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = data.map(n => n.ClientName);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const teamActionHandler = (action, data) => {
    setTeamDetails(data);
    if (action === 'detail') {
      setOpenDrawer(!openDrawer);
    }
    if (action === 'delete') {
      deleteTeam(data.Id);
    }
    if (action === 'leaveTeam') {
      openteamLeaveModal(data.Id, data.IsAdmin);
      const filterAdmin = tmMembers.filter(element => data.Id === element.TeamId);
      setTeamAdminFilter(filterAdmin);
    }
    setAnchorEl(null);
  };

  /** Delete company **/
  function deleteTeam(id) {
    let url = 'Acct/DeleteTeam';
    let obj = {
      TeamId: id,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, obj, { headers: authHeader() })
      .then(data => {
        getTeams();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  const handleClick = (event, data) => {
    data.edit = true;

    const selectedIndex = selected.indexOf(data.name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);

    /* Open Team details Drawer */
    // setOpenDrawer(!openDrawer);
    // setTeamDetails(data);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('Acct/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setData(data);
            setCustomData(data);
            setSkeletonLoaderState(false);
            updatedData();
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  useEffect(() => {
    setDense(false);
    getUserProfileDetails();
    getTeams();
  }, []);

  const updatedData = () => {
    if (customData.length > 0) {
      // console.log(customData)
      const getTeamMembersByTeam = async obj => {
        const responses = [];
        for (let i = 0; i < customData.length; i++) {
          let url = 'Acct/GetTeamMembers/' + customData[i].Id;
          responses.push(await customAxios.get(url, { headers: authHeader() }));
        }
        if (responses) {
          // console.log(responses);
          const teamMembers = [];
          for (let i = 0; i < responses.length; i++) {
            responses[i].data.forEach(element => {
              teamMembers.push(element);
            });
          }
          // console.log(teamMembers);
          setTmMembers(teamMembers);
        }
      };
      getTeamMembersByTeam();
    }

    return () => {
      setTmMembers([]);
    };
  };

  useEffect(() => {
    updatedData();
    getTeamMembers();
    getTeamAdmin();
  }, [customData]);

  const onClientsClose = () => {
    setOpen(false);
    getTeams();
  };

  //modal open/close
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setDetails([]);
  };

  /* Action Menu eg. edit/delete */
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleActionMenuClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleActionMenuClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const getCRMTeamHandler = () => {
    getTeams();
  };

  /* Get Team members by Id */
  const getTeamMembers = id => {
    const tmMem = tmMembers.filter(tm => +tm.TeamId === +id);
    return tmMem.length === 0 ? (
      <AvatarLoader />
    ) : (
      <AvatarGroup max={5} style={{ float: 'left' }}>
        {tmMem &&
          tmMem.map((tm, index) => {
            return <Avatar key={index}>{tm.Email.slice(0, 2)}</Avatar>;
          })}
      </AvatarGroup>
    );
  };

  const getTeamAdmin = (team, id) => {
    const tmMem = tmMembers.filter(tm => +tm.IsAdmin === 1 && +tm.TeamId === +id);

    return tmMem.length === 0 ? (
      <AvatarLoader />
    ) : (
      <AvatarGroup max={5} style={{ float: 'left' }}>
        {tmMem &&
          tmMem.map((tm, index) => {
            return <Avatar key={index}>{tm.Email.slice(0, 2)}</Avatar>;
          })}
      </AvatarGroup>
    );
  };

  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredDraftRows = [];
    let filteredSentRows = [];
    filteredDraftRows = getArrayFilterData(data, searchkey);
    // filteredSentRows = getArrayFilterData(completedData, searchkey);

    if (searchkey.length <= 0) {
      setCustomData(data);
      // setSearchSentData(completedData);
    } else {
      setCustomData(filteredDraftRows);
      // setSearchSentData(filteredSentRows);
    }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.AcctTeamDesc.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.AcctTeamName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setCustomData(data);
    // setSearchDraftData(data);
    // setSearchSentData(customData);
  };
  const getUserProfileDetails = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('Users/GetUserProfile', { headers: authHeader() })
        .then(({ data }) => {
          let correctedData = data;

          setUserDetails(correctedData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  function openteamLeaveModal(id, isAdmin) {
    let confirmationObj = { ...confirmationData };
    confirmationObj.handleClose = closeConfirmationModal;
    confirmationObj.modalTitle = 'Leave Team';
    if (isAdmin) {
      confirmationObj.hideCloseBtn = true;

      confirmationObj.modalMainText = ' You can not leave this team, as you are an admin';
      confirmationObj.saveBtnText = 'Ok';
      confirmationObj.handleSave = closeConfirmationModal;
    } else {
      confirmationObj.hideCloseBtn = false;
      confirmationObj.modalMainText = ' Do you want to leave this team?';
      confirmationObj.saveBtnText = 'Yes';
      confirmationObj.cancelBtnTxt = 'No';
      confirmationObj.handleSave = () => leaveTeam(id);
    }

    confirmationObj.teamId = id;

    confirmationObj.isModalOpen = true;
    setConfirmationData({ ...confirmationObj });
  }

  const sendActivityEmail = async () => {
    let emailList = teamAdminFilter.map(element => {
      return element.Email;
    });
    emailList.push(userDetails.EmailAddress);

    const bodyParameters = {
      EmailSubject: 'A member of the team leaves',
      EmailHtmlBody: getEmailBody(emailList, 'leave', 'Accounting'),
      ToList: emailList,
    };

    try {
      customAxios
        .post(`General/SendEmail`, JSON.stringify(bodyParameters), { headers: authHeader() })
        .then(({ data }) => {})
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Failed to accept' });
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  function leaveTeam(id) {
    let obj = {
      AcctTeamId: id,
      AcctMemberUserId: userDetails.UserId,
    };
    // console.log(obj,"leve tem");
    // return;
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .post('Acct/DeleteTeamMember', obj, { headers: authHeader() })
        .then(({ data }) => {
          dispatch({ type: FETCH_SUCCESS });
          sendActivityEmail();
          closeConfirmationModal();
          getTeams();
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  function closeConfirmationModal() {
    setConfirmationData(defaultConfirmationModalObj);
  }
  return (
    <div className="app-wrapper">
      <ContainerHeader match={props.match} title={<IntlMessages id="sidebar.main.accounting.teams" />} />
      <div className="justify-content-right pb-3 text-right d-flex justify-content-end">
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onChange={e => searchCRMData(e)}
            value={searchKey}
            inputRef={inputRef}
          />
          {searchKey && (
            <CloseIcon
              onClick={clearSearchData}
              style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
            />
          )}
        </Search>
        <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={toggle}>
          Add Team
        </Button>
      </div>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={customData.length}
              />

              <TableBody>
                {skeletonLoaderState ? (
                  <CustomSkeletonLoader tableCellCount={5} />
                ) : (
                  <>
                    {stableSort(customData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        // const isItemSelected = isSelected(row.CompanyName);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={event => handleClick(event, row)}
                            // role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            // selected={isItemSelected}
                            key={index}>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.AcctTeamName}
                            </TableCell>
                            <TableCell align="left">{row.AcctTeamDesc}</TableCell>
                            <TableCell align="left">{getTeamMembers(row.Id)}</TableCell>
                            <TableCell align="left">{getTeamAdmin(row.IsAdmin, row.Id)}</TableCell>

                            <TableCell align="right">
                              <IconButton onClick={e => handleActionMenuClick(index, e)}>
                                <MoreHorizIcon />
                              </IconButton>
                              <Menu
                                anchorEl={
                                  // Check to see if the anchor is set.
                                  anchorEl && anchorEl[index]
                                }
                                keepMounted
                                open={
                                  // Likewise, check here to see if the anchor is set.
                                  Boolean(anchorEl && anchorEl[index])
                                }
                                onClose={handleMenuClose}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                  },
                                }}>
                                {row.IsAdmin === 1 ? (
                                  <MenuItem onClick={() => teamActionHandler('detail', row)}>
                                    <EditIcon className={classes.actionIcon} /> Edit
                                  </MenuItem>
                                ) : null}
                                {row.IsAdmin === 1 ? (
                                  <MenuItem onClick={() => teamActionHandler('delete', row)}>
                                    <DeleteOutlineIcon className={classes.actionIcon} /> Delete
                                  </MenuItem>
                                ) : null}
                                <MenuItem onClick={() => teamActionHandler('leaveTeam', row)}>
                                  <LogoutIcon className={classes.actionIcon} /> Leave Team
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={customData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* {modal && (
          <CreateEdit modal={modal} details={details} toggle={toggle} onClientsClose={onClientsClose} clients={{ name: '' }} />
        )} */}
      {modal && (
        <CreateTeam modal={modal} details={details} toggle={toggle} onClientsClose={onClientsClose} clients={{ name: '' }} />
      )}
      {openDrawer && (
        <TeamDetailsDrawer
          openDrawer={openDrawer}
          toggleDrawer={toggleDrawer}
          teamDetails={teamDetails}
          getCRMTeamHandler={getCRMTeamHandler}
          getTeams={getTeams}
          userDetails={userDetails}
        />
      )}
      <ConfirmationModal confirmationObj={confirmationData} />
    </div>
  );
};

export default AccTeams;
