import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import authHeader from './auth-header';

// const history = useHistory();
const JWTAuth = {
  onRegister: ({ email, password, confirmPassword, history }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('api/Account/Register', {
          Email: email,
          Password: password,
          ConfirmPassword: confirmPassword,
        })
        .then(({ data }) => {
          localStorage.setItem('userEmailVerify', JSON.stringify(data));
          dispatch(fetchSuccess('Register Succesfully!'));
          // window.location.replace('/signin');
          // history.push('/signin');
          history.push('/confirm-user');
          // if (data.result) {
          //   localStorage.setItem('token', data.token.access_token);
          //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          //   dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          // } else {
          //   dispatch(fetchError(data.error));
          // }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },
  onLogin1: ({ email, password }) => {
    return async dispatch => {
      try {
        dispatch(fetchStart());
        await axios
          .post(
            'https://api.focus247.online/Token',
            new URLSearchParams({
              grant_type: 'password',
              username: email,
              password: password,
            }),
          )
          .then(({ data }) => {
            if (data.access_token) {
              localStorage.setItem('token', data.access_token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.access_token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogin: ({ email, password, isRemember }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(
            'Token',
            new URLSearchParams({
              grant_type: 'password',
              username: email,
              password: password,
              rememberMe: isRemember,
            }),
          )
          .then(({ data }) => {
            if (data.access_token) {
              localStorage.setItem('token', JSON.stringify(data));
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.access_token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError('The user name or password is incorrect.'));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return async dispatch => {
      const token = JSON.parse(localStorage.getItem('token'));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      dispatch(fetchStart());
      await axios
        .post('api/Account/Logout')
        .then(({ data }) => {
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          localStorage.removeItem('sockettoken');
          // localStorage.clear();
          dispatch(setAuthUser(null));
          // if (data) {
          //   dispatch(fetchSuccess());
          //   localStorage.removeItem('token');
          //   localStorage.removeItem('user');
          //   dispatch(setAuthUser(null));
          // } else {
          //   dispatch(fetchError(data.error));
          // }
        })
        .catch(function(error) {
          dispatch(fetchError('Session expired, logging out'));
        });
    };
  },

  getAuthUserPost: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .post('Account/UserInfo')
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },
  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('api/Account/UserInfo')
        .then(({ data }) => {
          if (data) {
            localStorage.setItem('user', JSON.stringify(data));
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(fetchError('Session Expired'));
          dispatch(updateLoadUser(true));
        });
    };
  },

  getAuthUserWithLocalStorage: (loaded = false, token) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      const data = JSON.parse(localStorage.getItem('user'));
      dispatch(fetchSuccess());
      dispatch(setAuthUser(data));
    };
  },

  removeAuthUser: () => {
    return dispatch => {
      dispatch(fetchStart());
      debugger;
      dispatch(setAuthUser(null));
      dispatch(fetchSuccess());
    };
  },

  onForgotPassword: ({ email, history }) => {
    return dispatch => {
      dispatch(fetchStart());

      // setTimeout(() => {
      //   dispatch(setForgetPassMailSent(true));
      //   dispatch(fetchSuccess());
      // }, 300);

      axios
        .post('api/Account/ForgotPassword', {
          Email: email,
        })
        .then(({ data }) => {
          dispatch(fetchSuccess('Please Check your Inbox!'));
          history.push('/signin');
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          dispatch(updateLoadUser(true));
        });
    };
  },

  resendConfirmEmail: ({ email, token }) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(fetchStart());
      axios
        .post('api/Account/ResendConfirmEmail', {
          Email: email,
          Token: token,
        })
        .then(({ data }) => {
          dispatch(fetchSuccess('Please Check your Inbox!'));
          // history.push('/confirm-user');
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          dispatch(updateLoadUser(true));
        });
    };
  },

  onUserEmailVerifying: ({ email, history, token }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('api/Account/ConfirmUserEmail', {
          Email: email,
          Token: token,
        })
        .then(({ data }) => {
          dispatch(fetchSuccess('Your email verify sucesfully'));
          history.push('/signin');
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          dispatch(updateLoadUser(true));
        });
    };
  },

  onResetPassword: ({ email, password, token, history }) => {
    // const tokenUpdated = new URL(window.location.href).search
    //   .split('?')
    //   .filter(function(el) {
    //     if (el.match('token') !== null) return true;
    //   })[0]
    //   .split('token=')[1];
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('api/Account/ResetPassword', {
          Email: email,
          Token: token,
          NewPassword: password,
        })
        .then(({ data }) => {
          dispatch(fetchSuccess('Password Changes successfully !'));
          history.push('/signin');
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
          dispatch(updateLoadUser(true));
        });
    };
  },
  onChangePassword: ({ oldPassword, password, confirmPassword, history }) => {
    return dispatch => {
      const token = JSON.parse(localStorage.getItem('token'));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
      dispatch(fetchStart());
      axios
        .post('api/Account/ChangePassword', {
          OldPassword: oldPassword,
          NewPassword: password,
          ConfirmPassword: confirmPassword,
        })
        .then(() => {
          dispatch(fetchSuccess('Your password changed!'));
          history.push('/dashboard-page');
        })
        .catch(function(error) {
          dispatch(fetchError('Your old password incorrect!'));
          dispatch(updateLoadUser(true));
        });
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
