import React, { createRef, useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getStore } from '../../../../redux/store';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';

/* Modal delete or archive */
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import moment from 'moment';
import { kanbanBoard } from './components/kanbanBoard';
import { setMultiArchive } from 'redux/actions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{ padding: '0 20px' }}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: -4,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
  },
  inputBoxWrapper: {
    flex: 1,
    zIndex: 9999,
  },
  inputBox: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-input': {
      padding: '5px 5px',
    },
  },
  listTitle: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    flex: 1,
    padding: '5px 5px',
  },
  menuHandle: {
    marginLeft: 4,
  },
}));

const ListHeader = ({
  updateTitle,
  laneId,
  canAddLanes,
  onDelete,
  label,
  title,
  titleStyle,
  labelStyle,
  laneDraggable,
  cards,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [cardLength, setCardLength] = React.useState(0);
  const [archivecardLength, setArchiveCardLength] = React.useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const dispatch = getStore().dispatch;
  const [boardData, setBoardData] = useState(kanbanBoard.boards);

  const headerRef = createRef();
  const store = getStore().getState();
  const { tmSelectedTeam } = store.common;

  useEffect(() => {
    if (cards.length > 0) {
      setCardLength(cards.length);
    }
    if (tmSelectedTeam) {
      setSelectedTeam(tmSelectedTeam);
    }
  }, [cards, tmSelectedTeam, cardLength]);

  /* Modal */
  const [openModal, setOpenModal] = React.useState(false);

  const [archiveOpenModal, setArchiveOpenModal] = React.useState(false);

  const dropDownBtnRef = useRef(null);
  const handleModalClickOpen = () => {
    setOpenModal(true);
  };
  const handleModalCancle = () => {
    setOpenModal(false);
  };
  const handleModalClose = () => {
    onDelete();
    setOpenModal(false);
  };

  const openArchivemodal = () => {
    setArchiveOpenModal(true);
    let archiveObj = [];
    cards.map(item => {
      archiveObj = [
        ...archiveObj,
        {
          tmTeamId: item?.tmTeamId,
          TaskId: item?.id,
        },
      ];
    });
    setArchiveCardLength(archiveObj.length);
  };

  const handleArchiveModalClose = () => {
    let archiveObj = [];
    cards.map(item => {
      archiveObj = [
        ...archiveObj,
        {
          tmTeamId: item?.tmTeamId,
          TaskId: item?.id,
          laneId: item?.StatusId,
        },
      ];
    });
    const archiveData = {
      card: cards,
      laneId: archiveObj[0]?.laneId,
    };

    dispatch(setMultiArchive(archiveData));

    // dispatch({ type: FETCH_START });
    // customAxios
    //   .post('/Task/ArchiveMultipleTasks', archiveObj, { headers: authHeader() })
    //   .then(data => {
    //     dispatch({ type: FETCH_SUCCESS });
    //     setBoardData(prevState => {
    //       let newState = { ...prevState };
    //       let cardData = newState.lanes?.filter(cardData => {
    //         return cardData?.id != cards[0]?.laneId;
    //       });
    //       let data = { ...cardData };
    //       return data;
    //     });
    //   })
    //   .catch(function(error) {
    //     dispatch({ type: FETCH_ERROR, payload: error.message });
    //   });
    setArchiveOpenModal(false);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const handleClick = () => {
    setAnchorEl(headerRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOutside = () => {
    dropDownBtnRef.current.classList.remove('btn-currentActive');
  };
  const handleDropDownBtnClick = () => {
    dropDownBtnRef.current.classList.toggle('btn-currentActive');
  };

  let isUserAdmin = selectedTeam && selectedTeam.IsAdmin;
  return (
    <div className={classes.root} ref={headerRef}>
      {edit && isUserAdmin ? (
        <ClickAwayListener onClickAway={() => setEdit(false)}>
          <div className={classes.inputBoxWrapper}>
            <AppTextInput
              className={classes.inputBox}
              placeholder="Enter a title..."
              autoFocus
              variant="outlined"
              fullWidth
              // selectedCard={selectedCard}
              value={title}
              onChange={e => {
                updateTitle(e.target.value);
                //kanbanBoard.boards.lanes.find()
              }}
            />
          </div>
        </ClickAwayListener>
      ) : (
        <Typography
          className={classes.listTitle}
          draggable={laneDraggable}
          variant="h4"
          component="h4"
          onClick={() => setEdit(true)}
          style={{ fontSize: 16 }}>
          {title}
        </Typography>
      )}

      {label && <span style={labelStyle}>{label}</span>}

      {canAddLanes && (
        <div className="tm-dropdown" style={{ position: 'absolute', right: 15, padding: 5, top: 5, cursor: 'pointer ' }}>
          <button onClick={handleDropDownBtnClick} className="tm-dropbtn">
            <i className="gg-more-vertical-alt"></i>
          </button>
          <div ref={dropDownBtnRef} className="tm-dropdown-content">
            <a href="#" className={isUserAdmin ? 'btn-active' : 'btn-disabled'} onClick={() => setEdit(true)}>
              Edit
            </a>
            <a href="#" onClick={handleModalClickOpen} className={isUserAdmin ? 'btn-active' : 'btn-disabled'}>
              Delete
            </a>
            <a href="#" className={isUserAdmin ? 'btn-active' : 'btn-disabled'} onClick={openArchivemodal}>
              Archive
            </a>
          </div>
        </div>
      )}

      <BootstrapDialog onClose={handleModalClose} aria-labelledby="delete-section-modal" open={openModal}>
        <BootstrapDialogTitle id="delete-section-modal" onClose={handleModalClose}>
          Are you sure you want to delete this section? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This Section <strong>"{title ? title : ''}"</strong> included <strong>{cardLength.toString()}</strong> Tasks.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleModalCancle}>
            Cancel
          </Button>
          <Button autoFocus onClick={handleModalClose} style={{ background: 'red', padding: '5px 15px', color: 'white' }}>
            Delete Section
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={() => setArchiveOpenModal(false)}
        aria-labelledby="delete-section-modal"
        open={archiveOpenModal}>
        <BootstrapDialogTitle id="delete-section-modal" onClose={() => setArchiveOpenModal(false)}>
          Are you sure you want to archive this section? &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This Section <strong>"{title ? title : ''}"</strong> included <strong>{archivecardLength.toString()}</strong>{' '}
            Tasks.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setArchiveOpenModal(false)}>
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="success"
            onClick={handleArchiveModalClose}
            style={{ padding: '5px 15px', color: 'white' }}>
            Archive Section
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default connect()(ListHeader);
