import React, { useState } from 'react';
// import { Button, Menu, MenuItem, IconButton, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
// import {
//   Edit,
//   Delete,
//   Share as ShareIcon,
//   Visibility as VisibilityIcon,
//   DateRange as DateRangeIcon,
//   Autorenew as AutorenewIcon,
// } from '@mui/icons-material';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
// import CmtAvatar from '@coremat/CmtAvatar';
import ReportsListView from './ReportsListView';
import ReportsGridView from './ReportsGridView';

const Reports = ({
  reportsData,
  isTeam,
  viewType,
  setViewType,
  allUserDetails,
  getAccessToken,
  loadingTeamReports,
  deleteReport,
  accTeam,
  currentTeamMember,
}) => {
  const [anchorElArray, setAnchorElArray] = useState(new Array(reportsData.length).fill(null));
  const [selectedItemArray, setSelectedItemArray] = useState(new Array(reportsData.length).fill(null));
  const [currentUser, setCurrentUser] = useState(null);
  // const [currentTeamMember, setCurrentTeamMember] = useState(null);
  // const [hoveredIndex, setHoveredIndex] = useState(null);
  const [userData, setUserData] = useState({
    AvatarImage: null,
    CompanyId: null,
    ContactNumber: null,
    EmailAddress: null,
    FirstName: null,
    Id: null,
    LastName: null,
    UserId: null,
  });

  const dispatch = useDispatch();

  // Replace this with your API call to fetch user data
  const getUserProfileData = () => {
    dispatch({ type: FETCH_START });

    customAxios
      .get('Users/GetUserProfile', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setCurrentUser(data);
          let correctedData = data;
          correctedData['AvatarImage'] = `data:image/jpeg;base64,${data.AvatarImage}`;
          setUserData(correctedData);
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };

  const getTeamMembers = () => {
    // dispatch({ type: FETCH_START });
    // try {
    //   customAxios
    //     .get(`EmbeddedAnalytics/GetTeamMembers?EATeamId=${accTeam}`, { headers: authHeader() })
    //     .then(({ data }) => {
    //       const currUser = data.find(element => element.EAUserId === currentUser.UserId);
    //       setCurrentTeamMember(currUser);
    //     })
    //     .catch(error => {
    //       // Handle the error
    //       console.error(error);
    //       dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    //     });
    // } catch (e) {
    //   // Handle any other exceptions
    //   console.error(e);
    //   dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    // }
  };

  const getRefreshDataset = reportId => {
    const bodyParameters = {
      TeamId: accTeam,
      ReportId: reportId,
    };

    dispatch({ type: FETCH_START });

    try {
      customAxios
        .post('EmbeddedAnalytics/RefreshDataset', JSON.stringify(bodyParameters), { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS, payload: 'Refresh Dataset Accepted' });
          console.log(data, 'success data');
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };

  // const checkRefreshStatus = async (teamId, reportId, requestId) => {
  //   try {
  //     const response = await axios.post('your-api-url/EmbeddedAnalytics/GetDatasetRefreshStatus', {
  //       TeamId: teamId,
  //       ReportId: reportId,
  //       RequestId: requestId
  //     });

  //     // Check the status in the response and decide whether to continue polling
  //     if (response.data.Status === 'Completed') {
  //       console.log('Dataset refresh completed successfully.');
  //     } else if (response.data.Status === 'InProgress') {
  //       // Continue polling
  //       setTimeout(() => checkRefreshStatus(teamId, reportId, requestId), 3000); // Poll every 3 seconds
  //     } else {
  //       console.error('Dataset refresh failed.');
  //     }
  //   } catch (error) {
  //     // Handle errors
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (accTeam && currentUser !== null) {
      getTeamMembers();
    }
  }, [accTeam, currentUser]);

  function stringAvatar(name) {
    return name
      ? {
          children: `${name.charAt(0)}${name.charAt(1)}`,
        }
      : null;
  }

  useEffect(() => {
    getUserProfileData();
  }, []);

  const handleClick = (event, item, index) => {
    const newAnchorElArray = [...anchorElArray];
    const newSelectedItemArray = [...selectedItemArray];
    newAnchorElArray[index] = event.currentTarget;
    newSelectedItemArray[index] = item;
    setAnchorElArray(newAnchorElArray);
    setSelectedItemArray(newSelectedItemArray);
  };

  const handleClose = index => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const handleMenuItem = (item, value) => {
    // console.log('Editing item:', selectedItemArray[index]);

    if (value === 'deleteReport') {
      deleteReport(item.ReportId);
    }

    if (value === 'viewReport') {
      getAccessToken(item.ReportId);
      handleClose(item);
    }

    if (value === 'imgClick') {
      getAccessToken(item.ReportId);
    }

    if (value === 'refreshDataset') {
      getRefreshDataset(item.ReportId);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-2"></div>
      {viewType === 'list' ? (
        <ReportsListView
          accTeam={accTeam}
          allUserDetails={allUserDetails}
          stringAvatar={stringAvatar}
          handleMenuItem={handleMenuItem}
          anchorElArray={anchorElArray}
          handleClick={handleClick}
          handleClose={handleClose}
          userData={userData}
          reportsData={reportsData}
          isTeam={isTeam}
          loading={loadingTeamReports}
          currentTeamMember={currentTeamMember}
        />
      ) : (
        <ReportsGridView
          accTeam={accTeam}
          allUserDetails={allUserDetails}
          stringAvatar={stringAvatar}
          handleMenuItem={handleMenuItem}
          anchorElArray={anchorElArray}
          handleClick={handleClick}
          handleClose={handleClose}
          userData={userData}
          reportsData={reportsData}
          isTeam={isTeam}
          loading={loadingTeamReports}
          currentTeamMember={currentTeamMember}
        />
      )}
    </div>
  );
};

export default Reports;
