/**
 * Version 1.0
 * Vikas W
 * 13.05.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useRef, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import Button from '@material-ui/core/Button';

import { useDispatch } from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../../@jumbo/constants/AppConstants';
import authHeader from '../../../../services/auth/jwt/auth-header';
import SearchIcon from '@material-ui/icons/Search';
import { SearchIconWrapper, Search, StyledInputBase } from './SearchBarStyledComp';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import 'bootstrap/dist/css/bootstrap.min.css';

//syncfusion data grid
import { GridComponent, ColumnsDirective, ColumnDirective, CommandColumn, Filter, Group } from '@syncfusion/ej2-react-grids';
import { Inject, Page, Toolbar, ToolbarItems, Edit, Sort, SortSettingsModel } from '@syncfusion/ej2-react-grids';
import DialogFormTemplate from './DialogFormTemplate';
import TableSkeletonLoader from './TableSkeletonLoader';
import EnhancedTableHead from './TableHead';

import { TableBody, TableCell, TableContainer, TablePagination, TableRow } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import { lighten, makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import BankAccountRowMenu from './BankaccountTabel/BankAccountRowMenu';
import ConfirmationModal from 'routes/Pages/Planning/TaskmanagementPage/ConfirmationModal';
import AddBankAccountModal from './AddBankAccountModal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const headCells = [
  {
    id: 'BankName',
    numeric: false,
    disablePadding: false,
    label: 'Bank Name',
  },
  {
    id: 'UserEmail',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'BIC',
    numeric: true,
    disablePadding: false,
    label: 'BIC',
  },
  {
    id: 'IBAN',
    numeric: true,
    disablePadding: false,
    label: 'IBAN',
  },

  {
    id: 'Sort',
    numeric: true,
    disablePadding: false,
    label: 'Bank Sort',
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];
function descendingComparator(a, b, orderBy) {
  //console.log(orderBy,a,b);

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  let stabalizedArr = stabilizedThis.map(el => el[0]);
  return stabalizedArr;
}
const BankAccounts = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);
  const classes = useStyles();

  //search
  const [searchKey, setSearchKey] = useState('');
  const [searchBankAccData, setSearchBankAccData] = useState([]);

  //pagination
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //sorting
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('Id');

  //search
  const inputRef = useRef(null);

  //Modal Box
  const defaultConfirmationModalObj = {
    isModalOpen: false,
    handleSave: null,
    handleClose: null,
    modalTitle: '',
    modalMainText: '',
    hideCloseBtn: false,
    cancelBtnTxt: null,
    saveBtnText: null,
    teamId: null,
  };

  const [confirmationData, setConfirmationData] = useState({ ...defaultConfirmationModalObj });
  const addModalRef = useRef(null);

  /** check invalid repsonse **/
  customAxios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status === 401 || error.response.status === 400) {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      } else {
        return Promise.reject(error);
      }
    },
  );

  /** syncfusion data tables **/
  let grid;
  const pageSettings = { pageSize: 10 };
  const pubsortSettings = {
    columns: [{ field: 'BankName', direction: 'Ascending' }],
  };
  const filterSettings = {
    type: 'Excel',
    // columns: [{ field: 'EirCode', operator: 'greaterthan', value: 2 }]
  };
  // const groupSettings = { columns: ['ClientName'] };
  const editSettings = {
    allowAdding: true,
    // allowEditing: true,
    allowDeleting: true,
    mode: 'Dialog',
    template: dialogTemplate,
  };

  const toolbarOptions = [
    'Add',
    // 'Edit',
    'Delete',
    'Search',
    // 'Update',
    // 'Cancel',
  ];
  const requiredRule = { required: true };
  const commands = [
    {
      type: 'Edit',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' },
    },
    {
      type: 'Delete',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' },
    },
    {
      type: 'Save',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' },
    },
    {
      type: 'Cancel',
      buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' },
    },
  ];

  function dialogTemplate(props) {
    return <DialogFormTemplate {...props} />;
  }

  function actionBegin(args) {
    let url = '/Bank/AddBankAccount';
    if (args.action === 'add') {
      url = '/Bank/AddBankAccount';
    }
    if (args.requestType === 'delete') {
      url = '/Bank/DeleteBankAccount/' + args.data[0].Id;
      deleteBankAccount(url);
    }
    if (args.requestType === 'save' && args.form) {
      addUpdateClient(url, args.data);
    }
  }
  function actionComplete(args) {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = args.dialog;
      dialog.header = args.requestType === 'beginEdit' ? 'Edit Bank Accounts' : 'New Bank Account';
    }
  }

  /** Add or Edit Clients **/
  function addUpdateClient(url, data) {
    url = url ? url : '/Bank/AddBankAccount';
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, data, { headers: authHeader() })
      .then(data => {
        getBankAccounts();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  /** Delete Client **/
  function deleteBankAccount(id) {
    dispatch({ type: FETCH_START });
    let url = '/Bank/DeleteBankAccount/' + id;
    customAxios
      .delete(url, { headers: authHeader() })
      .then(data => {
        closeConfirmationModal();

        getBankAccounts();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  //row Menu item
  const rowMenu = [
    {
      id: 'delete',
      icon: DeleteIcon,
      onClick: deleteBankAccount,
      label: 'Delte',
    },
  ];

  /** Get All Accounts **/
  const getBankAccounts = () => {
    dispatch({ type: FETCH_START });
    customAxios
      .get('Bank/GetBankAccounts', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setData(data);
          setSearchBankAccData(data);
          setTimeout(() => {
            setSkeletonLoaderState(false);
          }, 1000);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /** Get All Client on page load**/
  useEffect(() => {
    setSkeletonLoaderState(true);
    getBankAccounts();
  }, []);
  let filteredAcctRowsText = [];
  useEffect(() => {
    if (searchKey.length) {
      // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);

      filteredAcctRowsText = getArrayFilterData(data, searchKey);

      if (searchKey.length <= 0) {
        setSearchBankAccData(data);
      } else {
        setSearchBankAccData(filteredAcctRowsText);
      }
    }
  }, [data, searchKey]);

  function searchData() {
    let newdata = [];
    searchBankAccData.map(item => {
      item.child.map(newitem => {
        newdata = [...newdata, newitem];
      });
    });
    setSearchBankAccData(newdata);
  }

  // useEffect(() => {
  //   searchData();

  // }, [searchBankAccData]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //search

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setSearchBankAccData(data);
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.BankName.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.UserEmail.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.IBAN.toString().includes(searchkey.toString().toLowerCase()) ||
        row.BIC.toString().includes(searchkey.toString().toLowerCase()) ||
        row.Sort.toString().includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };
  const handelSearchBankAccData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredData = [];

    filteredData = getArrayFilterData(data, searchkey);

    if (searchkey.length <= 0) {
      setSearchBankAccData(data);
    } else {
      setSearchBankAccData(filteredData);
    }
  };

  const menuActionHandler = (menuItem, rowId) => {
    if (menuItem === 'delete') {
      let confirmationObj = { ...confirmationData };
      confirmationObj.handleClose = closeConfirmationModal;
      confirmationObj.modalTitle = 'Delete Bank Account ';

      confirmationObj.hideCloseBtn = false;
      confirmationObj.modalMainText = ' Do you want to delete the selected account ?';
      confirmationObj.saveBtnText = 'Yes';
      confirmationObj.cancelBtnTxt = 'No';
      confirmationObj.handleSave = () => deleteBankAccount(rowId);

      confirmationObj.isModalOpen = true;
      setConfirmationData({ ...confirmationObj });
    }
  };
  function closeConfirmationModal() {
    setConfirmationData(defaultConfirmationModalObj);
  }

  function openAddAccountModal() {
    addModalRef.current.openModal();
  }
  return (
    <div className="app-wrapper">
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 12 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.bankAccounts" />
          </h4>
        </div>
        {!skeletonLoaderState ? (
          <div className="content-horizontal__center">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => handelSearchBankAccData(e)}
                value={searchKey}
                inputRef={inputRef}
              />
              {searchKey && (
                <CloseIcon
                  onClick={clearSearchData}
                  style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
                />
              )}
            </Search>
            <Button
              variant="contained"
              style={{ textTransform: 'none' }}
              color="primary"
              onClick={() => {
                openAddAccountModal();
              }}>
              Add
            </Button>
            {/* <CmtDropdownMenu
              TriggerComponent={
                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleClickData}
                  sx={{
                    mx: 2,
                  }}
                  style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
                  <MoreVertIcon />
                </Button>
              }
              items={[...actionsList]}
              menuClasses={cusclass.root}
            /> */}
          </div>
        ) : (
          ''
        )}
      </div>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={searchBankAccData.length}
            headCells={headCells}
          />
          <TableBody>
            {/* {getFilterTabData(searchBankAccData, 'Yes').map((row, index) => ( */}
            {/* {stableSort(searchBankAccData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                // console.log(row, 'row');
                return (
                  <Row
                    key={index}
                    row={row}
                    details={detailsClickHandler}
                    handelSelect={handelSelect}
                    isSelected={isSelected}
                    labelId={index}n 
                    handleClicks={handleClick}
                    setSearchBankAccData={setSearchBankAccData}
                  />
                );
              })} */}
            {skeletonLoaderState ? (
              <TableSkeletonLoader row={5} col={6} />
            ) : (
              stableSort(searchBankAccData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow key={item.Id} id={item.Id}>
                      <TableCell>{item.BankName}</TableCell>
                      <TableCell>{item.UserEmail}</TableCell>
                      <TableCell>{item.BIC}</TableCell>
                      <TableCell>{item.IBAN}</TableCell>
                      <TableCell>{item.Sort}</TableCell>
                      <TableCell>
                        <BankAccountRowMenu
                          key={item.Id}
                          rowMenu={rowMenu}
                          menuActionHandler={menuActionHandler}
                          rowId={item.Id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationModal confirmationObj={confirmationData} />
      <AddBankAccountModal ref={addModalRef} addAccount={addUpdateClient} />
    </div>
    // <GridComponent
    //   id={'Grid'}
    //   ref={g => (grid = g)}
    //   dataSource={data}
    //   allowPaging={true}
    //   pageSettings={pageSettings}
    //   allowFiltering={true}
    //   filterSettings={filterSettings}
    //   allowSorting={true}
    //   sortSettings={pubsortSettings}
    //   toolbar={toolbarOptions}
    //   editSettings={editSettings}
    //   actionBegin={actionBegin}
    //   childMapping="subtasks"
    //   actionComplete={actionComplete}>
    //   <ColumnsDirective>
    //     <ColumnDirective
    //       field="BankName"
    //       width="150"
    //       textAlign="left"
    //       validationRules={requiredRule}
    //       headerText={'BankName'}
    //       filter={{ type: 'CheckBox' }}
    //     />
    //     <ColumnDirective
    //       field="UserEmail"
    //       width="150"
    //       textAlign="left"
    //       validationRules={requiredRule}
    //       headerText={'Email'}
    //       filter={{ type: 'CheckBox' }}
    //     />
    //     <ColumnDirective
    //       field="BIC"
    //       width="150"
    //       textAlign="left"
    //       validationRules={requiredRule}
    //       headerText={'BIC'}
    //       filter={{ type: 'CheckBox' }}
    //     />
    //     <ColumnDirective field="IBAN" width="120" textAlign="left" headerText={'IBAN'} validationRules={requiredRule} />
    //     <ColumnDirective
    //       field="Sort"
    //       width="120"
    //       textAlign="left"
    //       headerText={'Bank Sort'}
    //       validationRules={requiredRule}
    //     />

    //     {/* <ColumnDirective
    //     // headerText="Action"
    //     width="100"
    //     commands={commands}
    //   /> */}
    //   </ColumnsDirective>
    //   <Inject services={[Toolbar, Edit, Page, Sort, Filter, Group, CommandColumn]} />
    // </GridComponent>
  );
};

export default BankAccounts;
