import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../util/IntlMessages';
import { SingleSelect } from 'react-select-material-ui';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Dropdown from '../../Accounting/InvoicePage/Dropdown';
import $ from 'jquery';
/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  actionIcon: {
    cursor: 'pointer',
    marginTop: '15px',
  },
}));

function TableRows({ rowsData, deleteTableRows, handleRowsChange, handleRowsProductChange, allProducts, isCommonVat }) {
  const [value, setValue] = useState(null);
  const classes = useStyles();

  return rowsData.map((data, index) => {
    const { control, errors, product, quantity, productRate, vat, description } = data;
    return (
      <tr key={index}>
        {/* {JSON.stringify(product)} */}
        <td className="pt-5">
          <Controller
            name="product"
            control={control}
            defaultValue={product ? product : ''}
            render={({ field: { onChange, value, ref } }) => (
              <Dropdown
                addWindow={true}
                options={allProducts}
                defaultVal={product}
                id="value"
                label="label"
                prompt="Select product.."
                value={product ? product : ''}
                onChange={val => {
                  setValue(val);
                  onChange(val);
                  handleRowsProductChange(index, val);
                }}
                //   onClick={val => addProductsItem(val)}
              />
            )}
          />
          <ErrorMessage errors={errors} name="iProduct" render={({ message }) => <p>{message}</p>} />
        </td>
        <td>
          {/* <input
            type="text"
            value={quantity}
            onChange={evnt => handleRowsChange(index, evnt)}
            name="quantity"
            className="form-control"
          /> */}
          <Controller
            name="quantity"
            control={control}
            // defaultValue={quantity ? quantity : ''}
            render={({ field }) => (
              <TextField
                {...field}
                style={{ marginTop: '0px' }}
                label={<IntlMessages id="appModule.iQuantity" />}
                margin="normal"
                onChange={event => handleRowsChange(index, event)}
                value={quantity ? quantity : ''}
              />
            )}
          />
          <ErrorMessage errors={errors} name="iQuantity" render={({ message }) => <p>{message}</p>} />
        </td>
        <td>
          <Controller
            name="productRate"
            control={control}
            // defaultValue={productRate ? productRate : ''}
            render={({ field }) => (
              <TextField
                {...field}
                style={{ marginTop: '0px' }}
                label={<IntlMessages id="appModule.iRate" />}
                margin="normal"
                onChange={event => handleRowsChange(index, event)}
                value={productRate ? productRate : ''}
              />
            )}
          />
          <ErrorMessage errors={errors} name="iRate" render={({ message }) => <p>{message}</p>} />
        </td>
        {!isCommonVat && (
          <td>
            <Controller
              name="vat"
              control={control}
              // defaultValue={vat ? vat : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  style={{ marginTop: '0px' }}
                  label={<IntlMessages id="appModule.iVat" />}
                  margin="normal"
                  onChange={event => handleRowsChange(index, event)}
                  value={vat ? vat : ''}
                />
              )}
            />
            <ErrorMessage errors={errors} name="iVat" render={({ message }) => <p>{message}</p>} />
          </td>
        )}
        <td>
          <Controller
            name="description"
            control={control}
            // defaultValue={description ? description : ''}
            render={({ field }) => (
              <TextField
                {...field}
                style={{ marginTop: '0px' }}
                label={<IntlMessages id="appModule.iDescription" />}
                margin="normal"
                onChange={event => handleRowsChange(index, event)}
                value={description ? description : ''}
              />
            )}
          />
          <ErrorMessage errors={errors} name="iDescription" render={({ message }) => <p>{message}</p>} />
        </td>
        <td>
          <DeleteOutlineIcon className={classes.actionIcon} onClick={() => deleteTableRows(index)} />
        </td>
      </tr>
    );
  });
}

export default TableRows;
