import { Button, TextField } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import IntlMessages from '../../util/IntlMessages';

const AddBankAccountModal = forwardRef(({ addAccount, ...props }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const defaultModalData = {
    BankName: '',
    UserEmail: '',
    BIC: '',
    IBAN: '',
    Sort: '',
  };
  const [accountData, setAccountData] = useState({ ...defaultModalData });
  const toggle = () => {
    setAccountData({ ...defaultModalData });
    setIsModalOpen(!isModalOpen);
  };

  function onChange(el) {
    const { name, value } = el.target;
    setAccountData(prev => {
      let oldAddAccountObj = { ...prev };
      oldAddAccountObj[name] = value;
      return oldAddAccountObj;
    });
  }
  useImperativeHandle(ref, () => {
    return {
      openModal() {
        setIsModalOpen(true);
      },
    };
  });

  function handelSaveAccount(event) {
    event.preventDefault();
    let addAccountObj = { ...accountData };
    addAccount(false, addAccountObj);
    toggle();
    console.log(addAccountObj);
  }

  return (
    <Modal
      centered={true}
      className="invoice modal-box"
      isOpen={isModalOpen}
      style={{ maxWidth: 'auto !important', width: '50vw' }}
      toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        <IntlMessages id="bankAccounts.addAccount" />
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      <form
        name="myForm"
        onSubmit={handelSaveAccount}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          <div>
            <div className="form-row">
              <div className="form-group col-md-6">
                {/* <div className="e-float-input e-control-wrapper">
            <input id="Name" required name="BankName" type="text" value={accountData.BankName} onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">BankName </label>
          </div> */}
                <TextField
                  required
                  fullWidth
                  id="BankName"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Bank Name"
                  value={accountData.BankName}
                  onChange={onChange}
                  name="BankName"
                  type="text"
                  variant="outlined"
                />
              </div>
              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={accountData.UserEmail}
                  id="UserEmail"
                  name="UserEmail"
                  type="text"
                  onChange={onChange}
                  variant="outlined"
                  label="User Email"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={accountData.BIC}
                  id="BIC"
                  name="BIC"
                  type="text"
                  onChange={onChange}
                  variant="outlined"
                  label="BIC"
                />
              </div>
              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={accountData.IBAN}
                  id="IBAN"
                  name="IBAN"
                  type="text"
                  onChange={onChange}
                  variant="outlined"
                  label="IBAN"
                />
              </div>
              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={accountData.Sort}
                  id="Sort"
                  name="Sort"
                  type="text"
                  onChange={onChange}
                  variant="outlined"
                  label="Bank Sort"
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // disabled={details.length === 0 ? false : true}
          >
            Save
          </Button>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
});

export default AddBankAccountModal;
