/**
 * Version 1.0
 * Vikas W
 * 24.09.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';

/* Material Components */
import { Select, MenuItem, Button, FormControl, InputLabel } from '@mui/material';
import IntlMessages from '../../util/IntlMessages';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

/* import xlsx package */
import * as XLSX from 'xlsx';

const ImportExcel = ({ modal, toggle, selectedTeam, statusByTeam, getStatusHandler }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [crmDefault, setCrmDefault] = useState([
    // { TableColumnName: 'tmTeamId', ExelColumnName: '' },
    { TableColumnName: 'ClientId', TableColumnLabel: 'Client Name', ExelColumnName: '' },
    { TableColumnName: 'tName', TableColumnLabel: 'Task Name', ExelColumnName: '' },
    // { TableColumnName: 'StatusId', ExelColumnName: '' },
    { TableColumnName: 'TaskDescription', TableColumnLabel: 'Task Description', ExelColumnName: '' },
    { TableColumnName: 'TaskDueDate', TableColumnLabel: 'Due Date', ExelColumnName: '' },
    { TableColumnName: 'AssignedToId', TableColumnLabel: 'Assigned To', ExelColumnName: '' },
    { TableColumnName: 'TaskDescription2', TableColumnLabel: 'Project Name', ExelColumnName: '' },
  ]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  let fileCSVType = 'text/csv';
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  useEffect(() => {
    // console.log(statusByTeam);
    if (statusByTeam) {
      const status = statusByTeam.map(status => ({ label: status.title, value: status.id }));
      setAllStatus(status);
    }
  }, [statusByTeam]);
  /* Select Dropdown column Map*/
  const [excelColumnMap, setExcelColumnMap] = useState([]);

  const handleChange = (event, mapName) => {
    let finalMap = crmDefault.map(data => {
      if (data.TableColumnName === mapName.TableColumnName) {
        data.ExelColumnName = event.target.value;
      }
      return data;
    });
  };
  const handleStatusChange = event => {
    // console.log(event.target.value);
    setStatus(event.target.value);
  };

  const handlefle = e => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && (fileType.includes(selectedFile.type) || fileCSVType.includes(selectedFile.type))) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = e => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
          // console.log(e.target.result);
        };
      } else {
        setExcelFileError('please select excel file type');
        setExcelFile(null);
      }
    }
  };

  const importSubmit = e => {
    e.preventDefault();
    setButtonDisabled(true);
    let sheetWiseData = [];
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      // console.log(workbook.SheetNames);
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        sheetWiseData = [...sheetWiseData, ...data];
      });

      if (sheetWiseData) {
        setExcelData(sheetWiseData);
        var keys = Object.keys(sheetWiseData[0]);
        let data = keys.map(data => ({ label: data, value: data }));
        // console.log(data);
        setExcelColumnMap(data);
      }
    } else {
      setExcelData(null);
    }
  };

  const fileSubmitHandler = () => {
    const finalObj = [];
    excelData.forEach(data => {
      finalObj.push({
        tmTeamId: selectedTeam.value,
        StatusId: status,
        ClientId: data[crmDefault.filter(val => val.TableColumnName === 'ClientId')[0].ExelColumnName]
          ? data[crmDefault.filter(val => val.TableColumnName === 'ClientId')[0].ExelColumnName]
          : null,
        tName: data[crmDefault.filter(val => val.TableColumnName === 'tName')[0].ExelColumnName]
          ? data[crmDefault.filter(val => val.TableColumnName === 'tName')[0].ExelColumnName]
          : null,
        TaskDescription: data[crmDefault.filter(val => val.TableColumnName === 'TaskDescription')[0].ExelColumnName]
          ? data[crmDefault.filter(val => val.TableColumnName === 'TaskDescription')[0].ExelColumnName]
          : null,
        AssignedToId: data[crmDefault.filter(val => val.TableColumnName === 'AssignedToId')[0].ExelColumnName]
          ? data[crmDefault.filter(val => val.TableColumnName === 'AssignedToId')[0].ExelColumnName]
          : null,
        TaskDueDate: data[crmDefault.filter(val => val.TableColumnName === 'TaskDueDate')[0].ExelColumnName]
          ? data[crmDefault.filter(val => val.TableColumnName === 'TaskDueDate')[0].ExelColumnName]
          : null,
      });
    });
    // console.log(finalObj);
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/AddMultipleTasks', finalObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getStatusHandler(selectedTeam.value);
        toggle();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  // useEffect(() => {
  //   setCrmTeamId(currentTeam != {} ? currentTeam.value : null);
  //   setCrmStageId(currTabInfo != {} ? currTabInfo.StageId : null);
  // }, [excelData]);

  return (
    <Modal className="invoice modal-box" size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        Bulk Upload CSV/Excel
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>

      <ModalBody>
        <div className="modal-box-content">
          <ErrorMessage errors={errors} name="importExcel" render={({ message }) => <p>{message}</p>} />
          <form
            name="myForm"
            onSubmit={importSubmit}
            style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
            <div className="row no-gutters">
              <div className="col-lg-9 d-flex flex-column order-lg-1 invoice_label">
                <input type="file" className="form-control" required onChange={handlefle} />
                <div className="json-container">{excelFileError && <div>{excelFileError}</div>}</div>
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-2 d-flex flex-column order-lg-1 invoice_label">
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="crm.upload" />
                </Button>
              </div>
            </div>
          </form>
          {excelData && (
            <>
              <div className="row no-gutters">
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label mb-1">
                  <br />
                  <InputLabel htmlFor="age-native-helper">
                    <strong>Columns</strong>
                  </InputLabel>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                  <br />
                  <InputLabel htmlFor="age-native-helper">
                    <strong>Imported Column Names</strong>
                  </InputLabel>
                </div>
              </div>
            </>
          )}

          {excelData && (
            <>
              <div className="row no-gutters">
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                  <span className="import-label">Team Name</span>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">{selectedTeam.label}</div>
                <hr />
              </div>

              <div className="row no-gutters">
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                  <span className="import-label">Status</span>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                  <FormControl size="small" variant="standard">
                    <Select
                      fullWidth
                      id="columnStatus"
                      labelId="columnStatus"
                      // margin="dense"
                      displayEmpty
                      name="columnStatus"
                      onChange={e => handleStatusChange(e)}
                      // value={value}
                    >
                      {allStatus.map((element, idx) => {
                        // const [[key, val]] = Object.entries(element);
                        return (
                          <MenuItem value={element.value} key={idx}>
                            {element.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <hr />
              </div>
            </>
          )}
          {excelData &&
            crmDefault.map((row, index) => (
              <div className="row no-gutters" key={index}>
                <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                  <span className="import-label">{row.TableColumnLabel}</span>
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                  <FormControl size="small" variant="standard">
                    <Select
                      fullWidth
                      id={'columnMap_' + index}
                      labelId={'columnMapL_' + index}
                      // margin="dense"
                      displayEmpty
                      name={'columnMap_' + index}
                      onChange={e => handleChange(e, row)}
                      // value={value}
                    >
                      <MenuItem value="">Empty</MenuItem>
                      {excelColumnMap.map((element, idx) => {
                        const [[key, val]] = Object.entries(element);
                        return (
                          <MenuItem value={val} key={idx}>
                            {val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <hr />
              </div>
            ))}
        </div>
      </ModalBody>
      <ModalFooter>
        {buttonDisabled && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={fileSubmitHandler}
            // disabled={teamCheck || stageCheck ? true : false}
          >
            <IntlMessages id="invoices.createInvoice" />
          </Button>
        )}

        <Button onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImportExcel;
