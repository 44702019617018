import React, { useEffect, useState } from 'react';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Box, ButtonGroup, Card, CardContent, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const ConatctAttributes = ({ contactInfo, keyValuePair, setKeyValuePair, getAttData }) => {
  const dispatch = useDispatch();
  //start loading
  const [isLabelLoaded, setIsLabelLoaded] = useState(true);
  const [isAttributeLoaded, setIsAttributeLoaded] = useState(true);
  // const [keyValuePair, setKeyValuePair] = useState({});
  // end loading

  const [contactAttributes, setContactAttributes] = useState({});
  const [contactLabels, setContactLabels] = useState({});

  // popup start
  const [showPopup, setShowPopup] = useState(false);

  // popup end

  const [newAttributeValue, setNewAttributeValue] = useState('');
  const [newLabelValue, setNewLabelValue] = useState('');

  //get conact attributes and labels
  function getContactAttributes(args, parm) {
    // setIsAttributeLoaded(false);
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`Contacts/GetContactAttributes/${args.CrmTeamId}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          let AttrData = data.reduce((a, v) => ({ ...a, [v.LabelId]: v }), {});
          // setContactAttributes(AttrData);
          let newKeyValuePair = { ...keyValuePair };
          newKeyValuePair[parm.LabelId] = { ...keyValuePair[parm.LabelId], attrInfo: AttrData[parm.LabelId] };
          setKeyValuePair(newKeyValuePair);
          // if(parm)
          //   let keyValueAtt = Object.entries(parm).reduce((a,v)=>({...a,[v[0]]:{
          //                 labelInfo : v,
          //                 attrInfo: AttrData[parseInt(v[0])],
          //                 labelValue: AttrData[parseInt(v[0])] ? AttrData[parseInt(v[0])].LabelValue :""
          //               }}),{});
          //               setKeyValuePair(keyValueAtt)
          // }

          // setIsAttributeLoaded(true);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  function getContactAttributesLabel(args, fnc) {
    // setIsLoadingComplete({['label']:false})
    // setIsLabelLoaded(false);
    let url = 'Contacts/GetAttributeLabels/' + contactInfo.CrmTeamId;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          let labelData = data.reduce((a, v) => ({ ...a, [v.Id]: v.LabelName }), {});
          // setContactLabels(labelData);

          if (fnc) {
            fnc(labelData);
          }
          // setIsLabelLoaded(true);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }
  // end get contact attribute and labels

  //   Add new Field

  const AddNewField = () => {
    if (newLabelValue.trim() == '' || newAttributeValue.trim() == '') {
      dispatch({ type: FETCH_ERROR, payload: 'Please Enter Both the Values' });
      return;
    }

    let currLabel = newLabelValue.toLowerCase().trim();

    // for (const item in contactAttributes) {
    //     if(contactLabels[item.LabelId])
    //     if(contactLabels[item.LabelId].toLowerCase() == currLabel)
    //     {
    //         // possibility of edit
    //      dispatch({ type: FETCH_ERROR, payload: 'Please Enter a Different Label ' });
    //      return;

    //     }
    // }

    // for(const item in contactLabels){
    //     if(contactLabels[item])
    //     if(contactLabels[item].toLowerCase() == currLabel)
    //     {
    //         // possibility of Add
    //      dispatch({ type: FETCH_ERROR, payload: 'Please Enter a Different Label ' });
    //      return;

    //     }
    // }
    for (const item in keyValuePair) {
      if (keyValuePair[item].labelInfo[1].toLowerCase().trim() == currLabel) {
        dispatch({ type: FETCH_ERROR, payload: 'Duplicate label, Please enter a different label ' });
        return;
      }
    }

    AddNewLabel(newLabelValue);
  };
  const AddNewLabel = value => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(
          `Contacts/AddAttributeLabel`,
          { LabelName: value },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          getContactAttributesLabel(contactInfo, latestLabel => AddNewAttribute(newAttributeValue, latestLabel));
          // AddNewAttribute(newAttributeValue)
          dispatch({ type: FETCH_SUCCESS });
        })
        .then(() => {})
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const AddNewAttribute = (value, latestLabels) => {
    let label = newLabelValue.toLowerCase();
    let labelId = Object.entries(latestLabels).filter(item => {
      if (item[1]) if (item[1].toLowerCase() == label) return item;
    });
    let obj = {
      Crm_ContactId: contactInfo.crmId,
      LabelId: parseInt(labelId[0]),
      LabelValue: value,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`Contacts/AddContactAttribute`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          // getContactAttributes(contactInfo);
          getAttData();

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
    setNewAttributeValue('');
    setNewLabelValue('');
  };
  const AddAttribute = obj => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`Contacts/AddContactAttribute`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          getContactAttributes(contactInfo, obj);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const EditAttribute = (obj, lIndex) => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`Contacts/EditContactAttributes`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          // getContactAttributes(contactInfo);
          let newKeyValuePair = { ...keyValuePair };
          newKeyValuePair[lIndex] = {
            ...keyValuePair[lIndex],
            attrInfo: { ...keyValuePair[lIndex].attrInfo, labelValue: obj.LabelValue },
          };
          setKeyValuePair(newKeyValuePair);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const onInputChangeHandler = (value, info) => {
    if (!info.attrInfo) {
      if (value.trim() === '') return;
      let obj = {
        Crm_ContactId: contactInfo.crmId,
        LabelId: parseInt(info.labelInfo[0]),
        LabelValue: value,
      };
      AddAttribute(obj);
      return;

      // Add new Attribute
    }

    if (info.attrInfo.LabelValue.trim().toLowerCase() !== value.trim().toLowerCase()) {
      let obj = {
        Id: info.attrInfo.Id,
        LabelValue: value,
        crmTeamId: contactInfo.CrmTeamId,
      };
      EditAttribute(obj, info.attrInfo.LabelId);
    }
  };

  const onChange = (e, index) => {
    let newKeyValuePair = { ...keyValuePair };
    newKeyValuePair[parseInt(index)] = { ...keyValuePair[parseInt(index)], labelValue: e.target.value };
    setKeyValuePair(newKeyValuePair);
  };

  return (
    <div>
      {/* content start  */}
      <div>
        <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
          <CardContent component="div" style={{ paddingBottom: '16px', padding: showPopup ? '16px' : '0 16px' }}>
            <div
              style={{
                fontSize: '16px',
                fontFamily: 'normal',
                fontWeight: 'bold',
                lineHeight: 1,
                padding: '10px 0 10px 10px',
              }}>
              <IconButton
                variant="contained"
                onClick={() => setShowPopup(!showPopup)}
                size="small"
                style={{
                  background: '#3f51b5',
                  color: 'white',
                  transform: showPopup ? 'rotate(45deg)' : 'rotate(0deg)',
                  transition: 'all 0.5s',
                }}>
                <AddIcon style={{ fontWeight: 'bold', fontSize: 30 }} />
              </IconButton>
            </div>

            <div style={{ display: showPopup ? 'block' : 'none' }}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <form className="row mt-3">
                  <div className="col-md-6">
                    <TextField
                      label="Attribute Label"
                      id="AttributeLabel"
                      name="attributeLabel"
                      type="text"
                      variant="outlined"
                      value={newLabelValue}
                      onChange={e => setNewLabelValue(e.target.value)}
                      // onBlur={onInputChangeHandler}
                      // defaultValue={obj.crmContactFirst}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextField
                      label="Attribute Value"
                      id="AttributeValue"
                      name="attributeValue"
                      type="text"
                      variant="outlined"
                      value={newAttributeValue}
                      onChange={e => setNewAttributeValue(e.target.value)}
                      // onBlur={onInputChangeHandler}
                      // defaultValue={`${infoSidebarData.crmContactMiddle}`}
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                      inputProps={{ style: { fontSize: 14 } }}
                    />
                  </div>
                  <div className="col-md-12 d-flex justify-content-end " style={{ paddingInline: '20px', gap: '15px' }}>
                    <Button variant="contained" style={{ background: '#3f51b5', color: 'white' }} onClick={AddNewField}>
                      Add
                    </Button>
                    <Button
                      variant="contained"
                      style={{ background: '#3f51b5', color: 'white' }}
                      onClick={() => setShowPopup(false)}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </Box>
            </div>
          </CardContent>
        </Card>
        {isLabelLoaded && isAttributeLoaded ? (
          <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
            <CardContent component="div" style={{ paddingBottom: '16px' }}>
              <div>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off">
                  <div className="row mt-3">
                    {Object.entries(keyValuePair).map((item, index) => {
                      return (
                        <div className="col-md-4" key={index} style={{ marginBottom: '16px' }}>
                          <TextField
                            label={item[1].labelInfo[1]}
                            id={item[0]}
                            name={item[0]}
                            type="text"
                            variant="outlined"
                            value={item[1].labelValue}
                            onChange={e => onChange(e, item[0])}
                            onBlur={e => {
                              onInputChangeHandler(e.target.value, item[1]);
                            }}
                            // defaultValue={contactAttributes[item[0]] ? contactAttributes[item[0]].LabelValue:""}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px', color: 'black !important' }}
                            inputProps={{ style: { fontSize: 12, textTransform: 'capitalize' } }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </div>
            </CardContent>
          </Card>
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
            {<CircularProgress />}
          </div>
        )}
      </div>
      {/* content end */}
    </div>
  );
};

export default ConatctAttributes;
