import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';

const modules = {
  toolbar: [
    // [{ font: [] }],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    // [{ list:  "ordered" }, { list:  "bullet" }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image', 'video'],
    // ["clean"],
    // ["fullscreen"]
  ],
};
const CommentsInput = ({
  addNotification,
  selectedCard,
  tmTeamId,
  tmTaskId,
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onBlur,
  onChange,
  helperText,
  variant,
  error,
  height,
  commentRef,
  members,
  setMentionCount,
  handleDescBlurEditor,

  ...rest
}) => {
  // const Quill = ReactQuill.Quill;

  // var Block = Quill.import('blots/block');
  // Block.tagName = 'div';
  // Quill.register(Block);

  const [contributorId, setContributorId] = useState();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [ReadOnly, setReadOnly] = useState(true);
  const module = useMemo(() => {
    return {
      toolbar: [
        // [{ font: [] }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        // [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image', 'video'],
        // ["clean"],
        // ["fullscreen"]
      ],
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        linkTarget: 'https://www.google.com',
        source: function(searchTerm, renderItem, mentionChar) {
          // console.log('01010check');
          let values;
          if (mentionChar === '@') {
            values = members;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i += 1)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
              }
            renderItem(matches, searchTerm);
          }
        },
        onSelect: function(item, insertItem) {
          insertItem(item, true);
          let newValues = [];
          // const updateName = {};
          newValues.push(item);
          newValues.forEach((value, key) => {
            setContributorId(+value.id);
          });
          setMentionCount(preV => preV + 1);
        },
      },
    };
  }, [members, commentRef?.current?.state?.value]);

  const handleProcedureContentChange = (content, delta, source, editor) => {
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };

  useEffect(() => {
    if (contributorId) {
      addTaskContributors();
      console.log('running');
    }
  }, [contributorId]);

  const addTaskContributors = () => {
    const bodyDescription = {
      tmTeamId: tmTeamId,
      tmTaskId: tmTaskId,
      contributorId: contributorId,
    };

    dispatch({ type: FETCH_START });

    customAxios
      .post('Task/AddTaskContributors', bodyDescription, { headers: authHeader() })
      .then(({ data }) => {
        const noteObj = {
          UserIdTo: contributorId,
          ActivityId: selectedCard.id,
          NotifyMessage: 'You are Added as Task Contributor on Task Ref:' + selectedCard.tRefNumber,
          FunctionalityId: selectedCard.StatusId,
          TeamId: selectedCard.TeamId,
          IsClicked: 0,
        };
        const emailObj = {
          type: 'taskContributors',
          cardData: selectedCard,
          doneBy: authUser?.Email,
          message: 'task contributor update',
        };
        handleDescBlurEditor();
        addNotification(noteObj, emailObj);
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        return error;
      });
  };

  useEffect(() => {
    checking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRef.current]);

  const checking = () => {
    if (commentRef.current) {
      commentRef.current.blur();
    }
  };

  // useEffect(() => {
  //   handleButtonClick();
  // }, [commentRef]);

  // const handleButtonClick = () => {
  //   // Set focus on second Quill instance
  //   commentRef.current.getEditor().blur();
  //   console.log(commentRef.current.getEditor().blur(), '909090');
  // };

  const handleOnBlur = valuess => {};

  return (
    <div style={{ width: size, marginBottom: '35px' }} onClick={() => setReadOnly(false)}>
      <ReactQuill
        ref={commentRef}
        modules={module}
        style={{ height: height ? height : '200' }}
        defaultValue={value}
        readOnly={ReadOnly}
        // onChange={(content, delta, source, editor) => {
        //   onChange(content, delta, source, editor);
        // }}
        onBlur={handleOnBlur}
        onChange={(content, delta, source, editor) => handleProcedureContentChange(content, delta, source, editor)}
        theme="snow"
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'mention',
          'color',
          'background',
        ]}
        placeholder="Add comments"
      />
    </div>
  );
};

CommentsInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  members: PropTypes.array,
  commentRef: PropTypes.func,
};

CommentsInput.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
};

export default CommentsInput;

// import React, { useMemo, useRef } from 'react'
// import PropTypes from 'prop-types';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// import {
//   FETCH_ERROR,
//   FETCH_START,
//   FETCH_SUCCESS,
//   // INIT_URL,
//   // SIGNOUT_USER_SUCCESS,
//   // USER_DATA,
//   // USER_TOKEN_SET,
// } from '../../../constants/ActionTypes';
// import customAxios from '../../../util/Api';
// import authHeader from '../../../../../services/auth/jwt/auth-header';

// // const modules = {
// //   toolbar: [
// //       [{ font: [] }],
// //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
// //       ["bold", "italic", "underline", "strike"],
// //       [{ color: [] }, { background: [] }],
// //       [{ script:  "sub" }, { script:  "super" }],
// //       ["blockquote", "code-block"],
// //       [{ list:  "ordered" }, { list:  "bullet" }],
// //       [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
// //       ["link", "image", "video"],
// //       ["clean"],
// //       ["fullscreen"]
// //   ],
// // };

// const CommentsInput = ({ type, name, id, fullWidth, size, value, onBlur, helperText, variant, error, height, ...rest }) => {
//   const quillRef = useRef();
//   const imageHandler = (e) => {
//     const editor = quillRef.current.getEditor();
// console.log(editor)
//     const input = document.createElement("input");
//     input.setAttribute("type", "file");
//     input.setAttribute("accept", "image/*");
//     input.click();
//     input.onchange = async () => {
//       const file = input.files[0];
//       if (/^image\//.test(file.type)) {
// console.log(file);
//         const bodyAddAttachment = {
//           TaskId: id,
//           AttachmentBlob: "",
//           Attachment: URL.createObjectURL(file),
//           AttachmentName: file.name
//       };

//         // const formData = new FormData();
//         // formData.append("TaskId", id);
//         // formData.append("AttachmentBlob", "");
//         // formData.append("Attachment", file);
//         // formData.append("AttachmentName", file.name);
//         // const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
//         const res = await customAxios.post('/Task/AddAttachment', bodyAddAttachment, { headers: authHeader() })
// console.log(res);
//         // const url = res?.data?.url;
//         // editor.insertEmbed(editor.getSelection(), "image", url);
//       } else {
//         // ErrorToast('You could only upload images.');
//       }
//     };
//   }
//   const modules = useMemo(() => ({
//     toolbar: {
//       container: [
//         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
//         ['bold', 'italic', 'underline', "strike"],
//         [{ 'list': 'ordered' }, { 'list': 'bullet' },
//         { 'indent': '-1' }, { 'indent': '+1' }],
//         ['image', "link",],
//         [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
//       ],
//       handlers: {
//         image: imageHandler
//       }
//     },
//   }), [])

//   return (
//     <div style={{ width: size, marginBottom: "35px" }}>
//       <ReactQuill
//         ref={quillRef}
//         style={{ height: height ? height :  "200" }}
//         theme="snow"
//         value={value}
//         onBlur={onBlur}
//         modules={modules}
//         placeholder="Task Description goes here..."
//       />
//     </div>
//   );
// };

// CommentsInput.prototype = {
//   type: PropTypes.string,
//   name: PropTypes.string,
//   id: PropTypes.string,
//   fullWidth: PropTypes.bool,
//   value: PropTypes.string,
//   variant: PropTypes.string,
//   size: PropTypes.string,
//   height: PropTypes.string,
//   onBlur: PropTypes.func,
//   error: PropTypes.bool,
//   helperText: PropTypes.bool,
// };

// CommentsInput.defaultProps = {
//   type: 'text',
//   fullWidth: true,
//   size: 'small',
//   error: false,
//   helperText: '',
// };

// export default CommentsInput;
