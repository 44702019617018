import React, { useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Tooltip, Typography, Card, CardContent, FormControl } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Select from 'react-select';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
// import { closeFilterDialog } from '@syncfusion/ej2-react-grids';
import { useForm, Controller } from 'react-hook-form';
// import { closeFilterDialog } from '@syncfusion/ej2-react-grids';
import { ErrorMessage } from '@hookform/error-message';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import IntlMessages from 'routes/Pages/util/IntlMessages';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';

const ListViewStyles = makeStyles(theme => ({
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
}));

const TaskFilterDialog = ({
  openFilterDialog,
  setOpenFilterDialog,
  filterMembers,
  projectListData,
  selectedCard,
  selectedTeam,
  setFilterData,
  advancedClickFilter,
  onClose,
  setCurrentValue,
}) => {
  const {
    reset,
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm({});
  // const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const classes = ListViewStyles();
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [endOpen, setEndOpen] = React.useState(false);
  const [members, setMembers] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const handlestartDateHandler = (dateChange, cardId) => {
    setStartDateFilter(dateChange);
    setValue('startDate', dateChange);
    setOpen(false);

    // handleDueDateChange(dateChange, cardId);
  };

  const handleDueDateHandler = dateChange => {
    setEndDateFilter(dateChange);
    setValue('endDate', dateChange);
    setEndOpen(false);
  };
  const closeFilterDialog = () => {
    setOpenFilterDialog(false);
  };
  const handleClose = () => {
    closeFilterDialog();
    onClose();
    setCurrentValue(null);
  };

  useEffect(() => {
    if (projectListData) {
      var project = projectListData.map(c => ({
        value: c.pId,
        label: c.pName,
        isSelectedProject: c.pId == (project ? project.value : selectedCard?.ProjectId) ? true : false,
        ...c,
      }));
      setProjectList(project);
    }
  }, [projectListData]);

  /**
   * Function called when we submit the form
   *
   * @param formData The responses of the form
   */

  const onSubmit = formData => {
    setFilterData(formData);
    advancedClickFilter(formData);
    setOpenFilterDialog(false);
    onClose();

    let fieldsWithData = 0;
    let fieldsUndefined = 0;

    for (const key in formData) {
      if (Object.prototype.hasOwnProperty.call(formData, key)) {
        const value = formData[key];
        if (typeof value !== 'undefined') {
          fieldsWithData++;
        } else {
          fieldsUndefined++;
        }
      }
    }

    setCurrentValue(fieldsWithData);
  };

  const handleClearFilter = () => {
    setValue('project', []); // Reset project field to an empty array
    setValue('members', []); // Reset the members field using setValue
    setStartDateFilter(null); // Reset startDateFilter state to null
    setEndDateFilter(null); // Reset endDateFilter state to null
    setCurrentValue(null);
    setCurrentValue(null);
  };

  return (
    <div>
      {/* <Tooltip
        title={
          <div>
            <Typography>Advance Filters</Typography>
          </div>
        }
        style={{ background: 'white' }}
        arrow={true}>
        <Button
          variant="contained"
          className="px-2"
          onClick={() => {
            if (!openFilterDialog) setOpenFilterDialog(true);
          }}
          style={{
            height: 40,
            minWidth: '25px',
            background: 'none',
            color: '#3f51b5',
          }}>
          <FilterAltIcon />
        </Button>
      </Tooltip> */}
      <Dialog
        // fullWidth={fullWidth}
        // style={{width:'400px'}}
        maxWidth="md"
        open={openFilterDialog}
        onClose={handleClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0,0,0,0.250)' }}>
          <div className="w-100 d-flex justify-content-between ">
            <div>Advance Filters</div>
            <div className="d-flex" style={{ gap: '2rem' }}>
              <div>
                <Button type="submit" variant="contained" color="primary" form="myForm">
                  Apply
                </Button>

                <Button className="ml-3" size="medium" variant="contained" onClick={handleClearFilter}>
                  Clear Filter{' '}
                </Button>
              </div>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseRoundedIcon onClick={onClose} />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <Card style={{ marginBottom: '1rem', border: 'none', boxShadow: 'none', paddingInline: '0' }}>
            <CardContent component="div" style={{ padding: 20, width: '600px', height: '350px' }}>
              <form
                id="myForm"
                name="myForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{ paddingBottom: '4px', paddingTop: '-2px', paddingLeft: '4px', paddingRight: '4px' }}>
                <div className={classes.heading}>
                  <Typography variant="subtitle1">Select Project</Typography>
                  <div className="col-lg-8 d-flex flex-column invoice_label order-lg-1">
                    <Controller
                      name="project"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={projectList.filter(
                            proj => !proj.isSelectedProject && proj.pTeamId === selectedTeam?.value,
                          )}
                          isMulti
                          value={value}
                          onChange={selectedOptions => {
                            onChange(selectedOptions);
                            // if (selectedOptions && selectedOptions.length === 1) {
                            //   setCurrentValue(prevValue => prevValue + 1);
                            // }
                          }}
                        />
                      )}
                    />

                    {/* <ErrorMessage
                      errors={errors}
                      name="project"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    /> */}
                  </div>
                </div>
                <div className={classes.heading}>
                  <Typography variant="subtitle1">Select Members</Typography>
                  <div className="col-lg-8 d-flex flex-column invoice_label order-lg-1">
                    <Controller
                      name="members"
                      control={control}
                      // rules={{ required: 'This is required' }}
                      // defaultValue={details.CompanyName ? details.CompanyName : ''}

                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={filterMembers}
                          // value={companies.find(c => c.label == value)}
                          // isMulti
                          value={value}
                          onChange={e => {
                            onChange(e);
                            // setCurrentValue(prevValue => prevValue + 1);
                          }}
                        />
                      )}
                    />
                    {/* <ErrorMessage
                      errors={errors}
                      name="members"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    /> */}
                  </div>
                </div>
                <div style={{ fontSize: '1.2rem', color: 'black', marginBottom: '1rem' }}>Due Date</div>
                <div className="row no-gutters">
                  <div className="col-lg-5 d-flex flex-column invoice_label order-lg-1">
                    {/* <br /> */}
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.startDate" />
                    </InputLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      // rules={{ required: 'This is required' }}
                      // defaultValue={IssueDate ? IssueDate : ''}
                      render={({ field: { onChange, ...restField } }) => (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={open}
                            OpenDate={() => setOpen(true)}
                            CloseDate={() => setOpen(false)}
                            value={startDateFilter}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handlestartDateHandler}
                          />
                        </div>
                      )}
                    />
                    {/* <ErrorMessage
                      errors={errors}
                      name="startDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    /> */}
                  </div>

                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="col-lg-6 d-flex flex-column invoice_label  order-lg-1">
                    {/* <br /> */}
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.endDate" />
                    </InputLabel>
                    {/* {details.length === 0 ? ( */}

                    <Controller
                      name="endDate"
                      control={control}
                      // rules={{ required: 'This is required' }}
                      // defaultValue={DueDate ? DueDate : ''}
                      render={({ field }) => (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={endOpen}
                            OpenDate={() => setEndOpen(true)}
                            CloseDate={() => setEndOpen(false)}
                            value={endDateFilter}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handleDueDateHandler}
                          />
                        </div>
                      )}
                    />
                    {/* <ErrorMessage
                      errors={errors}
                      name="endDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    /> */}
                  </div>
                </div>
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // disabled={details.length === 0 ? false : true}
                >
                  <IntlMessages id="invoices.apply" />
                </Button> */}
              </form>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaskFilterDialog;
