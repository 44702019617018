import React, { useEffect, useState } from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Card, CardContent, Typography, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core';

// timeline imports
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Tooltip } from '@material-ui/core';
import { borderRadius, width } from '@mui/system';

//tabs
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import theme from '@amcharts/amcharts4/.internal/themes/animated';
import TimelineTaskHistory from './TimelineTaskHistory';
import TaskTimeHistory from './TaskTimeHistory';

const readMoreCardStyles = makeStyles(theme => ({
  actionIcon: {
    cursor: 'pointer',
  },
  avatar: {
    border: '1px solid rgba(0,0,0,0.175)',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
    },
  },
  root: {
    // width: '536px',
    // minHeight: '100px',
    padding: '3px 5px',
    fontFamily: 'Normal',
    // border: '1px solid rgba(0,0,0,0.125)',
    borderBottom: '1px solid rgba(0,0,0,0.225)',

    // borderRadius: '5px',
    marginTop: '3px',
    '& .readContent': {
      fontSize: '0.9rem',
      fontFamily: 'normal',
      wordBreak: 'break-all',
      width: '100%',
    },
    minHeight: '21px',
  },
}));
const timeLineStyle = makeStyles(theme => ({
  root: {
    flexDirection: 'column-reverse',
    paddingInline: 0,
    '&  .MuiTimelineItem-oppositeContent': {
      flex: '0.55',
    },
    '& .MuiTimelineContent': {
      padding: '6px 0 6px 16px',
    },
  },
}));
const commentPannel = makeStyles(theme => ({
  root: {
    '& .tabContent': {
      '& .MuiBox-root': {
        padding: 0,
      },
      height: '500px',
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
  },
}));
const tabNameStyle = makeStyles(theme => ({
  root: {
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'normal',
  },
}));

const ReadMore = ({ data, user, date }) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  return (
    <div style={{ paddingLeft: '10px', marginBottom: '20px' }}>
      {/* <Typography sx={{ fontFamily: 'normal', fontSize: '0.8rem' }}>{user}</Typography> */}
      <Typography sx={{ fontFamily: 'normal', fontSize: '0.8rem', padding: '3px 5px' }}>
        {date.substring(0, 10)} &emsp; {date.substring(11, 19)}
      </Typography>

      <div className={readMoreCardStyles().root}>
        {data && extractContent(data).length > 200 ? (
          <div>
            <Typography
              sx={{ display: 'inline', wordBreak: 'break-all' }}
              component="span"
              variant="body2"
              color="text.primary"
              className="commentSection readContent"
              dangerouslySetInnerHTML={{ __html: isReadMore ? data.slice(0, 150).trim() : data.trim() }}></Typography>
            <span onClick={toggleReadMore} className="read-or-hide">
              {isReadMore ? 'more' : 'less'}
            </span>
          </div>
        ) : (
          <Typography
            sx={{ display: 'inline', width: '100%', wordBreak: 'break-all' }}
            component="span"
            variant="body2"
            color="text.primary"
            className="commentSection readContent"
            dangerouslySetInnerHTML={{ __html: data }}></Typography>
        )}
        {/* {data && data.length >150 ?
           :''} */}
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ fontFamily: 'normal' }} component={'div'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
const TabWithCount = ({ children, count }) => {
  return (
    <Box sx={{ display: 'block', alignItems: 'center', textAlign: 'center', lineHeight: 'normal' }}>
      {count ? (
        <Typography component="div" variant="body2" sx={{ marginLeft: '0.5rem' }}>
          {count}
        </Typography>
      ) : null}
      <Typography component="div">{children}</Typography>
    </Box>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const tabHeaderSxProps = {
  ontSize: '0.8rem',
  fontFamily: 'normal',
  fontWeight: '600',
  textTransform: 'capitalize',
  fontSize: '14px',
};

const CommentDisplay = ({ commentsData, kanbanBoard, TeamId, selectedCard, projectList, members }) => {
  // const[comments,setComments] = useState([]);
  //  useEffect(()=>{
  //   console.log(commentsData)
  //   setComments(commentsData)
  //  },[commentsData])
  const [value, setValue] = useState(0);
  // const [taskHistory, setTaskHistory] = useState();

  // const TASK_HISTORY_URL = 'http://api.focus247.online/api/Task/GetTaskHistory';

  // useEffect(() => {
  //   loadTaskHistoryData();
  // }, []);

  // const loadTaskHistoryData = async () => {
  //   const response = await fetch(TASK_HISTORY_URL);
  //   const data = await response.json();
  //   setTaskHistory(data);
  // };
  // console.log(selectedCard, kanbanBoard, 'selectedCard');

  const teamData = kanbanBoard?.tmTeams;

  const filterTeam = teamData?.find(team => {
    return team?.TeamId === selectedCard?.tmTeamId;
  });

  const filterTeamMember = teamData?.find(member => {
    return member.TMUserId === selectedCard?.memberIds;
  });

  const filterProject = projectList.find(project => {
    return project?.value === selectedCard?.ProjectId;
  });

  // console.log(projectList, 'projects');

  // Example POST method implementation:
  // async function postData(url = '', data = {}) {
  //   const response = await fetch(url, {
  //     method: 'POST',
  //     mode: 'cors',
  //     cache: 'no-cache',
  //     credentials: 'same-origin',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     redirect: 'follow',
  //     referrerPolicy: 'no-referrer',
  //     body: JSON.stringify(data),
  //   });
  //   return response.json();
  // }

  // postData('http://api.focus247.online/api/Task/GetTaskHistory', { answer: 42 }).then(data => {
  //   console.log(data, 'task data'); // JSON data parsed by `data.json()` call
  // });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function stringAvatar(obj) {
    let children = '';
    if (obj && obj?.email) {
      let objId = obj.email;
      let img = members.filter(item => item.label === objId);
      if (img.length > 0 && img[0].image) {
        children = (
          <img
            src={`data:image/jpeg;base64,${img[0].image}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        );
      } else {
        let name = objId;
        children = `${name.charAt(0)}${name.charAt(1)}`;
      }
    }
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: children,
    };
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function removeHTMLtags(str) {
    const strippedStr = new DOMParser().parseFromString(str, 'text/html');
    return strippedStr.body.textContent || '';
  }

  return (
    <Box
      component={'div'}
      className={commentPannel().root}
      sx={{ width: '90%', marginInline: 'auto', border: 1, borderColor: 'divider', marginBottom: '1em' }}>
      <Box component={'div'} sx={{ border: 1, borderColor: 'divider' }}>
        <Tabs component={'div'} value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <div>
                {commentsData.length}
                <br></br>Comments
              </div>
            }
            sx={{ ...tabHeaderSxProps }}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div>
                <br></br>Task History
              </div>
            }
            sx={{ ...tabHeaderSxProps }}
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div>
                <br></br>Task Time History
              </div>
            }
            sx={{ ...tabHeaderSxProps }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel className="tabContent" value={value} index={0}>
        <Card component={'div'} sx={{ minWidth: 275, width: '100%' }} style={{ boxShadow: 'none' }}>
          <CardContent component={'div'}>
            <Timeline
              position="right"
              className={timeLineStyle().root}
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
              style={{ display: 'flex', flexDirection: 'column-reverse', paddingRight: 0 }}>
              {commentsData &&
                commentsData.map((comment, index) => {
                  return (
                    <TimelineItem key={index}>
                      {/* <TimelineOppositeContent sx={{ py: '12px', px: 1 }}>
                      <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.75rem' }}>
                        Delivered
                      </Typography>
                      <br />
                      <Typography fontFamily="normal" component="span" sx={{ fontSize: '0.85rem' }}>
                        {item.TimeStart.substring(0, 10)} - {item.TimeStart.substring(11)}
                      </Typography>
                    </TimelineOppositeContent> */}
                      <TimelineSeparator>
                        {/* <TimelineConnector /> */}
                        <TimelineDot className="avatarCommentSection">
                          <Tooltip title={comment?.user?.email}>
                            <Avatar {...stringAvatar(comment?.user)} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </TimelineDot>
                        {index != 0 && <TimelineConnector />}
                      </TimelineSeparator>
                      {/* <TimelineConnector/> */}
                      <TimelineContent sx={{ py: '12px', px: 1 }}>
                        <ReadMore data={comment?.comment} user={comment?.user?.email} date={comment?.createdAt} />
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
            </Timeline>
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel className="tabContent" value={value} index={1}>
        <Card sx={{ minWidth: 275, width: '100%' }} style={{ boxShadow: 'none' }}>
          <CardContent component="div">
            <TimelineTaskHistory selectedCard={selectedCard} />
          </CardContent>
        </Card>
      </TabPanel>
      <TabPanel className="tabContent" value={value} index={2}>
        <Card sx={{ minWidth: 275, width: '100%' }} style={{ boxShadow: 'none' }}>
          <CardContent component="div">
            <TaskTimeHistory selectedCard={selectedCard} />
          </CardContent>
        </Card>
      </TabPanel>
    </Box>
  );
};

export { CommentDisplay, timeLineStyle, ReadMore, readMoreCardStyles };
