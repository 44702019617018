import Box from '@mui/material/Box';

// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import React, { useState, useRef, useEffect } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import Button from '@material-ui/core/Button';

import 'react-image-crop/dist/ReactCrop.css';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Cropper({ open, setOpen, onFileSelect, setSelectedFile, currProfileData, setCurrProfileData }) {
  const handleOpen = () => setOpen(true);
  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);

  // Assume you have an image byte array in a variable, let's call it 'imageData'
  //data:image/jpeg;base64,
  const fileInput = document.getElementById('file');

  const handleFileInput = () => {
    if (fileInput) {
      fileInput.click();
    }
  };
  useEffect(() => {
    handleFileInput();
  }, [fileInput]);

  // if (fileInput) {
  //   fileInput.click();
  // }
  const imageData = currProfileData?.AvatarImage;

  // Create a Blob from the byte array
  const blob = new Blob([imageData]);

  // Create a File object from the Blob
  const file = new File([blob], 'imageFileName.jpg', { type: 'image/jpeg' });

  // 'imageFileName.jpg' is the name you want to give to the file, and 'image/jpeg' is the MIME type of the image.
  function byteArrayToFile(byteArray, fileName, mimeType) {
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  }

  // Usage:

  const fileName = 'myImage.jpg';
  const mimeType = 'image/jpeg';

  const imageFile = byteArrayToFile(imageData, fileName, mimeType);

  const handleClose = () => {
    if (completedCrop) {
      const canvas = previewCanvasRef.current;

      if (canvas) {
        // Get the cropped image data from the canvas
        const croppedImageData = canvas.toDataURL('image/jpeg'); // You can change the format as needed

        // Update currProfileData.AvatarImage with the cropped image data
        let newData = { ...currProfileData };
        newData['AvatarImage'] = croppedImageData;
        setCurrProfileData(newData);

        // If you want to create a File object from the cropped image data
        const imageArrayBuffer = new Uint8Array(
          atob(croppedImageData.split(',')[1])
            .split('')
            .map(char => char.charCodeAt(0)),
        );
        const fileName = 'croppedImage.jpg';
        const mimeType = 'image/jpeg';
        const croppedImageFile = new File([imageArrayBuffer], fileName, { type: mimeType });
        setSelectedFile(croppedImageFile);

        // Optionally, revoke any previous Object URL if needed
        if (blobUrlRef.current) {
          URL.revokeObjectURL(blobUrlRef.current);
        }
      }
    }

    setOpen(false);
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result || ''));
      onFileSelect(e.target.files[0]);
      //   setSelectedFile(e.target.files[0]);
      if (e.target.files) {
        let render = new FileReader();
        render.onload = function(event) {
          let newData = currProfileData;
          newData['AvatarImage'] = event.target.result;
          setCurrProfileData({ ...newData });
        };
        render.readAsDataURL(e.target.files[0]);
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    previewCanvasRef.current.toBlob(blob => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      hiddenAnchorRef.current.href = blobUrlRef.current;
      hiddenAnchorRef.current.click();
    });
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      const newCrop = centerAspectCrop(width, height, 16 / 9);
      setCrop(newCrop);
      setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="App">
            <div className="Crop-Controls">
              <Button style={{ marginBottom: '10px', textTransform: 'none' }} variant="contained" color="primary">
                <label htmlFor="file" style={{ margin: '0px' }}>
                  Upload photo
                </label>
              </Button>
              <input
                type="file"
                accept="image/*"
                id="file"
                name="file"
                onChange={onSelectFile}
                style={{ display: 'none' }}
              />

              {/* <div>
                <label htmlFor="scale-input">Scale: </label>
                <input
                  id="scale-input"
                  type="number"
                  step="0.1"
                  value={scale}
                  disabled={!imgSrc}
                  onChange={e => setScale(Number(e.target.value))}
                />
              </div>
              <div>
                <label htmlFor="rotate-input">Rotate: </label>
                <input
                  id="rotate-input"
                  type="number"
                  value={rotate}
                  disabled={!imgSrc}
                  onChange={e => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
                />
              </div>
              <div>
                <button onClick={handleToggleAspectClick}>Toggle aspect {aspect ? 'off' : 'on'}</button>
              </div> */}
            </div>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}>
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
                <div>
                  {/* <button onClick={handleClose}>crop photo</button> */}
                  <Button style={{ textTransform: 'none' }} variant="contained" color="primary" onClick={handleClose}>
                    Crop photo
                  </Button>
                  <a
                    ref={hiddenAnchorRef}
                    download
                    style={{
                      position: 'absolute',
                      top: '-200vh',
                      visibility: 'hidden',
                    }}>
                    Hidden download
                  </a>
                </div>
              </>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
