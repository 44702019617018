import React, { useEffect, useRef, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Tooltip, Typography, Card, CardContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import TextField from '@mui/material/TextField';

//api
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../constants/ActionTypes';
import customAxios from '../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Avatr
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import CmtAvatar from '@coremat/CmtAvatar';
import { type } from '@amcharts/amcharts4/core';
import Cropper from './Cropper';
import ImageViewerModal from './ImageViewerModal';

let InputStyles = makeStyles(theme => ({
  root: {
    '& .Mui-disabled': {
      ' -webkit-text-fill-color': 'rgba(0, 0, 0, 0.65) !important',
    },
  },
}));

const UserProfile = ({ profileOpenState, closeProfileDialog, getProfileData, data, setData }) => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const profileRef = useRef(null);
  const [currProfileData, setCurrProfileData] = useState({
    AvatarImage: null,
    CompanyId: null,
    ContactNumber: null,
    EmailAddress: null,
    FirstName: null,
    Id: null,
    LastName: null,
    UserId: null,
  });

  const [openCropModal, setOpenCropModal] = React.useState(false);

  const handleChange = e => {
    let fieldName = e.target.id;
    let fieldValue = e.target.value;
    // console.log(fieldValue)
    let newData = currProfileData;
    newData[fieldName] = fieldValue.trim();
    setCurrProfileData({ ...newData });
  };

  let textFieldData = [
    {
      shrink: true,
      value: currProfileData.FirstName,
      label: 'First Name',
      id: 'FirstName',
      size: 'small',
      sx: { width: '350px' },
      type: 'text',
      disable: false,
      change: handleChange,
    },
    {
      shrink: true,
      value: currProfileData.LastName,
      label: 'Last Name',
      id: 'LastName',
      size: 'small',
      sx: { width: '350px' },
      type: 'text',
      disable: false,
      change: handleChange,
    },
    {
      shrink: true,
      value: currProfileData.ContactNumber,
      label: 'Contact Number',
      id: 'ContactNumber',
      size: 'small',
      sx: { width: '350px' },
      type: 'number',
      disable: false,
      change: handleChange,
    },
    {
      shrink: true,
      value: currProfileData.EmailAddress,
      label: 'Email',
      id: 'EmailAddress',
      size: 'small',
      sx: { width: '350px' },
      type: 'text',
      disable: true,
      change: handleChange,
    },
  ];

  const handleClose = () => {
    closeProfileDialog();
  };
  useEffect(() => {
    if (profileOpenState) {
      setCurrProfileData({ ...data });
    }
  }, [profileOpenState]);

  //get user profile Data

  function stringAvatar(name) {
    return name
      ? {
          // sx: {
          //   bgcolor: stringToColor(name),
          // },

          children: `${name.charAt(0)}${name.charAt(1)}`,
        }
      : null;
  }

  const chooseAvatarTrigger = () => {
    profileRef.current.click();
  };
  const handelAvatarChange = e => {
    if (e.target.files) {
      let render = new FileReader();
      render.onload = function(event) {
        let newData = currProfileData;
        newData['AvatarImage'] = event.target.result;
        setCurrProfileData({ ...newData });
      };
      render.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSave = () => {
    // if(data.AvatarImage != currProfileData.AvatarImage){
    //   EditAvatar();
    // }
    // console.log(currProfileData)
    // console.log(data)

    if (
      currProfileData.FirstName === data.FirstName &&
      currProfileData.LastName === data.LastName &&
      currProfileData.ContactNumber == data.ContactNumber
    ) {
      updateProfilePic();

      return;
    }

    let updatedData = {
      FirstName: currProfileData.FirstName,
      LastName: currProfileData.LastName,
      ContactNumber: currProfileData.ContactNumber,
      AppNotifyTM: true,
      AppNotifyCRM: true,
      AppNotifyAcct: true,
      EmailNotifyTM: true,
      EmailNotifyCRM: true,
      EmailNotifyAcct: true,
    };

    dispatch({ type: FETCH_START });

    customAxios
      .post('Users/EditUserProfile', updatedData, { headers: authHeader() })
      .then(({ data }) => {
        updateProfilePic();
        closeProfileDialog();
        getProfileData();

        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };

  const handleFileSelect = file => {
    let newData = currProfileData;
    newData['AvatarImage'] = URL.createObjectURL(file);
    setCurrProfileData({ ...newData });
  };

  const updateProfilePic = () => {
    if (currProfileData.AvatarImage == data.AvatarImage) return;
    let Imgfiles = document.querySelector('#AvatarImage');
    let formData = new FormData();

    if (selectedFile) {
      const file = new File([selectedFile], selectedFile.name, { type: selectedFile.type });
      formData.append('file', file);
    }

    // formData.append('file', profileRef.current.target.files[0]);

    // dispatch({ type: FETCH_START });

    customAxios
      .post('Users/EditUserAvatar', formData, { headers: authHeader() })
      .then(({ data }) => {
        closeProfileDialog();
        getProfileData();
        // dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        // console.log('in catch')
        getProfileData();
        closeProfileDialog();
        // if (error.response.status == '400') {
        //   alert(error.response.data.title);
        //   var errorList = error.response.data.errors;
        //   if (errorList) {
        //     //iterate through errors {} and add key values
        //     // Object.keys(errorList).forEach((key, index) => {
        //     //   setError('apiError', {
        //     //     message: errorList[key],
        //     //   });
        //     // });
        //   }
        // }
        // dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const removeAvatar = () => {
    let oldData = currProfileData;
    oldData['AvatarImage'] = null;
    setCurrProfileData({ ...oldData });
  };

  const [viewPhotoModalOpen, setViewPhotoModalOpen] = useState(false);
  const [viewPhotoUrl, setViewPhotoUrl] = useState('');

  const handleViewPhoto = () => {
    setViewPhotoUrl(currProfileData.AvatarImage);
    setViewPhotoModalOpen(true);
  };

  return (
    <div>
      <Dialog
        // fullWidth={fullWidth}

        maxWidth="lg"
        open={profileOpenState}
        onClose={handleClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0,0,0,0.250)' }}>
          <div className="w-100 d-flex justify-content-between ">
            <div>Profile</div>
            <div className="d-flex" style={{ gap: '2rem' }}>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseRoundedIcon />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <Card style={{ marginBottom: '1rem', border: 'none', boxShadow: 'none', paddingInline: '0' }}>
            <CardContent component="div" style={{ padding: '0 20px', width: '800px', minHeight: '500px' }}>
              <div
                style={{ padding: '20px 20px 10px 20px', gap: '20px' }}
                className="d-flex justify-content-start align-items-center profileAvatar">
                <TextField
                  type="file"
                  inputRef={profileRef}
                  id="AvatarImage"
                  name="AvatarImage"
                  sx={{ display: 'none' }}
                  onChange={handelAvatarChange}
                />
                <CmtAvatar
                  alt="User Avatar"
                  {...stringAvatar(currProfileData.EmailAddress)}
                  src={currProfileData.AvatarImage}
                  size={80}
                />
                <div className="d-flex flex-column ">
                  <div className="d-flex align-items-center" style={{ gap: '10px', cursor: 'pointer' }}>
                    {currProfileData.AvatarImage && (
                      <Typography sx={{ fontFamily: 'normal' }} color="#3f51b5" onClick={handleViewPhoto}>
                        View photo
                      </Typography>
                    )}
                    <Typography
                      sx={{ fontFamily: 'normal' }}
                      // onClick={chooseAvatarTrigger}
                      onClick={() => setOpenCropModal(true)}
                      color="#3f51b5">
                      Upload new photo
                    </Typography>
                    <Typography sx={{ fontFamily: 'normal' }} color="#3f51b5" onClick={removeAvatar}>
                      Remove photo
                    </Typography>
                  </div>
                  <div>this photo will be visible to all your contacts</div>
                </div>
              </div>
              <ImageViewerModal
                open={viewPhotoModalOpen}
                onClose={() => setViewPhotoModalOpen(false)}
                imageUrl={viewPhotoUrl}
              />
              <Cropper
                open={openCropModal}
                setCurrProfileData={setCurrProfileData}
                setOpen={setOpenCropModal}
                currProfileData={currProfileData}
                onFileSelect={handleFileSelect}
                setSelectedFile={setSelectedFile}
              />
              <div
                style={{ padding: '20px 20px 10px 20px', gap: '20px' }}
                className="d-flex justify-content-start align-items-center flex-wrap  profileInfo">
                {textFieldData.map((item, index) => {
                  return (
                    <TextField
                      disabled={item.disable}
                      key={index}
                      InputLabelProps={{ shrink: item.shrink }}
                      value={item.value}
                      // defaultValue = {item.value ? item.value:""}
                      label={item.label}
                      id={item.id}
                      size={item.size}
                      sx={item.sx}
                      onChange={handleChange}
                      type={item.type}
                      className={InputStyles().root}
                    />
                  );
                })}
              </div>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserProfile;
