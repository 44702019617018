import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, TextField, Tooltip } from '@material-ui/core';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import Popover from '@mui/material/Popover';
import { data } from 'jquery';
// import { getTooltipText } from '@syncfusion/ej2-react-richtexteditor';

const avatarStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontSize: '16px',
    alignItems: 'center',
  },
  avatar: {
    background: '#979090',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: '16px',
    color: 'white',
    /* margin: auto; */
    // marginLeft: '20px',
  },
}));

const SalesRepView = ({ data, tmembers, getContactHandler, sendEmailNotification }) => {
  const [currSalesRep, setCurrSalesRep] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disableScrollLock, setDisableScrollLock] = React.useState(true);
  const dispatch = useDispatch();
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    setCurrSalesRep(data.crmSalesRep);
  }, [tmembers, data]);
  const AvatarStyles = avatarStyles();
  const returnAvatarName = memberId => {
    const teamMemberObj = tmembers.filter(c => c.crmUserId == memberId);
    if (teamMemberObj.length > 0) {
      return teamMemberObj[0].Email;
    }
    return 'None';
  };
  const [openTeam, setOpenTeam] = useState(false);

  const setPopupData = event => {
    setOpenTeam(true);
  };
  const getTooltipText = () => {
    let value = 'No Sales Representative';
    let opValue = tmembers.filter(item => item.value == data.crmSalesRep);
    return opValue.length > 0 ? opValue[0].Email : value;
  };
  const customDropdownStyles = {
    menuList: () => ({
      overflow: 'hidden',
    }),
  };
  const classes = useRowStyles();
  const handleClick = () => {
    setDisableScrollLock(false);
  };
  const handleMenuClose = () => {
    setDisableScrollLock(true);
    setAnchorEl(null);
  };
  const setSalesRep = args => {
    let customer = data;
    handleMenuClose();
    let dataObj = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'crmSalesRep',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${customer.crmId}`,
        crmTeamId: customer.CrmTeamId,
      },
    ];
    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', dataObj, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });

        getContactHandler(customer.crmId);
        let notificationMessage = `You are assigned as the Sales Rep of "${customer.crmContactFirst} ${customer.crmContactLast}" `;
        sendNotification(notificationMessage, args.value, customer.CrmTeamId);
        sendEmailNotification([args.label], notificationMessage);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  const sendNotification = (message, toUser, teamID) => {
    let notification = {
      UserIdTo: toUser,
      ActivityId: null,
      NotifyMessage: message,
      FunctionalityId: null,
      TeamId: teamID,
      IsClicked: 0,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Notifications/AddNotification', notification, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  return (
    <div className={AvatarStyles.root}>
      <Tooltip title={getTooltipText()} arrow>
        <p
          className={AvatarStyles.avatar}
          onClick={event => {
            event.persist();
            event.ignore = true;
            setAnchorEl(event.target);
            setPopupData(event);
          }}>
          {returnAvatarName(currSalesRep).substring(0, 2)}
        </p>
      </Tooltip>

      <Popover
        id="long-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        disableScrollLock={disableScrollLock}
        style={{ height: '200px' }}
        className={classes.popUpConatnier}
        transformOrigin={{
          vertical: 'top',
          horizontal: -35,
        }}
        PaperProps={{
          style: {
            width: '17ch',
            boxShadow: 'none',
            background: 'none',
            height: '500px',
            overflow: 'hidden',
          },
        }}>
        <div style={{ height: '100%' }}>
          <Select
            placeholder={'Select Rep..'}
            maxMenuHeight="138px"
            options={tmembers.filter(c => c.value != data.crmSalesRep)}
            value={tmembers.find(c => c.value == data.crmSalesRep)}
            onChange={e => setSalesRep(e)}
          />
        </div>
      </Popover>
    </div>
  );
};
const ITEM_HEIGHT = 48;
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  outlookImg: {
    marginRight: '10px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
  popUpConatnier: {
    paddingRight: 0,
    marginRight: 0,
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: 'inherit',
    },
    '& .MuiMenuItem-root': {
      borderRadius: 'inherit',
      border: '1px solid rgba(0,0,0,0.225)',
    },
  },
});
const TeamMembersMenu = ({ tmembers, popupData, getContactHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disableScrollLock, setDisableScrollLock] = React.useState(true);
  const [openSelect, setOpenSelect] = useState(false);
  const dispatch = useDispatch();
  const menuOpen = Boolean(anchorEl);

  const handleClick = () => {
    setOpenSelect(true);
    setAnchorEl(popupData.info);
    setDisableScrollLock(false);
  };
  const handleMenuClose = () => {
    console.log('menu close');
    setOpenSelect(false);
    setDisableScrollLock(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (popupData) handleClick();
  }, [popupData, tmembers]);

  const classes = useRowStyles();

  const setSalesRep = args => {
    let customer = popupData.data;

    let data = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'crmSalesRep',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${customer.crmId}`,
        crmTeamId: customer.CrmTeamId,
      },
    ];
    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', data, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });

        getContactHandler(customer.crmId);
        sendNotification(
          `You are assigned as the Sales Rep of " ${customer.crmContactFirst} ${customer.crmContactLast} " `,
          args.value,
          customer.CrmTeamId,
        );
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  const sendNotification = (message, toUser, teamID) => {
    let notification = {
      UserIdTo: toUser,
      ActivityId: null,
      NotifyMessage: message,
      FunctionalityId: null,
      TeamId: teamID,
      IsClicked: 0,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Notifications/AddNotification', notification, { headers: authHeader() })
      .then(e => {
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const customDropdownStyles = {
    menuList: () => ({
      overflow: 'hidden',
    }),
  };
  return (
    <React.Fragment>
      <Popover
        id="long-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        onBackdropClick={() => setOpenSelect(false)}
        disableScrollLock={disableScrollLock}
        style={{ height: '200px' }}
        className={classes.popUpConatnier}
        transformOrigin={{
          vertical: -38,
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '18ch',
            boxShadow: 'none',
            background: 'none',
            height: '500px',
            overflow: 'hidden',
          },
        }}>
        {/* {tmembers &&
          tmembers.map((item, index) => {
            let diff = false;
            if (popupData && popupData.data) {
              if (item.crmUserId == popupData.data.crmSalesRep) {
                diff = true;
              }laneId
            }
            if (!diff) {
              return (
                <MenuItem
                  key={index}
                  onClick={event => {
                    // event.persist();
                    // event.ignore = true;
                    setAnchorEl(null);
                    setSalesRep(item);
                  }}>
                  {item.Email}
                </MenuItem>
              );
            }
          })} */}

        {/* <div style={{height:'100%' }} >
          {
            anchorEl && <Select
            menuIsOpen={openSelect}
             placeholder={'Select Rep..'}
             maxMenuHeight='138px'
             
             
             options={tmembers}
             value={tmembers.find(c => c.value == ( (popupData && popupData.data) ? popupData.data.crmSalesRep : null))}
             onChange={(e)=>console.log(e)} />
          }
        
          </div> */}
      </Popover>
    </React.Fragment>
  );
};
export { SalesRepView, TeamMembersMenu };
