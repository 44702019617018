import { Button, TextField, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import IntlMessages from 'routes/Pages/util/IntlMessages';
import ClearIcon from '@material-ui/icons/Clear';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import OverrideFileModal from './OverrideFileModal';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const FormModal = ({ formModal, setFormModal, toggle, getTeamReports, accTeam, handleClick }) => {
  const { control, handleSubmit, setError, reset } = useForm({});
  const [file, setFile] = useState(null);
  const [openOverrideFileModal, setOpenOverrideFileModal] = useState(false);
  const [isOverride, setIsOverride] = useState(false);
  const [uploadingLoader, setUploadingLoader] = useState(false);

  const handleOpenOverrideFileModal = () => {
    setOpenOverrideFileModal(true);
    setIsOverride(true);
  };

  useEffect(() => {
    setIsOverride(false);
  }, [formModal]);

  const dispatch = useDispatch();
  const onSubmit = async data => {
    setUploadingLoader(true);
    if (!file) {
      setError('file', { type: 'required', message: 'File is required' });
      setUploadingLoader(false);
      return;
    }
    const formData = new FormData();
    formData.append('OverwriteFlag', isOverride);
    formData.append('DisplayName', data.fileName);
    formData.append('TeamId', accTeam);
    formData.append('file', file);
    dispatch({ type: FETCH_START });
    console.log(formData, data, 'Data of Form');
    try {
      const response = await customAxios.post('EmbeddedAnalytics/UploadPowerBIReport', formData, {
        headers: authHeader(),
      });
      setUploadingLoader(false);
      console.log('File uploaded successfully:', response.data);
      setFile(null);
      setError('fileName', ''); // Clear any previous error
      reset({ fileName: '' }); // Reset the value
      handleClick();
      setFormModal(false);
      getTeamReports();
      setIsOverride(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      if (error.response) {
        console.log('Error response from the server:', error.response.data);
        setUploadingLoader(false);
        handleOpenOverrideFileModal();
      } else if (error.request) {
        console.log('No response received:', error.request);
        setUploadingLoader(false);
        setFile(null);
        setFormModal(false);
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      } else {
        console.log('Error details:', error.message);
        setFile(null);
        setUploadingLoader(false);
        setFormModal(false);
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
    }
  };

  const handleRemoveFile = () => {
    setIsOverride(false);
    setFile(null);
  };

  return (
    <React.Fragment>
      <Modal className="modal-box" size="lg" isOpen={formModal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="d-flex">
          <IntlMessages id="modal.pages.uploadpbixFile" />
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggle}
            style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>
        <form
          name="myForm"
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
          <ModalBody>
            <div className="modal-box-content">
              <div className="row no-gutters">
                <div className="col-lg-5 order-lg-1">
                  <input
                    id="file"
                    type="file"
                    name="file"
                    style={{ display: 'none' }}
                    onChange={e => setFile(e.target.files[0])}
                  />
                  {file ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '10px' }}>{file.name}</span>
                      <IconButton color="secondary" size="small" onClick={handleRemoveFile}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <Button style={{ marginBottom: '20px', textTransform: 'none' }} variant="contained" color="primary">
                      <label htmlFor="file" style={{ margin: '0px' }}>
                        Browse .pbix File
                      </label>
                    </Button>
                  )}
                  <Controller
                    name="fileName"
                    control={control}
                    rules={{ required: 'Display Name is required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        fullWidth
                        type="text"
                        placeholder={'File Name'}
                        value={field.value || ''}
                        onChange={e => field.onChange(e.target.value)}
                        error={fieldState.invalid}
                        helperText={fieldState.invalid ? fieldState?.error?.message : ''}
                      />
                    )}
                  />
                  {/* <OverrideFileModal
                    onSubmit={onSubmit}
                    open={openOverrideFileModal}
                    handleOpenOverrideFileModal={handleOpenOverrideFileModal}
                    setIsOverride={setIsOverride}
                    setOpen={setOpenOverrideFileModal}
                  /> */}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" style={{ textTransform: 'none', width: 180 }} variant="contained">
              {uploadingLoader ? <CircularProgress size={27} /> : <>{isOverride ? 'Overwrite the file' : 'Submit'}</>}
            </Button>

            <Button
              style={{ textTransform: 'none' }}
              onClick={() => {
                toggle();
                setIsOverride(false);
                setError('fileName', '');
                reset({ fileName: '' });
              }}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
        {isOverride && (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="warning">File is Already Exist!</Alert>
          </Stack>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default FormModal;
