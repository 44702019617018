import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AlertDialogForArchive({ open, setOpen, details, handleArchive }) {
  const dispatch = useDispatch();

  // const handleArchive = () => {
  //   const reqbody = {
  //     TeamId: details.pTeamId,
  //     ProjectId: details.pId,
  //     ArchiveFlag: details.ArchiveFlag === 0 ? 1 : 0,
  //   };

  //   dispatch({ type: FETCH_START });
  //   customAxios
  //     .post('Project/ModifyProjectArchiveFlag', reqbody, { headers: authHeader() })
  //     .then(({ data }) => {
  //       dispatch({ type: FETCH_SUCCESS });

  //       getTeamProjectByTeam();
  //       setOpen(false); // Close dialog after success
  //     })
  //     .catch(error => {
  //       dispatch({ type: FETCH_ERROR, payload: error.message });
  //     });
  // };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Requires confirmation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={theme => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to {details?.ArchiveFlag === 0 ? 'archive' : 'unarchive'} the project?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={handleArchive}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
