import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const FileToBase64Converter = ({ files, setFiles }) => {
  const handleFileInputChange = event => {
    const newFiles = event.target.files;

    if (newFiles && newFiles.length > 0) {
      setFiles(prevFiles => [
        ...prevFiles,
        ...Array.from(newFiles).map(file => ({
          id: Date.now() + file.name, // Generate a unique ID
          name: file.name,
          file,
        })),
      ]);
    }
  };

  const handleDelete = id => {
    setFiles(prevFiles => prevFiles.filter(file => file.id !== id));
  };

  return (
    <div>
      <div>
        <InputLabel htmlFor="attachmentsInput">Attachments</InputLabel>
        <TextField type="file" sx={{ width: '100%' }} name="attachmentsInput" onChange={handleFileInputChange} multiple />
      </div>

      <div className="d-flex mt-3" style={{ gap: '5px' }}>
        {files.map(file => (
          <div key={file.id}>
            <Stack direction="row" spacing={3}>
              <Chip label={file.name} variant="outlined" onDelete={() => handleDelete(file.id)} />
            </Stack>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileToBase64Converter;
