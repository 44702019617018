/**
 * Version 2.0
 * Vikas W
 * 28.07.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IntlMessages from '../../util/IntlMessages';
import axios from 'axios';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
}));

const CreateExpense = ({
  modal,
  details,
  toggle,
  onClientsClose,
  clients,
  editData,
  getId,
  customData,
  addNewExpense,
  handleMenuClose,
  setOpenActionModal,
  setIsSalesModal,
  isBankStatement,
  accTeamId,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expenseForm, setExpenseForm] = useState(false);
  const [ocrForm, setOcrForm] = useState(true);
  const [ReceiptAmount, setReceiptAmount] = useState('');
  const [ReceiptDate, setReceiptDate] = useState(new Date());
  const [uploadedFiles, setUploadedFiles] = useState();
  const [displayImage, setDisplayImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  const fileInput = document.getElementById('file');

  // const handleFileInput = () => {
  //   console.log('getting');
  //   if (fileInput) {
  //     console.log('success');
  //     fileInput.click();
  //   }
  // };
  // useEffect(() => {
  //   console.log('waiting');
  //   handleFileInput();
  // }, [fileInput]);

  const uploadImage = async imageUrl => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const file = new File([blob], editData?.ImgName, { type: response.headers['content-type'] });
      const formData = new FormData();
      formData.append('file', file);
      setUploadedFiles(file);

      let postData = [];
      let url_2 = '/Expense/OCR';
      postData = formData;
      dispatch({ type: FETCH_START });
      try {
        const { data } = await customAxios.post(url_2, postData, { headers: authHeader() });
        dispatch({ type: FETCH_SUCCESS });

        if (data.IsSuccess) {
          setReceiptAmount(data.ReceiptAmount);
          setReceiptDate(new Date(data.ReceiptDate));
          setOcrForm(false);
          setExpenseForm(true);
        }
      } catch (error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
    } catch (error_1) {
      console.error('Error uploading image:', error_1);
    }
  };
  const [imageUrl, setImageUrl] = useState(editData?.ImgData);

  // let imageUrl = editData?.ImgData;

  const handleUpload = () => {
    setUploadStatus('Uploading...');
    uploadImage(`data:image/png;base64,${imageUrl}`)
      .then(() => {
        setUploadStatus('Upload successful');
        setDisplayImage(imageUrl);
      })
      .catch(() => {
        setUploadStatus('Upload failed');
      });
  };

  /**
   * This function is called after submiting the form
   *
   * @param formData
   */

  const onOCRSubmit = (data, event, string) => {
    const formData = new FormData();
    const imagefile = document.querySelector('#file');
    const fileImage = event.target.files[0];
    if (fileImage) {
      setDisplayImage(URL.createObjectURL(fileImage));
    }
    if (imagefile.files.length > 0) {
      formData.append('file', imagefile.files[0]);
      setUploadedFiles(imagefile.files[0]);
    }
    let postData = [];
    let url = '/Expense/OCR';
    postData = formData;
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });

        if (data.IsSuccess) {
          setReceiptAmount(data.ReceiptAmount);
          setReceiptDate(new Date(data.ReceiptDate));
          setOcrForm(false);
          setExpenseForm(true);
          setImageUrl(null);
        }
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const onSubmit = data => {
    const formData = new FormData();
    const imagefile = document.querySelector('#file');

    const vatAmount = parseFloat(data.VatAmount);
    console.log(vatAmount, data, 'vatAmount');

    if (uploadedFiles) {
      formData.append('file', uploadedFiles);
    }
    formData.append('ExpenseDescription', data['ExpenseDescription']);
    formData.append('ExpenseType', data['ExpenseType']);
    formData.append('ExpenseDateTime', data['ExpenseDateTime']);
    formData.append('ExpenseAmount', data['ExpenseAmount']);
    formData.append('AcctTeamId', accTeamId);
    formData.append('VatRate', parseFloat(data['VatRate']) || 0); // Parse as float, or use 0 if null
    formData.append('VatAmount', parseFloat(data['VatAmount']) || 0); // Parse as float, or use 0 if null

    console.log('VatAmount', parseFloat(data['VatAmount']));

    let postData = [];
    let url = '/Expense/AddExpense';
    if (details.edit) {
      url = '/Expense/EditExpense';
      formData.append('Id', details.Id);
      // formData['Id'] = details.Id;
      postData = formData;
    } else {
      postData = formData;
    }
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(data => {
        setOcrForm(true);
        setExpenseForm(false);
        dispatch({ type: FETCH_SUCCESS });
        toggle();
        onClientsClose();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    if (isBankStatement) {
      handleMenuClose();
      setOpenActionModal(true);
      setIsSalesModal(false);
    }
  };

  const isPdf = uploadedFiles?.name?.endsWith('pdf');

  return (
    <Modal className="modal-box" size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        {addNewExpense ? <IntlMessages id="modal.pages.addExpense" /> : <IntlMessages id="modal.pages.editExpense" />}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      {ocrForm && (
        <form
          onSubmit={handleSubmit(onOCRSubmit)}
          style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
          <ModalBody>
            {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
            <div className="modal-box-content">
              <div className="row no-gutters">
                <div className="col-lg-6 d-flex flex-column order-lg-1 mt-3">
                  {!addNewExpense && (
                    <div className="mb-3" style={{ width: '500px' }}>
                      {editData.ImgName?.endsWith('pdf') ? (
                        <embed
                          src={`data:application/pdf;base64,${imageUrl}`}
                          style={{ width: '100%', height: '400px' }}
                          type="application/pdf"
                        />
                      ) : (
                        <img src={`data:image/png;base64,${imageUrl}`} alt="Image preview" />
                      )}
                      {uploadStatus && <p>{uploadStatus}</p>}
                      <Button
                        onClick={handleUpload}
                        style={{ textTransform: 'none', padding: '9px 18px', marginTop: '10px', width: '250px' }}
                        size="small"
                        variant="contained"
                        color="primary">
                        Continue With Existing File
                      </Button>
                    </div>
                  )}

                  <div className="col-lg-6 d-flex flex-column order-lg-1 mt-1 mx-0 px-0">
                    <input
                      id="file"
                      type="file"
                      onChange={handleSubmit(onOCRSubmit)}
                      name="file"
                      style={{ display: 'none' }}
                    />
                    <Button
                      style={{ textTransform: 'none', padding: '9px 18px', width: `${!addNewExpense && '250px'}` }}
                      variant="contained"
                      color="primary">
                      <label htmlFor="file" style={{ margin: '0px' }}>
                        Upload New File
                      </label>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" style={{ textTransform: 'none' }} variant="contained" disabled>
              {addNewExpense ? <IntlMessages id="modal.pages.addExpense" /> : <IntlMessages id="modal.pages.editExpense" />}
            </Button>
            <Button style={{ textTransform: 'none' }} onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      )}

      {expenseForm && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
          <ModalBody>
            {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
            <div className="modal-box-content">
              <div className="d-flex" style={{ gap: '20px' }}>
                <div>
                  {displayImage && isPdf ? (
                    <div style={{ width: '400px' }}>
                      {/* <a target="_blank" rel="noreferrer" href={displayImage}>
                        Open PDF Document
                      </a> */}
                      <embed
                        src={imageUrl ? `data:image/png;base64,${imageUrl}` : displayImage}
                        style={{ width: '100%', height: '300px' }}
                        type="application/pdf"
                      />
                    </div>
                  ) : (
                    <img src={imageUrl ? `data:image/png;base64,${imageUrl}` : displayImage} alt="uploaded file" />
                  )}
                </div>
                <div className="">
                  <div className="d-flex flex-column order-lg-1 mx-3" style={{ width: '300px' }}>
                    <Controller
                      name="ExpenseDescription"
                      control={control}
                      defaultValue={details.ExpenseDescription ? details.ExpenseDescription : ''}
                      rules={{ required: 'This is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={<IntlMessages id="appModule.placeholder.ExpenseDescription" />}
                          margin="none"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="ExpenseDescription"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>
                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="d-flex flex-column order-lg-1 mx-3">
                    <Controller
                      name="ExpenseType"
                      control={control}
                      defaultValue={details.ExpenseType ? details.ExpenseType : ''}
                      rules={{ required: 'This is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={<IntlMessages id="appModule.placeholder.expenseType" />}
                          margin="none"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="ExpenseType"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>
                  <div className="d-flex flex-column order-lg-1 mx-3 mt-4">
                    <Controller
                      name="ExpenseDateTime"
                      control={control}
                      defaultValue={
                        details.ExpenseDateTime ? details.ExpenseDateTime : moment(ReceiptDate).format('YYYY-MM-DD HH:mm:ss')
                      }
                      rules={{ required: 'This is required' }}
                      render={({ field }) => (
                        <TextField {...field} type="datetime-local" margin="normal" style={{ marginTop: '0px' }} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="ExpenseDateTime"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>

                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="d-flex flex-column order-lg-1 mx-3">
                    <Controller
                      name="ExpenseAmount"
                      control={control}
                      defaultValue={details.ExpenseAmount ? details.ExpenseAmount : ReceiptAmount}
                      rules={{ required: 'This is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={<IntlMessages id="appModule.placeholder.ExpenseAmount" />}
                          margin="none"
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="ExpenseAmount"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>
                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="d-flex flex-column order-lg-1 mx-3">
                    <Controller
                      name="VatRate"
                      control={control}
                      defaultValue={details.VatRate ? details.VatRate : ''}
                      // rules={{ required: 'This is required' }}
                      render={({ field }) => <TextField {...field} label="Tax Rate" margin="none" />}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="VatAmount"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>
                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="d-flex flex-column order-lg-1 mx-3">
                    <Controller
                      name="VatAmount"
                      control={control}
                      defaultValue={details.VatAmount ? details.VatAmount : ''}
                      // rules={{ required: 'This is required' }}
                      render={({ field }) => <TextField {...field} label="Tax Amount" margin="none" />}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="TaxAmount"
                      render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                    />
                  </div>
                </div>
                {/* <div className="col-lg-6 d-flex flex-column order-lg-1 mt-3">
                  <input id="file" type="file" name="file" />
                </div> */}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button style={{ textTransform: 'none' }} type="submit" variant="contained" color="primary">
              {addNewExpense ? <IntlMessages id="modal.pages.addExpense" /> : <IntlMessages id="modal.pages.editExpense" />}
            </Button>
            <Button style={{ textTransform: 'none' }} onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};

export default CreateExpense;
