import React from 'react';
import {
  // Button,
  Menu,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
} from '@mui/material';
import {
  // Edit,
  // Delete,
  Share as ShareIcon,
  Visibility as VisibilityIcon,
  DateRange as DateRangeIcon,
  Autorenew as AutorenewIcon,
} from '@mui/icons-material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
// import customAxios from 'routes/Pages/util/Api';
// import authHeader from 'services/auth/jwt/auth-header';
// import { useDispatch, useSelector } from 'react-redux';
// import { useEffect } from 'react';
import CmtAvatar from '@coremat/CmtAvatar';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from 'react-router-dom';

const menuStyles = {
  fontFamily: 'normal',
};

const SkeletonLoader = () =>
  Array.from({ length: 5 }).map((_, index) => (
    <TableRow key={index}>
      <TableCell sx={{ display: 'flex', gap: 5 }}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={150} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={100} />
      </TableCell>
      {/* <TableCell>
        <Skeleton variant="text" width={100} />
      </TableCell> */}
    </TableRow>
  ));

const ReportsListView = ({
  reportsData,
  isTeam,
  stringAvatar,
  userData,
  handleClick,
  anchorElArray,
  handleClose,
  handleMenuItem,
  allUserDetails,
  loading,
  accTeam,
  currentTeamMember,
}) => {
  return (
    <div className="my-5">
      <h6
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          padding: '16px',
          borderRadius: '4px',
          background: '#61b3f2',
          color: '#fff',
          fontWeight: 'bold',
        }}>
        {isTeam ? 'Reports uploaded by team members' : 'Reports uploaded by you'}
      </h6>
      <TableContainer component={Paper} style={{ width: 450, margin: '20px 0px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={menuStyles}>Report Name</TableCell>
              {/* <TableCell sx={menuStyles}>Time</TableCell> */}
              <TableCell sx={menuStyles}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Display skeleton loader while loading
              <SkeletonLoader />
            ) : (
              reportsData.map((item, index) => {
                let filterAvatarImage;
                if (isTeam) {
                  filterAvatarImage = allUserDetails?.find(user => user.EAUserId === item.UserID);
                }

                // console.log(isTeam, filterAvatarImage, userData, 'detials view ');
                function formatDateToDDMMYYYY(timestamp) {
                  const date = new Date(timestamp);

                  const day = date
                    .getDate()
                    .toString()
                    .padStart(2, '0');
                  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
                  const year = date.getFullYear();

                  return `${day}/${month}/${year}`;
                }

                function formatDateString(dateString) {
                  const date = new Date(dateString);

                  const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  };

                  return date.toLocaleDateString('en-US', options);
                }
                const creditionals = `reportid=${item.ReportId}&&teamid=${accTeam}&&reportName=${item.ReportName}`;

                const handleReportRouting = () => {
                  return (
                    <NavLink to={`/embedded-analytics/report/${creditionals}`}>
                      <p style={{ fontFamily: 'normal', cursor: 'pointer', color: '#333333' }}>View Report</p>
                    </NavLink>
                  );
                };

                return (
                  <TableRow key={index}>
                    <TableCell TableCell>
                      <div className="d-flex align-items-center">
                        <div className="mr-2">
                          {isTeam ? (
                            <Tooltip title={filterAvatarImage?.Email || 'Team member not found'}>
                              <CmtAvatar
                                size="small"
                                src={`data:image/jpeg;base64,${filterAvatarImage?.AvatarImage}`}
                                {...stringAvatar(filterAvatarImage?.Email)}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={userData?.EmailAddress}>
                              <CmtAvatar
                                size="small"
                                src={userData?.AvatarImage}
                                {...stringAvatar(userData?.EmailAddress)}
                              />
                            </Tooltip>
                          )}
                        </div>
                        <NavLink to={`/embedded-analytics/report/${creditionals}`}>
                          <p
                            style={{ fontFamily: 'normal', cursor: 'pointer', color: '#333333' }}
                            // onClick={() => handleMenuItem(item.ReportId, 'imgClick')}
                          >
                            {item.ReportName}
                          </p>
                        </NavLink>
                      </div>
                    </TableCell>
                    {/* <TableCell sx={menuStyles}>
                      <Tooltip title={formatDateString(item.UploadTime)}>{formatDateToDDMMYYYY(item.UploadTime)}</Tooltip>
                    </TableCell> */}
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls={`menu-${index}`}
                        aria-haspopup="true"
                        onClick={event => handleClick(event, item, index)}>
                        <Tooltip title="Open Menu">
                          <MoreVertIcon />
                        </Tooltip>
                      </IconButton>
                      <Menu
                        id={`menu-${index}`}
                        anchorEl={anchorElArray[index]}
                        open={Boolean(anchorElArray[index])}
                        onClose={() => handleClose(index)}>
                        {currentTeamMember?.IsAdmin === 0 ? (
                          <MenuItem sx={menuStyles}>
                            <RemoveRedEyeOutlinedIcon /> {handleReportRouting()}
                          </MenuItem>
                        ) : (
                          <div>
                            <MenuItem sx={menuStyles} onClick={() => handleReportRouting(item, 'viewReport')}>
                              <RemoveRedEyeOutlinedIcon />
                              {handleReportRouting()}
                            </MenuItem>
                            <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'shareReport')}>
                              <ShareIcon /> Share Report
                            </MenuItem>
                            <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'schedulereport')}>
                              <DateRangeIcon /> Schedule Report
                            </MenuItem>
                            <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'refreshDataset')}>
                              <AutorenewIcon /> Refresh Dataset
                            </MenuItem>
                            <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'deleteReport')}>
                              <DeleteOutlineIcon /> Delete Report
                            </MenuItem>
                          </div>
                        )}
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportsListView;
