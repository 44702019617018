import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export function Poller(callback, delay, args) {
  const token = JSON.parse(localStorage.getItem('token'));
  const { authUser } = useSelector(({ auth }) => auth);

  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback, args]);

  useEffect(() => {
    function tick() {
      // if(token && authUser)
      savedCallback.current(args);
    }
    if (delay !== null && token && authUser) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay, args]);
}
