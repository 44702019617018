import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import NoteTimelineHistory from './NoteTimelineHistory';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0.3,
    '& .MuiTimelineItem-root': {},

    '& .MuiTimelineItem-missingOppositeContent': {},
    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 0,
    },
  },
  notesPaper: {
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
      display: 'flex',
      overflow: 'hidden',
    },
  },
}));

const textFieldStyles = makeStyles(theme => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none !important',
    },
    '&.MuiInputBase-root.Mui-disabled': {
      borderBottom: 'none !important',
    },
  },
}));

const AddNotePopover = ({
  open,
  id,
  anchorEl,
  handleClose,
  saveNote,
  currNote,
  updateNewNotesText,
  followUpDate,
  openDate,
  setOpenDate,
  handlefollowDateHandler,
  notesData,
  setNotesData,
  contactData,
  getNotesData,
}) => {
  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      disableEnforceFocus
      anchorEl={anchorEl}
      onClose={handleClose}
      style={{ display: 'flex', gap: '20px' }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        horizontal: -50,
        vertical: 20,
      }}
      className={classes.notesPaper}>
      <div
        className="e-content"
        style={{ border: '1px solid  rgba(0,0,0,.225)', width: '425px', height: '308px', borderRadius: '10px' }}>
        <div className="d-flex-column w-100  m-0 p-0 ">
          <div
            className="word-wrap text-break px-3 pt-2 "
            style={{ minHeight: '50px', maxHeight: '420px', background: 'whiteSmoke', borderRadius: '10px' }}>
            <TextField
              name="note"
              value={currNote}
              onChange={e => updateNewNotesText(e.target.value)}
              placeholder="Notes"
              fullWidth
              multiline
              autoFocus
              className={textFieldStyles().root}
              minRows={12}
              maxRows={12}></TextField>
          </div>
          <div className="px-4 w-100 pt-2  d-flex justify-content-between" style={{ gap: '10px' }}>
            <Box
              // component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <div className="row">
                <div className="followup">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minDate={moment().subtract(2, 'years')}
                      open={openDate}
                      inputFormat="DD-MM-YYYY"
                      onOpen={() => setOpenDate(true)}
                      onClose={() => setOpenDate(false)}
                      value={followUpDate}
                      // onChange={handleDueDateChange}
                      label={'Follow-Up Date'}
                      onChange={event => handlefollowDateHandler(event)}
                      // disableOpenPicker
                      clearable={true}
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onClick={() => setOpenDate(true)}
                        />
                      )}
                      componentsProps={{
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Box>
            <div className="d-flex flex-row-reverse" style={{ gap: '5px' }}>
              <Button
                variant="contained"
                style={{ background: '#3f51b5', color: 'white', textTransform: 'none' }}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ background: '#3f51b5', color: 'white', textTransform: 'none' }}
                onClick={saveNote}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '400px', maxHeight: '300px', overflow: 'scroll' }}>
        <NoteTimelineHistory
          notesData={notesData}
          setNotesData={setNotesData}
          contactData={contactData}
          getNotesData={getNotesData}
        />
      </div>
    </Popover>
  );
};

export default AddNotePopover;
