/**
 * Version 1.0
 * Raul Santos Barreira
 * 15.04.2022
 * -
 * This component appears when we want to add a new client in the tab "Clients"
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// // import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from '@material-ui/core/Input';
// import FormControl from '@material-ui/core/FormControl';
import IntlMessages from '../../util/IntlMessages';
import Select from 'react-select';
// import { SingleSelect } from 'react-select-material-ui';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import axios from '../../util/Api';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
}));

const CreateEdit = ({ modal, details, toggle, onClientsClose, clients }) => {
  const dispatch = useDispatch();
  const {
    // register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  const [countries, setCountries] = useState([]);
  const classes = useStyles();
  /**
   * Called when we submit the form
   */
  const onSubmit = formData => {
    let url = '/Client/AddClient';
    try {
      formData['PhoneNumber'] = parseInt(formData['PhoneNumber']);
      formData['Country'] = formData['Country'].value;
    } catch (e) {
      alert('Enter valid phone number');
    }
    dispatch({ type: FETCH_START });
    if (details.edit) {
      url = '/Client/EditClient';
      // formData['Country'] = formData['Country'].value ? formData['Country'].value : details.Country;
      formData['Id'] = details.Id;
      formData['UserId'] = details.UserId;
      console.log(formData);
    }
    axios
      .post(url, formData)
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        toggle();
        onClientsClose();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error.response.data.title);
          var errorList = error.response.data.errors;
          if (errorList) {
            //iterate through errors {} and add key values
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log('Error****:', error.message);
      });
  };
  /**
   * UseEffect used to get the countries
   */
  useEffect(() => {
    console.log(details);
    dispatch({ type: FETCH_START });
    axios
      .get('/Bank/GetCountries')
      .then(({ data }) => {
        console.log('data:', data);
        if (data) {
          var Country = data.map(c => ({ value: c.CountryName, label: c.CountryName }));
          console.log(Country);

          setCountries(Country);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          console.log('payload: data.error', data.error);
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log('Error****:', error.message);
      });
  }, []);

  return (
    <Modal className="modal-box" size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        {details.length === 0 ? <IntlMessages id="clients.addClient" /> : <IntlMessages id="clients.editClient" />}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
          <div className="modal-box-content">
            <div className="row no-gutters">
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="ClientName"
                  control={control}
                  defaultValue={details.ClientName ? details.ClientName : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => <TextField {...field} label={<IntlMessages id="appModule.name" />} margin="none" />}
                />
                <ErrorMessage
                  errors={errors}
                  name="ClientName"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-6 d-flex flex-column order-lg-1">
                <Controller
                  name="ClientEmail"
                  control={control}
                  defaultValue={details.ClientEmail ? details.ClientEmail : ''}
                  rules={{
                    required: 'This is required',
                    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' },
                  }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.email" />} margin="none" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="ClientEmail"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="PhoneNumber"
                  defaultValue={details.PhoneNumber ? details.PhoneNumber : ''}
                  control={control}
                  rules={{
                    required: 'This is required',
                    pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Invalid phone number' },
                  }}
                  render={({ field }) => (
                    <TextField {...field} type={'number'} label={<IntlMessages id="appModule.phone" />} margin="normal" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="PhoneNumber"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-6 d-flex flex-column order-lg-1">
                <Controller
                  name="VATNumber"
                  control={control}
                  defaultValue={details.VATNumber ? details.VATNumber : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.vatNo" />} margin="normal" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="VATNumber"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="AddressLine1"
                  control={control}
                  defaultValue={details.AddressLine1 ? details.AddressLine1 : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.addressLine1" />} margin="normal" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="AddressLine1"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-6 d-flex flex-column order-lg-1">
                <Controller
                  name="AddressLine2"
                  control={control}
                  defaultValue={details.AddressLine2 ? details.AddressLine2 : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.addressLine2" />} margin="normal" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="AddressLine2"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="PostCode"
                  control={control}
                  defaultValue={details.PostCode ? details.PostCode : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.areaCode" />} margin="normal" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="PostCode"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>

              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>

              <div className="col-lg-6 d-flex flex-column order-lg-1 selectbox">
                {/* <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.Country" />
                </InputLabel>
                <Controller
                  name="Country"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => (
                    <SingleSelect
                      options={countries}
                      value={countries.find(c => c.value == value)}
                      onChange={val => onChange(val)}
                    />
                  )}
                /> */}
                <Controller
                  name="Country"
                  control={control}
                  defaultValue={details.Country ? details.Country : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      // style={classes.selectBox}
                      className={classes.selectBox}
                      placeholder={'Select Country..'}
                      // {...field}
                      options={countries}
                      value={countries.find(c => c.value == value)}
                      onChange={val => onChange(val)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="Country"
                  render={({ message }) => <p className={classes.marginErr}>{message}</p>}
                />
              </div>
            </div>
          </div>
          {/* <div className="modal-box-footer d-flex flex-row">
            <Button type="submit" variant="contained" color="primary">
              <IntlMessages id="clients.saveClient" />
            </Button>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <Button type="submit" variant="contained" color="primary">
            {details.length === 0 ? <IntlMessages id="clients.saveClient" /> : <IntlMessages id="clients.updateClient" />}
          </Button>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateEdit;
