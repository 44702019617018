import React, { useEffect, useState } from 'react';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Box, ButtonGroup, Card, CardContent, colors, IconButton, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProjectAttributes = ({
  getAttributeData,
  getContactAttributes,
  getContactAttributesLabel,
  AddNewField,
  setNewLabelValue,
  setNewAttributeValue,
  attributeKeyValuePair,
  newLabelValue,
  newAttributeValue,
  setAttributeKeyValuePair,
  onAttributeChangeHandler,
}) => {
  const dispatch = useDispatch();
  // popup start
  const [showPopup, setShowPopup] = useState(false);
  const onChange = (e, index) => {
    let newKeyValuePair = { ...attributeKeyValuePair };
    newKeyValuePair[parseInt(index)] = { ...attributeKeyValuePair[parseInt(index)], labelValue: e.target.value };
    setAttributeKeyValuePair(newKeyValuePair);
  };
  return (
    <div>
      <div>
        <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }} className="attributeCtn">
          <CardContent component="div" style={{ paddingBottom: '16px', padding: showPopup ? '16px' : '0 16px' }}>
            <div
              style={{
                fontSize: '16px',
                fontFamily: 'normal',
                fontWeight: 'bold',
                lineHeight: 1,
                padding: '10px 0 10px 10px',
              }}>
              <IconButton
                variant="contained"
                onClick={() => setShowPopup(!showPopup)}
                size="small"
                style={{
                  background: '#3f51b5',
                  color: 'white',
                  transform: showPopup ? 'rotate(45deg)' : 'rotate(0deg)',
                  transition: 'all 0.5s',
                }}>
                <AddIcon style={{ fontWeight: 'bold', fontSize: 30 }} />
              </IconButton>
            </div>

            <div style={{ display: showPopup ? 'block' : 'none' }}>
              <Box
                component="form"
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <div className="col-md-6">
                  <TextField
                    label="Attribute Label"
                    id="AttributeLabel"
                    name="attributeLabel"
                    type="text"
                    variant="outlined"
                    value={newLabelValue}
                    onChange={e => setNewLabelValue(e.target.value)}
                    // onBlur={onInputChangeHandler}
                    // defaultValue={obj.crmContactFirst}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    label="Attribute Value"
                    id="AttributeValue"
                    name="attributeValue"
                    type="text"
                    variant="outlined"
                    value={newAttributeValue}
                    onChange={e => setNewAttributeValue(e.target.value)}
                    // onBlur={onInputChangeHandler}
                    // defaultValue={`${infoSidebarData.crmContactMiddle}`}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                    inputProps={{ style: { fontSize: 14 } }}
                  />
                </div>
                <div className="col-md-12 d-flex justify-content-end " style={{ paddingInline: '20px', gap: '15px' }}>
                  <Button variant="contained" style={{ background: '#3f51b5', color: 'white' }} onClick={AddNewField}>
                    Add
                  </Button>
                  <Button
                    variant="contained"
                    style={{ background: '#3f51b5', color: 'white' }}
                    onClick={() => setShowPopup(false)}>
                    Cancel
                  </Button>
                </div>
              </Box>
            </div>
          </CardContent>
        </Card>
        {/* {isLabelLoaded && isAttributeLoaded ? ( */}
        <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }} className="attributeCtn">
          <CardContent component="div" style={{ paddingBottom: '16px' }}>
            <div>
              <Box
                sx={{
                  '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off">
                <div className="row mt-3">
                  {Object.entries(attributeKeyValuePair).map((item, index) => {
                    return (
                      <div className="col-md-4" key={index} style={{ marginBottom: '16px' }}>
                        {/* <TextField
                          label={item[1].labelInfo[1]}
                          id={item[0]}
                          name={item[0]}
                          type="text"
                          variant="outlined"
                          value={item[1].labelValue}
                          // onChange={e => onChange(e, item[0])}
                          // onBlur={e => {
                          //   onInputChangeHandler(e.target.value, item[1]);
                          // }}
                          // defaultValue={contactAttributes[item[0]] ? contactAttributes[item[0]].LabelValue:""}
                          size="medium"
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                          inputProps={{ style: { fontSize: 14 } }}
                        /> */}
                        <TextField
                          label={item[1].labelInfo[1]}
                          id={item[0]}
                          name={item[0]}
                          type="text"
                          variant="outlined"
                          value={item[1].labelValue}
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%', fontSize: '14px', marginBottom: '10px', paddingBlock: '5px' }}
                          inputProps={{ style: { fontSize: 14 } }}
                          onChange={e => onChange(e, item[0])}
                          onBlur={e => {
                            onAttributeChangeHandler(e.target.value, item[1]);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Box>
            </div>
          </CardContent>
        </Card>
        {/* ) : (
          <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: '200px' }}>
            {<CircularProgress />}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ProjectAttributes;
