import React, { useState } from 'react';

const DialogFormTemplate = props => {
  const [obj, setObject] = useState(Object.assign({}, props));

  function onChange(args) {
    setObject({ [args.target.name]: args.target.value });
  }

  return (
    <div className="width-600">
      <div className="form-row">
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input id="Name" name="BankName" type="text" value={obj.BankName} onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">BankName </label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input value={obj.UserEmail} id="UserEmail" name="UserEmail" type="text" onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Email</label>
          </div> 
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input id="BIC" name="BIC" type="text" value={obj.BIC} onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">BIC</label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input id="IBAN" name="IBAN" type="text" value={obj.IBAN} onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">IBAN</label>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="e-float-input e-control-wrapper">
            <input id="Sort" name="Sort" type="text" value={obj.Sort} onChange={onChange} />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Bank Sort</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogFormTemplate;
