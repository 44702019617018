import IntlMessages from '@jumbo/utils/IntlMessages';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useState } from 'react';
import AddProject from './addProject';
import EditProject from './editProject';
import PropTypes from 'prop-types';
import { TableHeader } from './TabelHeader';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { AvatarGroup } from '@mui/material';
import Select from 'react-select';
import ProjectsList from 'routes/Pages/components/dashboard/Common/ProjectsList';
import { getStore } from 'redux/store';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArchiveIcon from '@mui/icons-material/Archive';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { styled, alpha } from '@mui/material/styles';
import { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AlertDialogForArchive from './AlertDialogForArchive';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const TableCellStyled = styled(TableCell)({
  display: 'flex',
});

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const headCells = [
  {
    id: 'pName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    minWidth: 80,
  },
  {
    id: 'CompletionPct',
    numeric: false,
    disablePadding: false,
    label: 'Completion Pct',
    minWidth: 80,
  },
  {
    id: 'pDescription',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    minWidth: 80,
  },
  {
    id: 'pStartDate',
    numeric: false,
    disablePadding: false,
    label: 'Start Date',
    minWidth: 100,
  },
  {
    id: 'pDueDate',
    numeric: false,
    disablePadding: false,
    label: 'Due Date',
    minWidth: 150,
  },
  {
    id: 'ProjectAssigned',
    numeric: false,
    disablePadding: false,
    label: 'Assigned',
    minWidth: 150,
  },
  {
    id: 'ProjectTeamId',
    numeric: false,
    disablePadding: false,
    label: 'Team',
    minWidth: 150,
  },
  {
    id: 'IsArchived',
    numeric: false,
    disablePadding: false,
    label: 'Is Archived',
    minWidth: 150,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 100,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ProjectSectionPage = ProjectId => {
  const ITEM_HEIGHT = 48;
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [openEdit, setOpenEdit] = useState(false);
  const [details, setDetails] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);
  const [project, setProject] = useState([]);
  const [teams, setTeams] = useState([]);
  const [tmMembers, setTmMembers] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [filterProject, setFilterProject] = useState([]);

  // table config
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);

  // search
  const [searchKey, setSearchKey] = useState('');
  const inputRef = useRef(null);
  const store = getStore().getState();
  const { projectList } = store.common;
  const [openAlert, setOpenAlert] = React.useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);

  const handleMenuClose = () => setAnchorEl({});

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
    setAnchorEl({});
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.ClientName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRefresh = () => getTeamProjectByTeam(true);
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    handleRefresh();
    setDetails([]);
  };

  const onCloseEditDrawer = () => {
    setOpenEdit(false);
    handleRefresh();
    setDetails([]);
  };
  const handleActionMenuClick = (index, event, row) => {
    setAnchorEl(prevAnchorEl => ({
      ...prevAnchorEl,
      [index]: event.currentTarget,
    }));
    setDeleteProjectId(row);
  };

  const teamActionHandler = (action, data) => {
    setDetails({ ...data, edit: true });
    if (action === 'detail') {
      if (data.IsAdmin === 1) {
        setOpenEdit(!openEdit);
      }
    }
    if (action === 'archive') {
      if (data.IsAdmin === 1) {
        handleArchiveDialog();
      }
    }
    // if (action === 'delete') {
    //   setOpenEdit(false);
    //   deleteProject(data.pId);
    // }
    setAnchorEl(null);
  };

  // const deleteProject = id => {
  //   const deleteObj = {
  //     tmTeamId: selectedTeam.value,
  //     projectId: id,
  //   };

  //   let url = 'Project/DeleteProject/' + id;
  //   dispatch({ type: FETCH_START });
  //   customAxios
  //     .post('Project/DeleteProject', deleteObj, { headers: authHeader() })
  //     .then(data => {
  //       getTeamProjectByTeam();
  //       dispatch({ type: FETCH_SUCCESS });
  //     })
  //     .catch(function(error) {
  //       dispatch({ type: FETCH_ERROR, payload: error.message });
  //     });
  // };
  const deleteProject = id => {
    const deleteObj = {
      tmTeamId: selectedTeam.value,
      projectId: id,
    };

    const url = `Project/DeleteProject/${id}`;
    dispatch({ type: FETCH_START });

    const config = {
      headers: authHeader(),
    };

    customAxios
      .post(url, deleteObj, config)
      .then(response => {
        getTeamProjectByTeam();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
    handleCloseAlert();
  };

  const fetchTaskTeams = () => {
    dispatch({ type: FETCH_START });
    customAxios
      .get('TaskTeams/GetTaskTeams', { headers: authHeader() })
      .then(({ data }) => {
        setData(data);
        setCustomData(data);
        setFilterData(data);
        const teams = data.map(team => {
          return { ...team, value: team.Id, label: team.tmTeamName };
        });
        updatedData();
        if (teams) {
          setTeams(teams);
          setSelectedTeam(teams[0]);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
    getTeamProjectByTeam();
  };

  useEffect(() => {
    if (teams.length > 0) {
      const getTeamMembersByTeam = async obj => {
        const responses = [];
        for (let i = 0; i < teams.length; i++) {
          let url = 'TaskTeams/GetTaskTeamMembers/' + teams[i].Id;
          responses.push(await customAxios.get(url, { headers: authHeader() }));
        }
        if (responses) {
          const teamMembers = [];
          for (let i = 0; i < responses.length; i++) {
            responses[i].data.forEach(element => {
              teamMembers.push(element);
            });
          }

          setTmMembers(teamMembers);
        }
      };
      getTeamMembersByTeam();
    }
    return () => {
      setTmMembers([]);
    };
  }, [teams]);

  useEffect(() => {
    fetchTaskTeams();
  }, []);
  const classes = useStyles();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleEdit = () => {
    setOpenEdit(!openEdit);
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const onTeamChangeHandler = args => {
    setSelectedTeam(args);
  };

  const getTeamProjectByTeam = async (isRefresh = false) => {
    try {
      const responses = [];
      if (!isRefresh) {
        dispatch({ type: FETCH_START });
      }
      for (let i = 0; i < teams.length; i++) {
        let url = 'Project/GetProjects/' + teams[i].Id;
        responses.push(await customAxios.get(url, { headers: authHeader() }));
      }
      if (responses) {
        const teamProject = [];
        for (let i = 0; i < responses.length; i++) {
          responses[i].data.forEach(element => {
            teamProject.push(element);
          });
        }

        setProject(teamProject);
        dispatch({ type: FETCH_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };

  useEffect(() => {
    if (teams.length) {
      //call api for project data
      getTeamProjectByTeam();
    }
  }, [teams]);

  useEffect(() => {
    updatedData();
  }, [selectedTeam, project]);
  const updatedData = () => {
    if (selectedTeam) {
      const teamid = project.filter(c => c.pTeamId === selectedTeam.Id);
      AddFilteredProjectToList(searchKey, teamid);
    }
  };

  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    AddFilteredProjectToList(searchkey);
  };

  const AddFilteredProjectToList = (searchkey, projectList) => {
    let filteredRows = [];
    let currProjectList = projectList ? [...projectList] : [...filterProject];
    filteredRows = getArrayFilterData(currProjectList, searchkey);
    setFilterProject(filteredRows);
    // if (searchkey.length <= 0) {
    //   setFilterProject(filterProject);
    // } else {
    //   setFilterProject(filteredRows);
    // }
  };

  const getArrayFilterData = (arr, searchkey) => {
    if (searchKey == '') {
      return arr;
    }

    const result = arr.filter(row => {
      return (
        row.pName?.toLowerCase().includes(searchkey.toString().toLowerCase()) ||
        row.pDescription?.toLowerCase().includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    getTeamProjectByTeam();
    setFilterProject(filterProject);
    // setSearchDraftData(data);
    // setSearchSentData(customData);
  };

  function getText(html) {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    let textContentOne =
      divContainer?.textContent?.length > 50
        ? divContainer?.textContent.substring(0, 50) + '...'
        : divContainer?.textContent;
    let innerTextTwo =
      divContainer.innerText?.length > 50 ? divContainer.innerText.substring(0, 50) + '...' : divContainer.inner;
    return textContentOne || innerTextTwo || '';
  }
  const [teamMembers, setTeamMembers] = useState([]);

  const getTeamMembersByTeam = () => {
    if (selectedTeam.Id !== undefined) {
      let url = `TaskTeams/GetTaskTeamMembers/${selectedTeam.Id}`;
      dispatch({ type: FETCH_START });
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          setTeamMembers(data);
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  useEffect(() => {
    getTeamMembersByTeam();
  }, [selectedTeam]);

  //archive dialog
  const [openArchiveDialog, setOpenArchiveDialog] = React.useState(false);

  const handleArchiveDialog = () => {
    setOpenArchiveDialog(true);
  };

  const handleArchive = () => {
    const reqbody = {
      TeamId: details.pTeamId,
      ProjectId: details.pId,
      ArchiveFlag: details.ArchiveFlag === 0 ? 1 : 0,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('Project/ModifyProjectArchiveFlag', reqbody, { headers: authHeader() })
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS, payload: 'Note Added Successfully' });

        getTeamProjectByTeam();
        setOpenArchiveDialog(false); // Close dialog after success
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  return (
    <div>
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={teams}
              value={teams.find(c => c.tmTeamName === selectedTeam.tmTeamName)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        <div className="content-horizontal__center">
          <div className="justify-content-right pb-3 text-right d-flex justify-content-end">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => searchCRMData(e)}
                value={searchKey}
                inputRef={inputRef}
              />
              {searchKey && (
                <CloseIcon
                  onClick={clearSearchData}
                  style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
                />
              )}
            </Search>
            <Button
              variant="contained"
              disabled={selectedTeam?.IsAdmin === 0}
              color="primary"
              onClick={() => setOpenDrawer(true)}>
              <IntlMessages id="projectsection.addProject" />
            </Button>
          </div>
        </div>
      </div>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={filterProject.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={customData.length}
              />
              {/* <TableHeader classes={classes} rowCount={customData.length} /> */}

              <TableBody>
                {/* {filterProject.map((row, index) => { */}
                {stableSort(filterProject, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // setColorID(row?.pTeamId);
                    // const isItemSelected = isSelected(row.CompanyName);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const currentUser = tmMembers?.find(instance => instance.TMUserId === row.pAssignedTo);
                    const currentTeam = teams?.find(instance => instance.Id === row.pTeamId);

                    return (
                      <TableRow hover key={index}>
                        <TableCell
                          sx={{ display: 'flex' }}
                          component="th"
                          id={labelId}
                          scope="row"
                          onClick={() => teamActionHandler('detail', row)}>
                          <div>
                            {row.pName}{' '}
                            <div
                              onClick={() => teamActionHandler('detail', row)}
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                                width: '40px',
                                height: '20px',
                                borderRadius: '3px',
                                backgroundColor: row.pHexColor,
                              }}></div>
                          </div>
                        </TableCell>
                        {/* <TableCell onClick={() => teamActionHandler('detail', row)}>
                          <div
                            style={{
                              border: '1px solid black',
                              height: '1.5rem',
                              width: '3.5rem',
                              borderRadius: '3px',
                              backgroundColor: row.pHexColor,
                            }}></div>
                        </TableCell> */}
                        <TableCell>
                          <ProgressBar now={60} />
                        </TableCell>
                        <TableCell align="left" onClick={() => teamActionHandler('detail', row)}>
                          {' '}
                          {getText(row.pDescription)}
                          {/* {row.pDescription} */}
                        </TableCell>
                        <TableCell align="left" onClick={() => teamActionHandler('detail', row)}>
                          {moment(row.pStartDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left" onClick={() => teamActionHandler('detail', row)}>
                          {moment(row.pDueDate).format('DD-MM-YYYY')}
                        </TableCell>
                        <TableCell align="left" onClick={() => teamActionHandler('detail', row)}>
                          {(currentUser?.Email && (
                            <AvatarGroup max={5} style={{ float: 'left' }}>
                              return <Avatar key={index}>{currentUser?.Email.slice(0, 2)}</Avatar>;
                            </AvatarGroup>
                          )) ||
                            row.pAssignedTo}
                        </TableCell>
                        <TableCell align="left" onClick={() => teamActionHandler('detail', row)}>
                          {currentTeam?.tmTeamName || row.pTeamId}
                        </TableCell>
                        <TableCell align="left">{row.ArchiveFlag ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="right">
                          <>
                            <IconButton disabled={row.IsAdmin === 0} onClick={e => handleActionMenuClick(index, e, row)}>
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              anchorEl={anchorEl && anchorEl[index]}
                              keepMounted
                              open={Boolean(anchorEl && anchorEl[index])}
                              onClose={handleMenuClose}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}>
                              <MenuItem
                                disabled={currentTeam?.IsAdmin === 0}
                                onClick={() => teamActionHandler('detail', row)}>
                                <EditIcon className={classes.actionIcon} /> Edit
                              </MenuItem>

                              <MenuItem
                                onClick={() => teamActionHandler('archive', row)}
                                disabled={currentTeam?.IsAdmin === 0}>
                                {' '}
                                <ArchiveIcon className={classes.actionIcon} /> {row.ArchiveFlag ? 'Unarchive' : 'Archive'}{' '}
                              </MenuItem>
                              {/* <MenuItem
                                  disabled={currentTeam?.IsAdmin === 0}
                                  onClick={() => teamActionHandler('delete', row)}>
                                  {console.log('de;')}
                                  <DeleteOutlineIcon className={classes.actionIcon} />
                                </MenuItem> */}
                              {/* <MenuItem
                                  disabled={currentTeam?.IsAdmin !== 0}
                                  onClick={() => deleteProject(row?.pId)}
                                  onClick={handleClickOpenAlert}>
                                  <DeleteOutlineIcon className={classes.actionIcon} />
                                </MenuItem> */}
                              <MenuItem disabled={currentTeam?.IsAdmin === 0} onClick={handleClickOpenAlert}>
                                <DeleteIcon className={classes.actionIcon} />
                                Delete
                              </MenuItem>
                            </Menu>
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            </Table>

            <AlertDialogForArchive
              getTeamProjectByTeam={getTeamProjectByTeam}
              details={details}
              handleArchive={handleArchive}
              open={openArchiveDialog}
              setOpen={setOpenArchiveDialog}
            />
            {/* {filterProject.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  height: '300px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}>
                <CircularProgress color="primary" />
              </Box>
            )} */}
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              {/* <DialogTitle id="alert-dialog-title">Do you want to delete a selected project?</DialogTitle> */}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to delete a selected project?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAlert}>Cancel</Button>
                <Button variant="outlined" color="secondary" onClick={() => deleteProject(deleteProjectId.pId)}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
        </Paper>
      </Box>
      <AddProject
        filterModalOpen={openDrawer}
        selectedTeamValue={selectedTeam}
        details={details}
        onClientsClose={onCloseDrawer}
        toggle={toggleDrawer}
        {...{
          teams,
          tmMembers,
        }}
      />
      <EditProject
        teamMembers={teamMembers}
        filterModalOpen={openEdit}
        selectedTeamValue={selectedTeam}
        details={details}
        onClientsClose={onCloseEditDrawer}
        toggle={toggleEdit}
        {...{
          teams,
          tmMembers,
          handleRefresh,
        }}
      />
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  // overrides: {
  root: {
    width: '100%',
    height: 'auto',
    overflowX: 'scroll',
    position: 'relative',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    overflow: 'scroll',
  },
  table: {
    minWidth: 750,
    overflowY: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    // overflow: 'scroll',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
  // },
}));
export default ProjectSectionPage;
