/**
 * Version 1.0
 * Raul Santos Barreira
 * 27.04.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useState } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../../@jumbo/constants/AppConstants';
import { NavLink } from 'react-router-dom';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';
//syncfusion data grid
// import {
//   GridComponent,
//   ColumnsDirective,
//   ColumnDirective,
//   CommandColumn,
//   Filter,
//   IFilter,
//   Grid,
//   VirtualScroll,
//   SelectionType,
//   Selection,
//   Group,
// } from '@syncfusion/ej2-react-grids';

/* Material Tables */
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { styled, alpha } from '@mui/material/styles';
import { FormControl } from '@mui/material';
import Select from 'react-select';

// import { DataUtil } from '@syncfusion/ej2-data';
// import { classList, Animation, createElement, closest, isNullOrUndefined } from '@syncfusion/ej2-base';
// import { Inject, Page, Toolbar, ToolbarItems, Edit, Sort, SortSettingsModel } from '@syncfusion/ej2-react-grids'
// import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
// import { DataUsageOutlined } from '@material-ui/icons';
import DialogFormTemplate from './DialogFormTemplate';
import { useRef } from 'react';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { CSVLink } from 'react-csv';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateClient from './CreateClient';
import SkeletonLoader from '@jumbo/components/PageComponents/CustomSkeletonLoader';

const menuStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '8px ',
      '& div': {
        marginRight: '8px',
        fontSize: '0.95rem',
      },
    },
    top: '40px !important',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'ClientName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    minWidth: 80,
  },
  {
    id: 'ClientEmail',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    minWidth: 150,
  },
  {
    id: 'AddressLine',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    minWidth: 150,
  },

  {
    id: 'PhoneNumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
    minWidth: 120,
  },
  {
    id: 'VATNumber',
    numeric: false,
    disablePadding: false,
    label: 'VAT Number',
    minWidth: 100,
  },
  {
    id: 'PostCode',
    numeric: false,
    disablePadding: false,
    label: 'Post Code',
    minWidth: 120,
  },
  {
    id: 'Country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
    minWidth: 100,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    minWidth: 100,
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionIcon: {
    cursor: 'pointer',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const Clients = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const downloadCsv = useRef(null);
  const [csvFile, setCsvFile] = useState([]);
  const cusclass = menuStyles();
  const inputRef = useRef(null);

  // search
  const [searchKey, setSearchKey] = useState('');

  //table config
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  /* Modal open/close */
  const [details, setDetails] = useState([]);
  const [open, setOpen] = useState(false);

  //AccTeams
  const [accTeamId, setAccTeamId] = useState(null);
  const [accTeamAll, setAccTeamAll] = useState([]);

  const [skeletonLoaderState, setSkeletonLoaderState] = useState(true);

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get('Acct/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data && data.length > 0) {
            let allteam = data.map(c => ({ value: c.Id, label: c.AcctTeamName }));

            setAccTeamAll(allteam);
            setAccTeamId(allteam[0].value);
            getClients(allteam[0].value);

            dispatch({ type: FETCH_SUCCESS });
          } else {
            setAccTeamId(null);
            dispatch({ type: FETCH_SUCCESS });
            // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  const onClientsClose = () => {
    setOpen(false);
    getClients();
  };

  //modal open/close
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setDetails([]);
  };

  const handleRequestSort = (event, property) => {
    console.log(event, property, 'property==property');
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = data.map(n => n.ClientName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const companyActionHandler = (action, data) => {
    if (action === 'detail') {
      data.edit = true;
      toggle();
      setDetails(data);
    }
    if (action === 'delete') {
      deleteClient(data.Id);
    }
  };

  const handleClick = (event, data) => {
    data.edit = true;

    const selectedIndex = selected.indexOf(data.name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, data.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const toggles = () => {
    // setModal(!modal);
    handleClickData();
    // return <csvlink {...csvReport}>Export to CSV</csvlink>;
  };
  const actionsList = [
    {
      onClick: toggles,
      label: <IntlMessages id="crm.excelCSVExport" />,
    },
  ];

  const handleClickData = () => {
    downloadCsv.current.link.click();
  };

  // customAxios.interceptors.response.use(
  //   function(response) {
  //     return response;
  //   },
  //   function(error) {
  //     if (error.response.status === 401 || error.response.status === 400) {
  //       dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  //     } else {
  //       return Promise.reject(error);
  //     }
  //   },
  // );

  /** syncfusion data tables **/
  // let grid;
  // const pageSettings = { pageSize: 10 };
  // const pubsortSettings = {
  //   columns: [{ field: 'ClientName', direction: 'Ascending' }],
  // };
  // const filterSettings = {
  //   type: 'Excel',
  //   // columns: [{ field: 'PostCode', operator: 'greaterthan', value: 2 }]
  // };
  // // const groupSettings = { columns: ['ClientName'] };
  // const editSettings = {
  //   allowEditing: true,
  //   allowAdding: true,
  //   allowDeleting: true,
  //   mode: 'Dialog',
  //   template: dialogTemplate,
  // };

  // const toolbarOptions = [
  //   'Add',
  //   'Edit',
  //   'Delete',
  //   'Search',
  //   // 'Update',
  //   // 'Cancel',
  // ];
  // const requiredRule = { required: true };
  // const commands = [
  //   {
  //     type: 'Edit',
  //     buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' },
  //   },
  //   {
  //     type: 'Delete',
  //     buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' },
  //   },
  //   {
  //     type: 'Save',
  //     buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' },
  //   },
  //   {
  //     type: 'Cancel',
  //     buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' },
  //   },
  // ];

  // function dialogTemplate(props) {
  //   return <DialogFormTemplate {...props} />;
  // }
  // // function commandClick(args) {
  // //   console.log(args.commandColumn.type);
  // //   console.log(grid.isEdit);
  // // }

  // function actionBegin(args) {
  //   let url = '/Client/EditClient';
  //   if (args.requestType === 'beginEdit' || args.requestType === 'add') {
  //     const dialog = args.dialog;
  //   }
  //   if (args.action === 'add') {
  //     url = '/Client/AddClient';
  //   }
  //   if (args.requestType === 'delete') {
  //     url = '/Client/DeleteClient/' + args.data[0].Id;
  //     deleteClient(url);
  //   }
  //   if (args.requestType === 'save' && args.form) {
  //     addUpdateClient(url, args.data);
  //   }
  // }
  // function actionComplete(args) {
  //   if (args.requestType === 'beginEdit' || args.requestType === 'add') {
  //     const dialog = args.dialog;
  //     // dialog.height = 400;
  //     // change the header of the dialog
  //     dialog.header = args.requestType === 'beginEdit' ? 'Edit Client' : 'New Client';
  //   }
  // }

  /** Add or Edit Clients **/
  function addUpdateClient(url, data) {
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, data, { headers: authHeader() })
      .then(data => {
        getClients();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  /** Delete Client **/
  function deleteClient(id) {
    let url = '/Client/DeleteClient/' + id;
    dispatch({ type: FETCH_START });
    customAxios
      .delete(url, { headers: authHeader() })
      .then(data => {
        getClients();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  /** Get All Client **/
  const getClients = id => {
    let teamId = id ? id : accTeamId;
    if (teamId == null) return;
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get('Client/GetClients/' + teamId, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            setData(data);
            setCustomData(data);
            setSkeletonLoaderState(false);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
            // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      // dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  /** Get All Client on page load**/
  // useEffect(() => {
  //   getClients();
  // }, []);

  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredRows = [];
    filteredRows = getArrayFilterData(data, searchkey);

    if (searchkey.length <= 0) {
      setCustomData(data);
    } else {
      setCustomData(filteredRows);
    }
  };

  const getArrayFilterData = (arr, searchkey) => {
    const result = arr.filter(row => {
      return (
        row.ClientName?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.AddressLine1?.toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });
    return result;
  };

  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setCustomData(data);
    // setSearchDraftData(data);
    // setSearchSentData(customData);
  };

  const onTeamChangeHandler = args => {
    // console.log(args);
    setAccTeamId(args.value);
    getClients(args.value);
  };

  return (
    <div className="app-wrapper">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.clients" />} /> */}

      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.clientsHeader" />
          </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={accTeamAll}
              value={accTeamAll.find(c => c.value == accTeamId)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
        </div>
        {accTeamAll.length > 0 ? (
          <div className="content-horizontal__center">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={e => searchCRMData(e)}
                value={searchKey}
                inputRef={inputRef}
              />
              {searchKey && (
                <CloseIcon
                  onClick={clearSearchData}
                  style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
                />
              )}
            </Search>
            <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={toggle}>
              Add
            </Button>
            <CmtDropdownMenu
              TriggerComponent={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickData}
                  sx={{
                    mx: 2,
                  }}
                  style={{ padding: '8px', minWidth: '40px', backgroundColor: '#3f51b5', marginLeft: '4px' }}>
                  <MoreVertIcon />
                </Button>
              }
              items={[...actionsList]}
              menuClasses={cusclass.root}
            />
            <CSVLink data={customData} ref={downloadCsv} filename={'clients-file.csv'} />
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {accTeamAll.length > 0 ? (
        <Box className={classes.root}>
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={customData.length}
                />
                <TableBody>
                  {skeletonLoaderState ? (
                    <SkeletonLoader tableCellCount={8} />
                  ) : (
                    <>
                      {stableSort(customData, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          console.log(row, 'rowwwww');
                          const isItemSelected = isSelected(row.CompanyName);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row)}
                              // role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                              // selected={isItemSelected}
                              key={index}>
                              <TableCell component="th" id={labelId} scope="row">
                                {row.ClientName}
                              </TableCell>
                              <TableCell align="left">{row.ClientEmail}</TableCell>
                              <TableCell align="left">
                                {row.AddressLine1}, {row.AddressLine2}
                              </TableCell>
                              <TableCell align="left">{row.PhoneNumber}</TableCell>
                              <TableCell align="left">{row.VATNumber}</TableCell>
                              <TableCell align="left">{row.PostCode}</TableCell>
                              <TableCell align="left">{row.Country}</TableCell>
                              <TableCell align="left">
                                <EditIcon
                                  className={classes.actionIcon}
                                  onClick={() => companyActionHandler('detail', row)}
                                />
                                &nbsp;&nbsp;
                                <DeleteOutlineIcon
                                  className={classes.actionIcon}
                                  onClick={() => companyActionHandler('delete', row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={customData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      ) : (
        <div style={{ marginTop: '5px', fontSize: '1rem', padding: '5px 20px' }}>
          You are not part of any team, Start your own Accounts team to add clients. Go to "Accounting Team" page.
          <NavLink to="/accounting/accounting-teams">"Here"</NavLink>{' '}
        </div>
      )}
      {modal && (
        <CreateClient
          modal={modal}
          details={details}
          toggle={toggle}
          onClientsClose={onClientsClose}
          clients={{ name: '' }}
          accTeamId={accTeamId}
        />
      )}
    </div>
  );
};

export default Clients;
