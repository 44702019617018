/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../util/IntlMessages';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import InputLabel from '@material-ui/core/InputLabel';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import Snippets from './Snippets';

/* text editor  */
const textEditorModules = {
  toolbar: [
    // [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image' /* "video"*/],
    // ["clean"],
    // ["fullscreen"]
  ],
};

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
  footerStyle: {
    marginTop: 30,
  },
  modalBodyStyles: {
    paddingTop: 0,

    '& .MuiInputBase-input': {
      fontSize: '0.95rem',
    },
    '& .MuiFormLabel-root': {
      marginBottom: 0,
    },
  },
}));

const CreateEmailTemplate = ({ modal, details, toggle, onClientsClose, selectedTeam }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [lastTestAreaFocus, setLastTestAreaFocus] = React.useState(null);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
  } = useForm({});
  const currMaibodyRef = useRef(null);
  const currMailSubRef = useRef(null);

  React.useEffect(() => {
    if (details.edit) {
      setValue('TemplateName', details.TemplateName);
      setValue('TemplateSubject', details.TemplateSubject);
      setValue('TemplateBody', details.TemplateBody);
    }
  }, [details]);

  const handleReset = () => {
    reset({
      TemplateName: '',
      TemplateSubject: '',
      TemplateBody: '',
    });
  };
  const handlePressCancelWithReset = () => {
    handleReset();
    toggle();
  };

  const onTextAreaFocus = e => {
    setLastTestAreaFocus(e);
  };
  /**
   * This function is called after submiting the form
   *
   * @param formData
   */
  const onSubmit = data => {
    let postData = {};
    let url = 'CRM/AddEmailTemplate';
    if (details.edit) {
      url = 'CRM/EditMultipleEmailTemplates';
      postData = [
        {
          TableName: 'CRM_EmailTemplates',
          ColumnName: 'TemplateName',
          NewValue: data['TemplateName'],
          PrimaryKeyValue: details.Id.toString(),
          crmTeamId: 0,
        },
        {
          TableName: 'CRM_EmailTemplates',
          ColumnName: 'TemplateSubject',
          NewValue: data['TemplateSubject'],
          PrimaryKeyValue: details.Id.toString(),
          crmTeamId: 0,
        },
        {
          TableName: 'CRM_EmailTemplates',
          ColumnName: 'TemplateBody',
          NewValue: data['TemplateBody'],
          PrimaryKeyValue: details.Id.toString(),
          crmTeamId: 0,
        },
      ];
    } else {
      postData = {
        TemplateName: data['TemplateName'],
        TemplateSubject: data['TemplateSubject'],
        TemplateBody: data['TemplateBody'],
      };
    }
    console.log(postData);
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        handleReset();
        onClientsClose();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error.response.data.title);
          var errorList = error.response.data.errors;
          if (errorList) {
            //iterate through errors {} and add key values
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  return (
    <Modal className={`modal-box ${classes.modalBodyStyles}`} size="lg" isOpen={modal} toggle={handlePressCancelWithReset}>
      <ModalHeader toggle={handlePressCancelWithReset} className="d-flex">
        <IntlMessages id="emailTemplate.addTemplate" />

        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handlePressCancelWithReset}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          <div className="col-lg-12 d-flex flex-column order-lg-1 md-5">
            <Snippets
              selectedTeam={selectedTeam}
              currMailSubRef={currMailSubRef}
              currMaibodyRef={currMaibodyRef}
              lastTestAreaFocus={lastTestAreaFocus}
              setValue={setValue}
            />
          </div>
          {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
          <div className="modal-box-content pt-1">
            <div className="col-lg-12 d-flex flex-column order-lg-1 mb-5 pb-1">
              <InputLabel htmlFor="age-native-helper">{<IntlMessages id="emailTemplate.TemplateName" />}</InputLabel>
              <Controller
                name="TemplateName"
                control={control}
                defaultValue={details.TemplateName ? details.TemplateName : ''}
                rules={{ required: 'This is required' }}
                render={({ field }) => <TextField {...field} margin="none" />}
              />
              <ErrorMessage
                errors={errors}
                name="TemplateName"
                render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
              />
            </div>

            <div className="col-lg-12 d-flex flex-column order-lg-1 mb-5 pb-1 ">
              <InputLabel htmlFor="age-native-helper">{<IntlMessages id="emailTemplate.TemplateSubject" />}</InputLabel>
              <Controller
                name="TemplateSubject"
                control={control}
                defaultValue={details.TemplateSubject ? details.TemplateSubject : ''}
                rules={{ required: 'This is required' }}
                render={({ field }) => (
                  <TextField
                    inputRef={currMailSubRef}
                    onChange={e => {
                      console.log(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    onFocus={e => onTextAreaFocus('TemplateSubject')}
                    margin="none"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="TemplateSubject"
                render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
              />
            </div>
            <div className="col-lg-12 d-flex flex-column order-lg-1 mb-5 ">
              <InputLabel className="mb-3" htmlFor="age-native-helper">
                Template Body
              </InputLabel>
              <Controller
                name="TemplateBody"
                control={control}
                defaultValue={details.TemplateBody ? details.TemplateBody : ''}
                rules={{ required: 'This is required' }}
                render={({ field }) => {
                  return (
                    // <TextField {...field} multiline label={<IntlMessages id="emailTemplate.TemplateBody" />} margin="none" />
                    <ReactQuill
                      ref={currMaibodyRef}
                      value={field.value}
                      style={{ height: '200px' }}
                      modules={textEditorModules}
                      onChange={e => {
                        setValue(field.name, e);
                      }}
                      onFocus={e => onTextAreaFocus('TemplateBody')}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name="TemplateBody"
                render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={classes.footerStyle}>
          <Button type="submit" variant="contained" color="primary">
            {!details.edit ? (
              <IntlMessages id="emailTemplate.addTemplate" />
            ) : (
              <IntlMessages id="emailTemplate.updateTemplate" />
            )}
          </Button>
          <Button onClick={handlePressCancelWithReset}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateEmailTemplate;
