import React from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';
import { styled } from '@material-ui/styles';

const CustomTableCell = styled(TableCell)`
  && {
    padding: 8px; /* Set the desired padding here */
  }
`;

const SkeletonLoader = ({ tableCellCount }) =>
  Array.from({ length: 5 }).map((_, index) => (
    <TableRow key={index}>
      {[...Array(tableCellCount)].map((_, cellIndex) => (
        <CustomTableCell key={cellIndex}>
          <Skeleton variant="text" width={100} height={40} />
        </CustomTableCell>
      ))}
    </TableRow>
  ));

export default SkeletonLoader;
