/**
 * Version 1.0.2
 * Vikas W
 * 20-08-2022
 * -
 * "Task Management"
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
// import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';
/* Sidebar drawer */
import Drawer from '@mui/material/Drawer';
import { Box, TextField, Card, CardContent, FormControl, Avatar, Chip } from '@mui/material';
// import { Button, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography, Divider } from "@mui/material";
// import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import Tostify from 'routes/Pages/Planning/TaskManageTeamsPage/Tostify';
import Dropdown from 'routes/Pages/Planning/TaskManageTeamsPage/Dropdown';
import { getEmailBody } from 'routes/Pages/components/emailBody/emailBody';
// import Button from '@material-ui/core/Button';

const DrawerStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root ': {
      width: 'auto !important',
    },
    // '& .MuiMenuItem-root': {
    //   padding: '8px ',
    //   '& div': {
    //     marginRight: '8px',
    //     fontSize: '0.95rem',
    //   },
    // },
    // top: '40px !important',
  },
  // counterRoot: {
  //   color: theme.palette.common.white,
  //   border: `solid 1px ${theme.palette.common.white}`,
  //   backgroundColor: theme.palette.warning.main,
  //   width: 20,
  // },
}));

const TeamDetailsDrawer = ({ openDrawer, toggleDrawer, teamDetails, getCRMTeamHandler, getTeams, userDetails }) => {
  const dispatch = useDispatch();
  const [members, setMembers] = useState([]);
  const [oldData, setOldData] = useState([]);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [unSelcetedAdmin, setUnSelectedAdmin] = useState([]);
  const [selectedUserAdmin, setSelectedUserAdmin] = useState([]);
  const [disableAdmin, setDisableAdmin] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [newTeamName, setNewTeamName] = useState('');
  const [newTeamDescription, setNewTeamDescription] = useState('');

  useEffect(() => {
    getAllUsers();
    setOldData(teamDetails);
  }, [teamDetails]);

  /* Get All Users */
  const getAllUsers = () => {
    customAxios
      .get(`EmbeddedAnalytics/GetTeamMembers?EATeamId=${teamDetails.Id}`, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var selectedData = data;
          console.log({ selectedData });
          setSelectedMembers(selectedData);
          let unAdmin = [];
          let admin = [];
          selectedData.filter(c => {
            if (c.IsAdmin === 0) {
              unAdmin = [...unAdmin, { value: c.EAUserId, label: c.Email }];
            } else if (c.IsAdmin === 1) {
              admin = [...admin, { value: c.EAUserId, label: c.Email }];
            }
          });
          setUnSelectedAdmin(unAdmin);
          setSelectedUserAdmin(admin);
          customAxios.get('/Users/GetAllUsers', { headers: authHeader() }).then(({ data }) => {
            if (data) {
              var unselectedData = data;
              const newArr = unselectedData.filter(
                instance => selectedData.findIndex(data => data.EAUserId === instance.UserId) === -1,
              );
              var users = newArr.map(c => ({ value: c.UserId, label: c.Email }));
              setMembers(users);
            }
          });
        }
      });
  };
  const sendActivityEmail = async (memberEmail, action, emailSubject) => {
    const filteredAdmin = selectedMembers.filter(admins => admins.IsAdmin === 1);

    let emailList = filteredAdmin.map(element => {
      return element.Email;
    });
    emailList.push(memberEmail);
    const index = emailList.indexOf(userDetails.EmailAddress);
    if (index !== -1) {
      // Swap the elements
      const temp = emailList[0];
      emailList[0] = emailList[index];
      emailList[index] = temp;
    }

    const bodyParameters = {
      EmailSubject: emailSubject,
      EmailHtmlBody: getEmailBody(emailList, action, 'Embedded Analytics'),
      ToList: emailList,
    };

    try {
      customAxios
        .post(`General/SendEmail`, JSON.stringify(bodyParameters), { headers: authHeader() })
        .then(({ data }) => {})
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Failed to accept' });
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const chooseTeamHandler = args => {
    let AddObj = {
      EATeamId: `${teamDetails.Id}`,
      EAMemberUserId: `${args.value}`,
    };

    customAxios.post('EmbeddedAnalytics/AddTeamMembers', AddObj, { headers: authHeader() }).then(data => {
      sendActivityEmail(args.label, 'add', 'Member Added to the team');
      getAllUsers();
      getTeams();
    });
  };
  const deleteTeamHandler = (e, args) => {
    e.preventDefault();
    let AddObj = {
      EATeamId: `${args.TeamId}`,
      EAMemberUserId: `${args.EAUserId}`,
    };
    customAxios.post('EmbeddedAnalytics/DeleteTeamMembers', AddObj, { headers: authHeader() }).then(data => {
      sendActivityEmail(args.Email, 'delete', 'Member Removed from the team');
      getAllUsers();
      getTeams();
    });
  };

  function onInputChangeHandler(args) {
    let InputVal = args.target.value;
    let key = args.target.name;
    let editedData = [];
    console.log(oldData, 'datavis');
    // Check if oldData and key exist before proceeding
    if (oldData && oldData[key] !== undefined) {
      if (
        oldData[key]
          .trim()
          .toString()
          .toLowerCase() !==
        InputVal.trim()
          .toString()
          .toLowerCase()
      ) {
        editedData.push({
          // TableName: 'CRM_Teams',
          ColumnName: key,
          NewTeamName: InputVal,
          TeamId: `${oldData.Id}`,
        });

        dispatch({ type: FETCH_START });
        console.log(editedData[0], 'edited data');
        customAxios
          .post('EmbeddedAnalytics/EditTeam', editedData[0], { headers: authHeader() })
          .then(e => {
            dispatch({ type: FETCH_SUCCESS });
            let newState = { ...oldData };
            newState[key] = InputVal;
            setOldData(newState);
            editedData = [];
            getCRMTeamHandler();
          })
          .catch(function(error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
          });
      }
    } else {
      // Handle the case when oldData or key is undefined or key doesn't exist in oldData
      console.error('oldData or key is undefined.');
    }
  }

  const handleUpdate = () => {
    if (newTeamName !== '' || newTeamDescription !== '') {
      const updatedData = {
        NewTeamName: newTeamName !== '' ? newTeamName : teamDetails.EATeamName,
        NewTeamDescription: newTeamDescription !== '' ? newTeamDescription : teamDetails.EATeamDesc,
      };

      dispatch({ type: FETCH_START });
      customAxios
        .post('EmbeddedAnalytics/EditTeam', { TeamId: teamDetails.Id, ...updatedData }, { headers: authHeader() })
        .then(e => {
          dispatch({ type: FETCH_SUCCESS });
          // Update local state with the new data
          setOldData(prevState => ({ ...prevState, ...updatedData }));
          getCRMTeamHandler();
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  const AdminchooseTeamHandler = args => {
    console.log(args, 'checkarges');
    let AddObjValue = {
      EATeamId: +teamDetails.Id,
      PassedUserId: +args?.value,
      IsAdmin: 1,
    };
    customAxios.post('EmbeddedAnalytics/UpdateTeamAdmin', AddObjValue, { headers: authHeader() }).then(data => {
      getAllUsers();
      getTeams();
    });
  };

  const removeAdmin = (e, args) => {
    console.log(e, args, 'checkarges');
    e.preventDefault();
    let AddObj = {
      EATeamId: +teamDetails.Id,
      PassedUserId: +args?.EAUserId,
      IsAdmin: 0,
    };
    customAxios.post('EmbeddedAnalytics/UpdateTeamAdmin', AddObj, { headers: authHeader() }).then(data => {
      getAllUsers();
      getTeams();
    });
  };

  useEffect(() => {
    onlyOneAdmin();
  }, [selectedMembers, selectedUserAdmin]);

  const onlyOneAdmin = () => {
    if (selectedUserAdmin.length === 1) {
      selectedMembers.filter(check => {
        if (check?.Email === selectedUserAdmin[0]?.label) {
          setDisableAdmin(check?.Email);
        }
      });
    } else {
      setDisableAdmin('');
    }
  };

  const handleCreate = inputValue => {
    setInviteEmail(inputValue);
    const ConfirmUserEmail = {
      TeamId: `${teamDetails.Id}`,
      Email: inputValue,
    };
    customAxios.post('/Account/InviteUserToCrmTeam', ConfirmUserEmail, { headers: authHeader() }).then(data => {
      if (data) {
        dispatch({ type: FETCH_SUCCESS });
        toastCreated();
        // history.push('/verifyInviteUser');
        getAllUsers();
        getTeams();
      } else {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
    });
    // console.log(inputValue, 'inputValue');
  };

  function toastCreated() {
    setShowAlert(true);
  }

  return (
    <PageContainer>
      <React.Fragment>
        <Drawer
          // variant="persistent"
          className={DrawerStyles().root}
          style={{ width: 'auto' }}
          anchor={'right'}
          open={openDrawer}
          onClose={toggleDrawer()}>
          <Box sx={{ width: 650 }} className="cardDetails">
            <Card variant="outlined" sx={{ minWidth: 275 }} style={{ margin: '1rem', height: '96vh' }}>
              <CardContent component="div">
                <div
                  style={{
                    fontSize: '16px',
                    fontFamily: 'normal',
                    fontWeight: 'bold',
                    lineHeight: 1.235,
                    padding: '10px 0px',
                  }}>
                  Analytics Team
                </div>
                <form autoComplete="off">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <label className="cardDetailsLabel">Name</label>
                      </div>
                      <div className="col-md-9 mb-3">
                        <TextField
                          label="Title"
                          id="EATeamName"
                          name="EATeamName"
                          type="text"
                          onChange={e => setNewTeamName(e.target.value)}
                          // value={}
                          // onChange={onChange}
                          onBlur={event => handleUpdate()}
                          // defaultValue={teamDetails.EATeamName}
                          size="small"
                          value={newTeamName || teamDetails.EATeamName}
                          InputLabelProps={{ shrink: false }}
                          style={{ width: '100%', fontSize: '14px' }}
                          inputProps={{ style: { fontSize: 12 } }}
                        />
                        {/* <Button>Update</Button> */}
                      </div>

                      <div className="col-md-3 mb-3">
                        <label className="cardDetailsLabel">Biography</label>
                      </div>
                      <div className="col-md-9 mb-3">
                        <TextField
                          label="Description"
                          id="EATeamDesc"
                          name="EATeamDesc"
                          type="text"
                          onChange={e => setNewTeamDescription(e.target.value)}
                          // value={}
                          // onChange={onChange}
                          // onBlur={event => onInputChangeHandler(event, teamDetails.Id)}
                          onBlur={event => handleUpdate()}
                          // defaultValue={teamDetails.EATeamDesc}
                          size="small"
                          value={newTeamDescription || teamDetails.EATeamDesc}
                          InputLabelProps={{ shrink: false }}
                          style={{ width: '100%', fontSize: '14px' }}
                          inputProps={{ style: { fontSize: 12 } }}
                        />
                      </div>
                      {/* <div className="col-md-12 mb-3">
                      
                        <Button variant="contained" color="primary" onClick={handleUpdate}>
                          Update
                        </Button>
                      </div> */}

                      <div className="col-md-3 mb-3">
                        <label className="cardDetailsLabel">Team Members</label>
                      </div>
                      <div className="col-md-9 mb-3">
                        <FormControl style={{ marginRight: '5px', marginTop: '-8px', width: '100%' }} size="small">
                          {/* <CreatableSelect
                            formatCreateLabel={inputText => `Invite User "${inputText}"`}
                            style={{ zIndex: 9999, marginRight: '5px' }}
                            placeholder={'Select Team Members../ Enter invite member...'}
                            onCreateOption={handleCreate}
                            options={members}
                            // value={members.find(c => c.label == obj.crmStageName)}
                            onChange={chooseTeamHandler}
                          /> */}
                          <Dropdown
                            addWindow={true}
                            options={members}
                            handleCreate={handleCreate}
                            id="value"
                            label="label"
                            prompt="Select Team Members.."
                            onChange={val => {
                              chooseTeamHandler(val);
                            }}
                          />
                        </FormControl>
                      </div>

                      <div className="col-md-12 mb-3">
                        {/* <Stack direction="row" spacing={1}> */}

                        {selectedMembers?.length === 1
                          ? selectedMembers &&
                            selectedMembers?.map((m, index) => {
                              return m.TeamId === teamDetails.Id ? (
                                <Chip
                                  avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                  label={m.Email}
                                  key={index}
                                  // onDelete={e => deleteTeamHandler(e, m)}
                                  style={{ margin: 5 }}
                                />
                              ) : (
                                ''
                              );
                            })
                          : selectedMembers &&
                            selectedMembers?.map((m, index) => {
                              return m.TeamId === teamDetails.Id && disableAdmin === m.Email ? (
                                <Chip
                                  avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                  label={m.Email}
                                  key={index}
                                  // onDelete={e => deleteTeamHandler(e, m)}
                                  style={{ margin: 5 }}
                                />
                              ) : (
                                <Chip
                                  avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                  label={m.Email}
                                  key={index}
                                  onDelete={e => deleteTeamHandler(e, m)}
                                  style={{ margin: 5 }}
                                />
                              );
                            })}

                        {/* {selectedMembers &&
                          selectedMembers.map((m, index) => {
                            // if(m.TeamId === teamDetails.Id){
                            //     return <Chip avatar={<Avatar>{m.Email.charAt(0)}</Avatar>} label={m.Email} key={index}
                            //     onDelete={(e) => deleteTeamHandler(e, m)}
                            //     style={{ margin: 5}}/>
                            // }

                            return m.TeamId === teamDetails.Id ? (
                              <Chip
                                avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                label={m.Email}
                                key={index}
                                onDelete={e => deleteTeamHandler(e, m)}
                                style={{ margin: 5 }}
                              />
                            ) : (
                              ''
                            );
                          })} */}
                        {/* </Stack> */}
                      </div>

                      <div className="col-md-3 mb-3 mt-5">
                        <label className="cardDetailsLabel">Admins</label>
                      </div>
                      <div className="col-md-9 mb-3 mt-5">
                        <FormControl style={{ marginRight: '5px', width: '100%' }} size="small">
                          <Select
                            style={{ marginRight: '5px' }}
                            placeholder={'Select Admin..'}
                            options={unSelcetedAdmin}
                            // value={members.find(c => c.label == obj.crmStageName)}
                            onChange={AdminchooseTeamHandler}
                          />
                        </FormControl>
                      </div>
                      <div className="col-md-12 mb-3">
                        {/* <Stack direction="row" spacing={1}> */}

                        {+selectedUserAdmin?.length === 1
                          ? selectedMembers &&
                            selectedMembers?.map((m, index) => {
                              return m.IsAdmin === 1 ? (
                                <Chip
                                  avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                  label={m.Email}
                                  key={index}
                                  // onDelete={e => removeAdmin(e, m)}
                                  style={{ margin: 5 }}
                                />
                              ) : (
                                ''
                              );
                            })
                          : selectedMembers &&
                            selectedMembers?.map((m, index) => {
                              return m.IsAdmin === 1 ? (
                                <Chip
                                  avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                  label={m.Email}
                                  key={index}
                                  onDelete={e => removeAdmin(e, m)}
                                  style={{ margin: 5 }}
                                />
                              ) : (
                                ''
                              );
                            })}
                        {/* {selectedMembers &&
                          selectedMembers?.map((m, index) => {
                            return m.IsAdmin === 1 ? (
                              <Chip
                                avatar={<Avatar>{m.Email.charAt(0)}</Avatar>}
                                label={m.Email}
                                key={index}
                                onDelete={e => removeAdmin(e, m)}
                                style={{ margin: 5 }}
                              />
                            ) : (
                              ''
                            );
                          })} */}
                        {/* </Stack> */}
                      </div>
                    </div>
                  </div>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Drawer>
        <Tostify showMessage={showAlert} setShowMessage={setShowAlert} inviteEmail={inviteEmail} />
      </React.Fragment>
    </PageContainer>
  );
};

export default TeamDetailsDrawer;
