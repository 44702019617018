import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import { Button } from '@material-ui/core';
import axios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import TextareaAutosize from '@mui/base/TextareaAutosize';
import CreateExpense from 'routes/Pages/Accounting/Expenses/CreateExpense';
import customAxios from '../../util/Api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40vw',
  bgcolor: 'background.paper',
  minHeight: '200px',
  boxShadow: 24,
  p: 4,
};

const invoiceStyles = {
  padding: '9px 24px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  fontSize: '18px',
  gap: '7px',
  fontFamily: 'normal',
  color: '#616161',
  '& p': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& p:nth-child(1)': {
    width: '50%',
  },
};

const menuItemStyle = {
  fontFamily: 'normal',
};

export default function ExpenseModal({ handleCloseActionModal, setAddNewExpense, setModal, setDetails }) {
  const dispatch = useDispatch();

  const [selectActivity, setSelectActivity] = React.useState('');
  const [selectExpense, setSelectExpense] = React.useState('');

  const [expenseDetails, setExpenseDetails] = React.useState({});
  const [expenseData, setExpenseData] = React.useState([]);

  const fetchExpenseData = () => {
    dispatch({ type: FETCH_START });

    axios
      .get('Expense/GetExpenses', { headers: authHeader() })
      .then(({ data }) => {
        setExpenseData(data);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    dispatch({ type: FETCH_START });
  };

  console.log(expenseData, 'expenseData');

  const handleActivityChange = event => {
    setSelectActivity(event.target.value);
    selectExpense && setSelectExpense('');

    if (event.target.value === 'connectToGetExpense') {
      fetchExpenseData();
    }

    if (event.target.value === 'uploadExpense') {
      setAddNewExpense(true);
      setModal(true);
      setDetails([]);
      handleCloseActionModal();
    }
  };
  const handleExpenseChange = event => {
    setSelectExpense(event.target.value);
    setExpenseDetails(expenseData?.find(expense => expense.Id === event.target.value));
  };

  function removeFileExtension(filename) {
    return filename.slice(0, filename.lastIndexOf('.'));
  }

  const dateFormater = date => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Box sx={style}>
      <div className="d-flex mb-3" style={{ gap: '14px' }}>
        <FormControl fullWidth>
          <InputLabel sx={menuItemStyle} id="demo-simple-select-label">
            Activity Type
          </InputLabel>
          <Select
            sx={menuItemStyle}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectActivity}
            label="Select Client"
            onChange={handleActivityChange}>
            <MenuItem sx={menuItemStyle} value={'uploadExpense'}>
              Upload New Expense
            </MenuItem>
            ;
            <MenuItem sx={menuItemStyle} value={'connectToGetExpense'}>
              Connect To Uploaded Receipt
            </MenuItem>
            ;
            <MenuItem sx={menuItemStyle} value={'miscellaneous'}>
              Miscellaneous
            </MenuItem>
            ;
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel sx={menuItemStyle} id="demo-simple-select-label">
            Uploaded Expenses
          </InputLabel>
          <Select
            sx={menuItemStyle}
            disabled={selectActivity !== 'connectToGetExpense'}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectExpense}
            label="Select Expense"
            onChange={handleExpenseChange}>
            {expenseData?.map(expense => {
              return (
                <MenuItem sx={{ display: 'flex', gap: '20px', fontFamily: 'normal' }} key={expense.Id} value={expense.Id}>
                  <span className="mr-3">Description - {expense.ExpenseDescription}</span>
                  <span className="mr-3">Amount - €{expense.ExpenseAmount}</span>
                  <span>Date - {dateFormater(expense.ExpenseDateTime)}</span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      {/* {selectActivity === 'miscellaneous' && (
        <TextareaAutosize
          aria-label="minimum height"
          minRows={8}
          placeholder="Write Notes Here"
          style={{ width: '100%', padding: 5 }}
        />
      )} */}

      {selectActivity === 'connectToGetExpense' && selectExpense && (
        <Box sx={{ marginTop: '12px' }}>
          <Typography variant="h5" sx={{ fontFamily: 'normal' }} gutterBottom>
            Expense Details
          </Typography>
          <Box sx={invoiceStyles}>
            <p>File Name : {removeFileExtension(expenseDetails.ImgName)}</p>
            <p>Description : {expenseDetails.ExpenseDescription}</p>
            <p>Date : {dateFormater(expenseDetails.ExpenseDateTime)}</p>
            <p>Amount: {expenseDetails.ExpenseAmount}</p>
          </Box>
          <div className="mt-3">
            <Button variant="contained" color="primary">
              Confirm
            </Button>
            <Button onClick={handleCloseActionModal}>Cancel</Button>
          </div>
        </Box>
      )}
    </Box>
  );
}
