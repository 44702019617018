export const getEmailBody = (emailList, type, Team, TeamName = '') => {
  let bodyParms = {
    mainHeading: '',
    madeBy: '',
    madeOn: '',
    mainMessage: '',
  };
  console.log(emailList, type, 'email function');

  switch (type) {
    case 'delete':
      bodyParms.mainHeading = `Team Member removed from the ${Team} team - ${TeamName}`;
      bodyParms.madeBy = emailList[0];
      bodyParms.madeOn = new Date().toISOString();
      bodyParms.mainMessage = `${emailList[emailList.length - 1]} has been removed from the ${Team} team - ${TeamName}`;
      break;
    case 'add':
      bodyParms.mainHeading = `New team member added in the ${Team} Team - ${TeamName}`;
      bodyParms.madeBy = emailList[0];
      bodyParms.madeOn = new Date().toISOString();
      bodyParms.mainMessage = `${emailList[emailList.length - 1]} has been Added in ${Team} team - ${TeamName}`;
      break;
    case 'leave':
      bodyParms.mainHeading = `Team member leaves the ${Team} team - ${TeamName}`;
      bodyParms.madeBy = emailList[emailList.length - 1];
      bodyParms.madeOn = new Date().toISOString();
      bodyParms.mainMessage = `${emailList[emailList.length - 1]} has left  ${Team} team - ${TeamName}`;
      break;
    default:
      bodyParms.mainHeading = `General mail `;
      bodyParms.madeBy = ``;
      bodyParms.madeOn = new Date().toISOString();
      bodyParms.mainMessage = `General mail`;
  }

  let emailBody = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml" lang="en">
  
        <head><link rel="stylesheet" type="text/css" hs-webfonts="true" href="https://fonts.googleapis.com/css?family=Lato|Lato:i,b,bi">
          <title>Email template</title>
          <meta property="og:title" content="Email template">
          
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
          
          <style type="text/css">
         
            a{ 
              text-decoration: underline;
              color: inherit;
              font-weight: bold;
              color: #253342;
            }
            
            h1 {
              font-size: 56px;
            }
            
              h2{
              font-size: 28px;
              font-weight: 900; 
            }
            
            p {
              font-weight: 100;
            }
            img{
              max-width:450px
            }
            
            td {
          vertical-align: top;
            }
            
            #email {
              margin: auto;
              width: 700px;
              background-color: white;
              box-shadow:5px 5px rgba(0,0,0,0.100)
            }
            
            button{
              font: inherit;
              background-color: #FF7A59;
              border: none;
              padding: 10px;
              text-transform: uppercase;
              letter-spacing: 2px;
              font-weight: 900; 
              color: white;
              border-radius: 5px; 
              box-shadow: 3px 3px #d94c53;
            }
            
            .subtle-link {
              font-size: 9px; 
              text-transform:uppercase; 
              letter-spacing: 1px;
              color: #CBD6E2;
            }
            .prop{
              font-weight: 600;
              font-size:1rem;
              width:fit-content;
              flex:none;

            }
            .val{
              font-weight: 500;
              font-size:1.1rem;
            }
            .rowCtn{
              display:flex;
              justify-content:start;
              align-items:baseline;
              margin-bottom:15px !important; 
            }
           
            
          </style>
          
        </head>
          
          <body bgcolor="#F5F8FA" style="width: 100%; margin: auto 0; padding:0; font-family:Lato, sans-serif; font-size:18px; color:#33475B; word-break:break-word">
        
       <! View in Browser Link --> 
            
      <div id="email">
            
        
        
               <table role="presentation" width="100%">
                  <tr>
               
                    <td bgcolor="#00A4BD" align="center" style="color: white;">
                  
                   <!-- <img alt="Flower" src="https://hs-8886753.f.hubspotemail.net/hs/hsstatic/TemplateAssets/static-1.60/img/hs_default_template_images/email_dnd_template_images/ThankYou-Flower.png" width="400px" align="middle"> -->
                      
                      <h1> Focus247 </h1>
                      
                      
                    </td>
              </table>
        
        
        
        
        
        <table role="presentation" border="0" cellpadding="0" cellspacing="10px" style="padding: 30px 30px 30px 60px;">
           <tr>
          <td>
          ${`
                 <h2> ${bodyParms.mainHeading} </h2>
                 <div>
                 <div class='rowCtn' >  <span class='prop'>Updated By : &nbsp; </span> <span class='val'>${
                   bodyParms.madeBy
                 }</span></div>
                 </div>
                 <div>
                 <div class='rowCtn' >  <span class='prop'>Updated On  : &nbsp; </span> <span class='val'>${bodyParms.madeOn.slice(
                   0,
                   10,
                 )}</span></div>
                </div>
                 <div>
                   <div class='rowCtn' >  <span class='prop' >Update Message : &nbsp; </span> <span class='val'>  ${
                     bodyParms.mainMessage
                   } </span></div>
                 </div>
                 
               `}
           <div>
                
                   
                 </div>
                
                 <div>
               
                  
                  
                 </div>
                
                 <div>
                  
                  
                 </div>
           </td>
                </tr>
                       </table>
        
       
            </div>
          </body>
            </html>`;
  // console.log(bodyParms);
  return emailBody;
};
