import React, { useEffect, useState } from 'react';
import { Box, IconButton, makeStyles, Popover, Tooltip, useTheme } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CmtCardHeader from '../../../../../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../../../../../@coremat/CmtCard/CmtCardContent';
import CmtList from '../../../../../../@coremat/CmtList';
import CmtCard from '../../../../../../@coremat/CmtCard';

import NotificationItem from './NotificationItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import Badge from '@mui/material/Badge';
import Typography from '@material-ui/core/Typography';

import { useDispatch, useSelector } from 'react-redux';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from 'services/auth/jwt/auth-header';
import { notifications } from 'routes/Pages/components/AppNotification/data';
import { Poller } from 'routes/Pages/util/Poller';
import { fetchError } from 'redux/actions';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .Cmt-card-content': {
      padding: '0 0 16px !important',
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &.active': {
      color: theme.palette.common.white,
    },
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20,
  },
  scrollbarRoot: {
    height: 300,
    padding: '0 8px',
  },
  popoverRoot: {
    '& .MuiPopover-paper': {
      width: 375,
    },
  },
}));

// const headerNotifications = [{NotifyMessage:'test mnotification  only'}];

const HeaderNotifications = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [counter, setCounter] = React.useState(5);
  const [headerNotifications, setHeaderNotifications] = useState([]);
  const theme = useTheme();
  const actions = [
    {
      label: 'Mark All Read',
      onClick: () => markNotificationAsRead(),
    },
  ];
  const { authUser } = useSelector(({ auth }) => auth);
  const onOpenPopOver = event => {
    getNotification();
    setAnchorEl(event.currentTarget);
    setCounter(0);
  };
  const getNotification = () => {
    try {
      customAxios
        .get(`Notifications/GetNotifications`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data?.length > 0) {
            let unReadNotification = data?.filter(noti => noti.IsClicked == 0);
            // console.log(unReadNotification);
            setHeaderNotifications(unReadNotification);
          } else {
            // let currNotification = [{ NotifyMessage: 'test mnotification  only' }];
            setHeaderNotifications([]);
          }
          // setIsLabelLoaded(true);
        })
        .catch(error => {
          let newError = JSON.stringify(error);
          let statusCode = JSON.parse(newError);
          if (statusCode?.message === 'Request failed with status code 401') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/signin';
          } else {
            // dispatch(fetchError('NetWork Error'));
          }
        });
    } catch (e) {
      dispatch(fetchError('Session expired, logging out'));
    }
  };

  Poller(getNotification, 10000, null);
  useEffect(() => {
    getNotification();
  }, []);

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const markNotificationAsRead = id => {
    // console.log(id);
    if (id) {
      setHeaderNotifications(prevState => {
        let newState = [...prevState];
        let recordIndex = newState.findIndex(record => record.Id == id);
        newState.splice(recordIndex, 1);
        return newState;
      });
    } else {
      setHeaderNotifications([]);
    }

    try {
      customAxios
        .get(`Notifications/MarkNotificationAsClicked/${id ? id : ''}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          // console.log(data);
          // setIsLabelLoaded(true);
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box pr={2}>
      <Tooltip title="Notifications">
        <IconButton
          onClick={onOpenPopOver}
          className={clsx(classes.iconRoot, 'Cmt-appIcon', {
            active: counter > 0,
          })}>
          <Badge badgeContent={headerNotifications.length} classes={{ badge: classes.counterRoot }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Notifications"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <CmtCardContent>
            {headerNotifications.length > 0 ? (
              <PerfectScrollbar className={classes.scrollbarRoot}>
                <CmtList
                  data={headerNotifications}
                  renderRow={(item, index) => <NotificationItem key={index} item={item} markRead={markNotificationAsRead} />}
                />
              </PerfectScrollbar>
            ) : (
              <Box p={6}>
                <Typography variant="body2">No notifications found</Typography>
              </Box>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </Box>
  );
};

export default HeaderNotifications;
