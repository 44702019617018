import React, { createContext, useReducer, Dispatch, ReactNode } from 'react';

const initialState = {
  linkSuccess: false,
  isItemAccess: false,
  linkToken: '', // Don't set to null or error message will show up briefly when site loads
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  products: ['transactions', 'transactions_refresh'],
  linkTokenError: {
    error_type: '',
    error_code: '',
    error_message: '',
  },
};

// type QuickstartAction = {
//   type: "SET_STATE";
//   state: Partial<QuickstartState>;
// };

// interface QuickstartContext extends QuickstartState {
//   dispatch: Dispatch<QuickstartAction>;
// }

const PlaidContext = createContext(initialState);

const { Provider } = PlaidContext;
export const PlaidProvider = props => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_STATE':
        return { ...state, ...action.state };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

export default PlaidContext;
