/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useEffect, useRef, useState } from 'react';
import { ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../util/IntlMessages';
import InputLabel from '@material-ui/core/InputLabel';
import Drawer from '@mui/material/Drawer';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { Box, Select, MenuItem, TextareaAutosize } from '@material-ui/core';
import moment from 'moment';
import { Button, List, Modal, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import DescriptionInput from '../TaskmanagementPage/components/DescriptionInput';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';
import { useDropzone } from 'react-dropzone';
import fileDownload from 'js-file-download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CollectionsIcon from '@mui/icons-material/Collections';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DescriptionIcon from '@mui/icons-material/Description';
import { object } from 'prop-types';
import ShowTasks from './ShowTasks';
import ProjectAttributes from './projectAttributes';
import useProjectAttributes from './hooks/useProjectAttributes';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
  footerStyle: {
    marginTop: 30,
  },
}));

const createOption = label => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

function TabPanelSideBar(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  // marginBottom: 8,
  // marginRight: 8,
  margin: 0,
  width: 40,
  height: 40,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  alignItems: 'center',
  width: '100%',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const Modalestyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
};

const EditProject = ({
  details,
  toggle,
  onClientsClose,
  filterModalOpen,
  teams,
  tmMembers,
  handleRefresh,
  selectedTeamValue,
  teamMembers,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const descRef = useRef(null);
  const [taskDescription, setTaskDescription] = useState(null);

  const [isEdtingDescription, setIsEdtingDescription] = useState(false);

  // date picker
  const [open, setOpen] = React.useState(false);
  const [endOpen, setEndOpen] = React.useState(false);
  const [startDateValue, setStartDateValue] = React.useState(details.startDate ? new Date(details.startDate) : null);
  const [dueDateValue, setDueDateValue] = React.useState(details.dueDate ? new Date(details.dueDate) : null);

  //Dropzone files
  const [files, setFiles] = useState([]);
  const responses = [];
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState([]);
  const [attachmentName, setAttachmentName] = useState();
  const [replaceData, setReplaceData] = useState();
  const [getActivities, setGetActivities] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const [sValue, setSValue] = React.useState(1);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({});

  const formatedDate = date => moment(date).format('yyyy-MM-DD');
  React.useEffect(() => {
    if (details.edit) {
      setValue('pName', details.pName);
      setValue('pHexColor', details.pHexColor);
      setValue('pDescription', details.pDescription);
      setValue('startDate', formatedDate(details.pStartDate));
      setStartDate(formatedDate(details.pStartDate));
      setValue('pDueDate', formatedDate(details.pDueDate));
      setEndDate(formatedDate(details.pDueDate));
      setValue('pAssignedToId', details.pAssignedTo);
      setAssignedTo(details.pAssignedTo);
      setValue('tmTeamId', details.pTeamId);
      setSelectedTeam(details.pTeamId);
      imageDataUpdate();
    }
  }, [details]);

  const handleReset = () => {
    reset({
      pName: '',
      pHexColor: '',
      pDescription: '',
      startDate: '',
      pDueDate: '',
      pAssignedToId: '',
      tmTeamId: '',
    });
    setStartDate(null);
    setEndDate(null);
    setSelectedTeam('');
    setAssignedTo('');
  };
  const handlePressCancelWithReset = () => {
    onClientsClose();
    handleReset();
  };

  useEffect(() => {
    if (details.pDescription) {
      setTaskDescription(details.pDescription);
    }
    return () => {
      setTaskDescription('');
    };
  }, [details.pDescription]);

  const getFieldName = field => {
    switch (field) {
      case 'startDate':
        return 'pStartDate';
      case 'pAssignedToId':
        return 'pAssignedTo';
      case 'tmTeamId':
        return 'pTeamId';
      default:
        return field;
    }
  };

  const handleUpdate = field => {
    const data = getValues();
    const fieldName = getFieldName(field);
    const payload = {
      tableName: 'TM_Projects',
      columnName: fieldName,
      newValue: data[field],
      primaryKeyValue: details.pId.toString(),
      tmTeamId: data['tmTeamId'].toString(),
    };
    onSubmit(payload);
  };

  const handleDescBlurEditor = () => {
    // setTimeout(() => {
    console.log(details.pId.toString(), 'details.pId.toString()');
    const bodyDescription = {
      tableName: 'TM_Projects',
      columnName: 'pDescription',
      newValue: descRef?.current?.state?.value,
      primaryKeyValue: details.pId.toString(),
      tmTeamId: details.pTeamId,
    };
    console.log(details.pDescription === descRef?.current?.state?.value, 'iwiwi');
    if (details.pDescription === descRef?.current?.state?.value) return;
    details.pDescription = descRef?.current?.state?.value;
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Project/EditProject', bodyDescription, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        handleRefresh();
        // getStatusHandler();
        // handleUpdateDescriptionInBoard(descRef?.current?.state?.value, details.id);
        setIsEdtingDescription(prevState => false);
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        setIsEdtingDescription(prevState => false);
      });

    // }, 50);
  };

  /**
   * This function is called after submiting the form
   *
   * @param formData
   */

  const onSubmit = payload => {
    let url = 'Project/EditProject';
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, payload, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        handleRefresh();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error.response.data.title);
          var errorList = error.response.data.errors;
          if (errorList) {
            //iterate through errors {} and add key values
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleChangeS = (event, newValue) => {
    setSValue(newValue);
  };

  const handleDescChangeEditor = (html, delta, source) => {
    // console.log(html);
    // setTaskDescription(html);
  };

  function a11yPropsSideBar(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handlestartDateHandler = dateChange => {
    setStartDateValue(dateChange);
    setValue('startDate', dateChange);
    setStartDate(dateChange);
    handleUpdate('startDate');
    setOpen(false);
  };

  const handleDueDateHandler = dateChange => {
    setDueDateValue(dateChange);
    setEndDate(dateChange);
    setValue('pDueDate', dateChange);
    handleUpdate('pDueDate');
    setEndOpen(false);
  };

  const handleOpen = file => {
    setDeleteAttachment(file);
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      );

      acceptedFiles.map(file => {
        let filterData = files.filter(filename => filename?.pAttachmentName === file?.name);
        if (filterData.length) {
          setOpenEditModal(true);
          setAttachmentName(file?.name);
        }
        const formdata = new FormData();
        formdata.append('pId', details.pId.toString());
        formdata.append('pTeamId', details.pTeamId);
        formdata.append('flagOverwrite', filterData?.length ? 1 : 0);
        formdata.append('file', file);

        filterData.length ? setReplaceData(formdata) : updateStages(formdata);
      });
    },
  });
  const updateStages = async obj => {
    responses.push(await customAxios.post('/Project/AddProjectAttachment', obj, { headers: authHeader() }));
    imageDataUpdate();
  };

  const EditAttachmentFile = () => {
    updateStages(replaceData);
    setOpenEditModal(false);
  };
  const handleDownload = file => {
    const bodyDescription = {
      AttachmentId: file?.Id,
      ProjectId: details.pId.toString(),
      TeamId: details.pTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Project/DownloadProjectAttachment', bodyDescription, { headers: authHeader(), responseType: 'blob' })
      .then(res => {
        fileDownload(res.data, file?.pAttachmentName);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  //project attribute
  const {
    getAttributeData,
    getContactAttributes,
    getContactAttributesLabel,
    AddNewField,
    setNewLabelValue,
    setNewAttributeValue,
    attributeKeyValuePair,
    newLabelValue,
    newAttributeValue,
    setAttributeKeyValuePair,
    onAttributeChangeHandler,
  } = useProjectAttributes(details.pId, details.pTeamId);

  const thumbs = files?.map((file, index) => {
    let type = file?.pAttachmentName?.split('.')[1];
    return (
      <div key={file?.name} className="d-flex flex-column m-1" style={{ width: '12rem' }}>
        <Tooltip
          title={file?.pAttachmentName}
          placement="top"
          arrow
          className="d-flex flex-row"
          style={{ cursor: 'pointer' }}>
          <div className="d-flex flex-row">
            <div style={thumb} styles={{ display: 'flex', flexDirection: 'column' }}>
              <div style={thumbInner}>
                {type === 'pdf' ? (
                  <PictureAsPdfIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'doc' || type === 'dot' ? (
                  <TextSnippetOutlinedIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'xls' || type === 'xlt' || type === 'xla' || type === 'xlsx' ? (
                  <DescriptionIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'txt' || type === 'csv' || type === 'odt' || type === 'pptx' ? (
                  <TextSnippetIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'movie' ||
                  type === 'mp4' ||
                  type === 'ogv' ||
                  type === 'webm' ||
                  type === 'wmv' ||
                  type === 'avi' ||
                  type === 'f4v' ||
                  type === 'flv' ||
                  type === 'mpeg' ? (
                  <VideoFileIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : type === 'png' || type === 'jpeg' || type === 'jpg' || type === 'gif' ? (
                  <CollectionsIcon style={{ width: 40, height: 29, color: 'red' }} />
                ) : (
                  <InsertDriveFileIcon style={{ width: 40, height: 29, color: 'red' }} />
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '5px',
              }}>
              <p style={{ fontSize: '11px', fontWeight: 700 }}>
                {/* {file?.tAttachmentName} */}
                {file?.pAttachmentName?.length > 20 ? file?.pAttachmentName.substring(0, 20) + '...' : file?.pAttachmentName}
              </p>
              <p style={{ fontSize: '8px', color: 'green' }}>
                {moment
                  .utc(file?.tAttachmentDate)
                  .local()
                  .format('DD-MM-YYYY')}{' '}
                at {''}
                {moment
                  .utc(file?.tAttachmentDate)
                  .local()
                  .format('hh:mm A')}
              </p>
            </div>
          </div>
        </Tooltip>
        <div className="d-flex flex-row justify-content-between pt-1 px-1">
          <div
            onClick={() => handleDownload(file)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              color: 'blue',
              cursor: 'pointer',
              fontSize: '12px',
            }}>
            Download
          </div>
          <div
            onClick={() => handleOpen(file)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '5px',
              color: '#b31616',
              cursor: 'pointer',
              fontSize: '12px',
            }}>
            Delete
          </div>
        </div>
      </div>
    );
  });

  const imageDataUpdate = () => {
    let attach = [];
    let getobject = {
      ProjectId: details.pId.toString(),
      TeamId: details.pTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios.post('/Project/GetProjectAttachments', getobject, { headers: authHeader() }).then(({ data, status }) => {
      dispatch({ type: FETCH_SUCCESS });
      let id = 1;
      setFiles(data);
      setGetActivities(attach);
    });
  };

  const DeleteAttachmentFile = () => {
    let newFiles = [];
    const bodyDescription = {
      Id: deleteAttachment?.Id,
      tmTeamId: details.tmTeamId,
    };
    files.filter(object => {
      if (object?.Id !== deleteAttachment?.Id) {
        newFiles = object;
      }
    });
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Project/DeleteProjectAttachment', bodyDescription, { headers: authHeader() })
      .then(data => {
        setOpenModal(false);
        imageDataUpdate();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const edithandleClose = () => {
    setOpenEditModal(false);
    imageDataUpdate();
  };

  return (
    <React.Fragment>
      <Drawer anchor={'right'} open={filterModalOpen} onClose={toggle} style={{ width: '600PX' }}>
        <Box sx={{ width: '100%' }} className="cardDetails">
          <ModalHeader toggle={handlePressCancelWithReset} className="d-flex">
            {details.edit ? (
              <IntlMessages id="projectsection.editProject" />
            ) : (
              <IntlMessages id="projectsection.addProject" />
            )}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handlePressCancelWithReset}
              style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
              <span aria-hidden="true">×</span>
            </button>
          </ModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
            <ModalBody>
              {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
              <div className="modal-box-content">
                <div className="col-lg-12 d-flex flex-column order-lg-1 mb-5">
                  <Controller
                    name="pName"
                    control={control}
                    defaultValue={details.pName ? details.pName : ''}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={<IntlMessages id="projectsection.ProjectName" />}
                        margin="none"
                        onBlur={() => handleUpdate('pName')}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pName"
                    render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                  />
                </div>
                <div className="col-lg-12 d-flex w-full flex-column order-lg-1 mb-5 ">
                  <InputLabel id="demo-simple-select-label">Project Color</InputLabel>
                  <Controller
                    name="pHexColor"
                    control={control}
                    defaultValue={details.pHexColor ? details.pHexColor : ''}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => {
                      return (
                        <input
                          {...field}
                          type="color"
                          disabled={details?.IsAdmin === 0}
                          onBlur={e => {
                            setValue('pHexColor', e.target.value);
                            handleUpdate('pHexColor');
                          }}
                          style={{ marginBottom: '10px' }}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pHexColor"
                    render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                  />
                  <div style={{ borderBottom: '1px solid gray', width: '100%', margin: 'auto' }}></div>
                </div>
                {/* <div className="col-lg-12 d-flex w-full flex-column order-lg-1 mb-5 ">
                  <Controller
                    name="pDescription"
                    control={control}
                    defaultValue={details.pDescription ? details.pDescription : ''}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        // label={<IntlMessages id="projectsection.ProjectSubject" />}
                        margin="none"
                        aria-label="minimum height"
                        className="project-description-textarea"
                        minRows={2}
                        placeholder="Project Description"
                        style={{ border: 'none', borderBottom: '1px solid gray' }}
                        onBlur={() => handleUpdate('pDescription')}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pDescription"
                    render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                  />
                </div> */}
                <div className="row">
                  <div className="col-md-12 mb-3 ml-2">
                    <DescriptionInput
                      name="TaskDescription"
                      placeholder="Description"
                      multiline
                      fullWidth
                      // value={quillInputValue}
                      value={taskDescription}
                      // onBlur={handleDescBlurEditor}
                      onChange={handleDescChangeEditor}
                      // handleDescKeyDown={handleDescKeyDown}
                      variant="outlined"
                      size="100%"
                      height={200}
                      // members={mentionDescMembers}
                      // id={selectedCard.id}
                      // members={mentionTeamMembers}
                      editorRef={descRef}
                      isEdtingDescription={isEdtingDescription}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      style={{ position: 'absolute', top: 5, left: '88%' }}
                      onClick={handleDescBlurEditor}>
                      Save
                    </Button>
                  </div>
                </div>

                <div className="flex-row d-flex mt-3">
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    <InputLabel id="demo-simple-select-label">Start Date</InputLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'This is required' }}
                      defaultValue={details.startDate ? details.startDate : ''}
                      render={({ field }) => {
                        return (
                          // <TextField
                          //   required={true}
                          //   type="date"
                          //   margin="normal"
                          //   onBlur={() => handleUpdate('startDate')}
                          //   style={{ marginTop: '0px' }}
                          //   value={startDate == null ? '' : startDate}
                          //   onChange={e => {
                          //     setValue('startDate', e.target.value);
                          //     setStartDate(e.target.value);
                          //   }}
                          // />
                          <div className="DateContainer">
                            <CustomeDatePicker
                              open={open}
                              OpenDate={() => setOpen(true)}
                              CloseDate={() => setOpen(false)}
                              value={startDate}
                              // onChange={handleDueDateChange}
                              handleDateHandler={handlestartDateHandler}
                            />
                          </div>
                        );
                      }}
                    />
                    {/* )} */}
                    <ErrorMessage
                      errors={errors}
                      name="startDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    <InputLabel id="demo-simple-select-label">End Date</InputLabel>
                    {details.length === 0 ? (
                      <TextField
                        required={true}
                        type="date"
                        mask=""
                        opento="month"
                        onBlur={() => handleUpdate('endDate')}
                        margin="normal"
                        style={{ marginTop: '0px' }}
                        value={endDate == null ? '' : endDate}
                        onChange={e => {
                          setEndDate(e.target.value);
                          setValue('pDueDate', e.target.value);
                        }}
                      />
                    ) : (
                      <Controller
                        name="pDueDate"
                        control={control}
                        rules={{ required: 'This is required' }}
                        defaultValue={details.pDueDate ? details.pDueDate : ''}
                        render={({ field }) => (
                          // <TextField
                          //   {...field}
                          //   type="date"
                          //   margin="normal"
                          //   style={{ marginTop: '0px' }}
                          //   onBlur={() => handleUpdate('pDueDate')}
                          // />
                          <div className="DateContainer">
                            <CustomeDatePicker
                              open={endOpen}
                              OpenDate={() => setEndOpen(true)}
                              CloseDate={() => setEndOpen(false)}
                              value={endDate}
                              // onChange={handleDueDateChange}
                              handleDateHandler={handleDueDateHandler}
                            />
                          </div>
                        )}
                      />
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="pDueDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row mt-3">
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    <Controller
                      name="tmTeamId"
                      control={control}
                      rules={{ required: 'This is required' }}
                      defaultValue={details.tmTeamId ? details.tmTeamId : ''}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <>
                            <InputLabel id="demo-simple-select-label">Select Team</InputLabel>
                            <Select
                              value={selectedTeam}
                              defaultValue={selectedTeam}
                              onBlur={() => handleUpdate('tmTeamId')}
                              onChange={event => {
                                setSelectedTeam(event.target.value);
                                setValue('tmTeamId', event.target.value);
                              }}>
                              {teams.map(team => {
                                return (
                                  <MenuItem value={team.Id} key={team.Id}>
                                    {' '}
                                    {team.tmTeamName}{' '}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </>
                        );
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="tmTeamId"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    <Controller
                      name="pAssignedToId"
                      control={control}
                      defaultValue={details.pAssignedToId ? details.pAssignedToId : ''}
                      rules={{ required: 'This is required' }}
                      render={({ field: { onChange, value, ref } }) => {
                        let selectedMember = tmMembers.filter(instance => instance.TeamId === selectedTeam) || [];
                        return (
                          <>
                            <InputLabel id="demo-simple-select-label">Select Assignee</InputLabel>

                            <Select
                              value={assignedTo}
                              onBlur={() => handleUpdate('pAssignedToId')}
                              onChange={event => {
                                setAssignedTo(event.target.value);
                                setValue('pAssignedToId', event.target.value);
                              }}>
                              {selectedMember.map(team => {
                                return (
                                  <MenuItem value={team.TMUserId} key={team.TMUserId}>
                                    {' '}
                                    {team.Email}{' '}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="list-group" style={{ fontSize: '1rem', color: 'black !important' }}>
                  <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={sValue} onChange={handleChangeS} aria-label="basic tabs example">
                        <Tab
                          label="Summary"
                          {...a11yPropsSideBar(0)}
                          wrapped
                          style={{ fontFamily: 'normal', textTransform: 'inherit' }}
                        />
                        <Tab
                          label="Attachments"
                          {...a11yPropsSideBar(1)}
                          wrapped
                          style={{ fontFamily: 'normal', textTransform: 'inherit' }}
                        />
                        <Tab
                          label="Work log"
                          {...a11yPropsSideBar(2)}
                          wrapped
                          style={{ fontFamily: 'normal', textTransform: 'inherit' }}
                        />
                        <Tab
                          label="Tasks"
                          {...a11yPropsSideBar(3)}
                          wrapped
                          style={{ fontFamily: 'normal', textTransform: 'inherit' }}
                        />
                        <Tab
                          label="Attributes"
                          {...a11yPropsSideBar(4)}
                          wrapped
                          style={{ fontFamily: 'normal', textTransform: 'inherit' }}
                        />
                      </Tabs>
                    </Box>
                    <TabPanelSideBar value={sValue} index={0}>
                      Summary
                    </TabPanelSideBar>
                    <TabPanelSideBar value={sValue} index={1}>
                      <div className="row">
                        <div className="col-md-12 mb-3 ml-2">
                          <Box className="dropzone-card">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <Typography variant={'body1'}>
                                Drag 'n' drop some files here, or click to select files
                              </Typography>
                            </div>
                          </Box>

                          <List disablePadding sx={{ display: 'flex' }}>
                            <div className="attachmentConatiner">{thumbs}</div>
                          </List>
                        </div>
                      </div>
                    </TabPanelSideBar>
                    <TabPanelSideBar value={sValue} index={2}>
                      Work log
                    </TabPanelSideBar>
                    <TabPanelSideBar value={sValue} index={3}>
                      <ShowTasks selectedTeamValue={selectedTeamValue} projectDetails={details} teamMembers={teamMembers} />
                    </TabPanelSideBar>
                    <TabPanelSideBar value={sValue} index={4}>
                      <ProjectAttributes
                        newAttributeValue={newAttributeValue}
                        newLabelValue={newLabelValue}
                        AddNewField={AddNewField}
                        attributeKeyValuePair={attributeKeyValuePair}
                        getAttributeData={getAttributeData}
                        getContactAttributes={getContactAttributes}
                        getContactAttributesLabel={getContactAttributesLabel}
                        setNewAttributeValue={setNewAttributeValue}
                        setNewLabelValue={setNewLabelValue}
                        setAttributeKeyValuePair={setAttributeKeyValuePair}
                        onAttributeChangeHandler={onAttributeChangeHandler}
                      />
                    </TabPanelSideBar>
                  </Box>
                </div>
              </div>
            </ModalBody>
          </form>
        </Box>
      </Drawer>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={Modalestyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: 'center' }}>
            Are you sure delete{' '}
            <span style={{ color: '12px', fontWeight: 'bold' }}>{deleteAttachment?.pAttachmentName}</span> {''} this
            Attachment
          </Typography>
          <div className="deleteModalButon">
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={DeleteAttachmentFile}>
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEditModal}
        onClose={edithandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={Modalestyle}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ textAlign: 'center' }}>
            This File {''}
            <span style={{ color: '12px', fontWeight: 'bold' }}>{attachmentName}</span> {''} Already Exists,Do you want
            replace it?
          </Typography>
          <div className="deleteModalButon">
            <Button variant="outlined" onClick={edithandleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={EditAttachmentFile}>
              Replace
            </Button>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditProject;
