import React, { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Tooltip, Typography, Card, CardContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useForm, Controller } from 'react-hook-form';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// import { closeFilterDialog } from '@syncfusion/ej2-react-grids';
import { ErrorMessage } from '@hookform/error-message';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import IntlMessages from 'routes/Pages/util/IntlMessages';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';

const TaskFilterDialog = props => {
  const {
    setFilterSelectedSend,
    setFilterSelectedData,
    filterData,
    fetchData,
    setIsFilterApplied,
    getUserProfileData,
  } = props;
  const [currDueDate, setCurrDueDate] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm({});

  // date filter

  const [open, setOpen] = React.useState(false);
  const [endOpen, setEndOpen] = React.useState(false);

  const handlestartDateHandler = (dateChange, cardId) => {
    setStartDateFilter(dateChange);
    setValue('startDate', dateChange);
    setOpen(false);

    // handleDueDateChange(dateChange, cardId);
  };

  const handleDueDateHandler = dateChange => {
    setEndDateFilter(dateChange);
    setValue('endDate', dateChange);
    setEndOpen(false);
  };

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const closeFilterDialog = () => {
    setOpenFilterDialog(false);
  };
  const handleClose = () => {
    closeFilterDialog();
  };

  function getNextDueDate(currDate) {
    let tempDate = new Date(currDate);
    tempDate.setDate(tempDate.getDate() + 30);
    let newDueDate = tempDate.toISOString().split('T')[0];
    // DueDate = newDueDate;
    setCurrDueDate(newDueDate);

    //  control._fields.dueDate._f.value = newDueDate

    // console.log(dateString);
  }

  /**
   * Function called when we submit the form
   *
   * @param formData The responses of the form
   */

  const onSubmit = formData => {
    let data = {
      startDate: startDateFilter,
      endDate: endDateFilter,
    };
    setFilterSelectedData(data);
    setFilterSelectedSend(data);
    setOpenFilterDialog(false);
    setIsFilterApplied(true);
  };

  const handleClearButton = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    getUserProfileData();
    setIsFilterApplied(false);
    setFilterSelectedData([]);
  };

  return (
    <div>
      <Tooltip
        title={
          <div>
            <Typography>Advance Filters</Typography>
          </div>
        }
        style={{ background: 'white' }}
        arrow={true}>
        <Button
          variant="contained"
          className="px-2"
          onClick={() => {
            if (!openFilterDialog) {
              setOpenFilterDialog(true);
              // getUserProfileData();
            }
          }}
          style={{
            height: 40,
            minWidth: '25px',
            background: 'none',
            color: '#3f51b5',
          }}>
          <FilterAltIcon />
        </Button>
      </Tooltip>
      <Dialog
        // fullWidth={fullWidth}
        // style={{width:'400px'}}
        maxWidth="md"
        open={openFilterDialog}
        onClose={handleClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0,0,0,0.250)' }}>
          <div className="w-100 d-flex justify-content-between ">
            <div>Advance Filters</div>
            <div className="d-flex" style={{ gap: '2rem' }}>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseRoundedIcon />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <Card style={{ marginBottom: '1rem', border: 'none', boxShadow: 'none', paddingInline: '0' }}>
            <CardContent component="div" style={{ padding: '1.5rem', width: '600px', height: '200px' }}>
              <div style={{ fontSize: '1.2rem', color: 'black' }}>Issue Date</div>
              <form
                name="myForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
                <div className="row no-gutters">
                  <div className="col-lg-5 d-flex flex-column invoice_label mb-4 order-lg-1">
                    <br />
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.startDate" />
                    </InputLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'This is required' }}
                      // defaultValue={IssueDate ? IssueDate : ''}
                      render={({ field: { onChange, ...restField } }) => (
                        // <TextField
                        //   {...field}
                        //   onChange={e => {
                        //     field.onChange(e);
                        //     // getNextDueDate(e.target.value);
                        //   }}
                        //   type="date"
                        //   margin="normal"
                        //   style={{ marginTop: '0px' }}
                        // />
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={open}
                            OpenDate={() => setOpen(true)}
                            CloseDate={() => setOpen(false)}
                            value={startDateFilter}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handlestartDateHandler}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="startDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>

                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="col-lg-6 d-flex flex-column invoice_label mb-4 order-lg-1">
                    <br />
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.endDate" />
                    </InputLabel>
                    {/* {details.length === 0 ? ( */}

                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: 'This is required' }}
                      // defaultValue={DueDate ? DueDate : ''}
                      render={({ field }) => (
                        // <TextField
                        //   {...field}
                        //   onChange={e => {
                        //     field.onChange(e);
                        //   }}
                        //   type="date"
                        //   margin="normal"
                        //   style={{ marginTop: '0px' }}
                        // />
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={endOpen}
                            OpenDate={() => setEndOpen(true)}
                            CloseDate={() => setEndOpen(false)}
                            value={endDateFilter}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handleDueDateHandler}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="endDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // disabled={details.length === 0 ? false : true}
                >
                  <IntlMessages id="invoices.apply" />
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  // disabled={details.length === 0 ? false : true}
                  onClick={handleClearButton}>
                  Clear
                </Button>
              </form>
            </CardContent>
          </Card>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default TaskFilterDialog;
