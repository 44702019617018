import React, { useEffect, useState } from 'react';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

const useProjectAttributes = (projectId, teamId) => {
  const [attributeKeyValuePair, setAttributeKeyValuePair] = useState({});
  const dispatch = useDispatch();
  const [newAttributeValue, setNewAttributeValue] = useState('');
  const [newLabelValue, setNewLabelValue] = useState('');
  const [currentLabelData, setCurrentLabelData] = useState([]);
  function getAttributeData() {
    getContactAttributesLabel(projectId, data => getContactAttributes(projectId, data));
  }
  useEffect(() => {
    if (teamId != null && projectId != null) {
      getAttributeData();
    }
  }, [projectId, teamId]);

  function getContactAttributes(args, parm) {
    dispatch({ type: FETCH_START });
    try {
      let obj = {
        pId: projectId,
        pTeamId: teamId,
      };
      customAxios
        .post(`/Task/GetTMProjectAttributes/`, obj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          let AttrData = data.reduce((a, v) => ({ ...a, [v.LabelId]: v }), {});
          if (parm) {
            let keyValueAtt = Object.entries(parm).reduce(
              (a, v) => ({
                ...a,
                [v[0]]: {
                  labelInfo: v,
                  attrInfo: AttrData[parseInt(v[0])],
                  labelValue: AttrData[parseInt(v[0])] ? AttrData[parseInt(v[0])].LabelValue : '',
                  isNewAttribute: AttrData[parseInt(v[0])] ? false : true,
                },
              }),
              {},
            );
            setAttributeKeyValuePair(keyValueAtt);
          }

          // setIsAttributeLoaded(true);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  function getContactAttributesLabel(args, fnc) {
    let obj = {
      pTeamId: teamId,
    };
    let url = 'Task/GetTMAttributesLabel/';
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(url, obj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          console.log(data);
          let labelData = data.reduce((a, v) => ({ ...a, [v.Id]: v.LabelName }), {});
          setCurrentLabelData(labelData);
          if (fnc) {
            fnc(labelData);
          }
          // setIsLabelLoaded(true);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  const AddNewField = () => {
    if (newLabelValue.trim() == '' || newAttributeValue.trim() == '') {
      dispatch({ type: FETCH_ERROR, payload: 'Please Enter Both the Values' });
      return;
    }

    let currLabel = newLabelValue.toLowerCase().trim();

    for (const item in attributeKeyValuePair) {
      if (attributeKeyValuePair[item].labelInfo[1].toLowerCase().trim() == currLabel) {
        dispatch({ type: FETCH_ERROR, payload: 'Duplicate label, Please enter a different label ' });
        return;
      }
    }

    AddNewLabel(newLabelValue);
  };
  const AddNewLabel = value => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(
          `/Task/AddTMAttributesLabel`,
          {
            pTeamId: teamId,
            labelName: value,
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          getContactAttributesLabel(projectId, latestLabel => AddNewAttribute(newAttributeValue, latestLabel));
          // AddNewAttribute(newAttributeValue)
          dispatch({ type: FETCH_SUCCESS });
        })
        .then(() => {})
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const AddNewAttribute = (value, latestLabels) => {
    let label = newLabelValue.toLowerCase();
    let labelId = Object.entries(latestLabels).filter(item => {
      if (item[1]) if (item[1].toLowerCase() == label) return item;
    });
    let obj = {
      pId: projectId,
      pTeamId: teamId,
      labelId: parseInt(labelId[0]),
      labelValue: value,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`/Task/AddTMProjectAttributes`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          // getContactAttributes(contactInfo);
          getAttributeData();

          // dispatch({ type: FETCH_SUCCESS, });
          dispatch({ type: FETCH_SUCCESS, payload: 'Attribute Added' });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
    setNewAttributeValue('');
    setNewLabelValue('');
  };

  const onAttributeChangeHandler = (value, info) => {
    console.log(info);
    if (!info.attrInfo) {
      if (value.trim() === '') return;
      // let obj = {
      //   pId: projectId,
      //   pTeamId: teamId,
      //   labelId: parseInt(info.labelInfo[0]),
      //   labelValue: value,
      //   id: parseInt(info.labelInfo[0]),
      // };
      let obj = {
        pId: projectId,
        pTeamId: teamId,
        labelId: parseInt(info.labelInfo[0]),
        labelValue: value,
      };
      AddAttribute(obj);
      // EditAttribute(obj, info.labelInfo[0]);
      return;

      // Add new Attribute
    }

    if (info.attrInfo.LabelValue.trim().toLowerCase() !== value.trim().toLowerCase()) {
      let obj = {
        id: info.attrInfo.ProjectAttributeId,
        labelValue: value,
        pId: projectId,
        pTeamId: teamId,
      };
      EditAttribute(obj, info.attrInfo.ProjectAttributeId);
    }
  };
  const AddAttribute = obj => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`/Task/AddTMProjectAttributes`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          getAttributeData();

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const EditAttribute = (obj, editIndex) => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`/Task/EditTMProjectAttributes`, obj, {
          headers: authHeader(),
        })
        .then(() => {
          getContactAttributes(currentLabelData);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  return {
    getAttributeData,
    getContactAttributes,
    getContactAttributesLabel,
    AddNewField,
    setNewLabelValue,
    setNewAttributeValue,
    attributeKeyValuePair,
    newAttributeValue,
    newLabelValue,
    setAttributeKeyValuePair,
    onAttributeChangeHandler,
  };
};

export default useProjectAttributes;
