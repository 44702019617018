import React, { useEffect, useRef, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './styles.css';
import { Button } from '@material-ui/core';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import IosShareIcon from '@mui/icons-material/IosShare';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { NavLink } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';

const menuStyles = {
  fontFamily: 'normal',
};

export default function PowerBIReport({
  accessToken,
  embedUrl,
  embedReportId,
  handleFullScreenModalClose,
  fetchNewToken,
  exisitngReportId,
  reportName,
  accTeam,
  datatsetRefreshHistory,
  getLastUpdatedTime,
  setRefreshStatusInfo,
  setOpenStatusSnackbar,
}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const reportRef = useRef(null);
  const [exportingObj, setExportingObj] = useState(null);

  // refresh dataset status
  const refreshDatasetStatus = datatsetRefreshHistory?.refreshes?.value[0]?.status;
  const findStatus = datatsetRefreshHistory?.refreshes?.value.find(element => element.status === 'Completed');

  useEffect(() => {
    if (findStatus) {
      processRefreshState();
    }
  }, [findStatus]);

  function processRefreshState() {
    switch (refreshDatasetStatus) {
      case 'Unknown':
        setOpenStatusSnackbar(true);
        setRefreshStatusInfo({ message: 'Completion state is unknown or a refresh is in progress.', severity: 'warning' });
        break;
      case 'Completed':
        setOpenStatusSnackbar(true);
        setRefreshStatusInfo({ message: 'Refresh completed successfully', severity: 'success' });
        break;
      case 'Failed':
        setOpenStatusSnackbar(true);
        setRefreshStatusInfo({ message: 'Refresh Dataset failed', severity: 'error' });
        break;
      case 'Disabled':
        setOpenStatusSnackbar(true);
        setRefreshStatusInfo({ message: 'Refresh disabled by selective refresh.', severity: 'info' });
        break;
      default:
        setOpenStatusSnackbar(true);
        setRefreshStatusInfo({ message: 'Unexpected refresh state', severity: 'error' });
    }
  }

  const handlePrintClick = () => {
    if (reportRef.current && reportRef.current.embed) {
      const report = reportRef.current.embed;
      report.print();
    }
  };

  const handleExportClick = () => {
    window.alert('Export is not available yet');
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRefreshDataset = () => {
    const bodyParameters = {
      TeamId: accTeam,
      ReportId: exisitngReportId,
    };

    dispatch({ type: FETCH_START });

    customAxios
      .post('EmbeddedAnalytics/RefreshDataset', JSON.stringify(bodyParameters), { headers: authHeader() })
      .then(data => {
        console.log(data, 'success');
        handleClose();
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const getFileOfExport = data => {
    customAxios
      .get(`EmbeddedAnalytics/GetFileOfExport?reportId=${data.reportId}&exportId=${data.id}`, {
        headers: authHeader(),
        responseType: 'arraybuffer',
      })
      .then(response => {
        console.log(response, 'success');
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        console.log(downloadUrl);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${data.reportName}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setExportingObj(null);
      })
      .catch(error => {
        console.error('Error downloading PDF:', error);
        dispatch({ type: FETCH_ERROR, payload: 'Failed to download PDF' });
      });
    handleClose();
  };

  const getDownloadStatus = data => {
    if (data) {
      customAxios
        .get(`EmbeddedAnalytics/GetFileExportStatus?reportId=${data.reportId}&exportId=${data.id}`, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data.status === 'Succeeded') {
            getFileOfExport(data);
            dispatch({ type: FETCH_SUCCESS, payload: 'File is ready to download' });
          } else {
            setTimeout(() => getDownloadStatus(data), 4000);
          }
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Invalid report credentials' });
        });
    }
  };

  const getPDFDownload = () => {
    const fileFormat = 'pdf';
    customAxios
      .get(
        `EmbeddedAnalytics/RequestReportFileExport?TeamId=${accTeam}&ReportId=${exisitngReportId}&fileFormat=${fileFormat}`,
        {
          headers: authHeader(),
        },
      )
      .then(({ data }) => {
        setExportingObj(data);
        getDownloadStatus(data);
        dispatch({ type: FETCH_SUCCESS, payload: 'Preparing your download' });
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Invalid report credentials' });
      });
    handleClose();
  };

  const getFormattedTime = (dateString, value) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${(date.getDate() < 10 ? '0' : '') + date.getDate()}-${(date.getMonth() + 1 < 10 ? '0' : '') +
        (date.getMonth() + 1)}-${date.getFullYear()} ${(date.getHours() < 10 ? '0' : '') +
        date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
      return `${value}  ${formattedDate}`;
    }
  };

  return (
    <div>
      <div className="">
        <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
          <div className="d-flex m-2 " style={{ gap: '10px' }}>
            <Button onClick={handleFullScreenModalClose}>
              <NavLink to={`/embedded-analytics/view-reports`}>
                <KeyboardBackspaceIcon sx={{ fontSize: 30, color: '#333333' }} />
              </NavLink>
            </Button>
            <IconButton onClick={handlePrintClick}>
              <LocalPrintshopIcon />
            </IconButton>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <IosShareIcon />
            </IconButton>
            <IconButton onClick={getRefreshDataset}>
              <RefreshIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem sx={menuStyles} onClick={getPDFDownload}>
                Export PDF
              </MenuItem>
            </Menu>
          </div>
          <div>
            <MenuItem sx={menuStyles}>{reportName}</MenuItem>
          </div>
          <div>
            <MenuItem sx={menuStyles}> {getFormattedTime(findStatus?.endTime, 'Last Update')}</MenuItem>
          </div>
        </div>
      </div>
      <PowerBIEmbed
        ref={reportRef}
        embedConfig={{
          type: 'report',
          id: embedReportId,
          embedUrl: embedUrl,
          accessToken: accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: { visible: false },
              pageNavigation: { visible: true },
            },
          },
        }}
        eventHandlers={
          new Map([
            [
              'loaded',
              function() {
                console.log('Report loaded');
              },
            ],
            [
              'rendered',
              function() {
                console.log('Report rendered');
              },
            ],
            [
              'error',
              function(event) {
                console.log(event.detail);
                if (event.detail.message === 'TokenExpired') {
                  fetchNewToken(exisitngReportId);
                }
              },
            ],
          ])
        }
        cssClassName={'report-style-class'}
      />
    </div>
  );
}
